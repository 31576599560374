import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  Box,
  Button,
  CardContent,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from '@mui/icons-material/Clear';
import ReactQuill from "react-quill";

// import { setPlannedHours, setNewTask } from "../../Slices/taskSlice";
import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import {
  taskCategoryNames,
  taskTypeOptions,
  taskStatusList,
  priorityArray,
} from "../Constant/constant";
import { getIcon } from "../ReusableComponents/UtilityFunctions";
import Loader from "../Loader/Loader";
import { errorMessage } from "../../Services/axiosInstance";
import { addTags, upsertTask } from "../../Services/TaskManageServices";
import { getProjectsName } from "../../Services/ProjectManageServices";
import { getEmployee } from "../../Services/EmployeeManageServices";
// import { getWorkFlowByProjectId } from "../../Services/WorkFlowServices";
import {
  LoadingBox,
  AssignByTextField,
  TitleBox,
  CloseBox,
  MainCard,
} from "../../Styles/TaskManagement/AddTaskFormStyle";
import { CLOUD_FUNCTIONS_ORIGIN } from "../../function-origin";

function AddTaskForm({ onClose, setChange, change, taskAdded, setTaskAdded }) {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const newTask = useSelector((state) => state.taskData.newTask);
  // const allData = useSelector((state) => state.taskData.employeesNames);
  const employeeCurrent = useSelector((state) => state.addEmployee.current);
  const adminData = useSelector((state) => state.adminData.adminData);
  const user = useSelector((state) => state.selectedActiveView.role);
  const taskPlannedHours = useSelector((state) => state.taskData.plannedHours);
  const currentEmployeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const [projects, setProjects] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({
    title: null,
    date: null,
    dueDate: null,
    category: null,
    type: null,
    project: null,
    priority: null,
    description: null,
    status: null,
    statusValue: null,
    plannedHours: null,
    actualHours: null,
    assignToName: null,
    tags: null,
  });
  const [displayMessageError, setDisplayMessageError] = useState("");
  const [formData, setFormData] = useState({
    title: newTask?.title || "",
    date: new Date().toISOString().split("T")[0],
    category: newTask?.category || "",
    type: newTask?.type || "",
    project: newTask?.project || "",
    priority: newTask?.priority || "",
    description: newTask?.description || "",
    status: newTask?.status || taskStatusList[0],
    statusColor: "",
    // status: newTask?.status || "",
    statusValue: newTask?.statusValue || 0,
    plannedHours: taskPlannedHours || "",
    assignToName: newTask?.assignToName || "",
    tags: newTask?.tags || "",
    dueDate: new Date().toISOString().split("T")[0],
  });
  const [assignEmpId, setAssignEmpId] = useState("");
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const today = new Date().toISOString().split("T")[0];

  const modules = {
    toolbar: [
      [
        { header: "1" },
        { header: "2" },
        { font: [] },
        { size: [] },
        { color: [] },
        { background: [] },
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "link",
        "code-block",
        { list: "ordered" },
        { list: "bullet" },
        // { indent: "-1" },
        { indent: "+1" },
        { script: "sub" },
        { script: "super" },
      ],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "color",
    "background",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "link",
    "code-block",
    "list",
    "bullet",
    "indent",
    "script",
  ];

  const setClose = () => {
    // dispatch(setPlannedHours(""));
    // dispatch(setNewTask([]));
    onClose();
  };
  function getuser() {
    if (currentEmployeeLogin) {
      return "user";
    } else if (adminLogin) {
      return "admin";
    }
  }

  function getName() {
    if (currentEmployeeLogin) {
      return employeeCurrent?.firstName;
    } else if (adminLogin) {
      return adminData?.firstName;
    }
  }

  function getEmpId() {
    if (currentEmployeeLogin) {
      return employeeCurrent?.empId;
    } else if (adminLogin) {
      return adminData?.empId;
    }
  }

  const name = getName();
  const currentEmployeeId = getEmpId();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setError((prevErrors) => ({
      ...prevErrors,
      [name]: null,
    }));

    setFormData((prevData) => {
      const newStatus =
        name === "statusValue"
          ? statusList.find((item) => item?._id === value)?.status
          : name === "status"
            ? value
            : prevData.status;
      const color =
        name === "statusValue"
          ? statusList.find((item) => item?._id === value)?.color
          : prevData.statusColor;
      return {
        ...prevData,
        [name]: value,
        status: newStatus,
        statusColor: color,
      };
    });
    if (name === "project") {
      const data = projects?.find((p) => p?._id === value && p?.workFlowId);
      if (data) {
        setStatusList(data?.statusData ?? []);
        setProjectId(data?._id);
      } else {
        setStatusList([]);
        setProjectId("");
      }
    }
  };

  const handleActualHoursChange = (event) => {
    let value = event.target.value;
    handleChange({ target: { name: event.target.name, value } });
    if (event.target.name === "plannedHours") {
      // dispatch(setPlannedHours(value));
      setFormData((prevData) => ({
        ...prevData,
        plannedHours: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = [];

    if (!formData.title) {
      errors.title = "Title is required";
    }

    if (!formData.category) {
      errors.category = "Task category is required";
    }
    if (!formData.type) {
      errors.type = "Task type is required";
    }
    if (!formData.project) {
      errors.project = "Project is required";
    }
    if (!formData.priority) {
      errors.priority = "Priority is required";
    }
    if (!formData.description) {
      errors.description = "Description is required";
    }
    if (!formData.status) {
      errors.status = "Status is required";
    }
    if (projectId && !formData.statusValue) {
      errors.statusValue = "Status is required";
    }
    if (!formData.tags || formData.tags.length === 0) {
      errors.tags = "At least one tag is required";
    }
    if (!formData.plannedHours) {
      errors.plannedHours = "Planned hours is required";
    } else if (
      formData.plannedHours === "00:00" ||
      formData.plannedHours === "0" ||
      formData.plannedHours === "0:00" ||
      formData.plannedHours === "00:0"
    ) {
      errors.plannedHours = "Planned hours can not be zero";
    } else {
      // Regular expression for validating time format "HH:MM"
      const timeRegex = /^(\d{1,}):[0-5][0-9]$/;
      // Regular expression for validating time format "H"
      const hourRegex = /^\d+$/;
      // If the input is just a single digit, convert it to HH:00 format
      if (hourRegex.test(formData.plannedHours)) {
        if (formData.plannedHours.length === 1) {
          formData.plannedHours = "0" + formData.plannedHours + ":00";
        } else {
          formData.plannedHours = formData.plannedHours + ":00";
        }
      } // If the input is in format "H:MM", convert it to HH:MM format
      else if (
        formData.plannedHours.indexOf(":") === 1 &&
        formData.plannedHours.split(":")[1].length !== 1
      ) {
        // If the input is in format "H:MM", convert it to HH:MM format
        formData.plannedHours = "0" + formData.plannedHours;
      } else if (formData.plannedHours.indexOf(":") === 3) {
        if (formData.plannedHours.charAt(0) === "0") {
          formData.plannedHours = formData.plannedHours.substring(1);
        }
      } else if (
        !timeRegex.test(formData.plannedHours) ||
        formData.plannedHours.split(":")[1].length === 1
      ) {
        errors.plannedHours =
          "Planned hours should be in format HH:MM (e.g., 00:00, 23:00, 23:34)";
      }
    }

    if (!formData.date) {
      errors.date = "Date is required";
    } else {
      const inputDate = new Date(formData.date);
      const currentDate = new Date();
      if (
        inputDate.getDate() < currentDate.getDate() ||
        inputDate.getMonth() < currentDate.getMonth() ||
        inputDate.getFullYear() < currentDate.getFullYear()
      ) {
        errors.date = "Date must not be older than today";
      }
    }
    if (!formData.dueDate) {
      errors.dueDate = "Due-date is required";
    } else {
      const inputDate = new Date(formData.dueDate);
      const currentDate = new Date(formData.date);
      if (
        !(
          ((inputDate.getDate() > currentDate.getDate() ||
            inputDate.getDate() === currentDate.getDate()) &&
            (inputDate.getMonth() > currentDate.getMonth() ||
              inputDate.getMonth() === currentDate.getMonth()) &&
            (inputDate.getFullYear() > currentDate.getFullYear() ||
              inputDate.getFullYear() === currentDate.getFullYear())) ||
          (inputDate.getDate() < currentDate.getDate() &&
            inputDate.getMonth() > currentDate.getMonth() &&
            (inputDate.getFullYear() > currentDate.getFullYear() ||
              inputDate.getFullYear() === currentDate.getFullYear()))
        )
      ) {
        errors.dueDate = "Due-date should set for a time after task start date";
      }
    }
    setError({
      title: errors.title,
      date: errors.date,
      dueDate: errors.dueDate,
      category: errors.category,
      type: errors.type,
      project: errors.project,
      priority: errors.priority,
      description: errors.description,
      status: errors.status,
      statusValue: errors.statusValue,
      plannedHours: errors.plannedHours,
      actualHours: errors.actualHours,
      assignToName: errors.assignToName,
      tags: errors.tags,
    });
    const noErrors = Object.keys(errors).length === 0;
    if (noErrors) {
      setIsLoading(true);
      try {
        setDisplayMessageError("");
        const getUser = getuser();
        const postData = JSON.stringify({
          formData,
          currentEmployeeId,
          assignEmpId,
        });
        const response = await upsertTask(getUser, postData);
        if (response.status === 200 || response.status === 201) {
          setTaskAdded(!taskAdded);
          // dispatch(setPlannedHours(""));
          // dispatch(setNewTask([]));
          onClose();
          if (change === true) {
            setChange(false);
          } else {
            setChange(true);
          }
          setIsLoading(false);
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Task added."));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setIsLoading(false);
        setDisplayMessageError(errorMessage ?? "");
      }
    }
    return errors;
  };

  const handleSelect = (event) => {
    setError((prevErrors) => ({
      ...prevErrors,
      assignToName: null,
    }));
    setFormData((prevFormData) => ({
      ...prevFormData,
      assignToName: event.target.value,
    }));
    if (event.target.value) {
      const selectedEmployee = employeeList?.find(
        (employee) => employee.firstName === event.target.value
      );
      if (selectedEmployee) {
        setAssignEmpId(selectedEmployee.empId);
      }
    }
  };

  const handleAddTag = async (tag) => {
    const trimmedTag = tag.trim();

    if (!trimmedTag) {
      setError({ tags: "This tag already exists" });
      return;
    }

    if (tags.some((t) => t.title === trimmedTag)) {
      setInputValue('');
      setError({ tags: "This tag already exists" });
      return;
    }

    try {
      const existingTag = options.find((t) => t.title === trimmedTag);
      if (existingTag) {
        const updatedTags = [...tags, existingTag];
        setTags(updatedTags);
        setFormData((prevData) => ({
          ...prevData,
          tags: updatedTags.map(t => t._id),
        }));
        setInputValue('');
        setError({ tags: null });
        return;
      }

      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
      const postData = JSON.stringify({ title: trimmedTag, color: randomColor })
      const response = await addTags(postData);
      if (response.status === 200 || response.status === 201) {
        const newTag = await response.data;
        const updatedTags = [...tags, newTag];
        setTags(updatedTags);
        setFormData((prevData) => ({
          ...prevData,
          tags: updatedTags.map(t => t._id),
        }));
        setInputValue('');
        setError({ tags: null });
        setOptions((prevOptions) => [...prevOptions, newTag]);
        return;
      } else if (response.status === 400) {
        setError({ tags: "Invalid tag submission" });
      } else {
        setError('Failed to add tag');
      }
    } catch (error) {
      setError({ tags: "Error adding tag to the database" });
    }
  };

  const handleDeleteTag = (tagToDelete) => () => {
    const updatedTags = tags.filter((tag) => tag.title !== tagToDelete.title);
    setTags(updatedTags);
    setFormData((prevData) => ({
      ...prevData,
      tags: updatedTags,
    }));
  };

  const handleDescriptionChange = (content, delta, source, editor) => {
    const htmlValue = editor.getHTML().trim();
    if (htmlValue === "<p><br></p>") {
      setFormData((prevData) => ({
        ...prevData,
        description: "",
      }));
      setError((prevErrors) => ({
        ...prevErrors,
        description: "Description is required",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        description: htmlValue,
      }));
      setError((prevErrors) => ({
        ...prevErrors,
        description: null,
      }));
    }
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(
          `${CLOUD_FUNCTIONS_ORIGIN}/admin/getTags`
        );
        const fetchedTags = response.data.map((tag) => ({
          _id: tag._id,
          title: tag.title,
          color: tag.color,
        }));
        setOptions(fetchedTags);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchTags();
  }, []);

  useEffect(() => {
    const fetchProjectName = async () => {
      try {
        const response = await getProjectsName();
        if (response.status === 200) {
          setProjects(response.data.projects);
        } else {
          setProjects([]);
        }
      } catch (error) {
        console.error("Error fetching project names:", error);
      }
    };
    fetchProjectName();
  }, []);

  useEffect(() => {
    const fetchEmployeeName = async () => {
      try {
        const response = await getEmployee();
        if (response.status === 200 || response.status === 201) {
          setEmployeeList(response.data.namesData);
        } else {
          setEmployeeList([]);
        }
      } catch (error) { }
    };
    fetchEmployeeName();
  }, []);

  return (
    <Box sx={{ padding: "22px 4px 8px 10px", height: "container" }}>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <>
          <TitleBox>
            {/* Box for items on the left */}
            <Box sx={{ padding: "5px 0px 5px 18px" }}>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Add Task
              </Typography>
            </Box>
            <CloseBox>
              <IconButton onClick={onClose}>
                <CloseIcon color="primary" />
              </IconButton>
            </CloseBox>
          </TitleBox>
          <Box sx={{ padding: "8px 4px 8px 10px", marginRight: "8px" }}>
            <MainCard>
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <AssignByTextField
                        fullWidth
                        disabled
                        InputProps={{
                          startAdornment: <InputAdornment position="start" />,
                        }}
                        sx={{
                          ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: "var(--secondary-text-color)",
                          },
                          svg: {
                            fill: "var(--secondary-text-color)",
                          },
                        }}
                        value={name}
                        label="Assign By"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        select
                        label="Assign To"
                        name="assignToName"
                        value={formData.assignToName}
                        onChange={(event, newValue) => handleSelect(event)}
                        multiline
                        variant="outlined"
                      >
                        {employeeList?.map((option) => (
                          <MenuItem
                            key={option.firstName}
                            value={option.firstName}
                          >
                            {option.firstName}
                          </MenuItem>
                        ))}
                      </TextField>

                      <Typography color="error">
                        {error.assignToName}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start" />,
                        }}
                        label="Task Title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        variant="outlined"
                      />
                      <Typography color="error">{error.title}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        sx={{
                          '& input[type="date"]::-webkit-calendar-picker-indicator':
                          {
                            filter: isDarkTheme ? "invert(1)" : "none",
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* Add your icon here if needed */}
                            </InputAdornment>
                          ),
                        }}
                        label="Task date"
                        name="date"
                        type="date"
                        value={formData.date}
                        onChange={handleChange}
                        variant="outlined"
                        inputProps={{
                          min: today, // Disable past dates
                        }}
                      />

                      <Typography color="error">{error.date}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        InputProps={{
                          startAdornment: <InputAdornment position="start" />,
                        }}
                        sx={{
                          '& input[type="date"]::-webkit-calendar-picker-indicator':
                          {
                            filter: isDarkTheme ? "invert(1)" : "none",
                          },
                        }}
                        label="Task dueDate"
                        name="dueDate"
                        type="date"
                        value={formData.dueDate}
                        onChange={handleChange}
                        variant="outlined"
                        inputProps={{
                          min: today, // Disable past dates
                        }}
                      />

                      <Typography color="error">{error.dueDate}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        select
                        label="Task Category"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                        multiline
                        variant="outlined"
                      >
                        {taskCategoryNames?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography color="error">{error.category}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        label="Task Type"
                        name="type"
                        value={formData.type}
                        onChange={handleChange}
                        variant="outlined"
                      >
                        {taskTypeOptions?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography color="error">{error.type}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        label="Project"
                        name="project"
                        value={formData.project}
                        onChange={handleChange}
                        variant="outlined"
                      >
                        {projects?.map((option) => (
                          <MenuItem key={option?._id} value={option?._id}>
                            {option?.project}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography color="error">{error.project}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        select
                        fullWidth
                        label="Priority"
                        name="priority"
                        value={formData.priority}
                        onChange={handleChange}
                        variant="outlined"
                      >
                        {priorityArray?.map((option) => (
                          <MenuItem key={option} value={option}>
                            <Box sx={{ display: "flex", height: "23px" }}>
                              <Typography sx={{ marginRight: "8px" }}>
                                {getIcon(option)}
                              </Typography>
                              <Typography>{option}</Typography>
                            </Box>
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography color="error">{error.priority}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Planned Hours"
                        name="plannedHours"
                        value={formData.plannedHours}
                        onChange={handleActualHoursChange}
                        variant="outlined"
                      ></TextField>
                      <Typography color="error">
                        {error.plannedHours}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        select
                        label="Status"
                        name={projectId ? "statusValue" : "status"}
                        value={
                          projectId ? formData.statusValue : formData?.status
                        }
                        onChange={handleChange}
                        variant="outlined"
                      >
                        {projectId
                          ? statusList?.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.status}
                            </MenuItem>
                          ))
                          : taskStatusList?.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                      </TextField>
                      <Typography color="error">
                        {projectId ? error.statusValue : error.status}
                      </Typography>
                      {/* <TextField
                        fullWidth
                        select
                        label="Status"
                        name="status"
                        value={formData.status}
                        onChange={handleChange}
                        variant="outlined"
                      >
                        {taskStatusList?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                      <Typography color="error">{error.status}</Typography> */}
                    </Grid>
                    <Grid item xs={6}>
                      <Autocomplete
                        freeSolo
                        options={options?.map((option) => option?.title) || []}
                        value={null}
                        inputValue={inputValue}
                        onInputChange={(event, newValue) => {
                          setInputValue(newValue);
                        }}
                        onChange={(event, newValue) => {
                          if (
                            newValue &&
                            !tags.some((t) => t.title === newValue)
                          ) {
                            handleAddTag(newValue);
                          }
                        }}
                        clearIcon={inputValue && <ClearIcon />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Tags"
                            variant="outlined"
                            placeholder="Press enter to add tags"
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                handleAddTag(inputValue);
                              }
                            }}
                          />
                        )}
                      />
                      {error.tags && (
                        <Typography color="error">
                          {error.tags}
                        </Typography>
                      )}
                      <Box mt={2}>
                        {tags.map((tag, index) => (
                          <Chip
                            key={index}
                            label={tag.title}
                            onDelete={handleDeleteTag(tag)}
                            // deleteIcon={
                            //   <IconButton style={{ color: 'white', size:"10px" }}>
                            //     <CloseIcon />
                            //   </IconButton>
                            // }
                            style={{
                              margin: "4px",
                              backgroundColor: tag.color,
                              color: "#fff",
                              height: "25px",
                              fontSize: "12px",
                            }}
                          />
                        ))}
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      {/* <TextField
                        fullWidth
                        label="Description"
                        name="description"
                        value={formData.description}
                        multiline
                        rows={4}
                        onChange={handleChange}
                        variant="outlined"
                      /> */}
                      <ReactQuill
                        className={isDarkTheme ? "ReactQuillDark" : ""}
                        style={{
                          color: isDarkTheme ? "#e0e0e0" : "#5e6e82",
                          flex: 1,
                          // ".ql-snow .ql-fill": {
                          //   fill: "red !important",
                          // },
                          ".qlSnow .qlFill": {
                            fill: "red !important",
                          },
                        }}
                        theme="snow"
                        value={formData.description}
                        onChange={handleDescriptionChange}
                        // onChange={(content, delta, source, editor) => {
                        //   console.log('editor:', editor.getHTML());
                        //   setFormData((prevData) => ({
                        //     ...prevData,
                        //     "description": editor.getHTML(),
                        //   }));
                        //   setError((prevErrors) => ({
                        //     ...prevErrors,
                        //     "description": null,
                        //   }));
                        //   // dispatch(setNewTask(formData));
                        // }}
                        modules={modules}
                        formats={formats}
                        placeholder="Description"
                      />
                      <Typography color="error">{error.description}</Typography>
                    </Grid>
                    {/* Add more Grid items for other form fields */}
                  </Grid>
                  <Box
                    mt={2}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Button
                      onClick={setClose}
                      sx={{ ml: 2, marginRight: "10px" }}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" variant="contained" color="primary">
                      Add Task
                    </Button>
                  </Box>
                </form>
                <Box mt={2}>
                  <Typography sx={{ color: "red" }}>
                    {displayMessageError}
                  </Typography>
                </Box>
              </CardContent>
            </MainCard>
          </Box>
        </>
      )}
    </Box>
  );
}

export default AddTaskForm;
