import { Box, Button, styled } from "@mui/material"

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "73vh"
}))

export const ProfileMainBox = styled(Box)(() => ({
    marginTop: "0px",
    padding: "0px 20px 5px 12px",
    overflowY: "auto",
    height: "cal(100-29vh)",
    '@media (max-width: 600px)': {
        padding: "0px",
    },
}))

export const MainBoxProfile = styled(Box)(() => ({
    padding: "20px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    borderRadius: "5px",
    width: "100%",
    alignItems: "center",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const PersonalDetailsButton = styled(Button)(({ dashboardprofilebuttons, selectedtab }) => ({
    ...dashboardprofilebuttons,
    backgroundColor:
        selectedtab === "personal"
            ? "var(--primary-highlight-color)"
            : "var(--background-table-sidebar-card-color)",
    color:
        selectedtab === "personal"
            ? "var(--primary-color)"
            : "var(--primary-text-color)",
    fontWeight:
        selectedtab === "personal" ? "bold" : "normal",
    marginTop: "0px",
}))

export const ContactDetailsButton = styled(Button)(({ dashboardprofilebuttons, selectedtab }) => ({
    ...dashboardprofilebuttons,
    backgroundColor:
        selectedtab === "contact"
            ? "var(--primary-highlight-color)"
            : "var(--background-table-sidebar-card-color)",
    color:
        selectedtab === "contact"
            ? "var(--primary-color)"
            : "var(--primary-text-color)",
    fontWeight:
        selectedtab === "contact" ? "bold" : "normal",
    border: "none",
}))

export const EducationQualificationButton = styled(Button)(({ dashboardprofilebuttons, selectedtab }) => ({
    ...dashboardprofilebuttons,
    backgroundColor:
        selectedtab === "education"
            ? "var(--primary-highlight-color)"
            : "var(--background-table-sidebar-card-color)",
    color:
        selectedtab === "education"
            ? "var(--primary-color)"
            : "var(--primary-text-color)",
    fontWeight:
        selectedtab === "education" ? "bold" : "normal",
    border: "none",
}))

export const FamilyDetailsButton = styled(Button)(({ dashboardprofilebuttons, selectedtab }) => ({
    ...dashboardprofilebuttons,
    backgroundColor:
        selectedtab === "family"
            ? "var(--primary-highlight-color)"
            : "var(--background-table-sidebar-card-color)",
    color:
        selectedtab === "family"
            ? "var(--primary-color)"
            : "var(--primary-text-color)",
    fontWeight:
        selectedtab === "family" ? "bold" : "normal",
    border: "none",
}))

export const ExperienceDetailsButton = styled(Button)(({ dashboardprofilebuttons, selectedtab }) => ({
    ...dashboardprofilebuttons,
    backgroundColor:
        selectedtab === "experience"
            ? "var(--primary-highlight-color)"
            : "var(--background-table-sidebar-card-color)",
    color:
        selectedtab === "experience"
            ? "var(--primary-color)"
            : "var(--primary-text-color)",
    fontWeight:
        selectedtab === "experience" ? "bold" : "normal",
    border: "none",
}))


export const FinancialDetailsButton = styled(Button)(({ dashboardprofilebuttons, selectedtab }) => ({
    ...dashboardprofilebuttons,
    backgroundColor:
        selectedtab === "financial"
            ? "var(--primary-highlight-color)"
            : "var(--background-table-sidebar-card-color)",
    color:
        selectedtab === "financial"
            ? "var(--primary-color)"
            : "var(--primary-text-color)",
    fontWeight:
        selectedtab === "financial" ? "bold" : "normal",
    border: "none",
}))

export const DangerZoneButton = styled(Button)(({ dashboardprofilebuttons, selectedtab }) => ({
    ...dashboardprofilebuttons,
    backgroundColor:
        selectedtab === "danger"
            ? "var(--primary-highlight-color)"
            : "var(--background-table-sidebar-card-color)",
    color:
        selectedtab === "danger"
            ? "var(--primary-color)"
            : "var(--primary-text-color)",
    fontWeight:
        selectedtab === "danger" ? "bold" : "normal",
    border: "none",
}))

export const AttendanceDetailsButton = styled(Button)(({ dashboardprofilebuttons, selectedtab }) => ({
    ...dashboardprofilebuttons,
    backgroundColor:
        selectedtab === "attendance"
            ? "var(--primary-highlight-color)"
            : "var(--background-table-sidebar-card-color)",
    color:
        selectedtab === "attendance"
            ? "var(--primary-color)"
            : "var(--primary-text-color)",
    fontWeight:
        selectedtab === "attendance" ? "bold" : "normal",
    border: "none",
}))

export const TaskEfficiencyButton = styled(Button)(({ dashboardprofilebuttons, selectedtab }) => ({
    ...dashboardprofilebuttons,
    backgroundColor:
        selectedtab === "efficiency"
            ? "var(--primary-highlight-color)"
            : "var(--background-table-sidebar-card-color)",
    color:
        selectedtab === "efficiency"
            ? "var(--primary-color)"
            : "var(--primary-text-color)",
    fontWeight:
        selectedtab === "efficiency" ? "bold" : "normal",
    border: "none",
}))

export const LeaveBalanceButton = styled(Button)(({ dashboardprofilebuttons, selectedtab }) => ({
    ...dashboardprofilebuttons,
    backgroundColor:
        selectedtab === "balance"
            ? "var(--primary-highlight-color)"
            : "var(--background-table-sidebar-card-color)",
    color:
        selectedtab === "balance"
            ? "var(--primary-color)"
            : "var(--primary-text-color)",
    fontWeight:
        selectedtab === "balance" ? "bold" : "normal",
    border: "none",
}))

export const RenderTabBox = styled(Box)(() => ({
    marginLeft: "30px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    borderRadius: "5px",
    width: "100%",
    padding: "15px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    color: "var(--primary-text-color)",
    '@media (max-width: 600px)': {
        padding: "15px",
        margin: "0px"
    },
}))
