import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";

function FilterChart({ weeklyData }) {
  const svgRef = useRef(null);
  useEffect(() => {
    const timeIntervals = {
      "9:00 - 10:00": 0,
      "10:00 - 11:00": 0,
      "11:00 - 18:00": 0,
    };
    weeklyData.forEach((dayData) => {
      dayData.forEach((employee) => {
        const startTime = Object.keys(timeIntervals).find(
          (interval) => employee.time === interval
        );
        if (startTime) {
          timeIntervals[startTime]++;
        }
      });
    });
    const data = Object.entries(timeIntervals).flatMap(
      ([timeInterval, count]) => {
        return weeklyData.map((dayData) => {
          const dayCount = dayData.filter(
            (employee) => employee.time === timeInterval
          ).length;
          return {
            day: dayData[0].day,
            timeInterval,
            count: dayCount,
          };
        });
      }
    );
    const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const handleResize = () => {
      const containerWidth = svgRef.current.parentNode.offsetWidth * 0.9;
      const svgWidth = containerWidth;
      const svgHeight = 300;
      const margin = { top: 30, right: 10, bottom: 20, left: 50 };
      const width = svgWidth - margin.left - margin.right;
      const height = svgHeight - margin.top - margin.bottom;
      const svg = d3
        .select(svgRef.current)
        .attr("width", svgWidth)
        .attr("height", svgHeight);
      // const tooltip = d3
      //   .select("#svgChartDivRef")
      //   .append("div")
      //   .attr("class", "tooltip")
      //   .style("opacity", 0)
      //   .style("background-color", "white")
      //   .style("border", "solid")
      //   .style("border-width", "2px")
      //   .style("border-radius", "5px")
      //   .style("padding", "5px");
      const tooltip = d3
        .select(".tooltip2")
        // .select(`#svgChartDivRef`)
        // .append("div")
        .style("opacity", 0)
        // .attr("class", "tooltip")
        .style("background-color", "white")
        .style("border", "solid")
        .style("border-width", "1px")
        .style("border-radius", "5px")
        .style("padding", "10px")
        .style("position", "absolute")
        .style("border", "0")
        .style("left", 0)
        .style("top", 0)
        .style("box-shadow", "rgba(0, 0, 0, 0.24) 0px 3px 8px")
        .style("width", "max-content");
      const xScale = d3
        .scaleBand()
        .domain(
          days.flatMap((day) =>
            ["9:00 - 10:00", "10:00 - 11:00", "11:00 - 18:00"].map(
              (interval) => `${day}-${interval}`
            )
          )
        )
        .range([margin.left, width + margin.left]);
      const yScale = d3
        .scaleLinear()
        .domain([0, d3.max(data, (d) => d.count)])
        .range([height, 0]);
      const xAxis = d3
        .axisBottom(xScale)
        .tickSize(0)
        .tickPadding(8)
        .tickValues(days.map((day) => `${day}-${"9:00 - 10:00"}`))
        .tickFormat((d) => d.split("-")[0]);
      const yAxis = d3
        .axisLeft(yScale)
        .ticks(d3.max(data, (d) => d.count))
        .tickValues(d3.range(0, d3.max(data, (d) => d.count) + 1));
      svg.selectAll("*").remove();
      svg
        .append("text")
        .attr("class", "axis-label")
        .attr(
          "transform",
          `translate(${width / 2 + margin.left},${height + margin.top + 40})`
        )
        .style("text-anchor", "middle")
        .style("font-size", "12px")
        .text("Days");
      // Append Y-axis label
      svg
        .append("text")
        .attr("class", "axis-label")
        .attr("transform", "rotate(-90)")
        .attr("y", margin.left - 30)
        .attr("x", 0 - (height / 2 + 20))
        .style("text-anchor", "middle")
        .style("font-size", "12px")
        .text("Number of Employees");
      const xAxisGroup = svg
        .append("g")
        .attr("transform", `translate(0, ${height + margin.top})`)
        .call(xAxis)
        .style("color", "var(--secondary-text-color)")
        .style("stroke-width", "0.8px");
      xAxisGroup
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", xScale.bandwidth() * 1.4)
        .style("stroke", "var(--secondary-text-color)")
        .style("color", "var(--secondary-text-color)")
        .style("font-size", "12px")
        .style("stroke-width", "0.8px")
        .style("font-weight", "lighter")
        .style("letter-spacing", "1px");
      svg
        .append("g")
        .attr("transform", `translate(${margin.left}, ${margin.top})`)
        .call(yAxis)
        .style("stroke", "var(--secondary-text-color)")
        .style("color", "var(--secondary-text-color)")
        .style("font-size", "12px")
        .style("stroke-width", "0.8px")
        .style("font-weight", "lighter");
      svg
        .selectAll(".bar")
        .data(data)
        .enter()
        .append("rect")
        .attr("class", "bar")
        .attr("x", (d) => {
          const currentDay = d.day;
          const currentTimeInterval = d.timeInterval;
          const currentX = xScale(`${currentDay}-${currentTimeInterval}`);
          if (currentTimeInterval === "9:00 - 10:00") {
            return currentX + 27; // Adjust this value to set the space between bars of the same day
          } else if (currentTimeInterval === "10:00 - 11:00") {
            return currentX + 13; // Adjust this value to set the space between bars of the same day
          } else if (currentTimeInterval === "11:00 - 18:00") {
            return currentX - 1; // Adjust this value to set the space between bars of the same day
          }
        })
        .attr("y", (d) => yScale(d.count) + margin.top)
        .attr("width", xScale.bandwidth() - 15)
        .attr("height", (d) => height - yScale(d.count))
        .attr("fill", (d) => {
          switch (d.timeInterval) {
            case "9:00 - 10:00":
              return "var(--approved-color)";
            case "10:00 - 11:00":
              return "var(--totalHrs-color)";
            case "11:00 - 18:00":
              return "var(--late-owl-point-color)";
            default:
              return "black";
          }
        })
        .on("mouseover", function (event, d) {
          d3.select(this).style("cursor", "pointer");
          const dateDetails = weeklyData.flatMap((dayData) =>
            dayData.filter(
              (employee) =>
                employee.day === d.day && employee.time === d.timeInterval
            )
          );
          const employeeDetails = weeklyData
            .flatMap((dayData) =>
              dayData.filter(
                (employee) =>
                  employee.day === d.day && employee.time === d.timeInterval
              )
            )
            .sort((a, b) => {
              if (a.daysData === "absent" && b.daysData !== "absent") {
                return 1;
              }
              if (a.daysData !== "absent" && b.daysData === "absent") {
                return -1;
              }
              return 0;
            })
            .map((employee) => `${employee.name} - ${employee.daysData}`);
          const tooltipContent = `<strong>${d.day} - ${dateDetails[0].date
            }</strong><br/><br/>${employeeDetails.join("<br/>")}`;
          tooltip.html(tooltipContent).style("opacity", 1);
          // tooltip.transition().duration(200).style("opacity", 0.9);
          // .style("left", event.pageX + "px")
          // .style("top", event.pageY - 28 + "px");
        })
        .on("mousemove", function (event) {
          tooltip
            .style("left", d3.pointer(event)[0] + 470 + "px")
            .style("top", d3.pointer(event)[1] + 60 + "px");
        })
        .on("mouseout", function (d) {
          tooltip.style("opacity", 0);
          // tooltip.transition().duration(500).style("opacity", 0);
        });
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [weeklyData]);
  return (
    <Box
      id="svgChartDivRef"
      className="svgChartDivRef"
      sx={{ paddingLeft: "15px" }}
    >
      <svg
        id="employeeChart"
        ref={svgRef}
        style={{
          height: "360px",
          bottom: "2px",
        }}
      ></svg>
      <Box className="tooltip2"></Box>
    </Box>
  );
}
export default FilterChart;