import { useSelector } from 'react-redux';

const usePermissions = () => {
  const getRoleByPermission = useSelector((state) => state?.selectedActiveView?.getRoleByPermission);
  // const getPermissionLocal = JSON.parse(window.localStorage.getItem('getRoleByPermission'));

  const hasPermission = (pageName) => {
    // const getPermission = getRoleByPermission?.length > 0 ? getRoleByPermission : getPermissionLocal;
    const getPermission = getRoleByPermission;
    const permission = getPermission?.find((item) => item?.pageName === pageName);
    // Return true if permission exists and is allowed; false otherwise
    return !!permission;
  };

  return { hasPermission };
};

export default usePermissions;
