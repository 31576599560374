import axios from "axios";
import { CLOUD_FUNCTIONS_ORIGIN } from "../function-origin";

let currentMethod = '';
let errorMessage = '';

const axiosInstance = axios.create({
  baseURL: CLOUD_FUNCTIONS_ORIGIN,
  headers: {
    // "Content-Type": "application/json",
  },
});

// Add request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    currentMethod = config.method.toUpperCase();
    const token = window.localStorage.getItem("Token");
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    else {
      config.headers["Content-Type"] = "application/json"
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (currentMethod === 'GET') {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          window.localStorage.removeItem("Token");
          window.location.href = "/";
        } else if (error.response.status === 404 || error.response.status === 500 || error.response.status === 400) {
          console.log("Server Error:", error.response.data.message);
        }
      }
      else {
        console.log("Error:", error.message);
      }
    }
    else {
      if (error.response) {
        if (error.response.status === 400 || error.response.status === 500 || error.response.status === 409) {
          errorMessage = error.response.data.message;
        }
        else if (error.response.status === 404) {
          errorMessage = error.response.data.message ?? "Api Not Found.";
        }
        else if (error.response.status === 501 || error.response.status === 510) {
          errorMessage = error.response.data.message;
        }
        else if (error.response.status === 401 || error.response.status === 403) {
          window.localStorage.removeItem("Token");
          window.location.href = "/";
        }
      }
      else {
        errorMessage = error.message;
        console.log("Error:", errorMessage);
      }
    }
    return Promise.reject(error);
  }
);

export { axiosInstance, errorMessage };
