import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FooterMainBox, PrivacyPolicyBox } from "../../Styles/AdminDashboard/DashBoardStyle";

export const Footer = () => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  let copyright =
    "Copyright © Version:1.0.0 Jyoti Technosoft LLP, All Rights Reserved by our company.";
  let currentYear = new Date().getFullYear();
  let insertAfterCharacter = "©";
  let index = copyright.indexOf(insertAfterCharacter);
  if (index !== -1) {
    copyright =
      copyright.slice(0, index + 1) +
      " " +
      currentYear +
      copyright.slice(index + 1);
  }

  return (
    <FooterMainBox>
      <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a", fontSize: "15px" }}>
        {copyright}
      </Box>
      <PrivacyPolicyBox>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </PrivacyPolicyBox>
    </FooterMainBox>
  );
};
