import { Box, Button, Card, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(({ isdarktheme }) => ({
    margin: "8px",
    height: "65vh",
    width: "43vw",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82"
    // backgroundColor: "yellow",
}))

export const TaskBox = styled(Box)(({ isdarktheme }) => ({
    marginLeft: "35px",
    marginRight: "35px",
    backgroundColor: isdarktheme === "true" ? "#142840" : "#cccccc",
    paddingLeft: "5px",
    paddingRight: "5px",
    borderRadius: "5px",
}))

export const ProjectTypography = styled(Typography)(() => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    marginBottom: "5px",
}))


export const InnerBoxOne = styled(Box)(({ isdarktheme }) => ({
    background: isdarktheme === "true" ? "#0b1727" : "#ffffff",
    overflowX: "hidden",
    height: "container",
}))

export const CloseBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    marginTop: "5px",
    padding: "0px 10px 0px 0px",
}))

export const InnerBoxTwo = styled(Box)(({ isdarktheme }) => ({
    borderBottom: `3px solid ${isdarktheme === "true" ? "#142840" : "#e5e7eb"}`,
    borderTop: `2px solid ${isdarktheme === "true" ? "#142840" : "#e5e7eb"}`,
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    background: isdarktheme === "true" ? "#0b1727" : "#ffffff",
}))

export const MainCard = styled(Card)(({ isdarktheme }) => ({
    paddingLeft: "30px",
    background: isdarktheme === "true" ? "#0b1727" : "#ffffff",
}))

export const FirstNameBox = styled(Box)(({ isdarktheme }) => ({
    borderRadius: "50%",
    width: "90px",
    height: "90px",
    backgroundColor: isdarktheme === "true" ? "#F9629F" : "#FFC0CB",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "35px",
    color: isdarktheme === "true" ? "#142840" : "#5e6e82",
    border: `2px solid ${isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82"
        }`,
    boxSizing: "border-box",
}))

export const InnerBoxThree = styled(Box)(({ isdarktheme }) => ({
    padding: "10px 0px 0px 18px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const TitleTypography = styled(Typography)(() => ({
    fontWeight: "bold",
    fontSize: "16px"
}))

export const ValueTypography = styled(Typography)(() => ({
    padding: "4px 0px 0px 1px ",
    fontSize: "14px"
}))

export const RenderComponentBox = styled(Box)(({ isdarktheme }) => ({
    background: isdarktheme === "true" ? "#0b1727" : "#ffffff",
    overflow: "scroll",
}))

export const PendingButton = styled(Button)(({ isdarktheme, selectedbutton }) => ({
    borderRadius: "0px",
    color:
        selectedbutton === "Pending"
            ? `${isdarktheme === "true" ? "#52B6D7" : "#338BA8"}`
            : `${isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82"}`,
    borderBottom:
        selectedbutton === "Pending" ? "2px solid #338BA8" : "none",
    margin: "0px 5px 0px 5px",
}))

export const CompletedButton = styled(Button)(({ isdarktheme, selectedbutton }) => ({
    borderRadius: "0px",
    color:
        selectedbutton === "completed"
            ? `${isdarktheme === "true" ? "#52B6D7" : "#338BA8"}`
            : `${isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82"}`,
    borderBottom:
        selectedbutton === "completed" ? "2px solid #338BA8" : "none",
    margin: "0px 5px 0px 5px",
}))

export const CancelButton = styled(Button)(({ isdarktheme, selectedbutton }) => ({
    borderRadius: "0px",
    color:
        selectedbutton === "cancel"
            ? `${isdarktheme === "true" ? "#52B6D7" : "#338BA8"}`
            : `${isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82"}`,
    borderBottom:
        selectedbutton === "cancel" ? "2px solid #338BA8" : "none",
    margin: "0px 5px 0px 5px",
}))

