import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	Checkbox,
	TableBody,
	TableCell,
	TablePagination,
	TableRow,
} from "@mui/material";

import {
	empTaskTableHeadCell,
	empTaskTableBodyCell,
} from "../ReusableComponents/CustomDesignMUI/CustomMUI";
import { numberArray } from "../Constant/constant";
import { TableMainBox, TableContainers, CustomTableCells, Checkboxs, TableCells, Tables, TableHeads } from "../../Styles/CompanyAndLeavePolicy/LeaveAssignCardStyle";

function SelectEmployeeForLeaves(props) {
	const {
		employeeList,
		setDisplayError,
		setEmployeeSelectedForLeave,
		setEmployeeSelectedForLeaveEmpId,
		employeeSelectedPreviously,
	} = props;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
	const startIndex = page * rowsPerPage;
	const endIndex = startIndex + rowsPerPage;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const [selected, setSelected] = useState([]);
	const [selectedEmpId, setSelectedEmpId] = useState([]);

	// const handleSelectAllClick = () => {
	//   if (selected.length === employeeList.length) {
	//     setSelectedEmpId([]);
	//     setSelected([]);
	//   } else {
	//     const Ids = employeeList.map((data) => data._id);
	//     const empIds = employeeList.map((data) => data.empId);
	//     setSelectedEmpId(empIds);
	//     setSelected(Ids);
	//   }
	// };
	const handleSelectAllClick = () => {
		setDisplayError("");
		if (selected.length === employeeList.length) {
			setSelectedEmpId([]);
			setSelected([]);
		} else {
			const previouslySelectedIds = new Set(employeeSelectedPreviously);
			const Ids = employeeList
				.map((data) => data._id)
				.filter((id) => !previouslySelectedIds.has(id));
			const empIds = employeeList
				.map((data) => data.empId)
				.filter(
					(id, index) =>
						!previouslySelectedIds.has(employeeList[index]._id)
				);

			setSelectedEmpId(empIds);
			setSelected(Ids);
		}
	};

	// const handleSelectClick = (Id, empId) => {
	// 	setDisplayError("");
	// 	if (selected.includes(Id)) {
	// 		setSelected(selected.filter((id) => id !== Id));
	// 		setSelectedEmpId(selectedEmpId.filter((empId) => empId !== empId));
	// 	} else {
	// 		setSelected([...selected, Id]);
	// 		setSelectedEmpId([...selectedEmpId, empId]);
	// 	}
	// };

	const handleSelectClick = (Id, empIdValue) => {
		setDisplayError("");
		if (selected.includes(Id)) {
			setSelected(selected.filter((id) => id !== Id));
			setSelectedEmpId(selectedEmpId.filter((empId) => empId !== empIdValue));
		} else {
			setSelected([...selected, Id]);
			setSelectedEmpId([...selectedEmpId, empIdValue]);
		}
	};


	const isSelected = (Id) => selected.includes(Id);

	useEffect(() => {
		setEmployeeSelectedForLeave(selected);
		setEmployeeSelectedForLeaveEmpId(selectedEmpId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected]);

	return (
		<TableMainBox>
			<TableContainers>
				<Tables isdarktheme={isDarkTheme ? "true" : "false"}>
					<TableHeads>
						<TableRow sx={{ textDecoration: "none" }}>
							<TableCell padding="checkbox">
								<Checkbox
									sx={{ color: "#2c7be5" }}
									indeterminate={
										selected.length > 0 &&
										selected.length < employeeList.length
									}
									checked={
										selected.length === employeeList.length
									}
									onChange={handleSelectAllClick}
								/>
							</TableCell>
							<TableCells
								emptasktableheadcell={empTaskTableHeadCell}
								isdarktheme={isDarkTheme ? "true" : "false"}
							>
								EMP-ID
							</TableCells>
							<TableCells
								emptasktableheadcell={empTaskTableHeadCell}
								isdarktheme={isDarkTheme ? "true" : "false"}
							>
								Name
							</TableCells>
						</TableRow>
					</TableHeads>
					<TableBody>
						{employeeList
							?.slice(startIndex, endIndex)
							.map((data, index) => {
								const isItemSelected = isSelected(data._id);
								return (
									<TableRow
										key={data.empId}
										sx={{
											maxHeight: "20px",
											backgroundColor:
												index % 2 === 1
													? "var(--highlight-color)"
													: "",
											background: isDarkTheme
												? "#142840"
												: "#ffffff",
										}}
									>
										<TableCell padding="checkbox">
											<Checkboxs
												checked={
													employeeSelectedPreviously.includes(
														data._id
													) || isItemSelected
												}
												disabled={employeeSelectedPreviously.includes(
													data._id
												)}
												onChange={() =>
													handleSelectClick(
														data._id,
														data.empId
													)
												}
											/>
										</TableCell>
										<CustomTableCells
											emptasktablebodycell={empTaskTableBodyCell}
											isdarktheme={isDarkTheme ? "true" : "false"}
										>
											{data.empId}
										</CustomTableCells>
										<CustomTableCells
											emptasktablebodycell={empTaskTableBodyCell}
											isdarktheme={isDarkTheme ? "true" : "false"}
										>
											{data.firstName}
										</CustomTableCells>
									</TableRow>
								);
							})}
					</TableBody>
				</Tables>
				<TablePagination
					sx={{ background: isDarkTheme ? "#142840" : "#2c7be51a" }}
					rowsPerPageOptions={numberArray}
					component="div"
					count={employeeList?.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</TableContainers>
		</TableMainBox>
	);
}

export default SelectEmployeeForLeaves;

// import React, { useState, useEffect } from "react";
// import { DataGrid } from "@mui/x-data-grid";
// import { useSelector } from "react-redux";
// import { Box } from "@mui/material";
// import { numberArray } from "../Constant/constant";

// function SelectEmployeeForLeaves(props) {
//   const {
//     employeeList,
//     setEmployeeSelectedForLeave,
//     setEmployeeSelectedForLeaveEmpId,
//     employeeSelectedPreviously = [], // default to an empty array if not provided
//   } = props;

//   const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
//   const [selectionModel, setSelectionModel] = useState([]);

//   const employeeDetailsColumns = [
//     { field: "empId", headerName: "Emp ID", flex: 1 },
//     { field: "name", headerName: "Name", flex: 2 },
//   ];

//   const FormattedEmployeeDetails = employeeList?.map((empdata) => ({
//     id: empdata?._id,
//     empId: empdata?.empId,
//     name: empdata?.firstName,
//     disableCheckboxSelection: employeeSelectedPreviously.includes(empdata?._id),
//   }));

//   useEffect(() => {
//     const previouslySelectedIds = FormattedEmployeeDetails
//       .filter((emp) => emp.disableCheckboxSelection)
//       .map((emp) => emp.id);
//     setSelectionModel(previouslySelectedIds);
//     setEmployeeSelectedForLeave(previouslySelectedIds);
//     setEmployeeSelectedForLeaveEmpId(previouslySelectedIds);
//   }, [employeeList]);

//   const handleSelectionModelChange = (newSelectionModel) => {
//     console.log('newlySelected', newlySelected)
//     const newlySelected = newSelectionModel.filter(
//       (id) => !employeeSelectedPreviously.includes(id)
//     );
//     setSelectionModel(newlySelected);
//     setEmployeeSelectedForLeave(newlySelected);
//     setEmployeeSelectedForLeaveEmpId(newlySelected);
//   };

//   return (
//     <Box style={{ height: 400 }}>
//       <DataGrid
//         rows={FormattedEmployeeDetails}
//         columns={employeeDetailsColumns}
//         checkboxSelection
//         disableSelectionOnClick
//         selectionModel={selectionModel}
//         onSelectionModelChange={(newSelectionModel) => handleSelectionModelChange(newSelectionModel)}
//         pageSizeOptions={numberArray}
//         getRowId={(row) => row.id}
//         initialState={{
//           pagination: {
//             paginationModel: {
//               pageSize: parseInt(window.localStorage.getItem("paginationNo"), 10) || 10,
//             },
//           },
//         }}
//         isRowSelectable={(params) => !params.row.disableCheckboxSelection}
//         sx={{
//           "& .MuiDataGrid-cellCheckbox": {
//             color: isDarkTheme ? "#e0e0e0e3 !important" : " !important",
//           },
//           "& .MuiDataGrid-row:hover": {
//             backgroundColor: "none",
//           },
//           ".MuiCheckbox-root": {
//             color: 'var(--secondary-text-color)',
//           },
//         }}
//         componentsProps={{
//           checkbox: {
//             checked: (params) => selectionModel.includes(params.id),
//             disabled: (params) => params.row.disableCheckboxSelection,
//           },
//         }}
//       />
//     </Box>
//   );
// }

// export default SelectEmployeeForLeaves;
