import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showDialogBox: false,
    totalTime: null,
    totalBreakTime: null,
    isLoading: false,
    isPlaying: false,
    isRunning: false,
    isStart: true,
    isDefault: false,
    disableStart: false,
    showTicketDialogBox: false,
    elapsedTimes: 0,
    currentWorkingTicketNo: "",
    taskList: [],
    error: "",
};

const timerDetailsSlice = createSlice({
    name: "timerData",
    initialState,
    reducers: {
        setShowDialogBox: (state, action) => {
            state.showDialogBox = action.payload;
        },
        setTotalTime: (state, action) => {
            state.totalTime = action.payload;
        },
        setTotalBreakTime: (state, action) => {
            state.totalBreakTime = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsPlaying: (state, action) => {
            state.isPlaying = action.payload;
        },
        setIsRunning: (state, action) => {
            state.isRunning = action.payload;
        },
        setIsStart: (state, action) => {
            state.isStart = action.payload;
        },
        setIsDefault: (state, action) => {
            state.isDefault = action.payload;
        },
        setDisableStart: (state, action) => {
            state.disableStart = action.payload;
        },
        setShowTicketDialogBox: (state, action) => {
            state.showTicketDialogBox = action.payload;
        },
        setElapsedTimes: (state, action) => {
            state.elapsedTimes = action.payload;
        },
        setTaskList: (state, action) => {
            state.taskList = action.payload;
        },
        setCurrentWorkingTicketNo: (state, action) => {
            state.currentWorkingTicketNo = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        resetTimerData: (state) => {
            return initialState;
        },
    },
});

export const {
    setShowDialogBox,
    setTotalTime,
    setTotalBreakTime,
    setIsLoading,
    setIsPlaying,
    setIsRunning,
    setIsStart,
    setCurrentWorkingTicketNo,
    setTaskList,
    setIsDefault,
    setShowTicketDialogBox,
    setElapsedTimes,
    setDisableStart,
    setError,
    resetTimerData,
} = timerDetailsSlice.actions;

export default timerDetailsSlice.reducer;
