import React, { useState } from "react";
import { Box } from "@mui/material";
// import { PDFViewer } from 'react-view-pdf';
import PDFViewer from "../ReusableComponents/PDFViewer";
import UploadViewDialog from "./UploadViewDialog";
import {
  CompanyPolicyBox,
  MainBox,
  TitleTypography,
} from "../../Styles/CompanyAndLeavePolicy/ViewAssignLeaveBalanceStyle";
import "../../assets/styles/DataGridStyles.css";
const CompanyPolicyAccordian = () => {
  const [openUpload_ViewDialog, setOpenUpload_ViewDialog] = useState(false);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [downloadDialog, setDownloadDialog] = useState(false);
  const [upload_ViewDialogName, setUpload_ViewDialogName] = useState("");
  const [fileName, setFileName] = useState("");
  const pdfFile = "http://localhost:3000" + require("../../assets/pdf/company_general_rule_&_regulation.pdf");
  return (
    <Box>
      <CompanyPolicyBox>
        <MainBox>
          <TitleTypography>Company General Policy</TitleTypography>
        </MainBox>
        <Box>
          <PDFViewer fileUrl={pdfFile}/>
        </Box>
      </CompanyPolicyBox>
      <UploadViewDialog
        open={openUpload_ViewDialog}
        setOpenUpload_ViewDialog={setOpenUpload_ViewDialog}
        upload_ViewDialogName={upload_ViewDialogName}
        setUpload_ViewDialogName={setUpload_ViewDialogName}
        uploadDialog={uploadDialog}
        setUploadDialog={setUploadDialog}
        downloadDialog={downloadDialog}
        setDownloadDialog={setDownloadDialog}
        fileName={fileName}
        setFileName={setFileName}
      />
    </Box>
  );
};
export default CompanyPolicyAccordian;
