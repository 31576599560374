import { axiosInstance } from './axiosInstance';

export const getEmployeesName = () => axiosInstance.get('/admin/getEmployeeReportingName');

export const getAllEmployeeName = () => axiosInstance.get('/admin/getEmployeeName');

export const upsertPersonalContact = (postData) => axiosInstance.post("/admin/personalContact", postData);

export const upsertEducation = (postData) => axiosInstance.post("/admin/education", postData);

export const upsertExperience = (postData) => axiosInstance.post("/admin/experience", postData);

export const upsertFamily = (postData) => axiosInstance.post("/admin/family", postData);

export const upsertFinancial = (postData) => axiosInstance.post("/admin/financial", postData);

export const upsertJob = (postData) => axiosInstance.post("/admin/job", postData);

export const upsertContact = (postData) => axiosInstance.put("/admin/contact", postData);

export const getEmployee = () => axiosInstance.get('/user/getEmployeeName');

export const getEmployeeDetails = () => axiosInstance.get('/user/employeeDetail');

export const getEmpDetails = (employeeId) => axiosInstance.get(`/user/employeeDetail/${employeeId}`);

export const changeStatusEmployee = (employeeId) => axiosInstance.put(`/admin/deleteData`, employeeId);

export const getEmp = (name) => axiosInstance.get(`/user/employeeDetail/${name}`);

export const upsertCheckPassword = (postData) => axiosInstance.post("/user/checkPassword", postData);

export const upsertPersonal = (postData) => axiosInstance.put("/admin/personal", postData);

export const getSpecialization = () => axiosInstance.get('/user/getAllSpecializations');

export const addSpecialization = (postData) => axiosInstance.post('/user/addSpecializations', postData);
