import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
import {
  viewEducationBox,
  viewProfileTitle,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { setAllLeaveData } from "../../../Slices/leaveData";
import { setAllData } from "../../../Slices/UserDataSlice";
import {
  resetEmployeeDetail,
  setEmployeeAdded,
  set_financial,
} from "../../../Slices/employeeDetailSlice";
import ExitDialog from "../ExitDialog";
import { errorMessage } from "../../../Services/axiosInstance";
import { upsertFinancial } from "../../../Services/EmployeeManageServices";
import { MainBox, SaveButton, TitleBox, AddRecordBox, AddRecordButton, InputTextField } from "../../../Styles/UpdateEmployee/UpdateFinancialStyle";
import { FooterBox } from "../../../Styles/UpdateEmployee/UpdatePersonalStyle";
import { CancelButton } from "../../../Styles/UpdateEmployee/UpdateContactStyle.js";

const DisplayFinancial = (props) => {
  const { previousButtonCallback } = props;
  const employee_id = useSelector((state) => state.addEmployee.personal.id);
  const financial = useSelector((state) => state.addEmployee.financial);
  const dispatch = useDispatch();

  const [financialDetailArray, setFinancialDetailArray] = useState(
    financial ? financial : []
  );
  const navigate = useNavigate();
  const [financialDetailErrorsArray, setFinancialDetailErrorsArray] = useState(
    []
  );
  const [displayError, setDisplayError] = useState("");
  const [displaySuccess, setDisplaySuccess] = useState("");
  const [openExitDialog, setOpenExitDialog] = useState(false);

  const reset = () => {
    nullRecord();
  };

  const handleCloseCancelDialog = async () => {
    setOpenExitDialog(false);
  };

  const nullRecord = () => {
    setFinancialDetailArray(financial || []);
    setFinancialDetailErrorsArray([]);
  };

  const handleInputChange1 = (index, event) => {
    const { name, value } = event.target;
    setFinancialDetailArray((prevArray) =>
      prevArray?.map((record, i) =>
        i === index ? { ...record, [name]: value } : record
      )
    );
  };

  const removeRecord = (index) => {
    setFinancialDetailArray((prevArray) =>
      prevArray.filter((_, i) => i !== index)
    );
    setFinancialDetailErrorsArray((prevErrorsArray) =>
      prevErrorsArray.filter((_, i) => i !== index)
    );
  };

  const getEmptyFinancialRecord = () => ({
    Bank_Name: "",
    Account_Number: "",
    Holder_Name: "",
    Ifsc_Code: "",
    Branch_Name: "",
  });

  const getEmptyFinancialErrors = () => ({
    Bank_Name: "",
    Account_Number: "",
    Holder_Name: "",
    Ifsc_Code: "",
    Branch_Name: "",
  });

  const addRecord = () => {
    if (validateAllForms()) {
      setFinancialDetailArray((prevArray) => [
        ...prevArray,
        getEmptyFinancialRecord(),
      ]);
      setFinancialDetailErrorsArray((prevErrorsArray) => [
        ...prevErrorsArray,
        getEmptyFinancialErrors(),
      ]);
    }
  };

  const validateAllForms = () => {
    const allErrors = financialDetailArray?.map((record, index) =>
      validateForm(index)
    );
    setFinancialDetailErrorsArray(allErrors);
    return allErrors.every((errors) =>
      Object.values(errors).every((error) => !error)
    );
  };

  const validateForm = (index) => {
    const record = financialDetailArray[index];
    const errors = getEmptyFinancialErrors();
    if (!record.Bank_Name) {
      errors.Bank_Name = "Bank name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(record.Bank_Name)) {
      errors.Bank_Name = "Bank name should not contain numeric values";
    }
    if (!record.Account_Number) {
      errors.Account_Number = "Account no is required";
    } else if (!/^\d+$/.test(record.Account_Number)) {
      errors.Account_Number = "Account no. should contain only numeric values";
    }

    if (!record.Holder_Name) {
      errors.Holder_Name = "Account holder name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(record.Holder_Name)) {
      errors.Holder_Name =
        "Account holder name should not contain numeric values";
    }

    if (!record.Ifsc_Code) {
      errors.Ifsc_Code = "IFSC code is required";
    }
    if (!record.Branch_Name) {
      errors.Branch_Name = "Branch name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(record.Branch_Name)) {
      errors.Branch_Name = "Bank branch name should not contain numeric values";
    }
    return errors;
  };

  const save = async () => {
    setDisplayError("");
    if (displaySuccess === "employee added.") {
    } else {
      try {
        // const response = await axios.post(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/financial`,
        //   JSON.stringify({ employee_id, financialDetailArray }),
        //   postHeader
        // );
        const postData = JSON.stringify({ employee_id, financialDetailArray });
        const response = await upsertFinancial(postData);
        if (response.status === 200) {
          dispatch(setAllData(response.data.user));
          dispatch(setAllLeaveData(response.data.leave));
          dispatch(set_financial(financialDetailArray));
          setDisplaySuccess("")
          navigate("/dashboard");
          dispatch(resetEmployeeDetail());
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Employee data updated successfully."));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  return (
    <MainBox>
      <TitleBox>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Financial Details
          </Typography>
        </Box>
      </TitleBox>
      <hr />
      <AddRecordBox>
        <Typography sx={{ ...viewProfileTitle }}>Financial Records</Typography>
        <AddRecordButton
          variant="outlined"
          onClick={() => addRecord()}
        >
          + Add Record
        </AddRecordButton>
      </AddRecordBox>
      <Box>
        {financialDetailArray?.map((record, index) => (
          <Box key={index} sx={viewEducationBox}>
            <Box sx={{ marginBottom: "15px" }}>
              <InputTextField
                name="Bank_Name"
                label="Bank Name"
                value={financialDetailArray[index].Bank_Name}
                onChange={(event) => handleInputChange1(index, event)}
              />
              {financialDetailErrorsArray[index]?.Bank_Name && (
                <Typography color="error">
                  {financialDetailErrorsArray[index].Bank_Name}
                </Typography>
              )}
              <InputTextField
                name="Account_Number"
                value={financialDetailArray[index].Account_Number}
                label="Account Number"
                onChange={(event) => handleInputChange1(index, event)}
              />
              {financialDetailErrorsArray[index]?.Account_Number && (
                <Typography color="error">
                  {financialDetailErrorsArray[index].Account_Number}
                </Typography>
              )}
              <InputTextField
                name="Holder_Name"
                value={financialDetailArray[index].Holder_Name}
                label="Holders Name"
                onChange={(event) => handleInputChange1(index, event)}
              />
              {financialDetailErrorsArray[index]?.Holder_Name && (
                <Typography color="error">
                  {financialDetailErrorsArray[index].Holder_Name}
                </Typography>
              )}
              <InputTextField
                name="Ifsc_Code"
                value={financialDetailArray[index].Ifsc_Code}
                label="IFSC code"
                onChange={(event) => handleInputChange1(index, event)}
              />
              {financialDetailErrorsArray[index]?.Ifsc_Code && (
                <Typography color="error">
                  {financialDetailErrorsArray[index].Ifsc_Code}
                </Typography>
              )}
              <InputTextField
                name="Branch_Name"
                value={financialDetailArray[index].Branch_Name}
                label="Branch Name"
                onChange={(event) => handleInputChange1(index, event)}
              />
              {financialDetailErrorsArray[index]?.Branch_Name && (
                <Typography color="error">
                  {financialDetailErrorsArray[index].Branch_Name}
                </Typography>
              )}
            </Box>
            {/* Remove button for record */}
            <Button
              variant="outlined"
              color="error"
              sx={{ fontWeight: "bold" }}
              onClick={() => removeRecord(index)}
            >
              Remove
            </Button>
          </Box>
        ))}
      </Box>
      <Typography sx={{ color: "red", marginTop: "20px" }}>
        {displayError}
      </Typography>
      <Typography sx={{ color: "green", marginTop: "20px" }}>
        {displaySuccess}
      </Typography>
      <Box>
        <FooterBox>
          <Button
            sx={{
              fontWeight: "bold",
              color: "gray",
            }}
            onClick={() => {
              previousButtonCallback();
            }}
          >
            Back
          </Button>
          <CancelButton
            onClick={() => {
              setOpenExitDialog(true);
              dispatch(setEmployeeAdded(""));
            }}
          >
            Cancel
          </CancelButton>
          <ExitDialog
            open={openExitDialog}
            reset={reset}
            onClose={handleCloseCancelDialog}
          />
          <SaveButton
            variant="contained"
            onClick={() => {
              if (validateAllForms()) {
                save();
                dispatch(setEmployeeAdded(""));
              }
            }}
          >
            Save
          </SaveButton>
        </FooterBox>
      </Box>
    </MainBox>
  );
};

export default DisplayFinancial;
