import { axiosInstance } from './axiosInstance';

export const getAdminFetchData = (selectedStartdate) => axiosInstance.get(`/admin/fetchAttendence/${selectedStartdate}`);

export const getWorkingDaysInThisMonths = (startDateFormatted) => axiosInstance.get(`/admin/getMonthlyPresentData/${startDateFormatted}`);

export const getEmployeeFetchTime = () => axiosInstance.get('/user/fetchTimer');

export const getAdminFetchAttendence = () => axiosInstance.get('/admin/fetchAttendence');

export const upserTimerStart = (user, postData) => axiosInstance.post(`/${user}/timer`, postData);

export const upserTimerEnd = (user, postData) => axiosInstance.post(`/${user}/endTimer`, postData);

export const editTicket = (postData) => axiosInstance.put(`/user/changeTask`, postData);

export const getTimerData = (user) => axiosInstance.get(`/${user}/fetchTimer`);

export const getAttendanceData = (user, startDate, endDate) => axiosInstance.get(`/${user}/fetchTimer?startDate=${startDate}&endDate=${endDate}`);

export const getTicketData = () => axiosInstance.get(`/user/getTicket`);

export const editTimer = (postData) => axiosInstance.put(`/admin/timerChange`, postData);

export const getAttendenceWithFilter = (empId, exportAttendanceStartDate, exportAttendanceEndDate) => axiosInstance.get(`/admin/fetchAttendanceWithFilter/${empId}?startDate=${exportAttendanceStartDate}&endDate=${exportAttendanceEndDate}`);

export const getMonthlyLeavesData = (empId, selectedMonth, selectedYear) => axiosInstance.get(`/admin/getMonthlyLeaves/${empId}/${selectedMonth}/${selectedYear}`);
