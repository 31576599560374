import { Box, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    margin: "0px 35px",
    // height: "80vh",
    overflowY: "auto",
    marginTop: "10px",
    marginLeft: "9px",
}))

export const TitleBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}))