import React from "react";
import { Box } from "@mui/material";

import TableComponent from "../ReusableComponents/TableComponent";
import { LeaveBalanceTypography } from "../../Styles/AdminDashboard/CommonDashBoardStyle";

function LeaveBalanceTable({ leaveBalanace }) {
	return (
		<Box sx={{ padding: "10px" }}>
			<LeaveBalanceTypography>
				Leave Balance
			</LeaveBalanceTypography>
			<hr />
			<TableComponent array={leaveBalanace} />
		</Box>
	);
}

export default LeaveBalanceTable;
