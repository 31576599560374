import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
	List,
	ListItemText,
	Tooltip,
	Zoom,
} from "@mui/material";

import { capitalizeFirstLetter, extractTime } from "../UtilityFunctions";
import { PointedMainBox, PointedCarousels, ListItems, ListItemTexts, TitleOfTypography } from "../../../Styles/AdminDashboard/CommonDashBoardStyle";
import { ReactComponent as SleepingBird } from "../../../assets/img/icons/SleepingBird.svg";
import { ReactComponent as AwakeOwl } from "../../../assets/img/icons/AwakeOwl.svg";

export const PointedTextContainer = ({
	TitleOfCointainer,
	DataInArrayOfObjectForm,
}) => {
	const navigate = useNavigate(0);

	const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

	// function filterRecords(array) {
	//   if (array.length === 0) {
	//     return [];
	//   }
	//   if (array[0].TitleOfCointainer === "Early Birds") {
	//     return array.slice(0, 5);
	//   } else {
	//     return array.slice(-5);
	//   }
	// }

	function chunkArray(array, chunkSize) {
		const chunks = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			chunks.push(array.slice(i, i + chunkSize));
		}
		return chunks;
	}

	return (
		<>
			<PointedMainBox>
				{/* <strong style={{ textAlign: "-webkit-center" }}>
					{TitleOfCointainer}
				</strong>
				<hr style={{ width: "100%", marginBottom: "0px" }} /> */}
				<TitleOfTypography>
					{TitleOfCointainer}
				</TitleOfTypography>
				<hr
					style={{
						width: "100%",
						marginBottom: "0px",
						marginTop: "4px",
					}}
				/>

				{DataInArrayOfObjectForm.length === 0 ? (
					TitleOfCointainer === "Early Birds" ? (
						<Tooltip
							title="Birds Sleeping!"
							TransitionComponent={Zoom}
							arrow
							followCursor
						>
							<SleepingBird
								style={{
									marginTop: "25px",
									alignSelf: "center",
									width: "100px",
									height: "100px",
								}}
							/>
						</Tooltip>
					) : (
						<Tooltip
							title="Owls Awaked!"
							TransitionComponent={Zoom}
							arrow
							followCursor
						>
							<AwakeOwl
								style={{
									filter: isDarkTheme ? "invert(1)" : "",
									marginTop: "22px",
									alignSelf: "center",
									width: "100px",
									height: "100px",
								}}
							/>
						</Tooltip>
					)
				) : (
					<PointedCarousels
						autoPlay={true}
						stopAutoPlayOnHover={true}
						indicators={false}
						swipe={true}
						animation={"fade"}
						interval={5000}
						duration={1500}
						navButtonsAlwaysInvisible={true}
					>
						{chunkArray(DataInArrayOfObjectForm, 4).map(
							(chunk, index) => (
								<List
									key={index}
									aria-label={`mailbox folders ${index}`}
									sx={{ paddingBottom: "0px" }}
								>
									{chunk.map((data, i) => (
										<ListItems
											key={i}
											onClick={() => {
												navigate(
													`/attendance-management/${data?.empId}`
												);
											}}
										>
											{/* <ListItemAvatar sx={{ minWidth: "30px" }}>
                  <PersonIcon />
                </ListItemAvatar> */}
											{/* <Tooltip
                  title={extractTime(data?.TimerStart)}
                  placement="right"
                  TransitionComponent={Zoom}
                  arrow
                > */}
											<ListItemText
												sx={{ width: "fit-content" }}
												primary={capitalizeFirstLetter(
													data?.name
												)}
											/>
											<ListItemTexts
												primary={extractTime(
													data?.TimerStart
												)}
											/>
											{/* </Tooltip> */}
											{/* <Divider component="li" /> */}
										</ListItems>
									))}
								</List>
							)
						)}
					</PointedCarousels>
				)}
			</PointedMainBox>
		</>
	);
};
