import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  on: false,
  msg: "",
  type: "",
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.on = action.payload;
    },
    setToastMsg: (state, action) => {
      state.msg = action.payload;
    },
    setToastType: (state, action) => {
      state.type = action.payload;
    },
    resetToastSliceReducer: (state) => {
      return initialState;
    },
  },
});

export const { setToast, setToastMsg, setToastType, resetToastSliceReducer } = toastSlice.actions;

export default toastSlice.reducer;
