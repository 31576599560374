import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // value: "CenterComponent", // Initial state for user data
  leaveDetail: null,
  isLeave: false,
  updatedLeaveType: '',
  // leaveManagement: false,
  editLeave: false,
  editSettings: false,
  role: '',
  employeeName: '',
  getRoleByPermission: [],
  getNotificationCount: '',
  EmployeeManagment: 'true',
  onLeaveManagement: '',
  // token: ''
};

const selectedActiveSlice = createSlice({
  name: "selectedActiveView",
  initialState,
  reducers: {
    setDetailLeave: (state, action) => {
      state.leaveDetail = action.payload;
    },
    setisLeave: (state, action) => {
      state.isLeave = action.payload;
    },
    setGetRoleByPermission: (state, action) => {
      state.getRoleByPermission = action.payload;
    },
    setEditSettings: (state, action) => {
      state.editSettings = action.payload;
    },
    setGetNotificationCount: (state, action) => {
      state.getNotificationCount = action.payload;
    },
    setUpdatedLeaveType: (state, action) => {
      state.updatedLeaveType = action.payload;
    },
    // setleaveManagement: (state, action) => {
    //   state.leaveManagement = action.payload;
    // },
    setEmployeeManagment: (state, action) => {
      state.EmployeeManagment = action.payload;
    },
    setEditLeave: (state, action) => {
      state.editLeave = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setEmployeeName: (state, action) => {
      state.employeeName = action.payload;
    },
    setOnLeaveManagement: (state, action) => {
      state.onLeaveManagement = action.payload;
    },
    // setToken: (state, action) => {
    //   state.token = action.payload;
    // },
    resetSelectedActiveReducer: (state) => {
      return initialState;
    },
  },
});

export const {
  setDetailLeave,
  setisLeave,
  // setleaveManagement,
  setUpdatedLeaveType,
  setEditLeave,
  setRole,
  setEmployeeName,
  setGetRoleByPermission,
  setEditSettings,
  setGetNotificationCount,
  setEmployeeManagment,
  setOnLeaveManagement,
  // setToken,
  resetSelectedActiveReducer
} = selectedActiveSlice.actions;

export default selectedActiveSlice.reducer;
