import { axiosInstance } from './axiosInstance';

export const getAllWorkFlow = () => axiosInstance.get(`/admin/getAllWorkflow`);

export const deleteWorkFlow = (id) => axiosInstance.delete(`/admin/workflow/delete/${id}`);

export const upsertWorkFlow = (payload) => axiosInstance.post(`/admin/upsert/workflow`, payload);

export const getByIdWorkFlow = (id) => axiosInstance.get(`/admin/workflow/getById/${id}`);

export const getWorkFlow = (user) => axiosInstance.get(`/${user}/getWorkflow`);

export const getWorkFlowByProjectId = (projectName) => axiosInstance.get(`/workflow?projectName=${projectName}`);

export const deleteStatus = (workflowId, statusId, newStatusId) => axiosInstance.delete(`/admin/status/delete?workflowId=${workflowId}&statusId=${statusId}&newStatusId=${newStatusId}`);
