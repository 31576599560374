import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";
// import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Switch from "@mui/material/Switch";
import FlipCameraIosSharpIcon from "@mui/icons-material/FlipCameraIosSharp";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../Slices/toastSlice";
import { CLOUD_FUNCTIONS_ORIGIN } from "../../function-origin";
import { errorMessage } from "../../Services/axiosInstance";
import { editEvent, upsertEvent, upsertEventImage } from "../../Services/EventManageServices";
import { CancleButton, CloseBox, DateTextField, InnerBoxOne, InnerGridFour, InnerGridOne, InnerGridThree, InnerGridTwo, InputTextField, MainBox, MainCard, MainGrid } from "../../Styles/ManageEvents/AddEventStyle";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddEventSlide({
  setChange,
  change,
  onClose,
  isExistingEvent,
  setIsExistingEvent,
  existingEvent,
  setExistingEvent,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.selectedActiveView.role);
  const eventData = useSelector((state) => state.adminData.eventData);
  const adminLogin = user === "admin";
  const isMobile = useMediaQuery('(max-width:600px)');
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const [eventDetails, setEventDetails] = useState({
    event: eventData ? eventData.event : "",
    // eventDate: eventData ? eventData.eventDate : "",
    eventDate: eventData ? dayjs(eventData.eventDate).isValid() ? dayjs(eventData.eventDate) : null : null,
    eventDay: eventData ? eventData.eventDay : "",
    eventTime: eventData ? eventData.eventTime : "",
    eventDescription: eventData ? eventData.eventDescription : "",
    eventAddress: eventData ? eventData.eventAddress : "",
    eventLocation: eventData ? eventData.eventLocation : "",
  });
  const [error, setError] = useState("");
  const [displayError, setDisplayError] = useState("");
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [isInOffice, setIsInOffice] = useState(true);
  const [choosenEventImageToUpload, setChoosenEventImageToUpload] = useState(
    {}
  );
  const [choosenEventImageToUploadName, setChoosenEventImageToUploadName] =
    useState("");

  const handleFromTimeChange = (time) => {
    setFromTime(time);
  };

  const handleToTimeChange = (time) => {
    setToTime(time);
  };

  const handleCombineTimes = () => {
    if (fromTime && toTime) {
      const fromTimeFormatted = dayjs(fromTime).format("hh:mm A");
      const toTimeFormatted = dayjs(toTime).format("hh:mm A");
      const combinedTime = `${fromTimeFormatted} - ${toTimeFormatted}`;
      setEventDetails((prevState) => ({
        ...prevState,
        eventTime: combinedTime,
      }));
    }
  };

  const handleSwitchChange = () => {
    setIsInOffice((prevIsInOffice) => !prevIsInOffice);
    setEventDetails((prevState) => ({
      ...prevState,
      eventAddress: "",
      eventLocation: "",
    }));
  };

  const dateAndDaySetter = (selectedDate) => {
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    const dayOfWeek = dayjs(selectedDate).format("dddd");
    setEventDetails((prevState) => ({
      ...prevState,
      eventDate: formattedDate,
      eventDay: dayOfWeek,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setChoosenEventImageToUpload(file);
      setChoosenEventImageToUploadName(file.name);
    }
  };

  const setClose = () => {
    setIsExistingEvent(true);
    setExistingEvent({});
    onClose();
  };


  const resetAllData = () => {
    setEventDetails({
      event: "",
      // eventDate: "",
      eventDate: null,
      eventDay: "",
      eventTime: "",
      eventDescription: "",
      eventAddress: "",
      eventLocation: "",
    });
    setChoosenEventImageToUpload({});
    setChoosenEventImageToUploadName("");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDisplayError("");

    setError((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    const capitalizedValue =
      name === "event"
        ? value
        : value
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

    setEventDetails((prevState) => ({
      ...prevState,
      [name]: capitalizedValue,
    }));
  };

  const addEditEvent = async () => {
    const errors = [];
    if (!eventDetails.event) {
      errors.EventTitle = "Add event title..";
    }
    if (!eventDetails.eventDate) {
      errors.EventDate = "Select event date..";
    }
    if (!eventDetails.eventTime) {
      errors.EventTime = "Select event timing..";
    }
    if (!eventDetails.eventDescription) {
      errors.eventDescription = "Add event description..";
    }
    if (!isInOffice) {
      if (
        eventDetails.eventAddress === "" &&
        eventDetails.eventLocation === ""
      ) {
        errors.EventLocation = "Please set the event's address or location.";
      }
    }
    setError(errors);
    const noErrors = Object.keys(error).length === 0;

    if (Object.keys(error).length === 0) {
      // Add or update Event Photo
      if (noErrors &&
        Object.keys(choosenEventImageToUpload).length !== 0 &&
        choosenEventImageToUploadName !== "") {
        try {
          const formData = new FormData();
          formData.append("EventImage", choosenEventImageToUpload);
          formData.append("EventImageFileName", choosenEventImageToUploadName);

          // const response = await axios.post(
          //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/event-image?fileName=${choosenEventImageToUploadName}`,
          //   formData,
          //   {
          //     headers: {
          //       Authorization: `${token}`,
          //       "Content-Type": "multipart/form-data",
          //     },
          //   }
          // );
          const response = await upsertEventImage(choosenEventImageToUploadName, formData);
          if (response.status === 200 || response.status === 201) {
            setChoosenEventImageToUpload({});
            setChoosenEventImageToUploadName("");
            setDisplayError("");
            dispatch(setToast(true));
            dispatch(setToastMsg(response.data.message));
            dispatch(setToastType("success"));
          }
        } catch (error) {
          setDisplayError("Error Uploading Event Image ", error.message);
          return;
        }
      }

      // Add or Edit Events
      if (noErrors) {
        if (isExistingEvent) {
          try {
            // const response = await axios.put(
            //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/editEvent`,
            //   JSON.stringify({ eventDetails, choosenEventImageToUploadName }),
            //   postHeader
            // );
            const postData = JSON.stringify({ eventDetails, choosenEventImageToUploadName });
            const response = await editEvent(postData)
            if (response.status === 200) {
              resetAllData();
              if (change === true) {
                setChange(false);
              } else {
                setChange(true);
              }
              setClose();
              dispatch(setToast(true));
              dispatch(setToastMsg(response.data.message ?? "Event Updated."));
              dispatch(setToastType("success"));
            }
          } catch (error) {
            setDisplayError(errorMessage ?? "")
          }
        } else {
          try {
            // const response = await axios.post(
            //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/addEvent`,
            //   JSON.stringify({ eventDetails, choosenEventImageToUploadName }),
            //   postHeader
            // );
            const postData = JSON.stringify({ eventDetails, choosenEventImageToUploadName });
            const response = await upsertEvent(postData)
            if (response.status === 200) {
              resetAllData();
              if (change === true) {
                setChange(false);
              } else {
                setChange(true);
              }
              setClose();
              dispatch(setToast(true));
              dispatch(setToastMsg(response.data.message ?? "Event added."));
              dispatch(setToastType("success"));
            }
          } catch (error) {
            // setDisplayError(errorMessage ?? "")
          }
        }
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    if (fromTime !== "" && toTime !== "") {
      handleCombineTimes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromTime, toTime]);

  useEffect(() => {
    if (isExistingEvent) {
      if (existingEvent) {
        // date convertion
        const edate = dayjs(
          new Date(existingEvent.date)
            .toLocaleDateString("en-GB")
            .split("/")
            .reverse()
            .join("-")
        );
        // week day convertion
        const { $W } = edate;
        const dayOfWeekFullName = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ][$W];
        // time set and convertion
        const [startTime, endTime] = existingEvent.time.split(" - ");
        setFromTime(
          dayjs(new Date(`${existingEvent.date} ${startTime}`).toISOString())
        );
        setToTime(
          dayjs(new Date(`${existingEvent.date} ${endTime}`).toISOString())
        );
        if (
          Object.keys(existingEvent).includes("address") &&
          Object.keys(existingEvent).includes("location")
        ) {
          if (existingEvent.address !== "" || existingEvent.location !== "") {
            handleSwitchChange();
          }
        }
        setEventDetails((prevState) => ({
          ...prevState,
          _id: existingEvent._id,
          event: existingEvent.eventTitle,
          eventDate: edate,
          eventDay: dayOfWeekFullName,
          eventTime: existingEvent.time,
          eventDescription: existingEvent.description,
          eventAddress: existingEvent.address ? existingEvent.address : "",
          eventLocation: existingEvent.location ? existingEvent.location : "",
        }));
        setChoosenEventImageToUploadName(existingEvent.eventImageName);
      } else {
        resetAllData();
      }
    }
  }, [isExistingEvent, existingEvent]);

  return (
    <Box sx={{ padding: "22px 4px 8px 10px", height: "container" }}>
      <MainBox>
        {/* Box for items on the left */}
        <Box sx={{ padding: "5px 0px 5px 18px" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {adminLogin
              ? isExistingEvent
                ? "Edit Event"
                : "Add Event"
              : "Event"}
          </Typography>
        </Box>
        <CloseBox>
          <IconButton onClick={setClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </CloseBox>
      </MainBox>

      <Box sx={{ padding: "8px 4px 8px 10px", marginRight: "8px" }}>
        <MainCard>
          <CardContent>
            <Grid container spacing={2}>
              {isExistingEvent ? (
                <>
                  {choosenEventImageToUploadName === "" ? (
                    <></>
                  ) : (
                    <MainGrid
                      item
                      sm={12}
                    >
                      <img
                        alt="notfound"
                        style={{
                          borderRadius: "5%",
                          backgroundColor: "var(--primary-highlight-color)",
                          padding: "10px",
                          border: "2px solid var(--secondary-text-color)",
                          width: "200px",
                          height: "auto",
                        }}
                        src={`${CLOUD_FUNCTIONS_ORIGIN}/admin/EventImage/${choosenEventImageToUploadName}`}
                      />
                    </MainGrid>
                  )}
                  {adminLogin ? (
                    <InnerGridOne
                      item
                      sm={12}
                      xs={12}
                    >
                      <Tooltip
                        title={"Upload Event Image"}
                        placement="right"
                        arrow
                      >
                        <Button
                          color="primary"
                          component="label"
                          role={undefined}
                          variant="text"
                          tabIndex={-1}
                        >
                          <FlipCameraIosSharpIcon sx={{ fontSize: "30px" }} />
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleFileChange}
                            accept="image/*,.jpg,.jpeg"
                          />
                        </Button>
                      </Tooltip>
                      <Typography color="primary">
                        {choosenEventImageToUploadName}
                      </Typography>
                    </InnerGridOne>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  {adminLogin ? (
                    <Grid item sm={12} xs={12} sx={{ textAlign: "center" }}>
                      <Tooltip
                        title={"Upload Event Image"}
                        placement="right"
                        arrow
                      >
                        <Button
                          color="primary"
                          component="label"
                          role={undefined}
                          variant="text"
                          tabIndex={-1}
                        >
                          <PhotoCameraBackIcon sx={{ fontSize: "30px" }} />
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleFileChange}
                            accept="image/*,.jpg,.jpeg"
                          />
                        </Button>
                      </Tooltip>
                      <Typography color="primary">
                        {choosenEventImageToUploadName}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              )}

              <Grid item sm={12} xs={12}>
                <InputTextField
                  fullWidth
                  label="Event Title"
                  name="event"
                  value={eventDetails?.event}
                  onChange={handleChange}
                  disabled={!adminLogin}
                />
                <Typography color="error">{error.EventTitle}</Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="en-gb"
                >
                  <DemoContainer components={["DatePicker"]}>
                    {isMobile ? <DateTextField
                      label="Event Date"
                      isdarktheme={isDarkTheme ? "true" : "false"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {/* Add your icon here if needed */}
                          </InputAdornment>
                        ),
                      }}
                      name="eventDate"
                      value={eventDetails.eventDate ? dayjs(eventDetails.eventDate).format("YYYY-MM-DD") : null}
                      onChange={(e) => {
                        dateAndDaySetter(e.target.value);
                        setDisplayError("");
                        setError([]);
                      }}
                      disablePast={true}
                      disabled={!adminLogin}
                      type="date"
                    /> : <DatePicker
                      sx={{ width: "-webkit-fill-available" }}
                      slotProps={{
                        openPickerButton: {
                          color: "primary",
                        },
                      }}
                      label="Event Date"
                      name="eventDate"
                      value={eventDetails.eventDate ? dayjs(eventDetails.eventDate) : null}
                      onChange={(date) => {
                        dateAndDaySetter(date);
                        setDisplayError("");
                        setError([]);
                      }}
                      disablePast={true}
                      disabled={!adminLogin}
                    />}
                  </DemoContainer>
                </LocalizationProvider>
                <Typography color="error">{error.EventDate}</Typography>
              </Grid>

              <Grid item sm={6} xs={12}>
                <Box>
                  <TextField
                    fullWidth
                    sx={{ marginTop: "8px" }}
                    label="Event Day"
                    name="eventDay"
                    value={eventDetails?.eventDay}
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                    focused={eventDetails?.eventDay === "" ? false : true}
                    disabled={!adminLogin}
                  />
                </Box>
              </Grid>
              <InnerGridTwo
                item
                sm={12}
                xs={12}
              >
                <InnerGridThree
                  item
                  sm={12}
                  xs={12}
                >
                  <Grid item sx={{ marginTop: "8px" }} sm={5.8} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        sx={{ width: "-webkit-fill-available" }}
                        label="Timing From"
                        value={fromTime}
                        onChange={handleFromTimeChange}
                        slotProps={{
                          openPickerButton: {
                            color: "primary",
                          },
                        }}
                        disabled={!adminLogin}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid sx={{ marginTop: "8px" }} item sm={0.5} xs={6}>
                    <hr />
                  </Grid>
                  <Grid item sx={{ marginTop: "8px" }} sm={5.8} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        sx={{ width: "-webkit-fill-available" }}
                        label="Timing To"
                        value={toTime}
                        onChange={handleToTimeChange}
                        slotProps={{
                          openPickerButton: {
                            color: "primary",
                          },
                        }}
                        disabled={!adminLogin}
                      />
                    </LocalizationProvider>
                  </Grid>
                </InnerGridThree>
                <Typography color="error">{error.EventTime}</Typography>
              </InnerGridTwo>

              <Grid sx={{ marginTop: "8px" }} item sm={12} xs={12}>
                <InputTextField
                  fullWidth
                  multiline
                  maxRows={12}
                  label="Event Description"
                  name="eventDescription"
                  value={eventDetails?.eventDescription}
                  onChange={handleChange}
                  disabled={!adminLogin}
                />
                <Typography color="error">{error.eventDescription}</Typography>
              </Grid>

              <InnerGridFour
                item
                sm={12}
                xs={12}
              >
                <Typography
                  fontWeight={isInOffice ? "bold" : ""}
                  color={isInOffice ? "primary" : "var(--secondary-text-color)"}
                >
                  In Office
                </Typography>
                <Switch
                  checked={!isInOffice}
                  onChange={handleSwitchChange}
                  disabled={!adminLogin}
                />
                <Typography
                  fontWeight={!isInOffice ? "bold" : ""}
                  color={
                    !isInOffice ? "primary" : "var(--secondary-text-color)"
                  }
                >
                  Outside Office
                </Typography>
              </InnerGridFour>

              {!isInOffice ? (
                <>
                  <Grid item sm={12} xs={12}>
                    <InputTextField
                      fullWidth
                      multiline
                      maxRows={12}
                      label="Event Address"
                      name="eventAddress"
                      value={eventDetails?.eventAddress}
                      onChange={handleChange}
                      disabled={!adminLogin}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <InputTextField
                      fullWidth
                      multiline
                      maxRows={12}
                      label="Event Location"
                      name="eventLocation"
                      value={eventDetails?.eventLocation}
                      onChange={handleChange}
                      disabled={!adminLogin}
                    />
                    <Typography color="error">{error.EventLocation}</Typography>
                  </Grid>{" "}
                </>
              ) : (
                <></>
              )}
            </Grid>

            <InnerBoxOne mt={2}>
              <CancleButton onClick={setClose}>
                Cancel
              </CancleButton>
              {isExistingEvent ? (
                adminLogin ? (
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      addEditEvent();
                    }}
                  >
                    Update event
                  </Button>
                ) : (
                  <></>
                )
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    addEditEvent();
                  }}
                >
                  Add event
                </Button>
              )}
            </InnerBoxOne>
            <Box mt={2}>
              <Typography sx={{ color: "red" }}>{displayError}</Typography>
            </Box>
          </CardContent>
        </MainCard>
      </Box>
    </Box>
  );
}

export default AddEventSlide;
