import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  InputAdornment,
} from "@mui/material";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
import { setAllData } from "../../../Slices/UserDataSlice";
import { viewProfileSubtitle } from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { setAllLeaveData } from "../../../Slices/leaveData";
import {
  resetEmployeeDetail,
  set_city,
  set_email,
  set_personalEmail,
  set_id,
  set_number1,
  set_number2,
  set_relation,
  set_resident,
  set_state,
  setEmployeeAdded,
} from "../../../Slices/employeeDetailSlice";
import ExitDialog from "../ExitDialog";
import { errorMessage } from "../../../Services/axiosInstance";
import { upsertPersonalContact } from "../../../Services/EmployeeManageServices";
import { MainBox, InnerBoxOne, SaveNextButton, SaveButton, CancelButton, InputTextField, CustomTextField, ErrorTypography } from "../../../Styles/UpdateEmployee/UpdateContactStyle";
import { FooterBox } from "../../../Styles/UpdateEmployee/UpdatePersonalStyle";
import { set_index } from "../../../Slices/employeeDetailSlice";

const DisplayContact = (props) => {
  const { saveNextButtonCallback, previousButtonCallback } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const themeChange = useSelector((state) => state.theme.isDarkTheme);
  const newEmployeeData = useSelector((state) => state.addEmployee.personal);
  const EmployeeDataContact = useSelector((state) => state.addEmployee.contact);
  const displaySucess = useSelector((state) => state.addEmployee.employeeAdded);
  const adminEmail = useSelector(
    (state) => state.adminData.adminData?.email
  );

  const [eid, setEid] = useState("");
  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [displayError, setDisplayError] = useState("");
  // const [displaySuccess, setDisplaySuccess] = useState("");
  const [errors, setErrors] = useState({});
  const [employeeContactDetails, setEmployeeContactDetails] = useState({
    contactPersonalNumber: EmployeeDataContact
      ? EmployeeDataContact.contactPersonalNumber
      : "",
    contactAdditionalNumber: EmployeeDataContact
      ? EmployeeDataContact.contactAdditionalNumber
      : "",
    relation: EmployeeDataContact ? EmployeeDataContact.relation : "",
    contactEmail: EmployeeDataContact ? EmployeeDataContact.contactEmail : "",
    personalContactEmail: EmployeeDataContact
      ? EmployeeDataContact.personalContactEmail
      : "",
    contactState: EmployeeDataContact ? EmployeeDataContact.contactState : "",
    contactCity: EmployeeDataContact ? EmployeeDataContact.contactCity : "",
    contactResidental: EmployeeDataContact
      ? EmployeeDataContact.contactResidental
      : "",
  });

  const handleCloseCancelDialog = async () => {
    setOpenExitDialog(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEmployeeContactDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevData) => ({
      ...prevData,
      [name]: "",
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!employeeContactDetails.contactPersonalNumber) {
      newErrors.contactPersonalNumber = "Phone number is required";
    } else if (!/^\d{10}$/.test(employeeContactDetails.contactPersonalNumber)) {
      newErrors.contactPersonalNumber = "Phone number must be exactly 10 digits";
    } else if (
      !/^[6-9]\d{9}$/.test(employeeContactDetails.contactPersonalNumber)
    ) {
      newErrors.contactPersonalNumber = "Phone number is not valid";
    }

    if (!employeeContactDetails.contactAdditionalNumber) {
      newErrors.contactAdditionalNumber = "Emergency number is required";
    } else if (!/^\d{10}$/.test(employeeContactDetails.contactAdditionalNumber)) {
      newErrors.contactAdditionalNumber = "Emergency number must be exactly 10 digits";
    } else if (
      !/^[6-9]\d{9}$/.test(employeeContactDetails.contactAdditionalNumber)
    ) {
      newErrors.contactAdditionalNumber = "Emergency number is not valid";
    } else if (
      employeeContactDetails.contactAdditionalNumber ===
      employeeContactDetails.contactPersonalNumber
    ) {
      newErrors.contactAdditionalNumber =
        "Phone number and Emergency Number should not be same";
    }

    if (!employeeContactDetails.contactEmail) {
      newErrors.contactEmail = "Email is required";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
        employeeContactDetails.contactEmail
      )
    ) {
      newErrors.contactEmail = "Invalid email format";
    }

    if (!employeeContactDetails.personalContactEmail) {
      newErrors.personalContactEmail = "Email is required";
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(
        employeeContactDetails.personalContactEmail
      )
    ) {
      newErrors.personalContactEmail = "Invalid email format";
    }

    if (!employeeContactDetails.contactState) {
      newErrors.contactState = "State is required";
    }
    if (!employeeContactDetails.relation) {
      newErrors.relation = "Relation is required";
    } else if (!/^[A-Za-z]+$/.test(employeeContactDetails.relation)) {
      newErrors.relation = "Relation should contain only alphabetic characters";
    }
    if (!employeeContactDetails.contactCity) {
      newErrors.contactCity = "City is required";
    }
    if (!employeeContactDetails.contactResidental) {
      newErrors.contactResidental = "Residental address is required";
    }
    setErrors(newErrors);
    const noErrors = Object.keys(newErrors).length === 0;

    if (noErrors) {
      return true;
    }

    return Object.keys(newErrors).length === 0;
  };

  const setContact = async () => {
    dispatch(set_number1(employeeContactDetails.contactPersonalNumber));
    dispatch(set_number2(employeeContactDetails.contactAdditionalNumber));
    dispatch(set_email(employeeContactDetails.contactEmail));
    dispatch(set_personalEmail(employeeContactDetails.personalContactEmail));
    dispatch(set_state(employeeContactDetails.contactState));
    dispatch(set_city(employeeContactDetails.contactCity));
    dispatch(set_relation(employeeContactDetails.relation));
    dispatch(set_resident(employeeContactDetails.contactResidental));
  };

  const saveAndNext2 = async () => {
    setDisplayError("");
    if (displaySucess === "employee added.") {
    } else {
      try {
        // const response = await axios.post(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/personalContact`,
        //   JSON.stringify({
        //     employeeContactDetails,
        //     newEmployeeData,
        //     adminEmail,
        //   }),
        //   postHeader
        // );
        const postData = JSON.stringify({ employeeContactDetails, newEmployeeData, adminEmail, })
        const response = await upsertPersonalContact(postData)
        if (response.status === 201 || response.status === 200) {
          dispatch(set_index(response?.data?.employeeId))
          setContact();
          dispatch(setEmployeeAdded("employee added."));
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Employee contact Data added successful!"));
          dispatch(setToastType("success"));
          navigate("/dashboard");
          dispatch(resetEmployeeDetail());
          setEid(response.data.employeeId);
          dispatch(set_id(response.data.employeeId));
          dispatch(setAllData(response.data.user));
          dispatch(setAllLeaveData(response.data.leave));
        }
        else {
          setEid("")
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  const saveAndNext = async () => {
    setDisplayError("");
    if (displaySucess === "employee added.") {
      saveNextButtonCallback();
    } else if (displaySucess === "") {
      try {
        // const response = await axios.post(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/personalContact`,
        //   JSON.stringify({
        //     employeeContactDetails,
        //     newEmployeeData,
        //     adminEmail,
        //   }),
        //   postHeader
        // );
        const postData = JSON.stringify({ employeeContactDetails, newEmployeeData, adminEmail, })
        const response = await upsertPersonalContact(postData)
        if (response.status === 201 || response.status === 200) {
          dispatch(set_index(response?.data?.employeeId))
          setContact();
          setEid(response.data.employeeId);
          dispatch(set_id(response.data.employeeId));
          dispatch(setAllData(response.data.user));
          dispatch(setAllLeaveData(response.data.leave));
          saveNextButtonCallback();
          dispatch(setEmployeeAdded("employee added."));
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Employee contact Data added successful!"));
          dispatch(setToastType("success"));
        }
        else {
          setEid("")
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  const reset = () => {
    setEmployeeContactDetails({
      contactPersonalNumber: "",
      contactAdditionalNumber: "",
      contactEmail: "",
      personalContactEmail: "",
      contactState: "",
      contactCity: "",
      relation: "",
      contactResidental: "",
    });
  };

  return (
    <MainBox>
      <InnerBoxOne>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Contact Details
          </Typography>
        </Box>
      </InnerBoxOne>
      <hr />
      <Box sx={{ marginBottom: "25px", paddingTop: "20px" }}>
        <Box>
          <Typography sx={viewProfileSubtitle}>Phone Number</Typography>
          <InputTextField
            themechange={themeChange ? "true" : "false"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
            }}
            name="contactPersonalNumber"
            value={employeeContactDetails.contactPersonalNumber}
            onChange={handleInputChange}
            inputProps={{ maxLength: 10 }}
          />
          <Typography sx={{ color: "red" }}>
            {errors.contactPersonalNumber}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "25px" }}>
        <Box>
          <Typography sx={viewProfileSubtitle}>Emergency Number</Typography>
          <InputTextField
            themechange={themeChange ? "true" : "false"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">+91</InputAdornment>
              ),
            }}
            name="contactAdditionalNumber"
            value={employeeContactDetails.contactAdditionalNumber}
            onChange={handleInputChange}
            inputProps={{ maxLength: 10 }}
          />
          <Typography sx={{ color: "red" }}>
            {errors.contactAdditionalNumber}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "25px" }}>
        <Box>
          <Typography sx={viewProfileSubtitle}>Emergency Relation</Typography>
          <InputTextField
            themechange={themeChange ? "true" : "false"}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start"></InputAdornment>
              ),
            }}
            name="relation"
            value={employeeContactDetails.relation}
            onChange={handleInputChange}
          />
          <Typography sx={{ color: "red" }}>{errors.relation}</Typography>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "25px" }}>
        <Box>
          <Typography sx={viewProfileSubtitle}>E-mail Address</Typography>
          <CustomTextField
            themechange={themeChange ? "true" : "false"}
            name="contactEmail"
            value={employeeContactDetails.contactEmail}
            onChange={handleInputChange}
          />
          <Typography sx={{ color: "red" }}>{errors.contactEmail}</Typography>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "25px" }}>
        <Box>
          <Typography sx={viewProfileSubtitle}>
            Personal E-mail Address
          </Typography>
          <CustomTextField
            themechange={themeChange ? "true" : "false"}
            name="personalContactEmail"
            value={employeeContactDetails.personalContactEmail}
            onChange={handleInputChange}
          />
          <Typography sx={{ color: "red" }}>
            {errors.personalContactEmail}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "25px" }}>
        <Box>
          <Typography sx={viewProfileSubtitle}>State of residence</Typography>
          <CustomTextField
            themechange={themeChange ? "true" : "false"}
            name="contactState"
            value={employeeContactDetails.contactState}
            onChange={handleInputChange}
          />
          <Typography sx={{ color: "red" }}>{errors.contactState}</Typography>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "25px" }}>
        <Box>
          <Typography sx={viewProfileSubtitle}>City</Typography>
          <CustomTextField
            themechange={themeChange ? "true" : "false"}
            name="contactCity"
            value={employeeContactDetails.contactCity}
            onChange={handleInputChange}
          />
          <Typography sx={{ color: "red" }}>{errors.contactCity}</Typography>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "21px" }}>
        <Box>
          <Typography sx={viewProfileSubtitle}>Residential Address</Typography>
          <CustomTextField
            themechange={themeChange ? "true" : "false"}
            name="contactResidental"
            value={employeeContactDetails.contactResidental}
            onChange={handleInputChange}
          />
          <Typography sx={{ color: "red" }}>
            {errors.contactResidental}
          </Typography>
        </Box>

        <ErrorTypography>
          {displayError}
        </ErrorTypography>
        <Typography sx={{ color: "green", marginTop: "20px" }}>
          {/* {displaySuccess} */}
        </Typography>
      </Box>
      <Box>
        <FooterBox>
          <Button
            sx={{
              fontWeight: "bold",
              color: "gray",
            }}
            onClick={() => {
              previousButtonCallback();
            }}
          >
            Back
          </Button>
          <CancelButton
            onClick={() => {
              setOpenExitDialog(true);
              dispatch(setEmployeeAdded(""));
            }}
          >
            Cancel
          </CancelButton>
          <ExitDialog
            open={openExitDialog}
            reset={reset}
            onClose={handleCloseCancelDialog}
          />
          <SaveButton
            onClick={() => {
              if (validateForm()) {
                saveAndNext2();
                dispatch(setEmployeeAdded(""));
              }
            }}
          >
            Save
          </SaveButton>
          <SaveNextButton
            variant="contained"
            onClick={() => {
              if (validateForm()) {
                saveAndNext();
                dispatch(set_id(eid));
              }
            }}
          >
            Save & Next
          </SaveNextButton>
        </FooterBox>
      </Box>
    </MainBox>
  );
};

export default DisplayContact;
