import { axiosInstance } from './axiosInstance';

export const upsertLogin = (postData) => axiosInstance.post('/admin/login', postData);

export const upsertSignUp = (postData) => axiosInstance.post('/admin/signUp', postData);

export const upsertGenerarteOTp = (postData) => axiosInstance.post('/admin/generarteOTp', postData);

export const upsertCheckOtp = (postData) => axiosInstance.post('/admin/checkOtp', postData);

export const upsertResetPassword = (postData) => axiosInstance.post('/admin/resetPassword', postData);

export const upsertChangePassword = (postData) => axiosInstance.post('/user/ChangePassword', postData);
