import { AccordionDetails, AccordionSummary, Box, styled } from "@mui/material"

export const LoadingBox = styled(Box)(({ adminlogin }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: adminlogin === "true" ? "54vh" : "59vh"
}))

export const AccordionsSummary = styled(AccordionSummary)(() => ({
    textTransform: "capitalize",
    backgroundColor: "var(--accordian-background-color)",
    color: "var(--primary-text-color) !important",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    ".MuiSvgIcon-fontSizeMedium": {
        fill: "var(--primary-text-color) !important",
    },
}))

export const AccordionsDetails = styled(AccordionDetails)(() => ({
    backgroundColor: "var(--accordian-background-color)",
    color: "var(--primary-text-color) !important",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const NoTaskAllocatedBox = styled(Box)(() => ({
    color: "var(--secondary-text-color) !important",
    display: "flex",
    justifyContent: "center",
    paddingY: "5px",
}))

export const EmpNameAccordionsSummary = styled(AccordionSummary)(() => ({
    background:
        "var(--subTaskdataGrid-background-color)",
    color: "var(--primary-text-color) !important",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    ".MuiSvgIcon-fontSizeMedium": {
        fill: "var(--primary-text-color) !important",
    },
}))

export const EmpAccordionDetails = styled(AccordionDetails)(() => ({
    backgroundColor:
        "var(--subTaskdataGrid-background-color)",
    color: "var(--primary-text-color) !important",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))