import { Box, Button, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    margin: "0px 0px ",
}))

export const InnerBoxOne = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
}))

export const ActiveEmployeesButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedbutton === "Active Employees"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "Active Employees"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const ArchivedEmployeesButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedbutton === "DeActive Employees"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "DeActive Employees"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const EmployeeButtonBox = styled(Box)(() => ({
     marginBottom: "20px",
     paddingRight: "15px",
     display: "flex",
     justifyContent: "flex-end",
     '@media (max-width: 600px)': {
        paddingRight: "4px",
    },
}))

export const SliderBox = styled(Box)(() => ({
'@media (max-width: 600px)': {
    padding: "10px 0px 0px 0px",
}
}))

export const InnerSliderBox = styled(Box)(() => ({
    "&::-webkit-scrollbar": {
        display: "none",
    },
    overflowY: "hidden",
}))