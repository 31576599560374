import React from 'react'
import { Box } from '@mui/material'

import EmpDataGrid from '../AdminDashboard/Tables/EmpDataGrid'
import { MainBox, InnerBoxOne, TitleTypography } from "../../Styles/Team/TeamStyle"

function Team() {
    return (
        <Box>
            <MainBox>
                <InnerBoxOne bottom={0}>
                    <TitleTypography variant="h6">
                        Team
                    </TitleTypography>
                </InnerBoxOne>
                <EmpDataGrid team={"Team"} selectedButton={"Active Employees"} />
            </MainBox>
        </Box>
    )
}

export default Team