import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
  leavesTypes:[],
};

const userLeaveDataSlice = createSlice({
  name: "employeeLeaveData",
  initialState,
  reducers: {
    setAllLeaveData: (state, action) => {
      state.value = action.payload;
    },
    setLeavesTypes: (state, action) => {
      state.leavesTypes = action.payload;
    },
    resetLeaveDataReducer: (state) => {
      return initialState;
    },
    deprecateLeaveType(state, action) {
      state.leavesTypes = state.leavesTypes.filter(
        (leave) => leave._id !== action.payload
      );
    }
  },
});

export const { setAllLeaveData, setLeavesTypes, resetLeaveDataReducer, deprecateLeaveType } = userLeaveDataSlice.actions;

export default userLeaveDataSlice.reducer;
