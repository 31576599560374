import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Edit } from "@mui/icons-material";

import { setAllData } from "../../../Slices/UserDataSlice.js";
import { setemployeeAllData } from "../../../Slices/employeeDetailSlice.js";
import {
  viewProfileTitle,
  viewEducationBox,
  viewEducationTitle,
  viewProfileSubtitle,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { upsertEducation } from "../../../Services/EmployeeManageServices.js";
import { setToast, setToastMsg, setToastType } from "../../../Slices/toastSlice.js";
import { errorMessage } from "../../../Services/axiosInstance.js";
import { AddRecordBox, AddRecordButton, InputTextField, MainBox, TitleBox } from "../../../Styles/UpdateEmployee/UpdateEducationStyle.js";
import { CancelButton, SaveButton, SaveNextButton } from "../../../Styles/UpdateEmployee/UpdateContactStyle.js";
import { FooterBox } from "../../../Styles/UpdateEmployee/UpdatePersonalStyle.js";

function UpdateEducation(props) {
  const { employeeCall, saveNextButtonCallback, setIsUpdated, isUpdated } = props;
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const user = useSelector((state) => state.selectedActiveView.role);
  const isMobile = useMediaQuery("(max-width:600px)");

  const [editEmployee, setEditEmployee] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [academicRecordsArray, setAcademicRecordsArray] = useState(
    employeeCall?.Academic_Records || []
  );
  const [academicRecordErrorsArray, setAcademicRecordErrorsArray] = useState(
    []
  );
  const [professionalRecordsArray, setProfessionalRecordsArray] = useState(
    employeeCall?.Professional_Records || []
  );
  const [professionalRecordErrorsArray, setProfessionalRecordErrorsArray] =
    useState([]);

  const employee_id = employeeCall?.empId;
  const adminLogin = user === "admin";

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleInputChange1 = (index, event) => {
    const { name, value } = event.target;
    setAcademicRecordsArray((prevArray) =>
      prevArray?.map((record, i) =>
        i === index ? { ...record, [name]: value } : record
      )
    );
  };

  const handleInputChange2 = (index, event) => {
    const { name, value } = event.target;
    setProfessionalRecordsArray((prevArray) =>
      prevArray?.map((record, i) =>
        i === index ? { ...record, [name]: value } : record
      )
    );
  };

  const removeRecord1 = (index) => {
    setAcademicRecordsArray((prevArray) =>
      prevArray.filter((_, i) => i !== index)
    );
    setAcademicRecordErrorsArray((prevErrorsArray) =>
      prevErrorsArray.filter((_, i) => i !== index)
    );
  };

  const getEmptyacademicRecord = () => ({
    University: "",
    Course: "",
    Start_DateA: "",
    End_DateA: "",
  });

  const getEmptyProfessionalRecordErrors = () => ({
    University: "",
    Course: "",
    Start_DateA: "",
    End_DateA: "",
  });

  const getEmptyprofessionalRecord = () => ({
    Certificate: "",
    Place: "",
    Start_DateP: "",
    End_DateP: "",
  });

  const getEmptyprofessionalRecordErrors = () => ({
    Certificate: "",
    Place: "",
    Start_DateP: "",
    End_DateP: "",
  });

  const addRecord = () => {
    if (editEmployee) {
      if (validateAllForms()) {
        setAcademicRecordsArray((prevArray) => [
          ...prevArray,
          getEmptyacademicRecord(),
        ]);
        setAcademicRecordErrorsArray((prevErrorsArray) => [
          ...prevErrorsArray,
          getEmptyProfessionalRecordErrors(),
        ]);
      }
    }
  };

  const addprofessionalRecord = () => {
    if (editEmployee) {
      if (validateAllForms2()) {
        setProfessionalRecordsArray((prevArray) => [
          ...prevArray,
          getEmptyprofessionalRecord(),
        ]);
        setProfessionalRecordErrorsArray((prevErrorsArray) => [
          ...prevErrorsArray,
          getEmptyprofessionalRecordErrors(),
        ]);
      }
    }
  };

  const validateAllForms = () => {
    if (academicRecordsArray?.length === 0) {
      return true;
    }
    const allErrors = academicRecordsArray?.map((record, index) =>
      validateForm(index)
    );
    setAcademicRecordErrorsArray(allErrors);
    return allErrors?.every((errors) =>
      Object.values(errors).every((error) => !error)
    );
  };

  const validateAllForms2 = () => {
    if (professionalRecordsArray?.length === 0) {
      return true;
    }
    const allErrors = professionalRecordsArray?.map((record, index) =>
      validateForm2(index)
    );
    setProfessionalRecordErrorsArray(allErrors);
    return allErrors?.every((errors) =>
      Object.values(errors).every((error) => !error)
    );
  };

  const validateForm = (index) => {
    const currentRecord = academicRecordsArray[index];
    const errors = getEmptyProfessionalRecordErrors();
    if (!currentRecord?.University) {
      errors.University = "University name is required.";
    } else if (!/^[a-zA-Z\s]*$/.test(currentRecord.University)) {
      errors.University = "University name should not contain numeric values.";
    }
    if (!currentRecord?.Course) {
      errors.Course = "Course name is required.";
    }
    if (!currentRecord?.Start_DateA) {
      errors.Start_DateA = "Start date is required.";
    }
    if (!currentRecord?.End_DateA) {
      errors.End_DateA = "End date is required.";
    } else if (currentRecord?.Start_DateA > currentRecord?.End_DateA) {
      errors.End_DateA = "Invalid date";
    }
    return errors;
  };

  const validateForm2 = (index) => {
    validateAllForms();
    const currentRecord = professionalRecordsArray[index];
    const errors = getEmptyprofessionalRecordErrors();
    if (!currentRecord?.Certificate) {
      errors.Certificate = "Certificate name is required.";
    } else if (!/^[a-zA-Z\s]*$/.test(currentRecord?.Certificate)) {
      errors.Certificate = "Certificate name should not contain numeric values.";
    }
    if (!currentRecord?.Place) {
      errors.Place = "place  is required";
    } else if (!/^[a-zA-Z\s]*$/.test(currentRecord?.Place)) {
      errors.Place = "Place should not contain numeric values.";
    }
    if (!currentRecord?.Start_DateP) {
      errors.Start_DateP = "Start date is required.";
    }
    if (!currentRecord?.End_DateP) {
      errors.End_DateP = "End date is required.";
    } else if (currentRecord?.Start_DateP > currentRecord?.End_Datep) {
      errors.End_DateP = "Invalid date.";
    }
    return errors;
  };

  const removeprofessionalRecord = (index) => {
    setProfessionalRecordsArray((prevArray) =>
      prevArray.filter((_, i) => i !== index)
    );
    setProfessionalRecordErrorsArray((prevErrorsArray) =>
      prevErrorsArray.filter((_, i) => i !== index)
    );
  };

  const saveAndNext = async () => {
    try {
      // const response = await axios.post(
      //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/education`,
      //   JSON.stringify({
      //     employee_id,
      //     academicRecordsArray,
      //     professionalRecordsArray,
      //   }),
      //   postHeader
      // );
      const postData = JSON.stringify({ employee_id, academicRecordsArray, professionalRecordsArray, });
      const response = await upsertEducation(postData)
      if (response.status === 200) {
        dispatch(setAllData(response.data.user));
        dispatch(setemployeeAllData(response.data.employeeAllDetail));
        if (adminLogin) {
          if (isUpdated === true) {
            setIsUpdated(false)
          } else {
            setIsUpdated(true)
          }
        }
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
        return true;
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  return (
    <Box>
      <MainBox ismobile={isMobile?"true":"false"}>
        <TitleBox>
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Education Qualification
            </Typography>
          </Box>
          {/* EDIT AND DELETE BUTTONS */}
          <Box>
            {editEmployee ? null : (
              <Box>
                <IconButton
                  onClick={() => setEditEmployee(true)}
                  disabled={editEmployee}
                  sx={{ color: "var( --third-color)" }}
                >
                  <Edit />
                </IconButton>
              </Box>
            )}
          </Box>
        </TitleBox>
        <hr />

        {/* FOR ACADEMIC RECORDS */}
        <AddRecordBox>
          <Typography sx={{ ...viewProfileTitle }}>Academic Records</Typography>
          {editEmployee ? (
            <AddRecordButton
              variant="outlined"
              onClick={() => addRecord()}
            >
              + Add Record
            </AddRecordButton>
          ) : null}
        </AddRecordBox>
        <Box>
          {editEmployee ? (
            <Box>
              {academicRecordsArray?.map((record, index) => (
                <Box key={index} sx={viewEducationBox}>
                  <Box sx={{ marginBottom: "15px" }}>
                    <InputTextField
                      name="University"
                      label="University Name"
                      disabled={!editEmployee}
                      value={academicRecordsArray[index]?.University}
                      onChange={(event) => handleInputChange1(index, event)}
                    />

                    {academicRecordErrorsArray[index]?.University && (
                      <Typography color="error">
                        {academicRecordErrorsArray[index].University}
                      </Typography>
                    )}
                    <InputTextField
                      name="Course"
                      disabled={!editEmployee}
                      value={academicRecordsArray[index]?.Course}
                      onChange={(event) => handleInputChange1(index, event)}
                      label="Course Name"
                    />
                    {academicRecordErrorsArray[index]?.Course && (
                      <Typography color="error">
                        {academicRecordErrorsArray[index].Course}
                      </Typography>
                    )}
                    <InputTextField
                      isdarktheme={isDarkTheme ? "true" : "false"}
                      InputProps={{
                        startAdornment: <InputAdornment position="start" />,
                      }}
                      name="Start_DateA"
                      disabled={!editEmployee}
                      value={academicRecordsArray[index]?.Start_DateA}
                      onChange={(event) => handleInputChange1(index, event)}
                      label="Start Year"
                      type="date"
                    />
                    {academicRecordErrorsArray[index]?.Start_DateA && (
                      <Typography color="error">
                        {academicRecordErrorsArray[index].Start_DateA}
                      </Typography>
                    )}
                    <InputTextField
                      isdarktheme={isDarkTheme ? "true" : "false"}
                      InputProps={{
                        startAdornment: <InputAdornment position="start" />,
                      }}
                      name="End_DateA"
                      disabled={!editEmployee}
                      value={academicRecordsArray[index]?.End_DateA}
                      onChange={(event) => handleInputChange1(index, event)}
                      label="End Year"
                      type="date"
                    />
                    {academicRecordErrorsArray[index]?.End_DateA && (
                      <Typography color="error">
                        {academicRecordErrorsArray[index].End_DateA}
                      </Typography>
                    )}
                  </Box>

                  {/* Remove button for academic record */}
                  <Button
                    variant="outlined"
                    color="error"
                    sx={{ fontWeight: "bold" }}
                    onClick={() => removeRecord1(index)}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              {academicRecordsArray?.map((record, index) => (
                <Box key={index} sx={viewEducationBox}>
                  <Typography sx={viewEducationTitle}>
                    {academicRecordsArray[index].University}
                  </Typography>
                  <Typography sx={viewProfileSubtitle}>
                    {academicRecordsArray[index].Course}
                    {","} {formatDate(academicRecordsArray[index].Start_DateA)}{" "}
                    - {formatDate(academicRecordsArray[index].End_DateA)}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </Box>

        {/* FOR PROFESIONAL QUALIFICATION */}
        <AddRecordBox>
          <Typography sx={viewProfileTitle}>Professional Records</Typography>
          {editEmployee ? (
            <Button
              variant="outlined"
              sx={{ color: "var(--primary-color)", fontWeight: "bold" }}
              onClick={() => addprofessionalRecord()}
            >
              + Add Record
            </Button>
          ) : null}
        </AddRecordBox>
        {editEmployee ? (
          <Box>
            {professionalRecordsArray?.map((record, index) => (
              <Box key={index} sx={viewEducationBox}>
                <Box sx={{ marginBottom: "15px" }}>
                  <Box>
                    <InputTextField
                      name="Certificate"
                      value={professionalRecordsArray[index]?.Certificate}
                      label="Education Certificate"
                      onChange={(event) => handleInputChange2(index, event)}
                    />
                    {professionalRecordErrorsArray[index]?.Certificate && (
                      <Typography color="error">
                        {professionalRecordErrorsArray[index].Certificate}
                      </Typography>
                    )}
                    <InputTextField
                      disabled={!editEmployee}
                      name="Place"
                      value={professionalRecordsArray[index]?.Place}
                      label="Place"
                      onChange={(event) => handleInputChange2(index, event)}
                    />
                    {professionalRecordErrorsArray[index]?.Place && (
                      <Typography color="error">
                        {professionalRecordErrorsArray[index].Place}
                      </Typography>
                    )}
                    <InputTextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start" />,
                      }}
                      isdarktheme={isDarkTheme ? "true" : "false"}
                      name="Start_DateP"
                      value={professionalRecordsArray[index]?.Start_DateP}
                      label="Start Date"
                      type="date"
                      disabled={!editEmployee}
                      onChange={(event) => handleInputChange2(index, event)}
                    />
                    {professionalRecordErrorsArray[index]?.Start_DateP && (
                      <Typography color="error">
                        {professionalRecordErrorsArray[index].Start_DateP}
                      </Typography>
                    )}
                    <InputTextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start" />,
                      }}
                      isdarktheme={isDarkTheme ? "true" : "false"}
                      name="End_DateP"
                      disabled={!editEmployee}
                      value={professionalRecordsArray[index]?.End_DateP}
                      label="End Date"
                      type="date"
                      onChange={(event) => handleInputChange2(index, event)}
                    />
                    {professionalRecordErrorsArray[index]?.End_DateP && (
                      <Typography color="error">
                        {professionalRecordErrorsArray[index].End_DateP}
                      </Typography>
                    )}
                  </Box>
                </Box>

                {/* Remove button for professional record */}
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ fontWeight: "bold" }}
                  onClick={() => removeprofessionalRecord(index)}
                >
                  Remove
                </Button>
              </Box>
            ))}
          </Box>
        ) : (
          <Box>
            {professionalRecordsArray?.map((record, index) => (
              <Box key={index} sx={viewEducationBox}>
                <Typography sx={viewEducationTitle}>
                  {professionalRecordsArray[index].Certificate}
                </Typography>
                <Typography sx={viewProfileSubtitle}>
                  {professionalRecordsArray[index].Place}
                  {","}{" "}
                  {formatDate(professionalRecordsArray[index].Start_DateP)} -{" "}
                  {formatDate(professionalRecordsArray[index].End_DateP)}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        <Typography sx={{ color: "red" }}>{displayError}</Typography>
        <Box>
          {editEmployee ? (
            <FooterBox>
              <CancelButton
                onClick={() => {
                  setEditEmployee(false);
                  setAcademicRecordsArray(employeeCall?.Academic_Records || []);
                  setProfessionalRecordsArray(
                    employeeCall?.Professional_Records || []
                  );
                }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                onClick={() => {
                  if (validateAllForms2() && validateAllForms()) {
                    saveAndNext();
                    setEditEmployee(false);
                  }
                }}
              >
                Save
              </SaveButton>
              <SaveNextButton
                variant="contained"
                onClick={() => {
                  if (validateAllForms2() && validateAllForms()) {
                    if (saveAndNext()) {
                      setEditEmployee(false);
                      saveNextButtonCallback();
                    }
                  }
                }}
              >
                Save & Next
              </SaveNextButton>
            </FooterBox>
          ) : null}
        </Box>
      </MainBox>
    </Box>
  );
}

export default UpdateEducation;
