import { Box, Button, Card, Typography, styled } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid";

export const InnerCardOne = styled(Card)(() => ({
    backgroundColor: 'var(--primary-highlight-color)',
    borderRadius: '8px',
    marginBottom: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    color: 'var(--secondary-text-color)'
}));

export const ShowAllButton = styled(Button)(() => ({
    marginTop: "3px",
    fontWeight: "normal",
    '&:hover': {
        fontWeight: "bold"
    }
}));

export const LoadingBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "65vh",
}))

export const MainBox = styled(Box)(() => ({
    margin: "0px 0px ",
}))

export const InnerBoxOne = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
}))

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
    marginLeft: "15px",
    marginBottom: "10px",
}))

export const DataGrids = styled(DataGrid)(({ dataGridHeight }) => ({
    height: dataGridHeight ? "230px" : "fit-content",
    ".MuiDataGrid-filler": {
      height: "0px !important",
      display: "none",
    },
    color: "var(--primary-text-color)",
    textTransform: "capitalize",
    ".MuiDataGrid-columnHeaderTitle": {
      textTransform: "none !important",
    },
    backgroundColor: "var(--subAccordian-background-color)",
    ".MuiDataGrid-cell ": {
      cursor: "pointer",
      alignContent: "center",
    },
    ".MuiDataGrid-cell:focus": {
      outline: "none",
    },
    ".MuiDataGrid-columnHeader:focus": {
      outline: "none",
    },
    ".MuiInput-underline:before": {
      borderBottom: "1px solid var(--primary-text-color) !important",
    },
    ".MuiSvgIcon-fontSizeSmall": {
      fill: "var(--primary-text-color) !important",
    },
    ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
    {
      color: "var(--primary-text-color) !important",
    },
    ".MuiDataGrid-overlay": {
      color: "var(--primary-text-color) !important",
      backgroundColor: "var(--subAccordian-background-color)",
    },
    ".MuiDataGrid-actionsCell": { type: "actions" }
  }));