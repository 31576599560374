import React from "react";
import { Box } from "@mui/material";

import { formatBirthDayDate } from "./UtilityFunctions";
import { Carousels, BirthDayShowCard, DOBTypography } from "../../Styles/AdminDashboard/CommonDashBoardStyle";

export const BirthDayShow = ({ upComingBirthDay }) => {
  return (
    <>
      {upComingBirthDay.length > 0 ? (
        <Carousels
          autoPlay={true}
          stopAutoPlayOnHover={true}
          indicators={false}
          swipe={true}
          animation={"fade"}
          interval={5000}
          duration={1500}
          navButtonsAlwaysInvisible={true}
        >
          {upComingBirthDay.map((item) => (
            <BirthDayShowCard
              key={item}
            >
              <Box>
                <lord-icon
                  src="https://cdn.lordicon.com/fkmafinl.json"
                  trigger="loop"
                  delay="50"
                  style={{ width: "50px", height: "50px" }}
                />
              </Box>
              <Box>
                <DOBTypography>
                  {item.FirstName} - {formatBirthDayDate(item.Date_Of_Birth)}
                </DOBTypography>
              </Box>
              {new Date(item.Date_Of_Birth).getUTCMonth() ===
                new Date().getUTCMonth() &&
                new Date(item.Date_Of_Birth).getUTCDate() ===
                new Date().getUTCDate() ? (
                <Box>
                  <lord-icon
                    src="https://cdn.lordicon.com/dkanzxbo.json"
                    trigger="loop"
                    colors="primary:#ee6d66,secondary:#e83a30,tertiary:#ffc738,quaternary:#9cf4df,quinary:#d4f49c"
                    style={{ width: "50px", height: "50px" }}
                  ></lord-icon>
                </Box>
              ) : (
                <Box></Box>
              )}
            </BirthDayShowCard>
          ))}
        </Carousels>
      ) : null}
    </>
  );
};
