import { Box, LinearProgress, Typography, styled } from "@mui/material"

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    display: "inline",
    fontWeight: "bold",
    fontSize: "130%",
}))

export const MainBox = styled(Box)(({ progessbarbox }) => ({
    border: "0.1px solid var(--secondary-highlight-color)",
    ...progessbarbox,
}))

export const LinearsProgress = styled(LinearProgress)(() => ({
    margin: "5px 0px 5px 0px",
    borderRadius: "5px",
    backgroundColor: "var(--secondary-highlight-color)",
    ".MuiLinearProgress-bar": {
        backgroundColor: "var(--secondary-color)",
    },
}))

export const ThisWeekBox = styled(Box)(({ progessbarbox }) => ({
    border: "1px solid #faaaaa1c",
    ...progessbarbox,
}))

export const ThisWeekLinearsProgress = styled(LinearProgress)(() => ({
    margin: "5px 0px 5px 0px",
    borderRadius: "5px",
    backgroundColor: "var(--reject-highlight-color)",
    ".MuiLinearProgress-bar": {
        backgroundColor: "var(--reject-color)",
    },
}))

export const ThisMonthBox = styled(Box)(({ progessbarbox }) => ({
    border: "1px solid #fbe2af14",
    ...progessbarbox,
}))

export const ThisMonthLinearsProgress = styled(LinearProgress)(() => ({
    margin: "5px 0px 5px 0px",
    borderRadius: "5px",
    backgroundColor: "var(--totalHrs-highlight-color)",
    ".MuiLinearProgress-bar": {
        backgroundColor: "var(--totalHrs-color)",
    },
}))

export const OverTimeBox = styled(Box)(({ progessbarbox }) => ({
    border: "1px solid var(--avgWorkingHrs-highlight-color)",
    ...progessbarbox,
}))

export const OverTimeLinearsProgress = styled(LinearProgress)(() => ({
    margin: "5px 0px 5px 0px",
    borderRadius: "5px",
    backgroundColor: "var(--avgWorkingHrs-highlight-color)",
    ".MuiLinearProgress-bar": {
        backgroundColor: "var(--avgWorkingHrs-color)",
    },
}))