import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import * as d3 from "d3";

import { RecordTypography } from "../../../Styles/TaskManagement/EmployeeEfficiencyStyle";

function BarChart({ dataArray, selectedButton, isMobile }) {
  const svgRef = useRef();

  useEffect(() => {
    if (!dataArray || dataArray.length === 0) return; // Handle empty data case gracefully

    d3.select(svgRef.current).selectAll("*").remove();
    const margin = { top: 8, right: 30, bottom: 15, left: 10 };
    const width = 330 - margin.left - margin.right;
    const height = 250 - margin.top - margin.bottom + 20;

    const svg = d3
      .select(svgRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom + 40)
      .append("g")
      .attr("transform", `translate(${margin.left + 30}, ${margin.top + 8})`);

    const x = d3
      .scaleBand()
      .domain(dataArray.map((d) => d.label))
      .range([0, width - 10])
      .padding(0.1);

    const y = d3
      .scaleLinear()
      // .domain([0, d3.max(dataArray, (d) => d.value)])
      .domain([0, d3.max(dataArray, (d) => d.value) || 0]) // Ensure y domain starts from 0
      .nice()
      .range([height, 0]);

    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("transform", "rotate(-45)")
      .style("fill", "var(--secondary-text-color)");

    svg
      .select(".domain")
      .attr("stroke", "var(--secondary-text-color)")
      .attr("stroke-width", 1.5)
      .style("color", "var(--secondary-text-color)");

    svg
      .selectAll(".tick line")
      .attr("stroke", "var(--secondary-text-color)")
      .attr("stroke-width", 1.5);

    svg
      .append("g")
      .call(d3.axisLeft(y))
      .style("color", "var(--secondary-text-color)")
      .attr("stroke-width", 1.5);

    svg
      .selectAll(".bar")
      .data(dataArray)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", (d) => x(d.label) + 19)
      // .attr("y", (d) => y(d.value))
      .attr("y", (d) => (y(d.value) || 0)) // Ensure y value is valid
      .attr("width", x.bandwidth() - 40)
      // .attr("height", (d) => height - y(d.value))
      .attr("height", (d) => (height - y(d.value)) || 0) // Ensure height is valid
      .attr("fill", (d) => d.color);
  }, [dataArray]);

  return (
    <Box sx={{ marginBottom: "-20px" }}>
      <Box className="bar-chart">
        <RecordTypography>
          {selectedButton} Records
        </RecordTypography>

        <Box sx={{ paddingTop: "40px", paddingLeft: isMobile ? "" : "10px" }}>
          <svg
            id="my_dataviz"
            style={{
              height: "328px",
              bottom: "2px",
            }}
            ref={svgRef}
          ></svg>
        </Box>
      </Box>
    </Box>
  );
}

export default BarChart;
