import React, { useState } from 'react'
import { Button, Checkbox, FormControlLabel, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import LeaveAssignCard from './LeaveAssignCard';
import { sucessButton, empTaskTableBodyCell, empTaskTableHeadCell } from "../ReusableComponents/CustomDesignMUI/CustomMUI";
import { TableHeads, EmpDetailGrid, EmpMainBox, UpdateButton, TableCompMainBox, TableRows, IconButtons } from "../../Styles/CompanyAndLeavePolicy/AssignLeavesStyle"

function TableComponentForleaveAssigned(props) {

	const { array, showEmployeelist, employeeList, selectedDataDailog, setChange, change } = props;

	const [openDailogBox, setOpenDailogBox] = useState(false);
	const [selectEmployeeCard, setSelectEmployeeCard] = useState(false);
	const [updateMonthlyRecord, setUpdateMonthlyRecord] = useState(false);
	const [rowNumber, setRowNumber] = useState(null);
	const [dates, setDates] = useState('');
	return (
		<TableContainer>
			<Table>
				<TableHeads>
					<TableCell sx={empTaskTableHeadCell}></TableCell>
					<TableCell sx={empTaskTableHeadCell}>Start Date</TableCell>
					<TableCell sx={empTaskTableHeadCell}>End Date</TableCell>
					<TableCell sx={empTaskTableHeadCell}>Percentage</TableCell>
					<TableCell sx={empTaskTableHeadCell}>Assigned</TableCell>
					<TableCell sx={empTaskTableHeadCell}>Action</TableCell>
				</TableHeads>
				<TableBody>
					{array.map((row, rowIndex) => (
						<React.Fragment key={rowIndex}>
							<TableRows key={rowIndex}
								onClick={() => {
									if ((rowIndex + 1) === rowNumber) {
										setRowNumber(null)
									} else {
										setRowNumber(rowIndex + 1);
									}
								}}>
								<TableCell sx={{ ...empTaskTableBodyCell, width: '10px' }}>
									<IconButtons>
										{rowNumber === (rowIndex + 1) ?
											<ExpandLessIcon sx={{ color: 'var(--secondary-text-color)' }} /> :
											<ExpandMoreIcon sx={{ color: 'var(--secondary-text-color)' }} />}
									</IconButtons>
								</TableCell>
								<TableCell sx={empTaskTableBodyCell}>
									{new Date(row.startDate)
										.toISOString()
										.split("T")[0].split('-').reverse().join('-')}
								</TableCell>
								<TableCell sx={empTaskTableBodyCell}>
									{new Date(row.EndDate)
										.toISOString()
										.split("T")[0].split('-').reverse().join('-')}
								</TableCell>
								<TableCell sx={empTaskTableBodyCell}>{row.percentage}%</TableCell>
								<TableCell sx={empTaskTableBodyCell}>{row.employeeIds?.length}/{employeeList?.length}</TableCell>
								<TableCell sx={{ ...empTaskTableBodyCell }}>
									{<TableCompMainBox>
										<UpdateButton
											color="primary"
											variant='contained'
											onClick={(e) => {
												e.preventDefault();
												e.stopPropagation();
												setUpdateMonthlyRecord(true);
												setSelectEmployeeCard(false);
												setOpenDailogBox(true);
												setDates({
													startDate: row.startDate,
													endDate: row.EndDate,
													employees: row.employeeIds,
													percentage: row.percentage,
													id: row._id
												})
											}}>Update</UpdateButton>
										{row.employeeIds?.length === employeeList?.length ? null :
											<Button sx={{ ...sucessButton, padding: '2px !important', }}
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													setSelectEmployeeCard(true);
													setUpdateMonthlyRecord(false);
													setOpenDailogBox(true);
													setDates({
														startDate: row.startDate,
														endDate: row.EndDate,
														employees: row.employeeIds,
														percentage: row.percentage,
														id: row._id
													})
												}}>
												Assign
											</Button>}

									</TableCompMainBox>
									}
								</TableCell>

							</TableRows>

							{showEmployeelist && rowNumber && rowIndex === (rowNumber - 1) ?
								<TableRow key={`detail_${rowIndex}`}>
									<TableCell sx={{ ...empTaskTableBodyCell, }}>
									</TableCell>
									<TableCell colSpan={5} sx={{ paddingLeft: '0px !important', paddingTop: '0px !important' }}>
										<EmpMainBox>
											<Grid container >
												{employeeList.map((employee) => (
													<EmpDetailGrid item xl={2} lg={3} md={3.5} sm={4} xs={6} key={employee._id}>
														<FormControlLabel
															control={
																<Checkbox
																	disabled
																	checked={row?.employeeIds.includes(employee._id)}
																/>
															}
															label={employee.firstName}
														/>
													</EmpDetailGrid>
												))}
											</Grid>
										</EmpMainBox>
									</TableCell>
								</TableRow>
								: null
							}
						</React.Fragment>
					))}
				</TableBody>
			</Table>
			<LeaveAssignCard
				setChange={setChange}
				change={change}
				selectedDataDailog={selectedDataDailog}
				open={openDailogBox}
				setOpenDailogBox={setOpenDailogBox}
				employeeList={employeeList}
				dates={dates}
				selectEmployeeCard={selectEmployeeCard}
				updateMonthlyRecord={updateMonthlyRecord}
			/>
		</TableContainer>
	)
}

export default TableComponentForleaveAssigned