import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import AttendanceTable from "../../AdminDashboard/Tables/AttendanceTable";
import {
  setemployeeAllAttendenceData,
  setSetAttendenceRecord,
} from "../../../Slices/employeeDetailSlice";
import { getAttendanceData } from "../../../Services/AttendanceManageServices";
import { AttendanceTabBox } from "../../../Styles/EmployeeManagement/Employee/AttendenceStyle";

const Attendance = ({ startDate, endDate }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const change = useSelector(
    (state) => state.addEmployee.setAttendenceStartRecord
  );
  const user = useSelector((state) => state.selectedActiveView.role);
  const currentEmployee = useSelector((state) => state.addEmployee.current)

  const [AttendenceData, setAttendenceData] = useState([]);
  const [working, setWorking] = useState();

  const email = currentEmployee?.email;
  const employeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const memoizedEmployeeFetchData = useMemo(() => {
    const user = employeeLogin ? "user" : adminLogin ? "admin" : "";
    if (user) {
      const getEmployeeData = async () => {
        try {
          let start;
          let end;
          if (startDate) {
            start = dayjs(startDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
          }
          if (endDate) {
            end = dayjs(endDate, 'DD-MM-YYYY').format('DD-MM-YYYY');
          }
          const response = await getAttendanceData(user, start ?? "", end ?? "")
          if (
            response.status === 200 ||
            response.status === 201 ||
            response.status === 202
          ) {
            const work = response?.data?.wholedata?.working;
            if (work === "false") {
              setWorking(false);
            } else {
              setWorking(true);
            }
            const dataRecords = response?.data?.wholedata?.records;
            if (startDate && endDate) {
              setAttendenceData(dataRecords);
            }
            else {
              const data = response?.data?.wholedata?.records;
              const data2 = data.reverse();
              setAttendenceData(data2);
            }
            dispatch(
              setemployeeAllAttendenceData(response.data.wholedata.records)
            );
            dispatch(setSetAttendenceRecord(false));
          }
        } catch (error) {
          // Handle error
        }
      };
      return getEmployeeData;
    } else {
      return () => { };
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    email,
    navigate,
    // weeklyData,
    // monthValue,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (employeeLogin || adminLogin) {
      memoizedEmployeeFetchData();
    }
  }, [memoizedEmployeeFetchData, employeeLogin, adminLogin, change]);

  return (
    <AttendanceTabBox>
      <AttendanceTable
        AttendenceData={AttendenceData}
        working={working}
        employeeLogin={true}
        adminLogin={false}
      />
    </AttendanceTabBox>
  );
};

export default Attendance;
