import React, { useRef, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  IconButton,
  Slide,
  TextField,
  Typography,
  Tooltip,
  Zoom,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Close } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent.js";
import AddKnowledge from "./AddKnowledge";
import Loader from "../Loader/Loader.js";
import { errorMessage } from "../../Services/axiosInstance.js";
import {
  deleteKnowledge,
  getAllKnowledge,
} from "../../Services/KnowledgeSharingServices.js";
import {
  MainBox,
  CancelButton,
  EmployeeDetailBox,
  CloseIconButton,
  ErrorTypography,
  DeleteButton,
  DialogsActions,
  InnerBoxTwoDialog,
  InnerBoxOneDialog,
  MainBoxDialog,
  InnerBoxFour,
  TitleBox,
  InnerBoxSix,
  InnerBoxFive,
  ContentBox,
  InnerBoxOne,
  NoDataFoundTypography,
  PublicKnowledgeButton,
  MySharedKnowledgeButton,
  InnerBoxTwo,
  EmployeeNameAutocomplete,
  LoadingBox,
  InnerBoxThree,
  KnowledgeButtonBox,
  SliderBox,
  InnerSliderBox,
} from "../../Styles/KnowledgeSharing/KnowledgeSharingStyle";
import usePermissions from "../RolePermission/usePermissions.js";

const KnowledgeSharing = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.selectedActiveView.role);
  const onAdminId = useSelector((state) => state.adminData.adminData?.empId);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const onEmpId = useSelector((state) => state.addEmployee.current?.empId);
  const allEmpDataWithoutOwnName = useSelector(
    (state) => state.taskData.employeesNames
  );
  const allEmployeeData = useSelector((state) => state.taskData.employeesNames);
  const { hasPermission } = usePermissions();

  const [displayError, setDisplayError] = useState("");
  const [selectedButton, setSelectedButton] = useState("Public Knowledge");
  const [change, setChange] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [_IdForDelete, set_IdForDelete] = useState("");
  const [_dataThatDelete, set_DataThatDelete] = useState({});
  const [filterKnowledge, setFilterKnowledge] = useState([]);
  const [isExistingKnowledge, setIsExistingKnowledge] = useState(false);
  const [existingKnowledge, setExistingKnowledge] = useState({});
  const [searchedText, setSearchedText] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [allKnowledges, setAllKnowledges] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width:600px)");
  const scrollRef = useRef(null);

  const adminLogin = user === "admin";
  const MyEmpID = adminLogin ? onAdminId : onEmpId;
  let data = allEmpDataWithoutOwnName.filter((item) => item.empId !== MyEmpID);
  const allEmpData = data;

  const EmployeesValueSetter = (event, newValue) => {
    setSelectedEmployees(newValue);
  };

  const onClose = () => {
    setOpenDialog(false);
    set_IdForDelete("");
  };

  const toggleForm = () => {
    setIsExistingKnowledge(false);
    setExistingKnowledge({});
    setShowForm(!showForm);
  };

  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const handleDeleteConfirm = async (idToDelete) => {
    try {
      const response = await deleteKnowledge(idToDelete);
      if (response.status === 200 || response.status === 201) {
        setDeleted(!deleted);
        onClose();
        dispatch(setToast(true));
        dispatch(
          setToastMsg(response.data.message ?? "Knowledge record deleted.")
        );
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "");
    }
  };

  const renderFormDeleteKnowledge = () => {
    return (
      <>
        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
          <Box>
            <CloseIconButton
              onClick={onClose}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
          <MainBoxDialog>
            <Box>
              <DialogTitle sx={{ fontWeight: "bold" }}>
                <InnerBoxOneDialog>
                  <InnerBoxTwoDialog
                    isdarktheme={isDarkTheme ? "true" : "false"}
                  />
                  <Box>
                    <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
                      Confirm Delete Knowledge
                    </Box>
                    <Typography
                      sx={{ color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82" }}
                    >
                      Are you sure you want to Delete{" "}
                      <span
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        {_dataThatDelete.Title}
                      </span>{" "}
                      Knowledge?
                    </Typography>
                  </Box>
                </InnerBoxOneDialog>
              </DialogTitle>
            </Box>
          </MainBoxDialog>
          <DialogsActions>
            <CancelButton
              isdarktheme={isDarkTheme ? "true" : "false"}
              onClick={onClose}
            >
              Cancel
            </CancelButton>
            <DeleteButton
              variant="contained"
              onClick={() => {
                handleDeleteConfirm(_IdForDelete);
              }}
              component={Link}
              color="error"
            >
              Delete
            </DeleteButton>
          </DialogsActions>
        </Box>
        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
          <ErrorTypography>{displayError}</ErrorTypography>
        </Box>
      </>
    );
  };

  const getknowledge = async (e) => {
    setIsLoading(true);
    try {
      const response = await getAllKnowledge();
      if (response.status === 200 || response.status === 201) {
        setAllKnowledges(response?.data?.AllKnowledges);
        setIsLoading(false);
      } else {
        setAllKnowledges([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };
  const debouncedFetchKnowledge = useMemo(
    () => debounce(getknowledge, 900),
    []
  );

  const getScrollAmount = () => {
    if (scrollRef.current) {
      return scrollRef.current.offsetWidth * 1.05;
    }
    return 700;
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: -getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({
        left: getScrollAmount(),
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    debouncedFetchKnowledge();
  }, [
    change,
    debouncedFetchKnowledge,
    // allKnowledges,
    searchedText,
    selectedEmployees,
    deleted,
  ]);

  useEffect(() => {
    if (
      hasPermission("My Shared Knowledge") &&
      selectedButton === "My Shared Knowledge"
    ) {
      const data = allKnowledges?.filter(
        (state) => state.createdBy === MyEmpID
      );
      if (searchedText) {
        const filteredDataByTitle = data?.filter((state) =>
          state.Title.toLowerCase().includes(searchedText.toLowerCase())
        );
        setFilterKnowledge(filteredDataByTitle);
      } else {
        setFilterKnowledge(data);
      }
    } else {
      if (hasPermission("Public Knowledge")) {
        const data = allKnowledges?.filter(
          (state) => state.createdBy !== MyEmpID
        );
        if (selectedEmployees.length === 0) {
          if (searchedText) {
            const filteredDataByTitle = data?.filter((state) =>
              state.Title.toLowerCase().includes(searchedText.toLowerCase())
            );
            setFilterKnowledge(filteredDataByTitle);
          } else {
            setFilterKnowledge(data);
          }
        } else {
          const filteredDataByEmpId = data?.filter((data) => {
            return selectedEmployees.some(
              (emp) => emp.empId === data.createdBy
            );
          });
          if (searchedText) {
            const filteredDataByTitle = filteredDataByEmpId?.filter((state) =>
              state.Title.toLowerCase().includes(searchedText.toLowerCase())
            );
            setFilterKnowledge(filteredDataByTitle);
          } else {
            setFilterKnowledge(filteredDataByEmpId);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [MyEmpID, searchedText, selectedButton, selectedEmployees, allKnowledges]);

  return (
    <Box sx={{ padding: "6px 4px 8px 7px" }}>
      {/* Public Knowledge & My Shared Knowledge && + Share New Knowledge BAR */}
      <MainBox>
        {/* Box for items on the left */}
        {isMobile ? (
          <SliderBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            overflow="hidden"
            position="relative"
          >
            <IconButton onClick={scrollLeft} style={{ marginRight: 12,
                 color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
             }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <InnerBoxOne>
              <InnerSliderBox
                ref={scrollRef}
                display="flex"
                flexDirection="row"
                overflowX="auto"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  flexShrink="0"
                  minWidth="100%"
                  justifyContent="center"
                >
                  {hasPermission("Public Knowledge") && (
                    <PublicKnowledgeButton
                      onClick={() => {
                        setSearchedText("");
                        setSelectedEmployees([]);
                        setSelectedButton("Public Knowledge");
                      }}
                      selectedbutton={selectedButton}
                    >
                      Public Knowledge
                    </PublicKnowledgeButton>
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexShrink="0"
                  minWidth="100%"
                  justifyContent="center"
                >
                  {hasPermission("My Shared Knowledge") && (
                    <MySharedKnowledgeButton
                      onClick={() => {
                        setSearchedText("");
                        setSelectedEmployees([]);
                        setSelectedButton("My Shared Knowledge");
                      }}
                      selectedbutton={selectedButton}
                    >
                      My Shared Knowledge
                    </MySharedKnowledgeButton>
                  )}
                </Box>
              </InnerSliderBox>
            </InnerBoxOne>
            <IconButton onClick={scrollRight} style={{ marginLeft: 12,
               color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82"}}>
              <ChevronRightIcon />
            </IconButton>
          </SliderBox>
        ) : (
          <>
            <InnerBoxOne>
              <Box>
                {hasPermission("Public Knowledge") && (
                  <PublicKnowledgeButton
                    onClick={() => {
                      setSearchedText("");
                      setSelectedEmployees([]);
                      setSelectedButton("Public Knowledge");
                    }}
                    selectedbutton={selectedButton}
                  >
                    Public Knowledge
                  </PublicKnowledgeButton>
                )}
                {hasPermission("My Shared Knowledge") && (
                  <MySharedKnowledgeButton
                    onClick={() => {
                      setSearchedText("");
                      setSelectedEmployees([]);
                      setSelectedButton("My Shared Knowledge");
                    }}
                    selectedbutton={selectedButton}
                  >
                    My Shared Knowledge
                  </MySharedKnowledgeButton>
                )}
              </Box>
            </InnerBoxOne>
          </>
        )}
        <KnowledgeButtonBox>
          {hasPermission("Share New Knowledge") && (
            <Tooltip
              title="Add New Task"
              arrow
              disableInteractive
              TransitionComponent={Zoom}
            >
              <Button variant="contained" color="primary" onClick={toggleForm}>
                + Share New Knowledge
              </Button>
            </Tooltip>
          )}
        </KnowledgeButtonBox>
      </MainBox>

      <InnerBoxTwo>
        {hasPermission("My Shared Knowledge") &&
        selectedButton === "My Shared Knowledge" ? (
          <></>
        ) : (
          hasPermission("Public Knowledge") && (
            <EmployeeNameAutocomplete
              // fullWidth
              multiple
              limitTags={1}
              options={allEmpData}
              getOptionLabel={(option) => option?.firstName}
              renderInput={(params) => (
                <TextField {...params} label="Filter By Employee Name" />
              )}
              value={selectedEmployees}
              onChange={EmployeesValueSetter}
            />
          )
        )}
        {(hasPermission("Public Knowledge") ||
          hasPermission("My Shared Knowledge")) && (
          <TextField
            sx={{
              marginLeft: "5px",
            }}
            label="Search By Title"
            variant="outlined"
            value={searchedText}
            onChange={(e) => {
              setSearchedText(e.target.value);
            }}
          />
        )}
      </InnerBoxTwo>

      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Box sx={{ margin: "20px 2px 2px 10px", bottom: 2 }}>
          <Grid container spacing={2}>
            {filterKnowledge.length === 0 ? (
              <Box sx={{ padding: "4%" }}>
                <NoDataFoundTypography
                  color="var(--secondary-text-color)"
                  variant="body0"
                >
                  No Knowledge Available!!
                </NoDataFoundTypography>
              </Box>
            ) : (
              filterKnowledge?.map((data, index) => (
                <Grid key={index} item lg={2} sm={3} xs={12}>
                  <InnerBoxFour>
                    <InnerBoxFive
                      onClick={() => {
                        setShowForm(true);
                        setIsExistingKnowledge(true);
                        setExistingKnowledge(data);
                      }}
                    >
                      {hasPermission("My Shared Knowledge") &&
                      selectedButton === "My Shared Knowledge" ? (
                        <InnerBoxSix>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenDialog(true);
                              set_IdForDelete(data._id);
                              set_DataThatDelete(data);
                            }}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </InnerBoxSix>
                      ) : (
                        <></>
                      )}
                      <ContentBox>
                        <TitleBox>{data?.Title}</TitleBox>
                        <EmployeeDetailBox>
                          {/* <CircleIcon
                        sx={{
                          fill: data?.status === "Active" ? "green" : "red",
                          fontSize: "12px",
                          margin: "5px 4px 0px 0px",
                        }}
                      /> */}
                          {
                            allEmployeeData.find(
                              (employee) => employee.empId === data.createdBy
                            ).firstName
                          }
                        </EmployeeDetailBox>
                      </ContentBox>
                    </InnerBoxFive>
                  </InnerBoxFour>
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      )}

      <Backdrop open={showForm} onClick={toggleForm} />
      <Slide direction="left" in={showForm} mountOnEnter unmountOnExit>
        <InnerBoxThree isdarktheme={isDarkTheme ? "true" : "false"}>
          <AddKnowledge
            setChange={setChange}
            change={change}
            onClose={toggleForm}
            isExistingKnowledge={isExistingKnowledge}
            setIsExistingKnowledge={setIsExistingKnowledge}
            existingKnowledge={existingKnowledge}
            setExistingKnowledge={setExistingKnowledge}
            selectedButton={selectedButton}
            setSelectedButton={setSelectedButton}
          />
        </InnerBoxThree>
      </Slide>
      {/* <DeleteKnowledgeDialog
				open={openDialog}
				setOpenDialog={setOpenDialog}
				_IdForDelete={_IdForDelete}
				set_IdForDelete={set_IdForDelete}
				_dataThatDelete={_dataThatDelete}
				setDeleted={setDeleted}
				deleted={deleted}
			/> */}
      <Box>
        <DialogComponent
          maxWidth="md"
          onClose={onClose}
          open={openDialog}
          renderChildern={renderFormDeleteKnowledge}
        />
      </Box>
    </Box>
  );
};

export default KnowledgeSharing;
