import { combineReducers } from "redux";
import userDataReducer from "./Slices/UserDataSlice";
import blackThemeReducer from "./Slices/blackThemeSlice";
import admindataReducer from "./Slices/adminDetailSlice";
import selectedAdminView from "./Slices/selectedActive";
import employeeDetailSlice from "./Slices/employeeDetailSlice";
// import employeeIndexShowSlice from "./Slices/employeeIndexShow";
// import employeeDeleteIndex from "./Slices/employeeDeleteIndex";
import toastSlice from "./Slices/toastSlice";
import searchData from "./Slices/searchData";
import leaveData from "./Slices/leaveData";
import timerDetailsSlice from "./Slices/timerDetailsSlice";
import taskSlice from "./Slices/taskSlice";
const rootReducer = combineReducers({
  employeeData: userDataReducer,
  theme: blackThemeReducer,
  adminData: admindataReducer,
  selectedActiveView: selectedAdminView,
  addEmployee: employeeDetailSlice,
  // employeeIndex: employeeIndexShowSlice,
  // EmployeeDelete: employeeDeleteIndex,
  toast: toastSlice,
  searchData: searchData,
  employeeLeaveData: leaveData,
  timerData: timerDetailsSlice,
  taskData: taskSlice,
});

export default rootReducer;
