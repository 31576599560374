import { Box, Grid, styled } from "@mui/material"

export const MainGrid = styled(Grid)(({ ismobile }) => ({
    padding: "0px 6px 0px 0px",
    marginTop: ismobile !== "true" && "30px",
    paddingRight: "10px",
    "@media (max-width: 600px)": {
        width: "100%",
    },
}))

export const CheckInBox = styled(Box)(({ updatestatcompbox }) => ({
    border: "1px solid var(--secondary-highlight-color)",
    ...updatestatcompbox,
}))

export const CheckOutBox = styled(Box)(({ updatestatcompbox }) => ({
    border: "1px solid var(--reject-highlight-color)",
    ...updatestatcompbox,
}))

export const BreakTimeBox = styled(Box)(({ updatestatcompbox }) => ({
    border: "1px solid var(--totalHrs-highlight-color)",
    ...updatestatcompbox,
}))

export const WorkingHrsBox = styled(Box)(({ updatestatcompbox }) => ({
    border: "1px solid var(--avgWorkingHrs-highlight-color)",
    ...updatestatcompbox,
}))