import { Box, Button, TextField, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    marginTop: "10px",
    marginLeft: "9px",
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}))

export const EditBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "-2px",
}))

export const InputTextField = styled(TextField)(({ themechange }) => ({
    width: "80%",
    height: "55px",
    background: themechange === "true" ? "#142840" : "#ffffff",
    ".MuiTypography-root": {
        color: "var(--secondary-text-color) !important",
    },
}))

export const CustomTextField = styled(TextField)(({ themechange }) => ({
    width: "80%",
    height: "55px",
    background: themechange === "true" ? "#142840" : "#ffffff",
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    marginTop: "20px"
}))

export const CancelButton = styled(Button)(() => ({
    fontWeight: "bold",
    color: "gray",
}))

export const SaveButton = styled(Button)(() => ({
    fontWeight: "bold",
    color: "var(--primary-color)",
}))

export const SaveNextButton = styled(Button)(() => ({
    fontWeight: "bold",
    backgroundColor: "var(--secondary-color)",
    color: "#ffffff",
}))