import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  useMediaQuery
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import {
  InputField,
  InputFieldProps,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import { passwordRegex } from "../../Constant/constant";
import LoginImage from "./../../../assets/img/AdminLoginSVG.svg";
import { ReactComponent as BrandLogo } from "../../../assets/img/icons/EmployeeVueLogo.svg";
import {
  upsertCheckOtp,
  upsertGenerarteOTp,
  upsertResetPassword,
} from "../../../Services/Authentication";
import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice";
import {
  ConfirmPasswordInputBox,
  ErrorMessagesTypography,
  ErrorsTypography,
  InnerBoxFive,
  InnerBoxFour,
  InnerBoxOne,
  InnerBoxThree,
  InnerBoxTwo,
  MainBox,
  MainGrid,
  OtpButton,
  OtpInputBox,
  PasswordInputBox,
  ResendOtpInputBox,
  SaveButton,
} from "../../../Styles/Authentication/ResetPasswordStyle";
import {
  FooterMainBox,
  InputBox,
  LinkTypography,
  TextTypography,
  SpaceTypography
} from "../../../Styles/Authentication/LoginStyle";
import "../Login/Login.scss";

function ResetPassword() {
  const is_JT_theme_Dark =
    window.localStorage.getItem("is_JT_theme_Dark") === "true" ? true : false;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [newPasswordForm, setNewPasswordForm] = useState(true);
  const [generateOtp, setGenerateOtp] = useState("Generate Otp");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showOtp, setShowOtp] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [timer, setTimer] = useState(30);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const validateInputs = () => {
    let valid = true;
    setEmailError("");
    if (!email) {
      setEmailError("Email is required");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Please include an '@' in the email address.");
      valid = false;
    }
    return valid;
  };

  const validateInputs2 = () => {
    let valid = true;
    setOtpError("");
    if (!otp) {
      setOtpError("OTP is required");
      valid = false;
    } else if (!/^\d{6}$/.test(otp)) {
      setOtpError(
        "OTP must be exactly 6 digits and contain only numeric characters."
      );
      valid = false;
    }
    return valid;
  };

  const validateInputs3 = () => {
    let valid = true;
    setConfirmPasswordError("");
    setPasswordError("");
    if (!password) {
      setPasswordError("Password is required");
      valid = false;
    } else if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long"
      );
      valid = false;
    } else if (!confirmPassword) {
      setConfirmPasswordError("Confirm Password is required");
      valid = false;
    } else if (!passwordRegex.test(password)) {
      setConfirmPasswordError(
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long"
      );
      valid = false;
    } else if (!passwordRegex.test(confirmPassword)) {
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      valid = false;
    }
    return valid;
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    if (validateInputs()) {
      try {
        const postData = JSON.stringify({ email });
        await upsertGenerarteOTp(postData)
          .then((response) => {
            if (response.status === 200) {
              setErrorMessage("");
              setShowOtp(false);
              setGenerateOtp("confirm Otp");
              setIsTimerActive(true);
              setTimer(30);
              dispatch(setToast(true));
              dispatch(setToastMsg(response.data.message));
              dispatch(setToastType("success"));
            }
          })
          .catch((error) => {
            if (error.response.status === 510) {
              setErrorMessage(error.response.data.message);
            } else if (error.response.status === 500) {
              setErrorMessage(error.response.data.message);
            }
          });
      } catch (error) {}
    }
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    if (validateInputs2()) {
      try {
        const postData = JSON.stringify({ email, otp });
        await upsertCheckOtp(postData)
          .then((response) => {
            if (response.status === 200) {
              setErrorMessage("");
              setNewPasswordForm(false);
              dispatch(setToast(true));
              dispatch(setToastMsg(response.data.message));
              dispatch(setToastType("success"));
            }
          })
          .catch((error) => {
            if (error.response.status === 401) {
              setErrorMessage(error.response.data.message);
            } else if (error.response.status === 500) {
              setErrorMessage(error.response.data.message);
            }
          });
      } catch (error) {}
    }
  };

  const handleSubmit3 = async (e) => {
    setShowOtp(false);
    setGenerateOtp("confirm Otp");
    e.preventDefault();
    if (validateInputs3()) {
      try {
        const postData = JSON.stringify({ email, password });
        await upsertResetPassword(postData)
          .then((response) => {
            if (response.status === 200) {
              setErrorMessage("");
              navigate("/");
              dispatch(setToast(true));
              dispatch(setToastMsg(response.data.message));
              dispatch(setToastType("success"));
            }
          })
          .catch((error) => {
            if (error.response.status === 404) {
              setErrorMessage(error.response.data.message);
            } else if (error.response.status === 500) {
              setErrorMessage(error.response.data.message);
            }
          });
      } catch (error) {}
    }
  };

  const handleResendOTP = (e) => {
    handleSubmit1(e);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (newPasswordForm && showOtp) {
        handleSubmit1(e);
      }
      if (newPasswordForm && !showOtp) {
        handleSubmit2(e);
      }
    }
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      handleSubmit3(e);
    }
  };

  const subFooter = () => {
    return (
      <>
        <FooterMainBox>
          <Box sx={{ paddingRight: "8px" }}>
            <LinkTypography
              component="a"
              href="#"
              onClick={() => navigate("/")}
            >
              Log In
            </LinkTypography>
          </Box>
          {!isMobile && (
          <Box
            sx={{
              color: is_JT_theme_Dark
                ? "var(--darkTheme-inputfield-color)"
                : "var(--lightTheme-inputfield-color)",
            }}
          >
            |
          </Box>
          )}
          <Box sx={{ paddingLeft: "8px" }}>
            <LinkTypography
              component="a"
              href="#"
              onClick={() => navigate("/signup")}
            >
              Create New Account
            </LinkTypography>
          </Box>
        </FooterMainBox>
        <TextTypography isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
          By using TEAMSPACE, you confirm that you have read and agree to our{" "}
          <Link
            className="link"
            to="/privacy-policy"
            state={{ from: "notlogIn" }}
          >
            Privacy Policy
          </Link>{" "}
          and{" "}
          <Link className="link" to="/privacy-policy">
            Term of Service
          </Link>{" "}
        </TextTypography>
      </>
    );
  };

  useEffect(() => {
    let intervalId;
    if (isTimerActive) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isTimerActive]);

  useEffect(() => {
    if (timer === 0) {
      setIsTimerActive(false);
    }
  }, [timer]);

  return (
    <MainBox isjtthemedark={is_JT_theme_Dark ? "true" : "false"}>
      <InnerBoxOne>
        <MainGrid
          container
          item
          isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
        >
          <Grid item sx={12} sm={12} md={12} lg={6}>
          {!isMobile && (
            <InnerBoxTwo>
              <Box>
                <img src={LoginImage} alt="loginImg" className="login-image" />
              </Box>
            </InnerBoxTwo>
          )}
          </Grid>
          <Grid
            item
            sx={12}
            sm={12}
            md={12}
            lg={6}
            style={{
              background: is_JT_theme_Dark
                ? "var(--darkTheme-before-login-sideblock-bg)"
                : "var(--lightTheme-before-login-bg)",
            }}
          >
            <InnerBoxThree className="admin-login">
              <Box className="admin-form">
                <BrandLogo
                  className={is_JT_theme_Dark ? "brandLogoDarkThemeclass" : ""}
                  style={{
                    textAlign: "center",
                    maxWidth: "200px",
                    padding: "0 0 0 0",
                    // margin: "0px 0px 0px 100px",
                    display:"block",
                    margin:"auto"
                  }}
                />
                <SpaceTypography
                  variant="5"
                  isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
                >
                  TEAMSPACE
                </SpaceTypography>
                <Box sx={{marginTop:"30px"}}>
                  {newPasswordForm ? (
                    <Box>
                      <Box component="form" onKeyDown={handleKeyPress}>
                        <InputBox
                          isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
                        >
                          <TextField
                            inputProps={{ sx: InputFieldProps() }}
                            sx={InputField}
                            disabled={!showOtp}
                            type="email"
                            label="E-mail Address"
                            aria-describedby="emailHelp"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {emailError && (
                            <ErrorsTypography>{emailError}</ErrorsTypography>
                          )}
                        </InputBox>
                        {showOtp ? null : (
                          <>
                            <OtpInputBox
                              isjtthemedark={
                                is_JT_theme_Dark ? "true" : "false"
                              }
                            >
                              <TextField
                                inputProps={{ sx: InputFieldProps() }}
                                sx={InputField}
                                label="OTP"
                                type="text"
                                placeholder="OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                              />
                              {otpError && (
                                <ErrorsTypography>{otpError}</ErrorsTypography>
                              )}
                            </OtpInputBox>
                            <ResendOtpInputBox
                              isjtthemedark={
                                is_JT_theme_Dark ? "true" : "false"
                              }
                            >
                              {isTimerActive ? (
                                <span>Resend OTP in {timer} seconds</span>
                              ) : (
                                <Link
                                  className="link"
                                  onClick={handleResendOTP}
                                >
                                  Resend OTP
                                </Link>
                              )}
                            </ResendOtpInputBox>
                          </>
                        )}
                        <ErrorMessagesTypography>
                          {errorMessage}
                        </ErrorMessagesTypography>
                        <InnerBoxFour>
                          {showOtp ? (
                            <OtpButton
                              onClick={handleSubmit1}
                              variant="contained"
                            >
                              {generateOtp}
                            </OtpButton>
                          ) : (
                            <OtpButton
                              onClick={handleSubmit2}
                              variant="contained"
                            >
                              {generateOtp}
                            </OtpButton>
                          )}
                        </InnerBoxFour>
                      </Box>
                      <InnerBoxFive>
                        <Box>{subFooter()}</Box>
                      </InnerBoxFive>
                    </Box>
                  ) : (
                    <Box>
                      <Box component="form" onKeyDown={handleKey}>
                        <PasswordInputBox
                          isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
                        >
                          <TextField
                            inputProps={{ sx: InputFieldProps() }}
                            sx={InputField}
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            aria-describedby="passwordHelp"
                            placeholder="Enter New Password"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                              if (!passwordRegex.test(e.target.value)) {
                                setPasswordError(
                                  "Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long"
                                );
                              } else {
                                setPasswordError("");
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowPassword(!showPassword)
                                    }
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <VisibilityOffIcon
                                        sx={{
                                          color: is_JT_theme_Dark
                                            ? "var(--darkTheme-inputfield-color)"
                                            : "var(--lightTheme-inputfield-color)",
                                        }}
                                      />
                                    ) : (
                                      <VisibilityIcon
                                        sx={{
                                          color: is_JT_theme_Dark
                                            ? "var(--darkTheme-inputfield-color)"
                                            : "var(--lightTheme-inputfield-color)",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {passwordError && (
                            <ErrorsTypography>{passwordError}</ErrorsTypography>
                          )}
                        </PasswordInputBox>
                        <ConfirmPasswordInputBox
                          isjtthemedark={is_JT_theme_Dark ? "true" : "false"}
                        >
                          <TextField
                            inputProps={{ sx: InputFieldProps() }}
                            sx={InputField}
                            label="Confirm New Password"
                            type={showConfirmPassword ? "text" : "password"}
                            id="confirmPassword"
                            placeholder="Enter Confirm Password"
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              if (password !== e.target.value) {
                                setConfirmPasswordError(
                                  "Passwords do not match"
                                );
                              } else {
                                setConfirmPasswordError("");
                              }
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() =>
                                      setShowConfirmPassword(
                                        !showConfirmPassword
                                      )
                                    }
                                    edge="end"
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityOffIcon
                                        sx={{
                                          color: is_JT_theme_Dark
                                            ? "var(--darkTheme-inputfield-color)"
                                            : "var(--lightTheme-inputfield-color)",
                                        }}
                                      />
                                    ) : (
                                      <VisibilityIcon
                                        sx={{
                                          color: is_JT_theme_Dark
                                            ? "var(--darkTheme-inputfield-color)"
                                            : "var(--lightTheme-inputfield-color)",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                          {confirmPasswordError && (
                            <ErrorsTypography>
                              {confirmPasswordError}
                            </ErrorsTypography>
                          )}
                        </ConfirmPasswordInputBox>
                        <ErrorMessagesTypography>
                          {errorMessage}
                        </ErrorMessagesTypography>
                        <InnerBoxFour></InnerBoxFour>
                        <SaveButton onClick={handleSubmit3} variant="contained">
                          Save New Password
                        </SaveButton>
                      </Box>
                      {subFooter()}
                    </Box>
                  )}
                </Box>
              </Box>
            </InnerBoxThree>
          </Grid>
        </MainGrid>
      </InnerBoxOne>
    </MainBox>
  );
}

export default ResetPassword;
