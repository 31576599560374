import { Box, Button, DialogActions, IconButton, TextField, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "550px",
    height: "75px",
}))

export const CloseIconButton = styled(IconButton)(({ isdarktheme, ismobile }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: ismobile === "true" ? "20px" : "10px",
    right: "-5px",
    fontWeight: "bold",
    textTransform: "capitalize",
    marginRight: "15px",
    fontSize: "2px",
    "&:hover": {
        background: "var(--highlight-color)",
    },
}))

export const DOBTextField = styled(TextField)(({ isdarktheme }) => ({
    width: "100%",
    height: "55px",
    marginTop: "8%",
    color: isdarktheme === "true" ? "white" : "black",
    background: isdarktheme === "true" ? "#142840" : "#ffffff",
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
        filter: isdarktheme === "true" ? "invert(1)" : "none",
    },
}));

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const ContentBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#4781ce51",
    borderRadius: "1000px",
    padding: "8px",
    marginRight: "25px",
}))

export const DialogsActions = styled(DialogActions)(() => ({
    background: "var(--highlight-color)",
    boxShadow: "0px 2px 10px var(--dark-highlight-color)",
}))

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textTransform: "capitalize",
    fontWeight: "bold",
    marginRight: "10px",
    borderRadius: "5px",
}))

export const ConfirmDateRangeButton = styled(Button)(() => ({
    borderRadius: "5px",
    textTransform: "capitalize",
}))