import { Box, styled } from "@mui/material"
import { DropdownNestedMenuItem } from "../../components/NastedDropdown/Dropdown"

export const MainBox = styled(Box)(() => ({
    overflowY: "auto",
    margin: "0px 0px 25px 0px",
}))

export const TitleBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    borderBottom: "1px solid var(--table-border-color)",
    color: "var(--secondary-text-color)",
    marginBottom: "20px",
}))

export const DropdownNestedMenuItems = styled(DropdownNestedMenuItem)(() => ({
    padding: '0px 5px 0px 5px !important',
    '.MuiList-root-MuiMenu-list': {
        paddingTop: '4px !important',
        paddingBottom: '4px !important',
    }
}))

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "52vh"
}))