import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
	Accordion,
	Box,
	Button,
} from "@mui/material";
import ArrowRight from "@mui/icons-material/ArrowRight";

import Loader from "../Loader/Loader";
import {
	Dropdown,
	DropdownMenuItem,
} from "../NastedDropdown/Dropdown";
import TableComponent from "../ReusableComponents/TableComponent";
import { formatReverseDate } from "../ReusableComponents/UtilityFunctions";
import { getLeavesDropdown, getleaveBalanacesData } from "../../Services/LeaveManageServices";
import { DropdownNestedMenuItems, EmpNameAccordionDetails, EmpNameAccordionSummary, EmpNameExpandMoreIcon, EmployeeExpandMoreIcon, EmployeesAccordionDetails, EmployeesAccordionSummary, LoadingBox, NoRecordFound } from "../../Styles/CompanyAndLeavePolicy/ViewAssignLeaveBalanceStyle";

function ViewAssignLeaveBalance() {
	const currentAdminId = useSelector(
		(state) => state.adminData.adminData?.empId
	);

	const [isLoading, setIsLoading] = useState(false);
	const [employeeLeaveBalance, setEmployeeLeaveBalance] = useState([]);
	const [leavesDropdownArray, setLeavesDropdownArray] = useState([]);
	const [selectedData, setSelectedData] = useState("");

	const handleChange = (data, month) => {
		if (selectedData !== month) {
			setSelectedData(month);
			getleaveBalanaceData(data);
		}
	};

	const getleaveBalanaceData = async (data) => {
		if (data) {
			try {
				setIsLoading(true);
				// const response = await axios.get(
				// 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/getLeaveBalanceMonthly/${data}`,
				// 	getHeader
				// );
				const response = await getleaveBalanacesData(data);
				if (response.status === 200) {
					setIsLoading(false);
					setEmployeeLeaveBalance(response.data.viewLeavesArray);
				}
				else {
					setIsLoading(false);
					setEmployeeLeaveBalance([]);
				}
			} catch (error) {
				setIsLoading(false);

			}
		}
	};

	const fetchLeavesDropdown = async () => {
		try {
			// const response = await axios.get(
			// 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/getLeavesDropdown`,
			// 	getHeader
			// );
			const response = await getLeavesDropdown()
			if (response.status === 200) {
				setLeavesDropdownArray(response.data.leavesDropdownArray);
			}
			else {
				setLeavesDropdownArray([])
			}
		} catch (error) {

		}
	};

	useEffect(() => {
		fetchLeavesDropdown();
	}, []);

	useEffect(() => {
		if (selectedData === "") {
			const date = formatReverseDate(new Date());
			getleaveBalanaceData(date);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Box>
			<Box>
				<Box sx={{ marginBottom: "15px" }}>
					<Dropdown
						trigger={
							<Button variant="contained">
								{selectedData ? selectedData : "select Year"}
							</Button>
						}
						menu={leavesDropdownArray.map((data, index) => (
							<DropdownNestedMenuItems
								keepopen={false}
								label={data.key}
								rightIcon={
									data.value.length > 0 ? (
										<ArrowRight
											sx={{
												marginLeft: "4px !important",
											}}
										/>
									) : null
								}
								menu={
									data.value.length > 0
										? data.value.map(
											(subData, subIndex) => (
												<DropdownMenuItem
													keepopen={false}
													// style={{ padding: '4px 10px 4px 10px'}}
													onClick={() =>
														handleChange(
															subData.EndDate,
															subData.month
														)
													}
												>
													{subData.month}
												</DropdownMenuItem>
											)
										)
										: null
								}
							/>
						))}
					/>
				</Box>
				{isLoading ? (
					<LoadingBox>
						<Loader />
					</LoadingBox>
				) : (
					<Accordion keepopen={false} sx={{ marginBottom: "10px" }} expanded>
						<EmployeesAccordionSummary
							expandIcon={
								<EmployeeExpandMoreIcon />
							}
							aria-controls={`panel-content`}
							id={`panel-header`}
						>
							Employees
						</EmployeesAccordionSummary>
						<EmployeesAccordionDetails>
							{employeeLeaveBalance?.map((option, index) =>
								option.empId === currentAdminId ? null : (
									<Accordion
										key={index}
										sx={{ marginBottom: "10px" }}
										keepopen={false}
									>
										<EmpNameAccordionSummary
											expandIcon={
												<EmpNameExpandMoreIcon />
											}
											aria-controls={`panel${index + 1
												}-content`}
											id={`panel${index + 1}-header`}
										>
											{option.name} ({option.empId})
										</EmpNameAccordionSummary>
										<EmpNameAccordionDetails>
											<TableComponent
												array={option.data}
											/>
										</EmpNameAccordionDetails>
									</Accordion>
								)
							)}
							{employeeLeaveBalance?.length === 0 ? (
								<NoRecordFound>
									No records found.
								</NoRecordFound>
							) : null}
						</EmployeesAccordionDetails>
					</Accordion>
				)}
			</Box>
		</Box>
	);
}

export default ViewAssignLeaveBalance;
