import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Tooltip } from "@mui/material";
import Zoom from "@mui/material/Zoom";

import { formatTime } from "../../ReusableComponents/UtilityFunctions";
import { CircularsProgress, ContentTypography, ProgressMainBox, TaskDescriptionButton } from "../../../Styles/EmployeeManagement/Employee/TimerStyle";

function CircularProgressWithLabel({ setshowcirculardialogbox, ...restProps }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularsProgress
        restprops={restProps.colorchange}
        variant="determinate"
        thickness={4}
        {...restProps}
      />
      <ProgressMainBox>
        <ContentTypography
          variant="caption"
          restprops={restProps.colorchange}
        >
          {formatTime(restProps.elapsedtime)}
          <br />
          {restProps.isstart === "true" ? "" : `Working On`}
          <br />
          {/* {restProps.isstart === "true" ? (
            ""
          ) : ( */}
          <Box style={{ color: "#2c7be5" }}>
            <Tooltip
              followCursor
              title="Edit Ticket"
              placement="right-start"
              TransitionComponent={Zoom}
            >
              <TaskDescriptionButton
                variant="text"
                onClick={() => {
                  setshowcirculardialogbox(true.toString());
                }}
              >
                <span
                  style={{
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "90px",
                    color: "#9bc31c",
                  }}
                >
                  {restProps.currentworkingticketno}:{" "}
                  {window.localStorage.getItem("TaskDescription")}
                </span>
              </TaskDescriptionButton>
            </Tooltip>
          </Box>
          {/* )} */}
        </ContentTypography>
      </ProgressMainBox>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */

  value: PropTypes.number,
};

export default function CircularWithValueLabel({
  setshowcirculardialogbox,
  elapsedTime,
  isstart,
  currentWorkingTicketNo,
}) {
  const [progress, setProgress] = useState(0);
  const [colorChange, setColorChange] = useState(false);
  const [time, setTime] = useState(0);

  useEffect(() => {
    const time = (elapsedTime) => {
      if (elapsedTime >= 28800) {
        setColorChange(true);
        setTime(elapsedTime);
        return elapsedTime - 28800;
      } else {
        setColorChange(false);
        setTime(elapsedTime);
        return elapsedTime;
      }
    };
    const timer = setInterval(() => {
      const maxHour = 28800;
      setProgress((time(elapsedTime) / maxHour) * 100);
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, [elapsedTime]);

  return (
    <>
      <CircularProgressWithLabel
        setshowcirculardialogbox={setshowcirculardialogbox}
        isstart={isstart}
        value={progress}
        elapsedtime={time}
        currentworkingticketno={currentWorkingTicketNo}
        colorchange={colorChange.toString()}
      />
    </>
  );
}
