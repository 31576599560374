import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, IconButton, Tooltip, Zoom } from '@mui/material';
import {
    GridToolbar,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
    setToast,
    setToastMsg,
    setToastType,
} from "../../Slices/toastSlice";
import { numberArray } from "../Constant/constant";
import Loader from '../Loader/Loader';
import AddWorkFlowDialog from './AddWorkFlowDialog';
import { deleteWorkFlow, getAllWorkFlow } from '../../Services/WorkFlowServices';
import { LoadingBox, MainBox, TitleTypography, InnerBoxOne, AddWorkFlowBox, DataGrids } from "../../Styles/WorkFlow/WorkFlowStyle"

function WorkFlow() {
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [getWorkFlowData, setGetWorkFlowData] = useState([]);
    const [dataGridHeight, setDataGridHeight] = useState(false);
    const [openDialogBox, setOpenDialogBox] = useState(false);
    const [editId, setEditId] = useState("");
    // const [expandedRowIds, setExpandedRowIds] = useState(new Set());

    const getAllWorlFlowData = async () => {
        setIsLoading(true);
        try {
            const response = await getAllWorkFlow();
            if (response.status === 200) {
                setIsLoading(false);
                setGetWorkFlowData(response?.data?.workflows);
            } else {
                setGetWorkFlowData([]);
            }
        } catch (error) {
            setIsLoading(false);
        }
    };

    const deleteWorkFlowData = async (workFlowId) => {
        try {
            const response = await deleteWorkFlow(workFlowId)
            if (response.status === 200 || response.status === 201) {
                const id = response?.data?.workFlowId
                setGetWorkFlowData(prevData => prevData?.filter(workflow => workflow?._id !== id));
                dispatch(setToast(true));
                dispatch(setToastMsg(response.data.message ?? "WorkFlow deleted."));
                dispatch(setToastType("success"));
            }
        }
        catch (error) {
            // setDisplayError(errorMessage ?? "")
        }
    }

    // Handle row expansion and collapse
    // const handleRowClick = (id) => {
    //     setExpandedRowIds((prev) => {
    //         const newSet = new Set(prev);
    //         if (newSet.has(id)) {
    //             newSet.delete(id);
    //         } else {
    //             newSet.add(id);
    //         }
    //         return newSet;
    //     });
    // };

    const FormattedWorkFlowData = getWorkFlowData?.map((item, index) => ({
        id: index + 1,
        workFlowName: item?.workFlowName,
        workFlowId: item?._id,
    }));

    const columns = [
        // {
        //     field: 'expand',
        //     headerName: '',
        //     renderCell: (params) => (
        //         <IconButton onClick={() => handleRowClick(params.row.id)}>
        //             {expandedRowIds.has(params.row.id) ? (
        //                 <ExpandLessIcon sx={{ color: 'var(--secondary-text-color)' }} />
        //             ) : (
        //                 <ExpandMoreIcon sx={{ color: 'var(--secondary-text-color)' }} />
        //             )}
        //         </IconButton>
        //     ),
        // },
        { field: "id", headerName: "No.", flex: 1 },
        { field: "workFlowName", headerName: "Workflow Name", flex: 1 },
        {
            field: "actions",
            headerName: "Actions",
            flex: 0.7,
            renderCell: (params) => (
                <Box sx={{ display: "flex", gap: "2px" }}>
                    <Tooltip
                        title="Edit"
                        placement="bottom"
                        arrow
                        TransitionComponent={Zoom}
                    >
                        <IconButton
                            sx={{
                                color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                            }}
                            variant="contained"
                            onClick={() => {
                                setEditId(params?.row?.workFlowId)
                                setOpenDialogBox(true)
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title="Delete"
                        placement="bottom"
                        arrow
                        TransitionComponent={Zoom}
                    >
                        <IconButton
                            sx={{
                                color: isDarkTheme ? "#d94a38" : "#c62828",
                            }}
                            variant="contained"
                            onClick={() =>
                                deleteWorkFlowData(params?.row?.workFlowId)
                            }
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
    ]

    // const renderChildRow = (row) => (
    //     <Box sx={{ marginTop: '10px', padding: '10px', backgroundColor: '#f5f5f5' }}>
    //         <Grid container spacing={2}>
    //             <Grid item xs={12}>Child content for {row.workFlowName}</Grid>
    //         </Grid>
    //     </Box>
    // );

    useEffect(() => {
        getAllWorlFlowData()
    }, []);

    return (
        <Box>
            {isLoading ? (
                <LoadingBox>
                    <Loader />
                </LoadingBox>
            ) : (
                <Box>
                    <MainBox bottom={0}>
                        <TitleTypography variant="h6">
                            Workflow
                        </TitleTypography>
                        <AddWorkFlowBox>
                            <Tooltip
                                title="Add New WorkFlow"
                                arrow
                                disableInteractive
                                TransitionComponent={Zoom}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setOpenDialogBox(true)
                                    }}
                                >
                                    + Add WorkFlow
                                </Button>
                            </Tooltip>
                        </AddWorkFlowBox>
                    </MainBox>
                    <InnerBoxOne>
                        {/* <Box style={{ height: dataGridHeight ? 'calc(100vh - 64px)' : '100vh', width: '100%', overflowX: "auto" }}> */}
                        <Box style={{ maxHeight: "fit-content", width: '100%', overflowX: "auto" }}>
                            <Box sx={{
                                '@media (max-width: 600px)': {
                                    width: '170%',
                                },
                            }}>
                                <DataGrids
                                    disableColumnMenu
                                    slots={{ toolbar: GridToolbar }}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                        },
                                    }}
                                    aria-selected="false"
                                    rows={FormattedWorkFlowData}
                                    getRowId={(row) => row.id}
                                    columns={columns}
                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                startDate: false,
                                            },
                                        },
                                        pagination: {
                                            paginationModel: {
                                                pageSize:
                                                    parseInt(window.localStorage.getItem("paginationNo"), 10) ||
                                                    10,
                                            },
                                        },
                                    }}
                                    onRowCountChange={(params) => {
                                        if (params === 0) {
                                            setDataGridHeight(true);
                                        } else {
                                            setDataGridHeight(false);
                                        }
                                    }}
                                    onPaginationModelChange={(pageSize) => {
                                        window.localStorage.setItem("paginationNo", pageSize.pageSize);
                                    }}
                                    onPageSizeChange={(pageSize) => {
                                        window.localStorage.setItem("paginationNo", pageSize);
                                    }}
                                    pageSizeOptions={numberArray}
                                    components={{
                                        Toolbar: GridToolbar,
                                        // Row: (props) => {
                                        //     const { row } = props;
                                        //     return (
                                        //         <div>
                                        //             <DataGrid.Row {...props} />
                                        //             {expandedRowIds.has(row.id) && renderChildRow(row)}
                                        //         </div>
                                        //     );
                                        // },
                                    }}
                                    dataGridHeight={dataGridHeight}
                                />
                            </Box>
                        </Box>
                    </InnerBoxOne>
                </Box>)
            }
            <AddWorkFlowDialog
                editId={editId}
                setEditId={setEditId}
                open={openDialogBox}
                setOpenDialogBox={setOpenDialogBox}
                setGetWorkFlowData={setGetWorkFlowData}
            />
        </Box >
    )
}

export default WorkFlow