import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Tooltip, Zoom } from "@mui/material";

import LeaveAccordian from "../../LeaveManagement/LeaveAccordian";
import Loader from "../../Loader/Loader";
import LeaveDataGrid from "../Tables/LeaveDataGrid";
import { getLeavesData } from "../../../Services/LeaveManageServices";
import { LoadingBox, MainBox, InnerBoxTwo, InnerBoxOne, LeavesTypography } from "../../../Styles/LeaveManagement/LeaveManagementStyle"

const Leave = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.selectedActiveView.role);
  const token = window.localStorage.getItem("Token");
  const EmployeeLogin = user === "employee";

  const [absentData, setAbsentData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddLeave = () => {
    navigate("/leave/add");
  };

  const memoizedgetLeaveData = useMemo(() => {
    const getLeaveData = async () => {
      setIsLoading(true);
      try {
        // const response = await axios.get(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/user/employeeOnLeave`,
        //   getHeader
        // );
        const response = await getLeavesData();
        if (response.status === 200) {
          setIsLoading(false);
          const employeesLeaves = response.data.leaves;
          setAbsentData(response.data.leaveData);
          setLeaveData(
            employeesLeaves.sort(
              (a, b) => new Date(b.startDate) - new Date(a.startDate)
            )
          );
        } else if (response.status === 401) {
          navigate("/");
        } else {
          setAbsentData([]);
          setLeaveData([]);
        }
      } catch (error) {
        setIsLoading(false);
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   } else if (error.response.status === 500) {
        //     console.log(error.response.data.message);
        //   }
        // } else if (error.request) {
        // }
      }
    };
    return getLeaveData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    memoizedgetLeaveData();
  }, [memoizedgetLeaveData]);

  return (
    <Box>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Box>
          <MainBox>
            <InnerBoxOne
              bottom={0}
              sx={{
                "@media (max-width: 600px)": {
                  flexDirection: "row",
                },
              }}
            >
              <LeavesTypography variant="h6">Leaves</LeavesTypography>
              <InnerBoxTwo>
                <Tooltip
                  title="Add Leave Details"
                  arrow
                  disableInteractive
                  TransitionComponent={Zoom}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={
                      {
                        // background: "var(--primary-color)",
                        // fontWeight: "bold",
                      }
                    }
                    onClick={handleAddLeave}
                  >
                    + Add Leaves
                  </Button>
                </Tooltip>
              </InnerBoxTwo>
            </InnerBoxOne>
            {/* <Box
							sx={{
								boxShadow:
									"0px 0px 5px rgba(0, 0, 0, 0.14) inset",
								borderRadius: "5px",
								marginTop: "30px",
								background:
									"var(--background-table-sidebar-card-color)",
								padding: "0px 10px 2px 10px",
							}}
						>
							<Box sx={{ padding: "20px" }}>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									<Box>
										<SearchBar />
									</Box>
								</Box>
							</Box>
							<Box
								sx={{
									marginTop: "10px",
									maxHeight: "calc(100vh - 415px)",
								}}
								overflow="auto"
							> */}
            {/* <LeaveTable
							selectedButton={"Upcoming Leaves"}
							leaveData={leaveData}
						/> */}
            <LeaveDataGrid leaveData={leaveData} />
            {/* </Box> */}
            {/* </Box> */}
          </MainBox>
          {EmployeeLogin && absentData.length > 0 ? (
            <LeaveAccordian absentData={absentData} />
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default Leave;
