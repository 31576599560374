import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice.js";
import Dialogcomponent from '../ReusableComponents/DialogBoxComponents/DialogComponent'
import { errorMessage } from "../../Services/axiosInstance.js";
import { upsertAddGroup, editRole, upsertRole, getAllGroupData } from '../../Services/ManageRoleServices.js';
import { ErrorTypography, CloseIconButton, CancelButton, GroupNameBox, GroupNameTextField, InnerBoxFive, InnerBoxFour, InnerBoxSix, InnerBoxThree, InnerBoxTwo, LevelNameBox, LinkBox, UpsertRoleButton } from "../../Styles/ManageRoles/ManageRolesStyle.js";
import { DialogsActions } from "../../Styles/Authentication/LogOutStyle.js";

const RolesDialog = ({
  buttonSelected,
  open,
  setOpenDialogBox,
  openDialogName,
  selectedRecord,
  FormattedTaskData,
  setChange,
  change,
}) => {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const selectedData = FormattedTaskData.find(
    (state) => state.role === selectedRecord
  );

  const [roleName, setRoleName] = useState("");
  const [listOfGroup, setListOfGroup] = useState([]);
  const [group, setGroup] = useState("");
  const [groupName, setGroupName] = useState("");
  const [levelName, setLevelName] = useState("");
  const [id, setId] = useState("");
  const [displayError, setDisplayError] = useState("");
  const [selectedValueGroup, setSelectedValueGroup] = useState("");
  const [error, setError] = useState("");

  const addGroup = async () => {
    const errors = [];
    if (!groupName) {
      errors.groupName = "Add Group..";
      setError(errors);
      setDisplayError("");
    } else {
      try {
        // const response = await axios.post(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/addGroup`,
        //   JSON.stringify({ groupName }),
        //   postHeader
        // );
        const postData = JSON.stringify({ groupName });
        const response = await upsertAddGroup(postData);
        if (response.status === 200) {
          if (response.data.fail) {
            setDisplayError(response.data.message);
          } else {
            if (buttonSelected === "Add Group") {
              setGroupName("");
              setOpenDialogBox(false);
              dispatch(setToast(true));
              dispatch(setToastMsg(response.data.message ?? "Group Name Added."));
              dispatch(setToastType("success"));
            }
            if (buttonSelected === "Add Role") {
              setSelectedValueGroup(false);
              setGroupName("");
            }
            setError("");
            setDisplayError("");
          }
        }
      } catch (error) {
        setDisplayError(errorMessage ?? "")
      }
    }
  };

  const addRole = async () => {
    const errors = [];
    if (!roleName) {
      errors.roleName = "Add Role..";
      setError(errors);
    } else if (!group) {
      errors.group = "Select Group..";
      setError(errors);
    } else if (!levelName) {
      errors.levelName = "Select Level..";
      setError(errors);
    } else {
      if (buttonSelected === "Update Role") {
        try {
          // const response = await axios.put(
          //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/UpdateRole`,
          //   JSON.stringify({ roleName, group, levelName, id }),
          //   postHeader
          // );
          const postData = JSON.stringify({ roleName, group, levelName, id });
          const response = await editRole(postData);
          if (response.status === 200) {
            if (change === true) {
              setChange(false);
            } else {
              setChange(true);
            }
            setOpenDialogBox(false);
            dispatch(setToast(true));
            dispatch(setToastMsg(response.data.message ?? "Role updated."));
            dispatch(setToastType("success"));
          }
        } catch (error) {
          setDisplayError(errorMessage ?? "")
        }
      } else {
        try {
          // const response = await axios.post(
          //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/addRole`,
          //   JSON.stringify({ roleName, group, levelName }),
          //   postHeader
          // );
          const postData = JSON.stringify({ roleName, group, levelName });
          const response = await upsertRole(postData);
          if (response.status === 200) {
            setRoleName("");
            setGroupName("");
            setGroup("");
            setLevelName("");
            setOpenDialogBox(false);
            if (change === true) {
              setChange(false);
            } else {
              setChange(true);
            }
            setOpenDialogBox(false);
            dispatch(setToast(true));
            dispatch(setToastMsg(response.data.message ?? "Role added."));
            dispatch(setToastType("success"));
          }
        } catch (error) {
          setDisplayError(errorMessage ?? "")
        }
      }
    }
  };

  const onClose = () => {
    setOpenDialogBox(false);
    setRoleName("");
    setGroupName("");
    setGroup("");
    setLevelName("");
    setError("");
    setSelectedValueGroup(false);
  }

  const renderForm = () => {
    return (<>
      <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
        <Box>
          <CloseIconButton
            onClick={onClose}
            isdarktheme={isDarkTheme ? "true" : "false"}
          >
            <Close />
          </CloseIconButton>
        </Box>
        <InnerBoxTwo>
          <Box>
            <DialogTitle sx={{ fontWeight: "bold" }}>
              <InnerBoxThree>
                <InnerBoxFour>
                  {buttonSelected === "Add Role" ||
                    buttonSelected === "Update Role" ? (
                    <AssignmentIndIcon
                      style={{ fontSize: "xx-large", fill: "#f4b73e" }}
                    />
                  ) : null}
                  {buttonSelected === "Add Group" ? (
                    <GroupAddIcon
                      style={{ fontSize: "xx-large", fill: "#f4b73e" }}
                    />
                  ) : null}
                </InnerBoxFour>
                <Box>
                  <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
                    {buttonSelected}
                  </Box>
                </Box>
              </InnerBoxThree>
            </DialogTitle>
          </Box>
        </InnerBoxTwo>
        <DialogContent>
          <InnerBoxFive
            isdarktheme={isDarkTheme ? "true" : "false"}
          >
            {buttonSelected === "Add Role" ||
              buttonSelected === "Update Role" ? (
              <>
                <TextField
                  fullWidth
                  label={openDialogName}
                  name="roleName"
                  value={roleName}
                  onChange={(event) => {
                    setRoleName(event.target.value);
                    setDisplayError("");
                    setError("");
                  }}
                  variant="outlined"
                />
              </>
            ) : null}
            {buttonSelected === "Add Group" ? (
              <>
                <TextField
                  fullWidth
                  label={openDialogName}
                  name="groupName"
                  value={groupName}
                  onChange={(event) => {
                    setGroupName(event.target.value);
                    setDisplayError("");
                    setError("");
                  }}
                  variant="outlined"
                ></TextField>
              </>
            ) : null}
          </InnerBoxFive>

          {buttonSelected === "Add Role" || buttonSelected === "Update Role" ? (
            <>
              <ErrorTypography>
                {error.roleName}
              </ErrorTypography>
            </>
          ) : null}

          {buttonSelected === "Add Group" ? (
            groupName ? (
              <ErrorTypography>
                {displayError}
              </ErrorTypography>
            ) : (
              <ErrorTypography>
                {error.groupName}
              </ErrorTypography>
            )
          ) : null}

          {buttonSelected === "Add Role" || buttonSelected === "Update Role" ? (
            <>
              <InnerBoxSix isdarktheme={isDarkTheme ? "true" : "false"}>
                <TextField
                  fullWidth
                  select
                  label="Group Name"
                  name="group"
                  value={group}
                  onChange={(event) => setGroup(event.target.value)}
                  variant="outlined"
                >
                  {listOfGroup?.map((item) => (
                    <MenuItem key={item.groupName} value={item.groupName}>
                      {item.groupName}
                    </MenuItem>
                  ))}
                </TextField>
              </InnerBoxSix>
              <ErrorTypography>
                {error.group}
              </ErrorTypography>
              <LinkBox isdarktheme={isDarkTheme ? "true" : "false"}>
                <Link
                  style={{
                    color: "#2369d5",
                  }}
                  type="button"
                  underline="hover"
                  onClick={() => {
                    setSelectedValueGroup(true);
                  }}
                >
                  Create One
                </Link>
              </LinkBox>
              {selectedValueGroup ? (
                <GroupNameBox isdarktheme={isDarkTheme ? "true" : "false"}>
                  <GroupNameTextField
                    inputProps={{
                      style: {
                        padding: "20px",
                        marginTop: "5px",
                        marginBottom: "5px",
                      },
                    }}
                    fullWidth
                    label="New Group Name"
                    name="groupName"
                    value={groupName}
                    onChange={(event) => {
                      setGroupName(event.target.value);
                      setError("");
                      setDisplayError("");
                    }}
                    variant="outlined"
                  ></GroupNameTextField>
                  <IconButton
                    onClick={() => {
                      setGroupName("");
                      setGroup("");
                      setError("");
                      setSelectedValueGroup(false);
                    }}
                  >
                    <CloseIcon
                      sx={{ color: "var(--secondary-text-color)" }}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setGroup(groupName);
                      setGroupName(groupName);
                      addGroup();
                    }}
                  >
                    <DoneIcon sx={{ color: "var(--secondary-text-color)" }} />
                  </IconButton>
                </GroupNameBox>
              ) : null}
              {groupName ? (
                <ErrorTypography>
                  {displayError}
                </ErrorTypography>
              ) : (
                <ErrorTypography>
                  {error.groupName}
                </ErrorTypography>
              )}
              <LevelNameBox isdarktheme={isDarkTheme ? "true" : "false"}>
                <TextField
                  fullWidth
                  select
                  label="Level Name"
                  name="levelName"
                  value={levelName}
                  onChange={(event) => {
                    setLevelName(event.target.value);
                    setError("");
                  }}
                  variant="outlined"
                >
                  {[...Array(12).keys()].map((value) => (
                    <MenuItem key={value + 1} value={value + 1}>
                      Level {value + 1}
                    </MenuItem>
                  ))}
                </TextField>
              </LevelNameBox>

              <ErrorTypography>
                {error.levelName}
              </ErrorTypography>
            </>
          ) : null}
        </DialogContent>

        <DialogsActions>
          <CancelButton
            onClick={onClose}
            isdarktheme={isDarkTheme ? "true" : "false"}
          >
            Cancel
          </CancelButton>
          {buttonSelected === "Update Role" ? (
            <UpsertRoleButton
              onClick={() => {
                addRole();
              }}
              variant="contained"
              component={Link}
              color="success"
            >
              {buttonSelected}
            </UpsertRoleButton>
          ) : null}
          {buttonSelected === "Add Role" ? (
            <UpsertRoleButton
              onClick={() => {
                addRole();
              }}
              variant="contained"
              component={Link}
              color="success"
            >
              {buttonSelected}
            </UpsertRoleButton>
          ) : null}
          {buttonSelected === "Add Group" ? (
            <UpsertRoleButton
              onClick={() => {
                addGroup();
              }}
              variant="contained"
              component={Link}
              color="success"
            >
              {buttonSelected}
            </UpsertRoleButton>
          ) : null}
        </DialogsActions>
      </Box>
    </>)
  }

  const memoizedAdminFetchData = useMemo(() => {
    const groupSelectData = async () => {
      try {
        // const response = await axios.get(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/getGroup`,
        //   getHeader
        // );
        const response = await getAllGroupData()
        if (response.status === 200) {
          setListOfGroup(response.data.groups);
        }
        else {
          setListOfGroup([])
        }
      } catch (error) {
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   }
        // }
      }
    };
    return groupSelectData;
  }, []);

  useEffect(() => {
    memoizedAdminFetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonSelected, group, open, groupName]);

  useEffect(() => {
    if (buttonSelected === "Update Role" && selectedData) {
      if (id !== selectedData.role_Id) {
        setGroup(selectedData.groupname);
        setLevelName(selectedData.levelName);
        setRoleName(selectedData.role);
        setId(selectedData.role_Id);
      }
    } else {
      setId("");
      setGroup("");
      setGroupName("");
      setLevelName("");
      setRoleName("");
    }
  }, [buttonSelected, selectedData, open, id]);

  return (
    <Box>
      <Dialogcomponent
        open={open}
        onClose={onClose}
        maxWidth="md"
        renderChildern={renderForm}
      />
    </Box>
    // <Box>
    //   <Dialog
    //     open={open}
    //     onClose={onClose}
    //     maxWidth="md"
    //   >
    //     <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
    //       <Box>
    //         <IconButton
    //           onClick={onClose}
    //           sx={{
    //             color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             position: "absolute",
    //             top: "10px",
    //             right: "-5px",
    //             fontWeight: "bold",
    //             textTransform: "capitalize",
    //             marginRight: "15px",
    //             fontSize: "2px",
    //             "&:hover": {
    //               background: "var(--highlight-color)",
    //             },
    //           }}
    //         >
    //           <Close />
    //         </IconButton>
    //       </Box>
    //       <Box
    //         sx={{
    //           marginTop: "10px",
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "space-between",
    //           width: "550px",
    //           height: "75px",
    //         }}
    //       >
    //         <Box>
    //           <DialogTitle sx={{ fontWeight: "bold" }}>
    //             <Box
    //               sx={{
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //               }}
    //             >
    //               <Box
    //                 sx={{
    //                   display: "flex",
    //                   justifyContent: "center",
    //                   alignItems: "center",
    //                   background: "#f4b73e25",
    //                   borderRadius: "1000px",
    //                   padding: "8px",
    //                   marginRight: "25px",
    //                 }}
    //               >
    //                 {buttonSelected === "Add Role" ||
    //                   buttonSelected === "Update Role" ? (
    //                   <AssignmentIndIcon
    //                     style={{ fontSize: "xx-large", fill: "#f4b73e" }}
    //                   />
    //                 ) : null}
    //                 {buttonSelected === "Add Group" ? (
    //                   <GroupAddIcon
    //                     style={{ fontSize: "xx-large", fill: "#f4b73e" }}
    //                   />
    //                 ) : null}
    //               </Box>
    //               <Box>
    //                 <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
    //                   {buttonSelected}
    //                 </Box>
    //               </Box>
    //             </Box>
    //           </DialogTitle>
    //         </Box>
    //       </Box>
    //       <DialogContent>
    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "left",
    //             marginTop: "-15px",
    //             paddingLeft: "35px",
    //             marginRight: "25px",
    //             color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //           }}
    //         >
    //           {buttonSelected === "Add Role" ||
    //             buttonSelected === "Update Role" ? (
    //             <>
    //               <TextField
    //                 fullWidth
    //                 label={openDialogName}
    //                 name="roleName"
    //                 value={roleName}
    //                 onChange={(event) => {
    //                   setRoleName(event.target.value);
    //                   setDisplayError("");
    //                   setError("");
    //                 }}
    //                 variant="outlined"
    //               />
    //             </>
    //           ) : null}
    //           {buttonSelected === "Add Group" ? (
    //             <>
    //               <TextField
    //                 fullWidth
    //                 label={openDialogName}
    //                 name="groupName"
    //                 value={groupName}
    //                 onChange={(event) => {
    //                   setGroupName(event.target.value);
    //                   setDisplayError("");
    //                   setError("");
    //                 }}
    //                 variant="outlined"
    //               ></TextField>
    //             </>
    //           ) : null}
    //         </Box>

    //         {buttonSelected === "Add Role" || buttonSelected === "Update Role" ? (
    //           <>
    //             <Typography
    //               sx={{
    //                 color: "red",
    //                 paddingLeft: "38px",
    //                 marginTop: "0px",
    //               }}
    //             >
    //               {error.roleName}
    //             </Typography>
    //           </>
    //         ) : null}

    //         {buttonSelected === "Add Group" ? (
    //           groupName ? (
    //             <Typography
    //               sx={{ color: "red", paddingLeft: "38px", marginTop: "0px" }}
    //             >
    //               {displayError}
    //             </Typography>
    //           ) : (
    //             <Typography
    //               sx={{ color: "red", paddingLeft: "38px", marginTop: "0px" }}
    //             >
    //               {error.groupName}
    //             </Typography>
    //           )
    //         ) : null}

    //         {buttonSelected === "Add Role" || buttonSelected === "Update Role" ? (
    //           <>
    //             <Box
    //               sx={{
    //                 display: "flex",
    //                 justifyContent: "left",
    //                 marginTop: "20px",
    //                 paddingLeft: "35px",
    //                 marginRight: "25px",
    //                 color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //               }}
    //             >
    //               <TextField
    //                 fullWidth
    //                 select
    //                 label="Group Name"
    //                 name="group"
    //                 value={group}
    //                 onChange={(event) => setGroup(event.target.value)}
    //                 variant="outlined"
    //               >
    //                 {listOfGroup?.map((item) => (
    //                   <MenuItem key={item.groupName} value={item.groupName}>
    //                     {item.groupName}
    //                   </MenuItem>
    //                 ))}
    //               </TextField>
    //             </Box>
    //             <Typography
    //               sx={{ color: "red", paddingLeft: "38px", marginTop: "0px" }}
    //             >
    //               {error.group}
    //             </Typography>
    //             <Box
    //               sx={{
    //                 color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //                 paddingLeft: "36px",
    //                 marginTop: "5px",
    //               }}
    //             >
    //               <Link
    //                 style={{
    //                   color: "#2369d5",
    //                 }}
    //                 type="button"
    //                 underline="hover"
    //                 onClick={() => {
    //                   setSelectedValueGroup(true);
    //                 }}
    //               >
    //                 Create One
    //               </Link>
    //             </Box>
    //             {selectedValueGroup ? (
    //               <Box
    //                 sx={{
    //                   display: "flex",
    //                   justifyContent: "left",
    //                   marginTop: "15px",
    //                   paddingLeft: "35px",
    //                   marginRight: "25px",
    //                   color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //                 }}
    //               >
    //                 <TextField
    //                   sx={{
    //                     width: { sm: 50, md: 350 },
    //                     "& .MuiInputBase-input": {
    //                       height: "0px", // adjust the height as needed
    //                     },
    //                   }}
    //                   inputProps={{
    //                     style: {
    //                       padding: "20px",
    //                       marginTop: "5px",
    //                       marginBottom: "5px",
    //                     },
    //                   }}
    //                   fullWidth
    //                   label="New Group Name"
    //                   name="groupName"
    //                   value={groupName}
    //                   onChange={(event) => {
    //                     setGroupName(event.target.value);
    //                     setError("");
    //                     setDisplayError("");
    //                   }}
    //                   variant="outlined"
    //                 ></TextField>
    //                 <IconButton
    //                   onClick={() => {
    //                     setGroupName("");
    //                     setGroup("");
    //                     setError("");
    //                     setSelectedValueGroup(false);
    //                   }}
    //                 >
    //                   <CloseIcon
    //                     sx={{ color: "var(--secondary-text-color)" }}
    //                   />
    //                 </IconButton>
    //                 <IconButton
    //                   onClick={() => {
    //                     setGroup(groupName);
    //                     setGroupName(groupName);
    //                     addGroup();
    //                   }}
    //                 >
    //                   <DoneIcon sx={{ color: "var(--secondary-text-color)" }} />
    //                 </IconButton>
    //               </Box>
    //             ) : null}
    //             {groupName ? (
    //               <Typography
    //                 sx={{
    //                   color: "red",
    //                   paddingLeft: "38px",
    //                   marginTop: "0px",
    //                 }}
    //               >
    //                 {displayError}
    //               </Typography>
    //             ) : (
    //               <Typography
    //                 sx={{
    //                   color: "red",
    //                   paddingLeft: "38px",
    //                   marginTop: "0px",
    //                 }}
    //               >
    //                 {error.groupName}
    //               </Typography>
    //             )}
    //             <Box
    //               sx={{
    //                 display: "flex",
    //                 justifyContent: "left",
    //                 marginTop: "20px",
    //                 paddingLeft: "35px",
    //                 marginRight: "25px",
    //                 color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //               }}
    //             >
    //               <TextField
    //                 fullWidth
    //                 select
    //                 label="Level Name"
    //                 name="levelName"
    //                 value={levelName}
    //                 onChange={(event) => {
    //                   setLevelName(event.target.value);
    //                   setError("");
    //                 }}
    //                 variant="outlined"
    //               >
    //                 {[...Array(12).keys()].map((value) => (
    //                   <MenuItem key={value + 1} value={value + 1}>
    //                     Level {value + 1}
    //                   </MenuItem>
    //                 ))}
    //               </TextField>
    //             </Box>

    //             <Typography
    //               sx={{ color: "red", paddingLeft: "38px", marginTop: "0px" }}
    //             >
    //               {error.levelName}
    //             </Typography>
    //           </>
    //         ) : null}
    //       </DialogContent>

    //       <DialogActions
    //         sx={{
    //           background: "var(--highlight-color)",
    //           boxShadow: "0px 2px 10px var(--dark-highlight-color)",
    //         }}
    //       >
    //         <Button
    //           onClick={onClose}
    //           sx={{
    //             color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //             textTransform: "capitalize",
    //             fontWeight: "bold",
    //             marginRight: "10px",
    //             borderRadius: "5px",
    //           }}
    //         >
    //           Cancel
    //         </Button>
    //         {buttonSelected === "Update Role" ? (
    //           <Button
    //             onClick={() => {
    //               addRole();
    //             }}
    //             variant="contained"
    //             sx={{
    //               borderRadius: "5px",
    //               textTransform: "capitalize",
    //             }}
    //             component={Link}
    //             color="success"
    //           >
    //             {buttonSelected}
    //           </Button>
    //         ) : null}
    //         {buttonSelected === "Add Role" ? (
    //           <Button
    //             onClick={() => {
    //               addRole();
    //             }}
    //             variant="contained"
    //             sx={{
    //               borderRadius: "5px",
    //               textTransform: "capitalize",
    //             }}
    //             component={Link}
    //             color="success"
    //           >
    //             {buttonSelected}
    //           </Button>
    //         ) : null}
    //         {buttonSelected === "Add Group" ? (
    //           <Button
    //             onClick={() => {
    //               addGroup();
    //             }}
    //             variant="contained"
    //             sx={{
    //               borderRadius: "5px",
    //               textTransform: "capitalize",
    //             }}
    //             component={Link}
    //             color="success"
    //           >
    //             {buttonSelected}
    //           </Button>
    //         ) : null}
    //       </DialogActions>
    //     </Box>
    //   </Dialog>
    // </Box>
  );
};

export default RolesDialog;
