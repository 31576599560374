import { axiosInstance } from './axiosInstance';

export const upsertKnowledge = (postData) => axiosInstance.post('/user/knowledge', postData);

export const editKnowledge = (id,postData) => axiosInstance.put(`/user/knowledge/${id}`, postData);

export const deleteKnowledge = (id) => axiosInstance.delete(`/user/DeleteKnowledge/${id}`);

export const getAllKnowledge = () => axiosInstance.get(`/user/getKnowledge`);

export const upsertKnowledgeFile = (knowledgeFileName, formData) => axiosInstance.post(`/user/upload-knowledge-file?fileName=${knowledgeFileName}`, formData);

