import { Box, Button, DialogActions, FormControl, IconButton, TextField, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    margin: "10px 0px 15px 0px",
    padding: "20px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    borderRadius: "5px",
    alignItems: "center",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    overflowY: "auto",
    height: "cal(100-20)vh",
}))

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "65vh",
}))

export const TitleBox = styled(Box)(() => ({
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 20px 0px",
    color: "var(--primary-text-color)",
}))

export const EmployeeNameTextField = styled(TextField)(() => ({
    paddingBottom: "10px",
    width: "98%",
    marginTop: "10px",
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
    {
        borderColor: "var(--secondary-text-color)",
    },
    svg: {
        fill: "var(--secondary-text-color)",
    },
}))
export const CustomFormControl = styled(FormControl)(() => ({
    paddingBottom: "10px",
    width: "98%",
    marginTop: "10px",
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ":
    {
        borderColor: "var(--secondary-text-color)",
    },
}))

export const CustomTextField = styled(TextField)(({ isdarktheme }) => ({
    width: "98%",
    paddingBottom: "10px",
    marginTop: "10px",
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ":
    {
        borderColor: "var(--secondary-text-color)",
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
        filter: isdarktheme === "true" ? "invert(1)" : "none",
    },
}))

export const FooterBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
}))

export const CancelButton = styled(Button)(() => ({
    fontWeight: "bold",
    color: "gray",
}))

export const SaveButton = styled(Button)(() => ({
    fontWeight: "bold",
    backgroundColor: "var(--secondary-color)",
    color: "#ffffff",
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const MainBoxHeader = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "450px",
    "@media (max-width: 600px)": {
        maxWidth: "100%",
    },
}))

export const CloseButton = styled(IconButton)(({ isdarktheme, ismobile }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    padding: ismobile === "true" ? "8px 5px" : "0px",
}))

export const MainBoxDialog = styled(Box)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "500px",
    "@media (max-width: 600px)": {
        maxWidth: "100%",
    },
}))

export const ContentBoxDialog = styled(Box)(({ ismobile }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: ismobile === "true" ? "35px 0px 10px" : "35px 0px",
}))

export const IconBox = styled(Box)(({ ismobile }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1000px",
    padding: "8px 5px",
}))

export const TextTypography = styled(Typography)(() => ({
    color: "#DAA520",
    fontSize: "small",
}))

export const DialogsActions = styled(DialogActions)(() => ({
    background: "var(--highlight-color)",
    boxShadow: "0px 2px 10px var(--dark-highlight-color)",
}))

export const CancelButtonDialog = styled(Button)(({ ismobile }) => ({
    color: "var(--secondary-text-color)",
    textTransform: "capitalize",
    fontWeight: "bold",
    marginRight: ismobile !== "true" && "10px",
    borderRadius: "5px",
}))

export const ContinueButton = styled(Button)(({ ismobile }) => ({
    borderRadius: "5px",
    textTransform: "capitalize",
    padding: ismobile === "true" ? "3px 8px" : "6px 16px",
    backgroundColor: "#DAA520",
    ":hover": {
        backgroundColor: "#b78509",
    },
}))

export const UpdateLeaveMainBox = styled(Box)(() => ({
    margin: "10px 0px 15px 0px",
    padding: "20px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    borderRadius: "10px",
    alignItems: "center",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const UpdateCustomTextField = styled(TextField)(({ isdarktheme }) => ({
    paddingBottom: "10px",
    width: "80%",
    marginTop: "10px",
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ":
    {
        borderColor: "var(--secondary-text-color)",
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
        filter: isdarktheme === "true" ? "invert(1)" : "none",
    },
}))

export const UpdateCustomFormControl = styled(FormControl)(() => ({
    paddingBottom: "10px",
    width: "80%",
    marginTop: "10px",
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ": {
        borderColor: "var(--secondary-text-color)",
    },
}))

export const UpdateLeaveFooterBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "end",
    marginTop: "30px",
}))