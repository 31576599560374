import { Dialog } from '@mui/material'
import React from 'react'

function DialogComponent(props) {
    const { onClose, open, maxWidth, arialabelledby, ariadescribedby, sx, renderChildern, PaperComponent, fullwidth, className, PaperProps } = props;
    return (
        <>
            <Dialog
                sx={sx}
                aria-labelledby={arialabelledby}
                aria-describedby={ariadescribedby}
                open={open}
                onClose={onClose}
                maxWidth={maxWidth}
                PaperComponent={PaperComponent}
                fullwidth={fullwidth}
                className={className}
                PaperProps={PaperProps}
            >
                {renderChildern()}
            </Dialog>
        </>)
}

export default DialogComponent