import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";

import TaskDialogBox from "../DetailTask";
import { numberArray } from "../../Constant/constant";
import "../../../assets/styles/DataGridStyles.css";

export default function EmployeeEfficiencyGrid({
	change,
	setChange,
	taskEdited,
	setTaskEdited,
	employeeEfficieny,
}) {
	// CSS styles for positioning the column menu at the top
	const admin = useSelector((state) => state.adminData.adminData?.empId);
	const [viewer, setViewer] = useState("");
	// const [viewerName, setViewerName] = useState("");
	const [taskId, setTaskId] = useState("");
	const [openDialog, setOpenDialog] = useState(false);
	const [dataGridHeight, setDataGridHeight] = useState(false);

	// const CustomNoRowsOverlay = () => {
	//   return (
	//     <Box sx={{ marginTop: "-80px",marginLeft:'10px', position: "fixed" }}>No records found</Box>
	//   );
	// };

	function handleDetailClick(id) {
		setOpenDialog(true);
		setTaskId(id);
	}

	const getStatusBackgroundColor = (status) => {
		switch (status) {
			case "In Progress":
				return "var(--pending-color)";
			case "Cancelled":
				return "var(--reject-color)";
			case "Completed":
				return "var(--approved-color)";
			case "Hold":
				return "var(--cancel-color)";
			case "To-Do":
				return "var(--primary-color)";
			default:
				return "var(--primary-color)";
		}
	};

	const FormattedTaskData =
		employeeEfficieny?.length > 0
			? employeeEfficieny[0]?.map((task, index) => ({
				id: index + 1,
				record_id: task?._id,
				timestamp: task?.timestamp,
				ticketNo: task?.ticketNo,
				history: task?.history,
				assignBy: task?.assignBy.name,
				assignByEmp: task?.assignBy.empId,
				assignTo: task?.name,
				assignToEmp: task?.empId,
				title: task?.title,
				project: task?.project,
				status: task?.status,
				statusValue: task?.statusValue,
				statusColor: task?.statusColor,
				category: task?.category,
				type: task?.type,
				description: task?.description,
				plannedHours: task?.plannedHours,
				actualHours: task?.actualHours,
			}))
			: [];

	const adminColumns = useMemo(
		() => [
			{ field: "project", headerName: "Project", flex: 2 },
			{ field: "ticketNo", headerName: "Ticket No", flex: 2 },
			{ field: "title", headerName: "Title", flex: 3 },
			{ field: "assignBy", headerName: "Assign By", flex: 2 },
			{
				field: "status",
				headerName: "Status",
				flex: 2,
				renderCell: (params) => {
					const { statusValue, statusColor } = params.row;
					return (
						<Box
							sx={{
								minWidth: "70px",
								backgroundColor: (statusValue && statusValue !== "0") ? statusColor : getStatusBackgroundColor(
									params.value
								), // Use the value of status
								alignItems: "center",
								color: "#fff",
								borderRadius: "5px",
								height: "40px",
								display: "flex",
								padding: "0px 10px 0px 10px ",
								justifyContent: "center",
							}}
						>
							{params.value} {/* Render the status value */}
						</Box>
					)
				},
			},
		],
		[]
	);
	useEffect(() => {
		// setViewerName("admin");
		setViewer(admin);
	}, [admin]);
	return (
		<Box style={{ width: "100%", maxHeight: "fit-content", overflowX: "auto" }}>
			<Box sx={{
				'@media (max-width: 600px)': {
					width: '300%',
				},
			}}>
				<DataGrid
					disableColumnMenu
					slots={{ toolbar: GridToolbar }}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
						},
					}}
					rows={FormattedTaskData}
					columns={adminColumns}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize:
									parseInt(
										window.localStorage.getItem("paginationNo"),
										10
									) || 10,
							},
						},
					}}
					onRowCountChange={(params) => {
						if (params === 0) {
							setDataGridHeight(true);
						} else {
							setDataGridHeight(false);
						}
					}}
					onRowClick={(params) => {
						handleDetailClick(params.row.ticketNo);
					}}
					onPaginationModelChange={(pageSize) => {
						window.localStorage.setItem(
							"paginationNo",
							pageSize.pageSize
						);
					}}
					onPageSizeChange={(pageSize) => {
						window.localStorage.setItem("paginationNo", pageSize);
					}}
					pageSizeOptions={numberArray}
					components={{
						Toolbar: GridToolbar,
						// noRowsOverlay: CustomNoRowsOverlay,
					}}
					sx={{
						height: dataGridHeight ? "230px" : "fit-content",
						".MuiDataGrid-filler": {
							height: "0px !important",
							display: "none",
						},
						color: "var(--primary-text-color)",
						textTransform: "capitalize",
						".MuiDataGrid-columnHeaderTitle": {
							textTransform: "none !important",
						},
						backgroundColor: "var(--subAccordian-background-color)",
						".MuiDataGrid-cell ": {
							cursor: "pointer",
							alignContent: "center",
						},
						".MuiDataGrid-cell:focus": {
							outline: "none",
						},
						".MuiDataGrid-columnHeader:focus": {
							outline: "none",
						},
						".MuiInput-underline:before": {
							borderBottom:
								"1px solid var(--primary-text-color) !important",
						},
						".MuiSvgIcon-fontSizeSmall": {
							fill: "var(--primary-text-color) !important",
						},
						".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
						{
							color: "var(--primary-text-color) !important",
						},
						// ".MuiSelect-icon": {
						//   fill: "var(--secondary-text-color)",
						// },
						// ".MuiTablePagination-root": {
						//   color: "var(--secondary-text-color)",
						// },
						// ".MuiDataGrid-root": {
						//   color: "var(--secondary-text-color)",
						// },
						// ".MuiDataGrid-cellContent": {
						//   color: "var(--secondary-text-color)",
						//   fontSize: "14px",
						// },
						// ".MuiDataGrid-columnHeader": {
						//   color: "var(--secondary-text-color)",
						//   fontSize: "18px",
						//   backgroundColor: "var(--primary-highlight-color)",
						//   width: "100vh",
						// },
						// ".MuiDataGrid-footerContainer": {
						//   color: "var(--secondary-text-color)",
						// },
						// ".MuiDataGrid-menuIconButton": {
						//   color: "var(--secondary-text-color)",
						// },
						// ".MuiDataGrid-sortIcon": {
						//   color: "var(--secondary-text-color)",
						//   visibility: true,
						// },
						// "MuiDataGrid-virtualScroller": { color: "red" },
						".MuiDataGrid-actionsCell": { type: "actions" },
						// ".MuiDataGrid-columnHeader:focus": {
						//   outlineColor: "rgb(25, 118, 210)",
						//   outlineStyle: "solid",
						//   outlineWidth: "0px",
						// },
						// ".MuiDataGrid-cell:focus": {
						//   outlineColor: "rgb(25, 118, 210)",
						//   outlineStyle: "solid",
						//   outlineWidth: "0px",
						// },
					}}
				/>
			</Box>
			<TaskDialogBox
				open={openDialog}
				// change={change}
				// setChange={setChange}
				setOpenDialog={setOpenDialog}
				// dialogHeading="Confirm Delete"
				// dialogDescription="Are you sure you want to delete this data?"
				// FormattedTaskData={FormattedTaskData}
				taskId={taskId}
				// setTaskEdited={setTaskEdited}
				// taskEdited={taskEdited}
				viewer={viewer}
			// viewerName={viewerName}
			/>
		</Box>
	);
}
