import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Checkbox,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import AddHomeIcon from "@mui/icons-material/AddHome";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";

import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../Slices/toastSlice";
import { deprecateLeaveType, setLeavesTypes } from '../../Slices/leaveData';
import DialogComponent from "../ReusableComponents/DialogBoxComponents/DialogComponent";
import { deleteHoliday, editHoliday, upsertHoliday } from "../../Services/HolidayServices";
import { deleteLeaveType, editLeaveType, getAllLeavesType, upsertLeaveType } from "../../Services/LeaveManageServices";
import { errorMessage } from "../../Services/axiosInstance";
import { MainBox, DateTextField, InnerBoxOne, CustomButton, CancelButton, CloseIconButton, DialogsActions, FormControlLabels, InnerBoxFive, InnerBoxFour, ErrorTypography, InnerBoxThree, InnerBoxTwo, TitleBox } from "../../Styles/CompanyAndLeavePolicy/DialogOfCompanyAndLeavePolicyStyle"

const DialogOfCompanyAndLeavePolicy = ({
  open,
  setOpenDialogBox,
  buttonSelected,
  buttonName,
  selectedRecordId,
  FormattedLeavesData,
  FormattedFestivalsData,
  festivalEditRecord,
  leaveEditRecord,
  festivalDeleteRecord,
  leaveDeleteRecord,
  // setFestivalEditRecord,
  // setFestivalDeleteRecord,
  // setLeaveEditRecord,
  // setLeaveDeleteRecord,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  // const LeavesType = useSelector( (state) => state.employeeLeaveData.leavesTypes);
  const dispatch = useDispatch();
  const selectedFestivalData = FormattedFestivalsData
    ? FormattedFestivalsData[selectedRecordId - 1]
    : null;
  const selectedLeaveData = FormattedLeavesData
    ? FormattedLeavesData[selectedRecordId - 1]
    : null;

  const [_id, set_id] = useState("");
  const [festival, setFestival] = useState("");
  const [festivalDate, setFestivalDate] = useState("");
  const [festivalDay, setFestivalDay] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [leaveNo, setLeaveNo] = useState("");
  const [enCash, setEnCash] = useState(false);
  const [deprecateLeave, setDeprecateLeave] = useState(false);
  const [displayError, setDisplayError] = useState("");
  const [error, setError] = useState([]);

  const dateAndDaySetter = (selectedDate) => {
    const formattedDate = dayjs(selectedDate).format("YYYY-MM-DD");
    const dayOfWeek = dayjs(selectedDate).format("dddd");
    setFestivalDate(formattedDate);
    setFestivalDay(dayOfWeek);
  };

  const resetForm = () => {
    setError([]);
    setDisplayError("");
    setOpenDialogBox(false);
  }

  const AddHolidayAction = async () => {
    const errors = [];
    if (!festival) {
      errors.festival = "Please Add Festival!";
      setError(errors);
      return;
    }
    if (!festivalDate) {
      errors.festivalDate = "Please Add Festival Date!";
      setError(errors);
      return;
    }

    try {
      const postData = JSON.stringify({ festival, festivalDate, festivalDay });
      const response = await upsertHoliday(postData)
      if (response.status === 200 || response.status === 201) {
        setFestival("");
        setFestivalDate("");
        setFestivalDay("");
        resetForm()
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "Festival added."));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const fetchLeaveTypes = async () => {
    try {
      const response = await getAllLeavesType();
      return response.data.leaveRecord;
    } catch (error) {
      return [];
    }
  };

  const AddLeaveTypeAction = async () => {
    const errors = [];
    if (!leaveType) {
      errors.leaveType = "Please Add Leave Type!";
      setError(errors);
      return;
    }
    if (!leaveNo) {
      errors.leaveNo = "Please Add Number Of Leave!";
      setError(errors);
      return;
    }
    try {
      // const response = await axios.post(
      //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/addLeaveType`,
      //   JSON.stringify({ leaveType, leaveNo, enCash }),
      //   postHeader
      // );
      const postData = JSON.stringify({ leaveType, leaveNo, enCash, deprecateLeave });
      const response = await upsertLeaveType(postData);
      if (response.status === 200) {
        if (deprecateLeave) {
          dispatch(deprecateLeaveType(response.data._id));
        } else {
          const updatedLeaveTypes = await fetchLeaveTypes();
          dispatch(setLeavesTypes(updatedLeaveTypes));
        }
        setLeaveType("");
        setLeaveNo("");
        resetForm()
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "Leave Type added."));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const EditHolidayAction = async () => {
    const errors = [];
    if (!festival) {
      errors.festival = "Please Add Festival!";
      setError(errors);
      return;
    }
    if (!festivalDate) {
      errors.festivalDate = "Please Add Festival Date!";
      setError(errors);
      return;
    }

    try {
      const postData = JSON.stringify({ _id, festival, festivalDate, festivalDay });
      const response = await editHoliday(postData);
      if (response.status === 200) {
        set_id("");
        setFestival("");
        setFestivalDate("");
        setFestivalDay("");
        resetForm()
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "Festival Updated."));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const EditLeaveTypeAction = async () => {
    const errors = [];
    if (!leaveType) {
      errors.leaveType = "Please Add Leave Type!";
      setError(errors);
      return;
    }
    if (!leaveNo) {
      errors.leaveNo = "Please Add Number Of Leave!";
      setError(errors);
      return;
    }

    try {
      // const response = await axios.put(
      //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/editLeaveType`,
      //   JSON.stringify({ _id, leaveType, leaveNo, enCash }),
      //   postHeader
      // );
      const postData = JSON.stringify({ _id, leaveType, leaveNo, enCash, deprecateLeave });
      const response = await editLeaveType(postData);
      if (response.status === 200 || response.status === 201) {
        if (deprecateLeave) {
          dispatch(deprecateLeaveType(_id));
        } else {
          const updatedLeaveTypes = await fetchLeaveTypes();
          dispatch(setLeavesTypes(updatedLeaveTypes));
        }
        set_id("");
        setLeaveType("");
        setLeaveNo("");
        resetForm();
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message ?? "Leave record Updated."));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const DeleteHolidayAction = async () => {
    try {
      // const response = await axios.delete(
      //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/deleteFestival/${_id}`,
      //   postHeader
      // );
      const response = await deleteHoliday(_id);
      if (response.status === 200) {
        set_id("");
        setFestival("");
        setFestivalDate("");
        setFestivalDay("");
        resetForm();
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const DeleteLeaveTypeAction = async () => {
    try {
      // const response = await axios.delete(
      //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/deleteLeaveType/${_id}`,
      //   postHeader
      // );
      const response = await deleteLeaveType(_id)
      if (response.status === 200) {
        set_id("");
        setLeaveType("");
        setLeaveNo("");
        resetForm();
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "")
    }
  };

  const onClose = () => {
    set_id("");
    setFestival("");
    setFestivalDate("");
    setFestivalDay("");
    setLeaveType("");
    setLeaveNo("");
    setDisplayError("");
    setError([]);
    setOpenDialogBox(false);
    // setFestivalEditRecord(false);
    // setFestivalDeleteRecord(false);
    // setLeaveEditRecord(false);
    // setLeaveDeleteRecord(false);
  }

  const renderForm = () => {
    return (<>
      <MainBox isdarktheme={isDarkTheme ? "true" : "false"}>
        <Box>
          <CloseIconButton
            onClick={onClose}
            isdarktheme={isDarkTheme ? "true" : "false"}
          >
            <Close />
          </CloseIconButton>
        </Box>
        <InnerBoxOne>
          <Box>
            <DialogTitle sx={{ fontWeight: "bold" }}>
              <InnerBoxTwo>
                <TitleBox>
                  {buttonName === "Add Holiday" ? (
                    <AddHomeIcon
                      color="success"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                  {buttonName === "Edit Holiday" ? (
                    <EditNoteIcon
                      color="warning"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                  {buttonName === "Delete Holiday" ? (
                    <DeleteSweepIcon
                      color="error"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                  {buttonName === "Add Leave Type" ? (
                    <PlaylistAddCircleIcon
                      color="success"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                  {buttonName === "Edit Leave Type" ? (
                    <EditNoteIcon
                      color="warning"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                  {buttonName === "Delete Leave Type" ? (
                    <DeleteSweepIcon
                      color="error"
                      style={{ fontSize: "xx-large" }}
                    />
                  ) : null}
                </TitleBox>
                <Box>
                  <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
                    {buttonName}
                  </Box>
                </Box>
              </InnerBoxTwo>
            </DialogTitle>
          </Box>
        </InnerBoxOne>
        <DialogContent>
          <InnerBoxThree isdarktheme={isDarkTheme ? "true" : "false"}>
            {buttonSelected === "Add Holiday" ? (
              <>
                <TextField
                  fullWidth
                  label="Festival"
                  name="festival"
                  value={festival}
                  onChange={(event) => {
                    setFestival(event.target.value);
                    setDisplayError("");
                    setError([]);
                  }}
                  variant="outlined"
                  disabled={festivalDeleteRecord}
                />
              </>
            ) : null}
            {buttonSelected === "Add Leave Type" ? (
              <>
                <TextField
                  fullWidth
                  label="Leave Type"
                  name="leaveType"
                  value={leaveType}
                  onChange={(event) => {
                    setLeaveType(event.target.value);
                    setDisplayError("");
                    setError([]);
                  }}
                  variant="outlined"
                  disabled={leaveDeleteRecord}
                ></TextField>
              </>
            ) : null}
          </InnerBoxThree>
          <ErrorTypography>
            {error.festival}
          </ErrorTypography>
          <ErrorTypography>
            {error.leaveType}
          </ErrorTypography>
          <InnerBoxFour isdarktheme={isDarkTheme ? "true" : "false"}>
            {buttonSelected === "Add Holiday" ? (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    '@media (min-width: 600px)': {
                      flexDirection: 'row',
                    },
                  }}
                >
                  <Box sx={{ width: "100%", '@media (min-width: 600px)': { width: "65%" } }}>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en-gb"
                    >
                      <DemoContainer components={["DatePicker"]}>
                        {
                          isMobile ? (
                            <DateTextField
                              label="Festival Date"
                              isdarktheme={isDarkTheme ? "true" : "false"}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {/* Add your icon here if needed */}
                                  </InputAdornment>
                                ),
                              }}
                              name="festivalDate"
                              value={festivalDate ? dayjs(festivalDate).format("YYYY-MM-DD") : null}
                              onChange={(e) => {
                                dateAndDaySetter(e.target.value);
                                setDisplayError("");
                                setError([]);
                              }}
                              disabled={festivalDeleteRecord}
                              type="date"
                            />)
                            : (<DatePicker
                              slotProps={{
                                openPickerButton: {
                                  color: "primary",
                                },
                              }}
                              label="Festival Date"
                              name="festivalDate"
                              value={festivalDate ? dayjs(festivalDate) : null}
                              onChange={(date) => {
                                dateAndDaySetter(date);
                                setDisplayError("");
                                setError([]);
                              }}
                              disabled={festivalDeleteRecord}
                            />)
                        }
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>

                  <Box sx={{ width: "100%", '@media (min-width: 600px)': { width: "60%", marginTop: "8px" } }}>
                    <TextField
                      fullWidth
                      label="Festival Day"
                      name="festivalDay"
                      value={festivalDay}
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Box>
                </Box>
              </>
            ) : null}
            {buttonSelected === "Add Leave Type" ? (
              <>
                <TextField
                  fullWidth
                  type="number"
                  label="No Of Leaves"
                  name="leaveNo"
                  value={leaveNo}
                  onChange={(event) => {
                    setLeaveNo(event.target.value);
                    setDisplayError("");
                    setError([]);
                  }}
                  variant="outlined"
                  disabled={leaveDeleteRecord}
                ></TextField>
              </>
            ) : null}
          </InnerBoxFour>
          <InnerBoxFive isdarktheme={isDarkTheme ? "true" : "false"}>
            {buttonSelected === "Add Leave Type" ? (
              <>
                <Box sx={{ height: "55px", paddingTop: '10px' }}>
                  <FormControlLabels
                    control={<Checkbox checked={enCash} onChange={() => setEnCash(!enCash)} />}
                    label="enCash"
                  />
                </Box>
                <Box sx={{ height: "55px", paddingTop: '10px' }}>
                  <FormControlLabels
                    control={<Checkbox checked={deprecateLeave} onChange={() => setDeprecateLeave(!deprecateLeave)} />}
                    label="Deprecate Leave"
                  />
                </Box>
              </>
            ) : null}
          </InnerBoxFive>
          <ErrorTypography>
            {error.festivalDate}
          </ErrorTypography>
          <ErrorTypography>
            {error.leaveNo}
          </ErrorTypography>
        </DialogContent>
        <ErrorTypography>
          {displayError}
        </ErrorTypography>
        <DialogsActions>
          <CancelButton
            onClick={onClose}
            isdarktheme={isDarkTheme ? "true" : "false"}
          >
            Cancel
          </CancelButton>

          {buttonSelected === "Add Holiday" ? (
            <CustomButton
              onClick={() => {
                if (buttonName === "Add Holiday") {
                  AddHolidayAction();
                }
                if (buttonName === "Edit Holiday") {
                  EditHolidayAction();
                }
                if (buttonName === "Delete Holiday") {
                  DeleteHolidayAction();
                }
              }}
              variant="contained"
              component={Link}
              color={
                festivalEditRecord
                  ? "warning"
                  : festivalDeleteRecord
                    ? "error"
                    : "success"
              }
            >
              {buttonName}
            </CustomButton>
          ) : null}
          {buttonSelected === "Add Leave Type" ? (
            <CustomButton
              onClick={() => {
                if (buttonName === "Add Leave Type") {
                  AddLeaveTypeAction();
                }
                if (buttonName === "Edit Leave Type") {
                  EditLeaveTypeAction();
                }
                if (buttonName === "Delete Leave Type") {
                  DeleteLeaveTypeAction();
                }
              }}
              variant="contained"
              component={Link}
              color={
                leaveEditRecord
                  ? "warning"
                  : leaveDeleteRecord
                    ? "error"
                    : "success"
              }
            >
              {buttonName}
            </CustomButton>
          ) : null}
        </DialogsActions>
      </MainBox>
    </>)
  }

  useEffect(() => {
    if (festivalEditRecord || festivalDeleteRecord) {
      if (selectedFestivalData) {
        set_id(selectedFestivalData.festival_Id);
        setFestival(selectedFestivalData.festival);
        setFestivalDate(
          dayjs(
            selectedFestivalData.festivalDate.split("-").reverse().join("-")
          )
        );
        setFestivalDay(selectedFestivalData.festivalDay);
      } else {
        set_id("");
        setFestival("");
        setFestivalDate("");
        setFestivalDay("");
      }
    }
    if (leaveEditRecord || leaveDeleteRecord) {
      if (selectedLeaveData) {
        set_id(selectedLeaveData.leave_Id);
        setLeaveType(selectedLeaveData.leaveType);
        setLeaveNo(selectedLeaveData.leaveNo);
        setDeprecateLeave(selectedLeaveData.deprecateLeave);
        if (selectedLeaveData.enCash === "Yes") {
          setEnCash(true);
        }
        else {
          setEnCash(false);
        }
      } else {
        set_id("");
        setLeaveType("");
        setLeaveNo("");
      }
    }
  }, [
    festivalEditRecord,
    leaveEditRecord,
    selectedFestivalData,
    selectedLeaveData,
    festivalDeleteRecord,
    leaveDeleteRecord,
  ]);

  return (
    <Box>
      <DialogComponent
        open={open}
        onClose={onClose}
        maxWidth="md"
        renderChildern={renderForm}
      />
    </Box>
    // <Box>
    //   <Dialog
    //     open={open}
    //     onClose={() => {
    //       set_id("");
    //       setFestival("");
    //       setFestivalDate("");
    //       setFestivalDay("");
    //       setLeaveType("");
    //       setLeaveNo("");
    //       setDisplayError("");
    //       setError([]);
    //       setOpenDialogBox(false);
    //       setFestivalEditRecord(false);
    //       setLeaveEditRecord(false);
    //       setFestivalDeleteRecord(false);
    //       setLeaveDeleteRecord(false);
    //     }}
    //     maxWidth="md"
    //   >
    //     <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
    //       <Box>
    //         <IconButton
    //           onClick={() => {
    //             set_id("");
    //             setFestival("");
    //             setFestivalDate("");
    //             setFestivalDay("");
    //             setLeaveType("");
    //             setLeaveNo("");
    //             setDisplayError("");
    //             setError([]);
    //             setOpenDialogBox(false);
    //             setFestivalEditRecord(false);
    //             setLeaveEditRecord(false);
    //             setFestivalDeleteRecord(false);
    //             setLeaveDeleteRecord(false);
    //           }}
    //           sx={{
    //             color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "center",
    //             position: "absolute",
    //             top: "10px",
    //             right: "-5px",
    //             fontWeight: "bold",
    //             textTransform: "capitalize",
    //             marginRight: "15px",
    //             fontSize: "2px",
    //             "&:hover": {
    //               background: "var(--highlight-color)",
    //             },
    //           }}
    //         >
    //           <Close />
    //         </IconButton>
    //       </Box>
    //       <Box
    //         sx={{
    //           marginTop: "10px",
    //           display: "flex",
    //           alignItems: "center",
    //           justifyContent: "space-between",
    //           width: "550px",
    //           height: "75px",
    //         }}
    //       >
    //         <Box>
    //           <DialogTitle sx={{ fontWeight: "bold" }}>
    //             <Box
    //               sx={{
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //               }}
    //             >
    //               <Box
    //                 sx={{
    //                   display: "flex",
    //                   justifyContent: "center",
    //                   alignItems: "center",
    //                   background: "#62626280",
    //                   borderRadius: "1000px",
    //                   padding: "8px",
    //                   marginRight: "25px",
    //                 }}
    //               >
    //                 {buttonName === "Add Holiday" ? (
    //                   <AddHomeIcon
    //                     color="success"
    //                     style={{ fontSize: "xx-large" }}
    //                   />
    //                 ) : null}
    //                 {buttonName === "Edit Holiday" ? (
    //                   <EditNoteIcon
    //                     color="warning"
    //                     style={{ fontSize: "xx-large" }}
    //                   />
    //                 ) : null}
    //                 {buttonName === "Delete Holiday" ? (
    //                   <DeleteSweepIcon
    //                     color="error"
    //                     style={{ fontSize: "xx-large" }}
    //                   />
    //                 ) : null}
    //                 {buttonName === "Add Leave Type" ? (
    //                   <PlaylistAddCircleIcon
    //                     color="success"
    //                     style={{ fontSize: "xx-large" }}
    //                   />
    //                 ) : null}
    //                 {buttonName === "Edit Leave Type" ? (
    //                   <EditNoteIcon
    //                     color="warning"
    //                     style={{ fontSize: "xx-large" }}
    //                   />
    //                 ) : null}
    //                 {buttonName === "Delete Leave Type" ? (
    //                   <DeleteSweepIcon
    //                     color="error"
    //                     style={{ fontSize: "xx-large" }}
    //                   />
    //                 ) : null}
    //               </Box>
    //               <Box>
    //                 <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
    //                   {buttonName}
    //                 </Box>
    //               </Box>
    //             </Box>
    //           </DialogTitle>
    //         </Box>
    //       </Box>

    //       <DialogContent>
    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "left",
    //             marginTop: "-15px",
    //             paddingLeft: "35px",
    //             marginRight: "25px",
    //             color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //           }}
    //         >
    //           {buttonSelected === "Add Holiday" ? (
    //             <>
    //               <TextField
    //                 fullWidth
    //                 label="Festival"
    //                 name="festival"
    //                 value={festival}
    //                 onChange={(event) => {
    //                   setFestival(event.target.value);
    //                   setDisplayError("");
    //                   setError([]);
    //                 }}
    //                 variant="outlined"
    //                 disabled={festivalDeleteRecord}
    //               />
    //             </>
    //           ) : null}
    //           {buttonSelected === "Add Leave Type" ? (
    //             <>
    //               <TextField
    //                 fullWidth
    //                 label="Leave Type"
    //                 name="leaveType"
    //                 value={leaveType}
    //                 onChange={(event) => {
    //                   setLeaveType(event.target.value);
    //                   setDisplayError("");
    //                   setError([]);
    //                 }}
    //                 variant="outlined"
    //                 disabled={leaveDeleteRecord}
    //               ></TextField>
    //             </>
    //           ) : null}
    //         </Box>
    //         <Typography
    //           sx={{
    //             color: "red",
    //             paddingLeft: "38px",
    //             marginTop: "0px",
    //           }}
    //         >
    //           {error.festival}
    //         </Typography>
    //         <Typography
    //           sx={{
    //             color: "red",
    //             paddingLeft: "38px",
    //             marginTop: "0px",
    //           }}
    //         >
    //           {error.leaveType}
    //         </Typography>

    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "center",
    //             alignItems: "flex-end",
    //             marginTop: "20px",
    //             paddingLeft: "35px",
    //             marginRight: "25px",
    //             color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //           }}
    //         >
    //           {buttonSelected === "Add Holiday" ? (
    //             <>
    //               <Box sx={{ width: "65%" }}>
    //                 <LocalizationProvider
    //                   dateAdapter={AdapterDayjs}
    //                   adapterLocale="en-gb"
    //                 >
    //                   <DemoContainer components={["DatePicker"]}>
    //                     <DatePicker
    //                       slotProps={{
    //                         openPickerButton: {
    //                           color: "primary",
    //                         },
    //                       }}
    //                       label="Festival Date"
    //                       name="festivalDate"
    //                       value={festivalDate}
    //                       onChange={(date) => {
    //                         dateAndDaySetter(date);
    //                         setDisplayError("");
    //                         setError([]);
    //                       }}
    //                       disabled={festivalDeleteRecord}
    //                     />
    //                   </DemoContainer>
    //                 </LocalizationProvider>
    //               </Box>

    //               <Box sx={{ width: "60%" }}>
    //                 <TextField
    //                   fullWidth
    //                   label="Festival Day"
    //                   name="festivalDay"
    //                   value={festivalDay}
    //                   variant="outlined"
    //                   InputProps={{
    //                     readOnly: true,
    //                   }}
    //                 />
    //               </Box>
    //             </>
    //           ) : null}
    //           {buttonSelected === "Add Leave Type" ? (
    //             <>
    //               <TextField
    //                 fullWidth
    //                 type="number"
    //                 label="No Of Leaves"
    //                 name="leaveNo"
    //                 value={leaveNo}
    //                 onChange={(event) => {
    //                   setLeaveNo(event.target.value);
    //                   setDisplayError("");
    //                   setError([]);
    //                 }}
    //                 variant="outlined"
    //                 disabled={leaveDeleteRecord}
    //               ></TextField>
    //             </>
    //           ) : null}
    //         </Box>
    //         <Box
    //           sx={{
    //             display: "flex",
    //             justifyContent: "flex-start",
    //             alignItems: "flex-end",
    //             marginTop: "20px",
    //             paddingLeft: "35px",
    //             marginRight: "25px",
    //             color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //           }}
    //         >
    //           {buttonSelected === "Add Leave Type" ? (
    //             <>
    //               <Box sx={{ height: "55px", paddingTop: '10px' }}>
    //                 <FormControlLabel
    //                   sx={{
    //                     color: 'var(--secondary-text0-color)',
    //                     ".MuiCheckbox-root": {
    //                       color: "var(--secondary-text-color)",
    //                     }
    //                   }}
    //                   control={<Checkbox checked={enCash} onChange={() => setEnCash(!enCash)} />}
    //                   label="enCash"
    //                 />
    //               </Box>
    //             </>
    //           ) : null}
    //         </Box>
    //         <Typography
    //           sx={{
    //             color: "red",
    //             paddingLeft: "38px",
    //             marginTop: "0px",
    //           }}
    //         >
    //           {error.festivalDate}
    //         </Typography>
    //         <Typography
    //           sx={{
    //             color: "red",
    //             paddingLeft: "38px",
    //             marginTop: "0px",
    //           }}
    //         >
    //           {error.leaveNo}
    //         </Typography>
    //       </DialogContent>
    //       <Typography
    //         sx={{
    //           color: "red",
    //           paddingLeft: "38px",
    //           marginTop: "0px",
    //         }}
    //       >
    //         {displayError}
    //       </Typography>

    //       <DialogActions
    //         sx={{
    //           background: "var(--highlight-color)",
    //           boxShadow: "0px 2px 10px var(--dark-highlight-color)",
    //         }}
    //       >
    //         <Button
    //           onClick={() => {
    //             set_id("");
    //             setFestival("");
    //             setFestivalDate("");
    //             setFestivalDay("");
    //             setLeaveType("");
    //             setLeaveNo("");
    //             setDisplayError("");
    //             setError([]);
    //             setOpenDialogBox(false);
    //             setFestivalEditRecord(false);
    //             setLeaveEditRecord(false);
    //             setFestivalDeleteRecord(false);
    //             setLeaveDeleteRecord(false);
    //           }}
    //           sx={{
    //             color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
    //             textTransform: "capitalize",
    //             fontWeight: "bold",
    //             marginRight: "10px",
    //             borderRadius: "5px",
    //           }}
    //         >
    //           Cancel
    //         </Button>

    //         {buttonSelected === "Add Holiday" ? (
    //           <Button
    //             onClick={() => {
    //               if (buttonName === "Add Holiday") {
    //                 AddHolidayAction();
    //               }
    //               if (buttonName === "Edit Holiday") {
    //                 EditHolidayAction();
    //               }
    //               if (buttonName === "Delete Holiday") {
    //                 DeleteHolidayAction();
    //               }
    //             }}
    //             variant="contained"
    //             sx={{
    //               borderRadius: "5px",
    //               textTransform: "capitalize",
    //             }}
    //             component={Link}
    //             color={
    //               festivalEditRecord
    //                 ? "warning"
    //                 : festivalDeleteRecord
    //                   ? "error"
    //                   : "success"
    //             }
    //           >
    //             {buttonName}
    //           </Button>
    //         ) : null}
    //         {buttonSelected === "Add Leave Type" ? (
    //           <Button
    //             onClick={() => {
    //               if (buttonName === "Add Leave Type") {
    //                 AddLeaveTypeAction();
    //               }
    //               if (buttonName === "Edit Leave Type") {
    //                 EditLeaveTypeAction();
    //               }
    //               if (buttonName === "Delete Leave Type") {
    //                 DeleteLeaveTypeAction();
    //               }
    //             }}
    //             variant="contained"
    //             sx={{
    //               borderRadius: "5px",
    //               textTransform: "capitalize",
    //             }}
    //             component={Link}
    //             color={
    //               leaveEditRecord
    //                 ? "warning"
    //                 : leaveDeleteRecord
    //                   ? "error"
    //                   : "success"
    //             }
    //           >
    //             {buttonName}
    //           </Button>
    //         ) : null}
    //       </DialogActions>
    //     </Box>
    //   </Dialog>
    // </Box>
  );
};

export default DialogOfCompanyAndLeavePolicy;
