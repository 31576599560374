import { configureStore } from "@reduxjs/toolkit";
import { thunk } from 'redux-thunk';
import { createLogger } from 'redux-logger';

import rootReducer from "./rootReducer";

const logger = createLogger();
// Configure the store
const isProd = process.env.NODE_ENV === 'production';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();

    if (!isProd) {
      // Add logger middleware in development environment
      return middleware.concat(thunk, logger);
    }

    // Only add thunk middleware in production environment
    return middleware;
  },
});
export default store;

// import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "./rootReducer";
// const store = configureStore({
//   reducer: rootReducer,
// });
// export default store;
