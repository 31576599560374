import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import {
	progessBarTitleBox,
	progessBarTitle,
	progessBarBox,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import {
	formatTimeWithoutSeconds,
	formatDate,
	formatDateSlashTo_,
} from "../../ReusableComponents/UtilityFunctions";
import { overtimeHours, working_hours } from "../../Constant/constant";
import { DateLinearsProgress, MonthLinearsProgress, OvertimeLinearsProgress, StatisticsTypography, WeekLinearsProgress } from "../../../Styles/UpdateEmployee/EmployeeProgressStyle";

const EmployeeProgress = ({ EmpId }) => {
	const { employeeId } = useParams();
	const allEmployeeAttendence = useSelector(
		(state) => state.employeeData.allEmployeeAttendenceData
	);
	const selectedDate = useSelector((state) => state.searchData.calenderValue);
	const selectedEmpId = employeeId || EmpId;

	const [attendenceData, setAttendenceData] = useState([]);
	const [date, setDate] = useState();
	const formattedTodayDate = formatDate(new Date());

	const calculateDailyWorkingHours = (records, targetDate) => {
		if (records?.length === 0) {
			return "00:00";
		}
		const filteredRecords = records?.filter(
			(record) => record?.date === targetDate
		);
		const totalHours = filteredRecords[0]?.dailytimer?.TotalTime;
		if (totalHours === undefined) {
			return `00:00`;
		}
		return formatTimeWithoutSeconds(totalHours / 1000);
	};

	const calculateDailyWorkingHoursInSec = (records, targetDate) => {
		if (records?.length === 0) {
			return 0;
		}
		const filteredRecords = records?.filter(
			(record) => record?.date === targetDate
		);
		const totalHours = filteredRecords[0]?.dailytimer?.TotalTime;
		if (totalHours === undefined) {
			return 0;
		}
		if (totalHours > working_hours * 3600 * 1000) {
			return working_hours * 3600;
		}
		return totalHours / 1000;
	};

	const getMondayDate = () => {
		const formattedDate = date.split("-").reverse().join("-");
		const firstDay = new Date(formattedDate);
		const dayOfWeek = firstDay.getDay();
		const difference = dayOfWeek - 1;
		const mondayDate = new Date(firstDay);
		mondayDate.setDate(firstDay.getDate() - difference);
		return formatDate(mondayDate);
	};

	const filterRecordsByDateRange = (records) => {
		if (records?.length === 0) {
			return 0;
		}
		const formattedStartDate = getMondayDate();
		const formattedDate = formattedStartDate.split("-").reverse().join("-");
		const startDate = new Date(formattedDate);
		const endDate = new Date(startDate);
		endDate.setDate(endDate.getDate() + 6);
		const weeks = records.filter((record) => {
			const formattedDate = record?.date.split("-").reverse().join("-");
			const recordDate = new Date(formattedDate);
			return (
				(recordDate >= startDate &&
					recordDate <= endDate &&
					recordDate.getMonth() === startDate.getMonth() &&
					recordDate.getFullYear() === startDate.getFullYear()) ||
				(startDate.getMonth() !== endDate.getMonth() &&
					recordDate <= endDate &&
					recordDate.getMonth() === endDate.getMonth() &&
					recordDate.getFullYear() === endDate.getFullYear())
			);
		});
		const totalHours = weeks.reduce((total, record) => {
			const weeklyTotalHours =
				total + (record?.dailytimer?.TotalTime || 0);
			return weeklyTotalHours;
		}, 0);
		return totalHours / 1000;
	};

	const filterRecordsByMonth = (records) => {
		if (records?.length === 0) {
			return 0;
		}
		const formattedDate = date?.split("-").reverse().join("-");
		const currentDate = new Date(formattedDate);
		const firstDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		);
		const monthRecords = records?.filter((record) => {
			const formattedDate = record?.date?.split("-").reverse().join("-");
			const recordDate = new Date(formattedDate);
			return (
				recordDate.getDate() >= firstDayOfMonth.getDate() &&
				recordDate.getDate() <= lastDayOfMonth.getDate() &&
				recordDate.getMonth() === firstDayOfMonth.getMonth() &&
				recordDate.getFullYear() === firstDayOfMonth.getFullYear()
			);
		});

		const totalHours = monthRecords?.reduce((total, record) => {
			const monthlyTotalHours =
				total + (record?.dailytimer?.TotalTime || 0);
			return monthlyTotalHours;
		}, 0);
		return totalHours / 1000;
	};

	const totaldaysInMonth = () => {
		const formattedDate = date?.split("-").reverse().join("-");
		const currentDate = new Date(formattedDate);
		const firstDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		);
		const startDate = new Date(firstDayOfMonth);
		const endDate = new Date(lastDayOfMonth);
		const diff = (endDate - startDate) / (1000 * 60 * 60 * 24);
		const totalWorkingHoursMonth = (diff - 6 + 1) * working_hours;
		return totalWorkingHoursMonth;
	};

	const calculateOvertime = (records, value) => {
		if (records?.length === 0) {
			return 0;
		}
		const formattedDate = date?.split("-").reverse().join("-");
		const currentDate = new Date(formattedDate);
		const firstDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		);
		const monthRecords = records?.filter((record) => {
			const formattedDate = record?.date?.split("-").reverse().join("-");
			const recordDate = new Date(formattedDate);
			return (
				recordDate.getDate() >= firstDayOfMonth.getDate() &&
				recordDate.getDate() <= lastDayOfMonth.getDate() &&
				recordDate.getMonth() === firstDayOfMonth.getMonth() &&
				recordDate.getFullYear() === firstDayOfMonth.getFullYear()
			);
		});
		const totalHours = monthRecords?.reduce((total, record) => {
			if (
				record?.dailytimer?.TotalTime >
				working_hours * 3600 * 1000 + 1800000
			) {
				const monthlyOverTimeTotalHours =
					total +
					(record?.dailytimer?.TotalTime -
						working_hours * 3600 * 1000);
				return monthlyOverTimeTotalHours;
			}
			return total;
		}, 0);
		if (value === 1) {
			return formatTimeWithoutSeconds(totalHours / 1000);
		} else if (value === 2) {
			return totalHours / (3600 * 1000);
		}
	};

	useEffect(() => {
		if (selectedDate) {
			setDate(formatDateSlashTo_(selectedDate));
		} else {
			setDate(formatDateSlashTo_(formattedTodayDate));
		}
		const filteredDataByEmployee = allEmployeeAttendence?.filter(
			(item) => item?.empId === selectedEmpId
		);
		if (filteredDataByEmployee?.length !== 0) {
			const filteredDataBydate = filteredDataByEmployee[0]?.records;
			setAttendenceData(filteredDataBydate);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedEmpId, selectedDate, allEmployeeAttendence]);

	const totalDaysHours = totaldaysInMonth();
	const totalworking = filterRecordsByMonth(attendenceData) / (60 * 60);
	const monthRecord = (totalworking / totalDaysHours) * 100;

	return (
		<Box
		sx={{"@media (max-width: 600px)": {
			width:"100%",
		  }
		}}>
			<StatisticsTypography>
				Statistics
			</StatisticsTypography>

			<Box
				sx={{
					border: "0.1px solid var(--secondary-highlight-color)",
					...progessBarBox,
				}}
			>
				<Box sx={progessBarTitleBox}>
					<Typography sx={progessBarTitle}>
						Date - {selectedDate}
					</Typography>
					<Box sx={{ fontSize: "13px" }}>
						<span style={{ fontWeight: "500" }}>
							{calculateDailyWorkingHours(attendenceData, date)}/{" "}
							{working_hours}
						</span>{" "}
						<span>Hrs</span>
					</Box>
				</Box>
				<DateLinearsProgress
					variant="determinate"
					value={
						(calculateDailyWorkingHoursInSec(attendenceData, date) /
							(working_hours * 3600)) *
						100
					}
				/>
			</Box>

			<Box
				sx={{
					border: "1px solid #faaaaa1c",
					...progessBarBox,
				}}
			>
				<Box sx={progessBarTitleBox}>
					<Typography sx={progessBarTitle}>Week</Typography>
					<Box sx={{ fontSize: "13px" }}>
						<span style={{ fontWeight: "500" }}>
							{formatTimeWithoutSeconds(
								filterRecordsByDateRange(attendenceData)
							)}{" "}
							/{working_hours * 6}
						</span>{" "}
						<span>Hrs</span>
					</Box>
				</Box>
				<WeekLinearsProgress
					variant="determinate"
					value={
						(filterRecordsByDateRange(attendenceData) /
							(working_hours * 6 * 3600)) *
							100 >
							100
							? 100
							: (filterRecordsByDateRange(attendenceData) /
								(working_hours * 6 * 3600)) *
							100
					}
				/>
			</Box>

			<Box
				sx={{
					border: "1px solid #fbe2af14",
					...progessBarBox,
				}}
			>
				<Box sx={progessBarTitleBox}>
					<Typography sx={progessBarTitle}>Month</Typography>
					<Box sx={{ fontSize: "13px" }}>
						<span style={{ fontWeight: "500" }}>
							{formatTimeWithoutSeconds(
								filterRecordsByMonth(attendenceData)
							)}
							/{totaldaysInMonth()}{" "}
						</span>{" "}
						<span>Hrs</span>
					</Box>
				</Box>
				<MonthLinearsProgress
					variant="determinate"
					value={monthRecord > 100 ? 100 : monthRecord}
				/>
			</Box>

			<Box
				sx={{
					border: "1px solid var(--avgWorkingHrs-highlight-color)",
					...progessBarBox,
				}}
			>
				<Box sx={progessBarTitleBox}>
					<Typography sx={progessBarTitle}>Overtime</Typography>
					<Box sx={{ fontSize: "13px" }}>
						<span style={{ fontWeight: "500" }}>
							{calculateOvertime(attendenceData, 1)}/
							{overtimeHours}
						</span>{" "}
						<span>Hrs</span>
					</Box>
				</Box>
				<OvertimeLinearsProgress
					variant="determinate"
					value={
						(calculateOvertime(attendenceData, 2) / overtimeHours) *
							100 >
							100
							? 100
							: (calculateOvertime(attendenceData, 2) /
								overtimeHours) *
							100
					}
				/>
			</Box>
		</Box>
	);
};

export default EmployeeProgress;
