import { Box } from "@mui/material";
import React from "react";

import "./BirthdayPage.css";

export const BirthdayPage = () => {
	return (
		<Box
			sx={{
				height: "100vh",
				justifyContent: "space-around",
				alignItems: "center",
				display: "flex",
				backgroundColor: "#073b4c",
			}}
		>
			<Box className="birthdayCardforbirthday">
				<Box className="cardFrontforbirthday">
					<h3 className="happyforbirthday">
						HAPPY BIRTHDAY Dipesh Sir!
					</h3>
					<div className="balloonsforbirthday">
						<div className="balloonOne" />
						<div className="balloonTwo" />
						<div className="balloonThree" />
						<div className="balloonFour" />
					</div>
				</Box>
				<div className="cardInsideforbirthday">
					<h3 className="backforbirthday">HAPPY BIRTHDAY Sir!</h3>
					<p className="pteg">Dear Dipesh sir,</p>
					<p className="pteg">
						Happy birthday!! We hope your day is filled with lots of
						love and laughter! May all of your birthday wishes come
						true.
					</p>
					<p className="pteg nameforbirthday">JT Dev Team,</p>
				</div>
			</Box>
		</Box>
		// <Box sx={{ padding: "6px 4px 8px 7px", background: "red" }}>
		// 	<script src="https://cdn.lordicon.com/lordicon.js"></script>
		// 	<lord-icon
		// 		src="https://cdn.lordicon.com/cqofjexf.json"
		// 		trigger="loop"
		// 		delay="500"
		// 		stroke="light"
		// 		style={{ width: "250px", height: "250px" }}
		// 	></lord-icon>
		// </Box>
	);
};
