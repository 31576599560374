import { axiosInstance } from './axiosInstance';

export const getEmployeeTaskData = () => axiosInstance.get('/user/employeeTaskList');

export const getAdminEmployeeTaskData = () => axiosInstance.get('/admin/employeeTaskList');

export const getEmployeeFetchTask = (employeeId) => axiosInstance.get(`/admin/fetchEmployeeTask/${employeeId}`);

export const upsertTask = (getUser, postData) => axiosInstance.post(`/${getUser}/task`, postData);

export const editTaskDescription = (getUser, postData) => axiosInstance.put(`/${getUser}/updateTaskDescription`, postData);

export const editTaskPlannedHours = (getUser, postData) => axiosInstance.put(`/${getUser}/updateTaskPlannedHours`, postData);

export const upsertComment = (getUser, postData) => axiosInstance.post(`/${getUser}/addComment`, postData);

export const editApprovedStatus = (getUser, postData) => axiosInstance.put(`/${getUser}/updateApprovedStatus`, postData);

export const editTask = (getUser, postData) => axiosInstance.put(`/${getUser}/updateTask`, postData);

export const getTaskById = (taskId) => axiosInstance.get(`/user/getTaskById/${taskId}`);

export const getAllTaskData = (apiUser) => axiosInstance.get(`/${apiUser}/getTask`);

export const upsertEmployeeEffecicency = (postData) => axiosInstance.post(`/admin/getEffecicency`, postData);

export const addTags = (postData) => axiosInstance.post(`/admin/addTags`, postData);