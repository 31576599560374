import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import {
  extractDTime,
  extractDTimeWithOutAMPM,
} from "../ReusableComponents/UtilityFunctions";
import CommonDashBoard from "./CommonDashBoard";
import {
  getEmployeLeaveBalance,
  getEmployeeBirthDay,
  getEmployeeTime,
  getEmployeesPunchInCount,
  getLeaveAndAttendance,
  getWeekChartData,
  getWorkingDaysInThisMonthData,
} from "../../Services/DashboardServices";
import "./Dashboard.scss";

function CenterComponent() {
  // const attendence = useSelector(
  //   (state) => state.employeeData.allEmployeeAttendenceData
  // );
  // const userData = useSelector((state) => state.employeeData.value);
  const user = useSelector((state) => state.selectedActiveView.role);
  const employeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const [absentData, setAbsentData] = useState([]);
  const [upComingAbsentData, setUpComingAbsentData] = useState([]);
  const [upComingBirthDay, setUpComingBirthDay] = useState([]);
  const [employeeInTime, setEmployeeInTime] = useState([]);
  const [employeeChartData, setEmployeeChartData] = useState([]);
  const [employeeWeekChartData, setEmployeeWeekChartData] = useState([]);
  const [workingDaysInThisMonth, setWorkingDaysInThisMonth] = useState([]);
  const [leaveBalanace, setLeaveBalanace] = useState([]);
  const [presentDaysData, setPresentDaysData] = useState([]);
  const [employeesCount, setEmployeesCount] = useState(0);
  const [punchInCount, setPunchInCount] = useState(0);

  const memoizedGetLeaveAndAttendance = useMemo(() => {
    const getData = async () => {
      try {
        const response = await getLeaveAndAttendance();
        if (response.status === 200) {
          setAbsentData(response.data.leaveData);
          setUpComingAbsentData(response.data.nextLeaves);
        } else {
          setAbsentData([]);
          setUpComingAbsentData([]);
        }
      } catch (error) { }
    };
    return getData;
  }, []);

  // const memoizedPresentEmployees = useMemo(() => {
  //   const presentEmployee = async () => {
  //     const currentDate = formatDate(new Date());
  //     const presentEmployees = attendence?.filter((employee) => {
  //       return employee?.records?.some(
  //         (record) => record?.date === currentDate
  //       );
  //     });
  //     setCount(presentEmployees?.length);
  //     return presentEmployees;
  //   };
  //   return presentEmployee;
  // }, [attendence]);

  const memoizedEmployeeTime = useMemo(() => {
    const getData = async () => {
      try {
        // const response = await axios.get(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/employeeInTime`,
        //   getHeader
        // );
        const response = await getEmployeeTime();
        if (response.status === 200) {
          const dataArray = response.data.dataArray;
          const newdata = dataArray.sort((a, b) => {
            if (a.TimerStart === null && b.TimerStart !== null) {
              return 1;
            }
            if (a.TimerStart !== null && b.TimerStart === null) {
              return -1;
            }
            if (a.TimerStart === null && b.TimerStart === null) {
              return 0;
            }
            return a.TimerStart - b.TimerStart;
          });
          setEmployeeInTime(newdata);
          const staticChartData = newdata?.map(
            ({ name, TimerStart, Time }) => ({
              name,
              time:
                TimerStart === "12:00"
                  ? "12:00"
                  : extractDTimeWithOutAMPM(TimerStart),
              realTime: Time === "absent" ? "absent" : extractDTime(Time),
            })
          );
          setEmployeeChartData(staticChartData);
        } else {
          setEmployeeInTime([]);
          setEmployeeChartData([]);
        }
      } catch (error) {
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   }
        //   else if (error.response.status === 500) {
        //     console.log(error.response.data.message);
        //   }
        // }
      }
    };
    return getData;
  }, []);

  const memoizedEmployeeBirthDay = useMemo(() => {
    const getData = async () => {
      try {
        // const response = await axios.get(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/birthdayCheck`,
        //   getHeader
        // );
        const response = await getEmployeeBirthDay();
        if (response.status === 200) {
          setEmployeesCount(response.data.employeesCount);
          setUpComingBirthDay(response.data.BirthDays);
        } else {
          setEmployeesCount(0);
          setUpComingBirthDay([]);
        }
      } catch (error) {
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   }
        //   else if (error.response.status === 500) {
        //     console.log(error.response.data.message);
        //   }
        // }
      }
    };
    return getData;
  }, []);

  const memoizedEmployeLeaveBalance = useMemo(() => {
    const getData = async () => {
      try {
        // const response = await axios.get(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/user/LeaveBalance`,
        //   getHeader
        // );
        const response = await getEmployeLeaveBalance();
        if (response.status === 200) {
          setLeaveBalanace(response.data.array);
        } else {
          setLeaveBalanace([]);
        }
      } catch (error) {
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   }
        //   else if (error.response.status === 500) {
        //     console.log(error.response.data.message);
        //   }
        // }
      }
    };
    return getData;
  }, []);

  const memoizedWorkingDaysInThisMonthData = useMemo(() => {
    const getWorkingDaysInThisMonth = async () => {
      try {
        // const response = await axios.get(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/user/getMonthlyPresentData`,
        //   getHeader
        // );
        const response = await getWorkingDaysInThisMonthData();
        if (response.status === 200) {
          setWorkingDaysInThisMonth(response.data.WorkingDaysInThisMonth);
          setPresentDaysData(response.data.PresentDaysData);
        } else {
          setWorkingDaysInThisMonth([]);
          setPresentDaysData([]);
        }
      } catch (error) {
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   }
        //   else if (error.response.status === 500) {
        //     console.log(error.response.data.message);
        //   }
        // }
      }
    };
    return getWorkingDaysInThisMonth;
  }, []);

  const memoizedWeekChartData = useMemo(() => {
    const getData = async () => {
      try {
        // const response = await axios.get(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/employeeWeekInTime`,
        //   getHeader
        // );
        const response = await getWeekChartData();
        if (response.status === 200) {
          setEmployeeWeekChartData(response.data.weekData);
        } else {
          setEmployeeWeekChartData([]);
        }
      } catch (error) {
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   }
        //   else if (error.response.status === 500) {
        //     console.log(error.response.data.message);
        //   }
        // }
      }
    };
    return getData;
  }, []);

  const memoizedEmployeesPunchInCount = useMemo(() => {
    const getAdminData = async () => {
      try {
        // const response = await axios.get(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/activeEmployees`,
        //   getHeader
        // );
        const response = await getEmployeesPunchInCount();
        if (response.status === 200) {
          // const data = response.data.punchInCount;
          setPunchInCount(response.data.punchInCount);
        } else {
          setPunchInCount(0);
        }
      } catch (error) {
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   }
        //   else if (error.response.status === 500) {
        //     console.log(error.response.data.message);
        //   }
        // }
      }
    };
    return getAdminData;
  }, []);

  useEffect(
    () => {
      if (adminLogin) {
        memoizedEmployeeBirthDay();
        memoizedEmployeLeaveBalance();
        memoizedEmployeesPunchInCount();
      } else if (employeeLogin) {
        memoizedEmployeeBirthDay();
        memoizedEmployeLeaveBalance();
        memoizedEmployeesPunchInCount();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  useEffect(
    () => {
      if (adminLogin) {
        memoizedEmployeeTime();
        memoizedWeekChartData();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // memoizedEmployeeTime,
      //  memoizedWeekChartData,
      //   adminLogin
    ]
  );

  useEffect(
    () => {
      if (adminLogin) {
        memoizedWorkingDaysInThisMonthData();
        memoizedGetLeaveAndAttendance();
      } else if (employeeLogin) {
        memoizedWorkingDaysInThisMonthData();
        memoizedGetLeaveAndAttendance();
      }
      // memoizedPresentEmployees();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]
  );

  return (
    <Box>
      <CommonDashBoard
        absentData={absentData}
        count={punchInCount}
        upComingAbsentData={upComingAbsentData}
        upComingBirthDay={upComingBirthDay}
        employeeInTime={employeeInTime}
        employeeChartData={employeeChartData}
        employeeWeekChartData={employeeWeekChartData}
        workingDaysInThisMonth={workingDaysInThisMonth}
        presentDaysData={presentDaysData}
        userData={employeesCount}
        leaveBalanace={leaveBalanace}
      />
    </Box>
  );
}

export default CenterComponent;
