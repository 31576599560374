import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Box, Button, Tooltip, Zoom, Typography, DialogTitle } from '@mui/material';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Close } from '@mui/icons-material';

import { setAllData } from "../../Slices/UserDataSlice.js";
import { setToast, setToastMsg, setToastType } from '../../Slices/toastSlice.js';
import { set_index } from "../../Slices/employeeDetailSlice.js";
import { errorMessage } from "../../Services/axiosInstance.js";
import { changeStatusEmployee } from '../../Services/EmployeeManageServices';
import { viewEducationBox } from '../ReusableComponents/CustomDesignMUI/CustomMUI';
import DialogComponent from '../ReusableComponents/DialogBoxComponents/DialogComponent.js';
import { MainBox, TitleBox, InnerBoxOne } from "../../Styles/UpdateEmployee/DangarZoneStyle";
import { MainBoxDialog, ActiveButton, InnerBox, InnerBoxTwo, CancelButton, ErrorTypography } from "../../Styles/UpdateEmployee/UpdatePersonalStyle.js";
import { CloseIconButton } from "../../Styles/ManageEvents/ManageEventsStyle.js";
import { DialogsActions } from "../../Styles/Authentication/LogOutStyle.js";

function DangarZone({ employeeCall }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onAdminId = useSelector((state) => state.adminData.adminData?.empId);
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
    const isEmpManagement = useSelector(
        (state) => state.selectedActiveView.EmployeeManagment
    );
    const [openDialog, setOpenDialog] = useState(false);
    const [displayError, setDisplayError] = useState("");

    const onClose = () => {
        setOpenDialog(false)
    }

    const deletedata = async () => {
        try {
            const deleteEmpId = employeeCall?.empId;
            const response = await changeStatusEmployee({ deleteEmpId })
            if (response.status === 200 || response.status === 201) {
                onClose()
                dispatch(setAllData(response.data.User));
                if (isEmpManagement === "true") {
                    navigate("/employee-management");
                } else {
                    navigate("/dashboard");
                }
                dispatch(set_index(""));
                dispatch(setToast(true));
                dispatch(setToastMsg(response.data.message));
                dispatch(setToastType("success"));
            }
        } catch (error) {
            setDisplayError(errorMessage ?? "")
        }
    };

    const renderForm = () => {
        return (<>
            <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
                <Box>
                    <CloseIconButton
                        onClick={() => setOpenDialog(false)}
                        isdarktheme={isDarkTheme ? "true" : "false"}
                    >
                        <Close />
                    </CloseIconButton>
                </Box>
                <MainBoxDialog isDarkTheme={isDarkTheme}>
                    <Box>
                        <DialogTitle sx={{ fontWeight: "bold" }}>
                            <InnerBox>
                                <InnerBoxTwo>
                                    <WarningAmberIcon
                                        sx={{
                                            color: "#c70000",
                                            fontSize: "30px",
                                            borderRadius: "50px",
                                        }}
                                    />
                                </InnerBoxTwo>
                                <Box>
                                    <Box>{employeeCall?.status === "Active"
                                        ? "Confirm Archive the employee"
                                        : "Confirm Active the employee"}</Box>
                                    <Typography>{employeeCall?.status === "Active"
                                        ? "Are you sure you want to Archive this data?"
                                        : "Are you sure you want to Active this data?"}</Typography>
                                </Box>
                            </InnerBox>
                        </DialogTitle>
                    </Box>
                </MainBoxDialog>
                <DialogsActions>
                    <CancelButton onClick={() => setOpenDialog(false)}>
                        Cancel
                    </CancelButton>
                    <ActiveButton
                        variant="contained"
                        onClick={() => {
                            deletedata();
                        }}
                        component={Link}
                        color="error"
                    >
                        {employeeCall?.status === "Active" ? "Archive" : "Active"}
                    </ActiveButton>
                </DialogsActions>
                <ErrorTypography>
                    {displayError}
                </ErrorTypography>
            </Box >
        </>)
    }
    return (
        <MainBox>
            <TitleBox>
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Dangar Zone
                    </Typography>
                </Box>
            </TitleBox>
            <hr />
            <Box sx={{ ...viewEducationBox, marginTop: "2%" }}>
                <InnerBoxOne>
                    <Typography sx={{ fontWeight: "bold" }}>
                        Archive this employee
                    </Typography>
                    {employeeCall?.empId !== onAdminId ?
                        <Tooltip
                            title={
                                employeeCall?.status === "Active"
                                    ? "DeActive Employee"
                                    : "Active Employee"
                            }
                            arrow
                            disableInteractive
                            TransitionComponent={Zoom}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                sx={{ fontWeight: "bold" }}
                                onClick={() => { setOpenDialog(true); dispatch(set_index(employeeCall?.empId)); }}
                            >
                                {employeeCall?.status === "Active"
                                    ? "DeActive Employee"
                                    : "Active Employee"}
                            </Button>
                        </Tooltip> : null}
                </InnerBoxOne>
            </Box>
            <Box>
                <DialogComponent
                    open={openDialog}
                    onClose={onClose}
                    maxWidth="md"
                    renderChildern={renderForm}
                />
            </Box>
        </MainBox >
    )
}

export default DangarZone