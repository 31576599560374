import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  adminData: {},
  projectData: {},
  eventData: {},
  projectWorkingMemberName: [],
  allWorkingData: [],
  roles: [],
  value: [],
  specializations: [],
};

const userDataSlice = createSlice({
  name: "adminData",
  initialState,
  reducers: {
    setAdminData: (state, action) => {
      state.adminData = action.payload;
    },
    setProjectData: (state, action) => {
      state.projectData = action.payload;
    },
    setEventData: (state, action) => {
      state.eventData = action.payload;
    },
    setAllWorkingData: (state, action) => {
      state.allWorkingData = action.payload;
    },
    setProjectWorkingMemberName: (state, action) => {
      state.projectWorkingMemberName = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setSpecializations: (state, action) => {
      state.specializations = action.payload;
    },
    resetAdmindetailReducer: (state) => {
      return initialState;
    },
  },
});

export const {
  setProjectData,
  setEventData,
  setAdminData,
  setAllWorkingData,
  setProjectName,
  setProjectWorkingMemberName,
  setRoles,
  setSpecializations,
  resetAdmindetailReducer,
} = userDataSlice.actions;

export default userDataSlice.reducer;
