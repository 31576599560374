import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItemText,
  ListItemButton,
  Link as MuiLink,
  Tooltip,
  Zoom,
  useMediaQuery,
  IconButton
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import DashboardIcon from "@mui/icons-material/Dashboard";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PersonIcon from "@mui/icons-material/Person";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SchoolIcon from "@mui/icons-material/School";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CottageIcon from "@mui/icons-material/Cottage";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import CelebrationIcon from "@mui/icons-material/Celebration";
import GroupIcon from '@mui/icons-material/Group';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import SchemaIcon from '@mui/icons-material/Schema';

import { CLOUD_FUNCTIONS_ORIGIN } from "../../../function-origin";
import axios from "axios";
import LogoutDialog from "../MenuItems/LogoutDialog";
import { resetUserDataReducer } from "../../../Slices/UserDataSlice";
import { resetToastSliceReducer } from "../../../Slices/toastSlice";
import { resetTaskSliceReducer } from "../../../Slices/taskSlice";
import { resetSelectedActiveReducer } from "../../../Slices/selectedActive";
import { resetSearchDataReducer } from "../../../Slices/searchData";
import { resetLeaveDataReducer } from "../../../Slices/leaveData";
import { resetEmployeeReducer } from "../../../Slices/employeeDetailSlice";
import { resetAdmindetailReducer } from "../../../Slices/adminDetailSlice";
import { ContentRightBox, ContentLeftBox, ProfileBox } from "../../../Styles/AdminDashboard/DashBoardStyle"
import ProfileImg from "../../../assets/img/adminIcon.svg";
import ProfileImgGirl from "../../../assets/img/icons/girl.png";
import ProfileImgBoy from "../../../assets/img/icons/man.svg";
import ProfileImgOthers from "../../../assets/img/icons/others.png";
import "./Sidebar.scss";

const SideBar = (props) => {
  const { isOpen, handleSidebarShow } = props;
  const imgRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const getPermissionLocal =
  //   JSON.parse(window.localStorage.getItem("getRoleByPermission"));
  const adminData = useSelector((state) => state.adminData.adminData);
  const getRoleByPermission = useSelector(
    (state) => state?.selectedActiveView?.getRoleByPermission
  );
  const adminFirstName = useSelector(
    (state) => state.adminData.adminData?.firstName
  );
  const adminPosition = useSelector(
    (state) => state.adminData.adminData?.position?.role
  );
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const user = useSelector((state) => state.selectedActiveView.role);
  const currentEmployee = useSelector((state) => state.addEmployee.current);
  const employeeCall = useSelector(
    (state) => state.addEmployee.employeeAllData
  );
  const { pathname } = useLocation();
  const isActive = (path) => {
    return pathname === path;
  };
  const currentEmployeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const [ProfileImageAvailable, setProfileImageAvailable] = useState(false);
  const [expanded, setExpanded] = React.useState("");
  const [policyExpanded, setPolicyExpanded] = React.useState("");
  const [filteredMenu, setFilteredMenu] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  // const [currentUserId, setCurrentUserId] = useState("");
  const [profileImgSrc, setProfileImgSrc] = useState(true);

  const handleChangeExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangePolicyExpanded = (panel) => (event, newExpanded) => {
    setPolicyExpanded(newExpanded ? panel : false);
  };

  const capitalizeFirstLetter = (string) => {
    if (string && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return string;
    }
  };

  const handleLogoutConfirmation = async () => {
    let is_JT_theme_Dark = localStorage.getItem("is_JT_theme_Dark");
    window.localStorage.clear();
    localStorage.setItem("is_JT_theme_Dark", is_JT_theme_Dark);
    navigate("/");
    dispatch(resetUserDataReducer());
    dispatch(resetToastSliceReducer());
    dispatch(resetTaskSliceReducer());
    dispatch(resetSelectedActiveReducer());
    dispatch(resetSearchDataReducer());
    dispatch(resetLeaveDataReducer());
    dispatch(resetEmployeeReducer());
    dispatch(resetAdmindetailReducer());
    setOpenDialog(false);
  };

  const handleCancelLogout = () => {
    setOpenDialog(false);
  };

  function Gender() {
    if (employeeCall?.Gender === "Male") {
      return ProfileImgBoy;
    } else if (employeeCall?.Gender === "Female") {
      return ProfileImgGirl;
    } else return ProfileImgOthers;
  }

  const profileSrc = Gender();

  const ManagementPanelMenu = [
    {
      id: 8.1,
      pageName: "Employee Management",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/employee-management"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/employee-management")
                ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? <><PersonIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            />
              <ListItemText primary="Employees" /></> :
              <Tooltip title="Employees"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <PersonIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                />
              </Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 8.2,
      pageName: "Leave Management",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/leave-management"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/leave-management")
                ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? <><WorkHistoryIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            />
              <ListItemText primary="Leaves" /></> : <Tooltip title="Leaves"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}><WorkHistoryIcon
                width="18px"
                sx={{
                  height: "41px",
                  paddingLeft: "10px",
                  fill: "var(--secondary-text-color)",
                }}
              /></Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 8.3,
      pageName: "Attendance Management",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/attendance-management"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/attendance-management")
                ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? <><FingerprintIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            />
              <ListItemText primary="Attendances" /></>
              : <Tooltip title="Attendances"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <FingerprintIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                />
              </Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 8.4,
      pageName: "Task Management",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/task-management"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/task-management")
                ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? <><ListAltIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            />
              <ListItemText primary="Tasks" /></> :
              <Tooltip title="Tasks"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <ListAltIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                /></Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 8.5,
      pageName: "Project Management",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/project-management"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/project-management")
                ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? <><AccountTreeIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            />
              <ListItemText primary="Projects" /></> :
              <Tooltip title="Projects"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}><AccountTreeIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                /></Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 8.6,
      pageName: "Role Management",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/manage-roles"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/manage-roles") ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? <><ManageAccountsIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            />
              <ListItemText primary="Roles" /></> :
              <Tooltip title="Roles"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}><ManageAccountsIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                /></Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 8.7,
      pageName: "Event Management",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/manage-events"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/manage-events")
                ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? <><CelebrationIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            />
              <ListItemText primary="Events" /></> : <Tooltip title="Events"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}><CelebrationIcon
                width="18px"
                sx={{
                  height: "41px",
                  paddingLeft: "10px",
                  fill: "var(--secondary-text-color)",
                }}
              /></Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 8.8,
      pageName: "WorkFlow",
      render: (<ListItemButton
        sx={{ padding: "0px" }}
        component={Link}
        to="/workflow"
        onClick={() => isMobile && handleSidebarShow()}
      >
        <Box
          className={
            isActive("/workflow")
              ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
          }
        >
          {isOpen ? <><SchemaIcon
            width="18px"
            sx={{
              height: "41px",
              paddingLeft: "10px",
              fill: "var(--secondary-text-color)",
            }}
          />
            <ListItemText primary="WorkFlow" /></> : <Tooltip title="WorkFlow"
              arrow
              placement="left"
              disableInteractive
              TransitionComponent={Zoom}><SchemaIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            /></Tooltip>}
        </Box>
      </ListItemButton>)
    },
    {
      id: 8.9,
      pageName: "Setting",
      render: (<ListItemButton
        sx={{ padding: "0px" }}
        component={Link}
        to="/setting"
        onClick={() => isMobile && handleSidebarShow()}
      >
        <Box
          className={
            isActive("/setting")
              ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
          }
        >
          {isOpen ? <><DisplaySettingsIcon
            width="18px"
            sx={{
              height: "41px",
              paddingLeft: "10px",
              fill: "var(--secondary-text-color)",
            }}
          />
            <ListItemText primary="Setting" /></> : <Tooltip title="Setting"
              arrow
              placement="left"
              disableInteractive
              TransitionComponent={Zoom}><DisplaySettingsIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            /></Tooltip>}
        </Box>
      </ListItemButton>)
    },
    isMobile && {
      id: 8.10,
      pageName: "Logout",
      render: (
        <>
          <ListItemButton
            sx={{ padding: "0px" }}
            onClick={handleLogoutConfirmation}
          >
            <Box
              className={
                isActive("/")
                  ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active")
                  : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
              }
            >
              {isOpen ? (
                <>
                  <DisplaySettingsIcon
                    width="18px"
                    sx={{
                      height: "41px",
                      paddingLeft: "10px",
                      fill: "var(--secondary-text-color)",
                    }}
                  />
                  <ListItemText primary="Logout" />
                </>
              ) : (
                <Tooltip title="Logout" arrow placement="left" disableInteractive TransitionComponent={Zoom}>
                  <DisplaySettingsIcon
                    width="18px"
                    sx={{
                      height: "41px",
                      paddingLeft: "10px",
                      fill: "var(--secondary-text-color)",
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          </ListItemButton>

          <LogoutDialog
            openDialog={openDialog}
            handleCancelLogout={handleCancelLogout}
            handleLogoutConfirmation={handleLogoutConfirmation}
          />
        </>
      )
    }
  ];

  const PolicyPanelMenu = [
    {
      id: 10.1,
      pageName: "General Policy",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/general-policy"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/general-policy")
                ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? <><ReceiptLongIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            />
              <ListItemText primary="General Policy" /></> :
              <Tooltip title="General Policy"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <ReceiptLongIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                />
              </Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 10.2,
      pageName: "Leave Policy",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/leave-policy"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/leave-policy") ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? <><CottageIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            />
              <ListItemText primary="Leave Policy" /></> :
              <Tooltip title="Leave Policy"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <CottageIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                />
              </Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
  ];

  const renderAllMenu = [
    {
      id: 1,
      pageName: "Dashboard",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/dashboard"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/dashboard") ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? (<>
              <DashboardIcon
                width="18px"
                sx={{
                  height: "41px",
                  paddingLeft: "10px",
                  fill: "var(--secondary-text-color)",
                }}
              />
              <ListItemText primary="Dashboard" />
            </>) :
              <Tooltip title="Dashboard"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <DashboardIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                /></Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 2,
      pageName: "TeamBoard",
      render: (<ListItemButton
        sx={{ padding: "0px" }}
        component={Link}
        to="/teamboard"
        onClick={() => isMobile && handleSidebarShow()}
      >
        <Box
          className={isActive("/teamboard") ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")}
        >
          {isOpen ? (<><InsertChartIcon
            width="18px"
            sx={{
              height: "41px",
              paddingLeft: "10px",
              fill: "var(--secondary-text-color)",
            }}
          /><ListItemText primary="Teamboard" /></>) :
            <Tooltip title="Teamboard"
              arrow
              placement="left"
              disableInteractive
              TransitionComponent={Zoom}>
              <InsertChartIcon
                width="18px"
                sx={{
                  height: "41px",
                  paddingLeft: "10px",
                  fill: "var(--secondary-text-color)",
                }}
              />
            </Tooltip>}
        </Box>
      </ListItemButton>),
    },
    {
      id: 3,
      pageName: "Team",
      render: (<ListItemButton
        sx={{ padding: "0px" }}
        component={Link}
        to="/team"
        onClick={() => isMobile && handleSidebarShow()}
      >
        <Box
          className={isActive("/team") ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")}
        >
          {isOpen ? (<><GroupIcon
            width="18px"
            sx={{
              height: "41px",
              paddingLeft: "10px",
              fill: "var(--secondary-text-color)",
            }}
          /><ListItemText primary="Team" /></>) :
            <Tooltip title="Team"
              arrow
              placement="left"
              disableInteractive
              TransitionComponent={Zoom}>
              <GroupIcon
                width="18px"
                sx={{
                  height: "41px",
                  paddingLeft: "10px",
                  fill: "var(--secondary-text-color)",
                }}
              />
            </Tooltip>}
        </Box>
      </ListItemButton>),
    },
    {
      id: 4,
      pageName: "Attendance",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/attendance"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/attendance") ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >

            {isOpen ? (<> <FingerprintIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            /><ListItemText
                primary={
                  "Attendance"
                }
              /></>) : <Tooltip title="Attendance"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
              <FingerprintIcon
                width="18px"
                sx={{
                  height: "41px",
                  paddingLeft: "10px",
                  fill: "var(--secondary-text-color)",
                }}
              />
            </Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 5,
      pageName: "Tasks",
      render: currentEmployeeLogin ? (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/tasks"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/tasks")
                ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? (<><ListAltIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            /><ListItemText primary="Tasks" /></>) :
              <Tooltip title="Tasks"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <ListAltIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                />
              </Tooltip>}
          </Box>
        </ListItemButton>
      ) : null,
    },
    {
      id: 6,
      pageName: "Leaves",
      render: (
        <ListItemButton sx={{ padding: "0px" }} component={Link} to="/leaves" onClick={() => isMobile && handleSidebarShow()}>
          <Box
            className={
              isActive("/leaves") ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? (<><WorkHistoryIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            /><ListItemText primary="Leaves" /></>) :
              <Tooltip title="Leaves"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <WorkHistoryIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                />
              </Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 7,
      pageName: "Projects",
      render: (
        <ListItemButton sx={{ padding: "0px" }} component={Link} to="/projects" onClick={() => isMobile && handleSidebarShow()}>
          <Box
            className={
              isActive("/projects") ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? (<> <AccountTreeIcon
              width="18px"
              sx={{
                height: "41px",
                paddingLeft: "10px",
                fill: "var(--secondary-text-color)",
              }}
            /><ListItemText primary="Projects" /></>) :
              <Tooltip title="Projects"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <AccountTreeIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                />
              </Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 8,
      pageName: "Events",
      render: (
        <ListItemButton sx={{ padding: "0px" }} component={Link} to="/events" onClick={() => isMobile && handleSidebarShow()}>
          <Box
            className={
              isActive("/events") ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? (<>
              <CelebrationIcon
                width="18px"
                sx={{
                  height: "41px",
                  paddingLeft: "10px",
                  fill: "var(--secondary-text-color)",
                }}
              /><ListItemText primary="Events" />
            </>) :
              <Tooltip title="Events"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <CelebrationIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                />
              </Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
    {
      id: 9,
      pageName: "Knowledge Sharing",
      render: (
        <ListItemButton
          sx={{ padding: "0px" }}
          component={Link}
          to="/knowledge-sharing"
          onClick={() => isMobile && handleSidebarShow()}
        >
          <Box
            className={
              isActive("/knowledge-sharing")
                ? (isOpen ? "sidebar-menu-active" : "sidebar-menu-hide-active") : (isOpen ? "sidebar-menu" : "sidebar-menu-hide")
            }
          >
            {isOpen ? (<>
              <SchoolIcon
                width="18px"
                sx={{
                  height: "41px",
                  paddingLeft: "10px",
                  fill: "var(--secondary-text-color)",
                }}
              /><ListItemText primary="Knowledge Sharing" />
            </>) :
              <Tooltip title="Knowledge Sharing"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <SchoolIcon
                  width="18px"
                  sx={{
                    height: "41px",
                    paddingLeft: "10px",
                    fill: "var(--secondary-text-color)",
                  }}
                />
              </Tooltip>}
          </Box>
        </ListItemButton>
      ),
    },
  ];

  useEffect(() => {
    const path = window.location.pathname;
    const lastSegment = path.substring(path.lastIndexOf("/") + 1);
    if (lastSegment === "company-policy" || lastSegment === "leave-policy") {
      setPolicyExpanded("PolicyPanel");
    }
    if (
      lastSegment === "employee-management" ||
      lastSegment === "leave-management" ||
      lastSegment === "attendance-management" ||
      lastSegment === "task-management" ||
      lastSegment === "project-management" ||
      lastSegment === "manage-roles" ||
      lastSegment === "manage-events" ||
      lastSegment === "Event Management"
    ) {
      setExpanded("ManagementPanel");
    }
    setProfileImageAvailable(false);
  }, []);

  useEffect(
    () => {
      async function checkImageAvailability(id) {
        const imageUrl = `${CLOUD_FUNCTIONS_ORIGIN}/user/getProfileImage/${id}.png`;
        try {
          const response = await axios.head(imageUrl);
          if (response.status === 200 || response.status === 201) {
            setProfileImageAvailable(true);
            setProfileImgSrc(imageUrl);
          } else {
            setProfileImageAvailable(false);
            setProfileImgSrc(ProfileImg);
          }
        } catch (error) {
          setProfileImageAvailable(false);
        }
      }

      if (currentEmployeeLogin) {
        // setCurrentUserId(currentEmployee?.empId);
        checkImageAvailability(currentEmployee?.empId);
        setProfileImgSrc(false);
      } else if (adminLogin) {
        // setCurrentUserId(adminData?.empId);
        checkImageAvailability(adminData?.empId);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // dispatch, currentEmployeeLogin, adminLogin, currentEmployee, adminData
    ]
  );

  useEffect(() => {
    // const getPermission =
    //   getRoleByPermission?.length > 0
    //     ? getRoleByPermission
    //     : getPermissionLocal;
    const getPermission = getRoleByPermission;
    const filtered = renderAllMenu?.filter((menuItem) =>
      getPermission?.some(
        (permission) => permission?.pageName === menuItem?.pageName
      )
    );

    const filteredManagementPanelMenu = ManagementPanelMenu?.filter(
      (menuItem) =>
        getPermission?.some(
          (permission) => permission?.pageName === menuItem?.pageName
        )
    );

    const managementPanelShouldBeIncluded =
      filteredManagementPanelMenu?.length > 0;

    const filteredPolicyPanelMenu = PolicyPanelMenu?.filter((menuItem) =>
      getPermission?.some(
        (permission) => permission?.pageName === menuItem?.pageName
      )
    );

    const policyPanelMenuShouldBeIncluded = filteredPolicyPanelMenu?.length > 0;

    const managementPanelAccordion = (
      <Accordion
        disableGutters
        elevation={0}
        expanded={expanded === "ManagementPanel"}
        onChange={handleChangeExpanded("ManagementPanel")}
      >
        <AccordionSummary
          expandIcon={
            expanded ? (
              isOpen ? <KeyboardArrowUpIcon
                fontSize="small"
                sx={{ color: "var(--secondary-text-color)" }}
              /> : ""
            ) : (
              isOpen ? <KeyboardArrowRightIcon
                fontSize="small"
                sx={{ color: "var(--secondary-text-color)" }}
              /> : ""
            )
          }
          sx={{
            fontFamily: "Jost, sans-serif",
            margin: "0px 0px 0px",
            padding: "0px 0px 0px ",
            textTransform: "capitalize",
            background: "transparent",
            color: "var(--secondary-text-color)",
          }}
        >
          <Box className={isOpen ? "sidebar-accordion" : "sidebar-accordion-hide"}>
            {isOpen ? <><RoomPreferencesIcon sx={{ paddingLeft: "10px" }} /><Box
              sx={{
                marginLeft: "9px",
                color: expanded
                  ? "var(--primary-color)"
                  : "var(--secondary-text-color)",
              }}
            >
              Management
            </Box></> : <Tooltip title="Management"
              arrow
              placement="left"
              disableInteractive
              TransitionComponent={Zoom}>
              <RoomPreferencesIcon sx={{
                color: expanded
                  ? "var(--primary-color)" : ""
              }} />
            </Tooltip>}
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ background: "transparent", padding: isOpen ? "8px 16px 16px" : "0px", paddingTop: "0px" }}>
          {filteredManagementPanelMenu?.map((item) => (
            <React.Fragment key={item?.id}>{item?.render}</React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>
    );

    const policyPanelAccordion = (
      <Accordion
        disableGutters
        elevation={0}
        expanded={policyExpanded === "PolicyPanel"}
        onChange={handleChangePolicyExpanded("PolicyPanel")}
      >
        <AccordionSummary
          expandIcon={
            policyExpanded ? (
              isOpen ? <KeyboardArrowUpIcon
                fontSize="small"
                sx={{
                  color: "var(--secondary-text-color)",
                }}
              /> : ""
            ) : (
              isOpen ? <KeyboardArrowRightIcon
                fontSize="small"
                sx={{
                  color: "var(--secondary-text-color)",
                }}
              /> : ""
            )
          }
          sx={{
            fontFamily: "Jost, sans-serif",
            margin: "0px 0px 0px",
            padding: "0px 0px 0px 0px",
            textTransform: "capitalize",
            background: "transparent",
            color: "var(--secondary-text-color)",
          }}
        >
          <Box className={isOpen ? "sidebar-accordion" : "sidebar-accordion-hide"}>
            {isOpen ? <><DocumentScannerIcon sx={{ paddingLeft: "10px" }} /><Box
              sx={{
                marginLeft: "9px",
                color: policyExpanded
                  ? "var(--primary-color)"
                  : "var(--secondary-text-color)",
              }}
            >
              Company Policy
            </Box></> : <Tooltip title="Company Policy"
              arrow
              placement="left"
              disableInteractive
              TransitionComponent={Zoom}>
              <DocumentScannerIcon sx={{
                color: policyExpanded
                  ? "var(--primary-color)"
                  : "",
              }} />
            </Tooltip>}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          sx={{ background: "transparent", padding: isOpen ? "8px 16px 16px" : "0px", paddingTop: "0px" }}
        >
          {filteredPolicyPanelMenu?.map((item) => (
            <React.Fragment key={item?.id}>{item?.render}</React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>
    );

    const updatedFilteredMenu = filtered?.filter(
      (item) =>
        item?.pageName !== "ManagementPanel" || item?.pageName !== "PolicyPanel"
    );

    if (managementPanelShouldBeIncluded) {
      updatedFilteredMenu?.push({
        id: 8,
        pageName: "ManagementPanel",
        render: managementPanelAccordion,
      });
    }

    if (policyPanelMenuShouldBeIncluded) {
      updatedFilteredMenu?.push({
        id: 10,
        pageName: "PolicyPanel",
        render: policyPanelAccordion,
      });
    }
    setFilteredMenu(updatedFilteredMenu);
    // eslint-disable-next-line
  }, [getRoleByPermission, expanded, policyExpanded, pathname, isOpen]);

  return (
    <Grid item xs={12} md={isOpen ? 2.5 : 0.5}
      sx={{
        height: "100vh",
        overflowY: "auto",
        display: isMobile && !isOpen ? "none" : "block",
      }}>
      <Box
        sx={{
          position: isMobile ? "fixed" : "relative",
          width: isOpen ? (isMobile ? "90%" : "auto") : "auto",
          background: "var(--background-table-sidebar-card-color)",
          padding: isOpen ? "0px 20px" : "0px",
          height: "100%",
          overflow: isOpen ? "auto" : "hidden",
          color: "red",
          boxShadow: isOpen && isMobile ? "0px 13px 10px -20px #111" : "none",
          borderRight: isMobile ? "none" : "0.5px solid var(--table-border-color)",
        }}
      >
        <Box
          sx={{
            height: isOpen ? "100%" : "90%",
            overflow: !isOpen ? "auto" : "",
            // "&:hover": {
            //   overflow: !isOpen ? "auto" : "hidden",
            // },
          }}
        >
          {!isOpen ? (
            !isMobile && (
              <>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <ContentRightBox onClick={handleSidebarShow}>
                    <MenuIcon style={{ fontSize: "2rem" }} />
                    {/* <ChevronRightIcon style={{ fontSize: "1.8rem", fill: "#2c7be5" }} /> */}
                  </ContentRightBox>
                </Box>
                <Box marginTop={2}>
                  <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
                    {filteredMenu?.map((item) => (
                      <React.Fragment key={item?.id}>{item?.render}</React.Fragment>
                    ))}
                  </List>
                </Box>
              </>
            )
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "20px",
                }}
              >
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate("/my-profile")}
                >
                  {ProfileImageAvailable ? (
                    <>
                      <img
                        ref={imgRef}
                        style={{
                          borderRadius: "50%",
                          backgroundColor: "var(--primary-highlight-color)",
                          padding: "5px",
                          border: "2px solid var(--secondary-text-color)",
                        }}
                        width={"90px"}
                        height={"92px"}
                        // src={`${CLOUD_FUNCTIONS_ORIGIN}/user/getProfileImage/${currentUserId}.png`}
                        src={profileImgSrc}
                        alt="profile"
                      />
                    </>
                  ) : !currentEmployeeLogin ? (
                    <>
                      <img width={"90px"} src={ProfileImg} alt="profile" />
                    </>
                  ) : (
                    <>
                      <img
                        style={{
                          borderRadius: "50%",
                          backgroundColor: "var(--primary-highlight-color)",
                          padding: "5px",
                          border: "2px solid var(--secondary-text-color)",
                        }}
                        width={"90px"}
                        src={profileSrc}
                        alt="profile"
                      />
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    paddingLeft: "15px",
                  }}
                >
                  <Typography
                    sx={{ fontWeight: "bold", color: "var(--secondary-text-color)" }}
                  >
                    {currentEmployeeLogin
                      ? capitalizeFirstLetter(currentEmployee?.firstName)
                      : null}
                    {adminLogin ? capitalizeFirstLetter(adminFirstName) : null}
                  </Typography>
                  <Typography
                    sx={{ color: "var(--secondary-text-color)", fontSize: "13px" }}
                  >
                    {currentEmployeeLogin
                      ? capitalizeFirstLetter(currentEmployee?.position?.role)
                      : null}
                    {adminLogin ? capitalizeFirstLetter(adminPosition) : null}
                  </Typography>
                  <MuiLink
                    component={RouterLink}
                    to="/my-profile"
                    onClick={() => isMobile && handleSidebarShow()}
                    sx={{
                      color: "var(--primary-color)",
                      fontSize: "13px",
                      cursor: "pointer",
                      marginTop: "10px",
                      textDecoration: "none",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>View Profile</Typography>
                  </MuiLink>
                </Box>
                {/* <Box marginBottom={4} sx={{ display: "flex", justifyContent: "center" }}>
                  <ContentLeftBox isdarktheme={isDarkTheme ? "true" : "false"} onClick={handleSidebarShow}>
                    <ChevronLeftIcon style={{ fontSize: "1.8rem", fill: "#2c7be5" }} />
                  </ContentLeftBox>
                </Box> */}
                {/* </Box> */}
                {isMobile ? (
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                    }}
                    onClick={handleSidebarShow}
                  >
                    <CloseIcon style={{ fontSize: "1.8rem", fill: "#2c7be5" }} />
                  </IconButton>
                ) : (
                  <ContentLeftBox isdarktheme={isDarkTheme ? "true" : "false"} onClick={handleSidebarShow}>
                    <ChevronLeftIcon style={{ fontSize: "1.8rem", fill: "#2c7be5" }} />
                  </ContentLeftBox>
                )}
              </Box>
              <hr
                style={{
                  border: "none",
                  height: "1px",
                  background: "var(--table-border-color)",
                }}
              />
              <Box sx={{ margin: "0px" }} mt={2}>
                <List sx={{ paddingTop: "0px", paddingBottom: "0px" }}>
                  {filteredMenu?.map((item) => (
                    <React.Fragment key={item?.id}>{item?.render}</React.Fragment>
                  ))}
                </List>
              </Box>
            </>
          )}
        </Box>
        {!isOpen ? <Box
          sx={{
            height: "10%",
          }}
        >
          <ProfileBox
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            onClick={() => navigate("/my-profile")}
          >
            {ProfileImageAvailable ? (
              <>
                <img
                  ref={imgRef}
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "var(--primary-highlight-color)",
                    padding: "5px",
                    border: "2px solid var(--secondary-text-color)",
                    width: "40px",
                  }}
                  // src={`${CLOUD_FUNCTIONS_ORIGIN}/user/getProfileImage/${currentUserId}.png`}
                  src={profileImgSrc}
                  alt="profile"
                />
              </>
            ) : !currentEmployeeLogin ? (
              <Tooltip title="Profile"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <img width={"40px"} style={{
                  borderRadius: "50%",
                  backgroundColor: "var(--primary-highlight-color)",
                  padding: "5px",
                  border: "3px solid var(--secondary-text-color)",
                }} src={ProfileImg} alt="profile" />
              </Tooltip>
            ) : (
              <Tooltip title="Profile"
                arrow
                placement="left"
                disableInteractive
                TransitionComponent={Zoom}>
                <img
                  style={{
                    borderRadius: "50%",
                    backgroundColor: "var(--primary-highlight-color)",
                    padding: "5px",
                    border: "3px solid var(--secondary-text-color)",
                  }}
                  width={"40px"}
                  src={profileSrc}
                  alt="profile"
                />
              </Tooltip>
            )}
          </ProfileBox>
        </Box> : ""}
      </Box>
    </Grid>
  );
};

export default SideBar;
