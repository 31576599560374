import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  Box,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  RadioGroup,
  Paper,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { CloseIconButton, CustomButton, CancelButton, DialogsActions, CustomTextField, ErrorTypography, CustomRadio, ContentBox, TimerOffIcons, InnerBoxTwoDialog, InnerBoxOneDialog, MainBoxDialog } from "../../../Styles/EmployeeManagement/Employee/TimerStyle"
import DialogComponent from "../../ReusableComponents/DialogBoxComponents/DialogComponent";
import {
  setTotalBreakTime,
  setIsPlaying,
  setIsRunning,
  setIsStart,
  setIsDefault,
  setShowDialogBox,
} from "../../../Slices/timerDetailsSlice";
import { formatDate } from "../../ReusableComponents/UtilityFunctions";
import { upserTimerEnd } from "../../../Services/AttendanceManageServices";
import { setSetAttendenceRecord } from "../../../Slices/employeeDetailSlice";
import { setToast, setToastMsg, setToastType } from "../../../Slices/toastSlice";
import { errorMessage } from "../../../Services/axiosInstance";

const PaperComponent = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

const TimerDialogBox = ({ open }) => {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const user = useSelector((state) => state.selectedActiveView.role);
  const employeeEmail = useSelector((state) => state.addEmployee.current?.email);
  const adminEmail = useSelector((state) => state.adminData.adminData?.email);
  const employeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const [displayError, setDisplayError] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState("Leaving for the day");

  function getEmail() {
    if (employeeLogin) {
      return employeeEmail;
    } else if (adminLogin) {
      return adminEmail;
    }
  }

  function getUser() {
    if (employeeLogin) {
      return "user";
    } else if (adminLogin) {
      return "admin";
    }
  }

  const onCloseTimerDialogBox = () => {
    dispatch(setShowDialogBox(false))
  }

  const handleRadioChange = (event) => {
    setShow(false);
    setDisplayError("");
    setSelectedValue(event.target.value);
    if (event.target.value === "Leaving for the day") {
      setShow(true);
    }
  };

  const stopTimmer = () => {
    if (!selectedValue) {
      setDisplayError("Reason Required.");
    } else if (!comment && show) {
      setDisplayError("Reason Required.");
    } else {
      if (show) {
        handleEnd(comment);
      } else {
        handleEnd(selectedValue);
      }
    }
  };

  const handleCommentChange = (event) => {
    setDisplayError("");
    setComment(event.target.value);
  };

  const handleEnd = async (selectedValue) => {
    setDisplayError("");
    navigator.geolocation.getCurrentPosition(async (position) => {
      try {
        const currentDate = new Date();
        const endTime = new Date().getTime();
        const date = formatDate(currentDate);
        const email = getEmail();
        const user = getUser();
        const postData = JSON.stringify({
          email, endTime, date, selectedValue, currentWorkingTicketNo: window.localStorage.getItem("TaskNo") || "",
          CurrentLocation: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        })
        const response = await upserTimerEnd(user, postData)
        if (response.status === 200 || response.status === 201) {
          const TimerStart = response.data.totalTime.TimerStart;
          const BreakTime = response.data.totalTime.BreakTime;
          const totalTime = endTime - TimerStart - BreakTime;
          const totalInSec = Math.floor(totalTime / 1000);
          window.localStorage.setItem("totalTime", totalInSec);

          dispatch(setTotalBreakTime(BreakTime));
          dispatch(setIsDefault(true));
          dispatch(setIsPlaying(false));
          dispatch(setIsRunning(false));
          dispatch(setIsStart(true));
          dispatch(setShowDialogBox(false));
          dispatch(setSetAttendenceRecord(true));
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Time updated."));
          dispatch(setToastType("success"));

          window.localStorage.removeItem("pauseTime");
          window.localStorage.removeItem("start");
          window.localStorage.removeItem("gap");
          // dispatch(setIsLoading(false));
        }
        else {
          dispatch(setTotalBreakTime(0));
        }
      } catch (error) {
        // dispatch(setIsLoading(false))
        setDisplayError(errorMessage ?? "");
      }
    });
  };

  const renderTimerDialogForm = () => {
    return (<>
      <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
        <Box>
          <CloseIconButton
            onClick={onCloseTimerDialogBox}
            isdarktheme={isDarkTheme ? "true" : "false"}
          >
            <Close />
          </CloseIconButton>
        </Box>
        <MainBoxDialog
          id="draggable-dialog-title"
        >
          <Box>
            <DialogTitle
              sx={{ fontWeight: "bold", }}
            >
              <InnerBoxOneDialog>
                <InnerBoxTwoDialog>
                  <TimerOffIcons />
                </InnerBoxTwoDialog>
                <Box>
                  <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
                    {/* {"Reason for stoping Timer !"} */}
                    {
                      "Reason to stop the timer"
                    }
                  </Box>
                </Box>
              </InnerBoxOneDialog>
            </DialogTitle>
          </Box>
        </MainBoxDialog>
        <DialogContent>
          <ContentBox isdarktheme={isDarkTheme ? "true" : "false"}>
            <RadioGroup value={selectedValue} onChange={handleRadioChange}>
              <FormControlLabel
                value="Lunch Break"
                control={
                  <CustomRadio isdarktheme={isDarkTheme ? "true" : "false"} />
                }
                label="Lunch Break"
              />
              <FormControlLabel
                value="Tea Break"
                control={
                  <CustomRadio isdarktheme={isDarkTheme ? "true" : "false"} />
                }
                label="Tea Break"
              />
              <FormControlLabel
                value="Leaving for the day"
                control={
                  <CustomRadio isdarktheme={isDarkTheme ? "true" : "false"} />
                }
                label="Leaving for the day"
              />
            </RadioGroup>
          </ContentBox>
          <ErrorTypography>
            {displayError}
          </ErrorTypography>
          {show ? (
            <>
              <CustomTextField
                isdarktheme={isDarkTheme ? "true" : "false"}
                label="Add Reason For Leaving"
                multiline
                rows={2}
                variant="outlined"
                name="comment"
                value={comment}
                onChange={handleCommentChange}
              />
            </>
          ) : null}
        </DialogContent>
        <DialogsActions>
          <CancelButton
            onClick={onCloseTimerDialogBox}
            isdarktheme={isDarkTheme ? "true" : "false"}
          >
            Cancel
          </CancelButton>
          <CustomButton
            onClick={() => stopTimmer()}
            variant="contained"
            component={Link}
            color="error"
          >
            Stop
          </CustomButton>
        </DialogsActions>
      </Box>
    </>)
  }

  useEffect(() => {
    setDisplayError("");
    setShow(false);
    setComment("Leaving for the day");
    setSelectedValue("");
  }, [open]);

  return (
    <Box>
      <DialogComponent
        open={open}
        onClose={onCloseTimerDialogBox}
        maxWidth="md"
        PaperComponent={PaperComponent}
        renderChildern={renderTimerDialogForm}
      />
    </Box>
  );
};

export default TimerDialogBox;
