import React, { useState } from "react";
import { useSelector } from "react-redux";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { Box } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Zoom from "@mui/material/Zoom";

function EventCalendar({ festivalData, eventData }) {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const [value, onChange] = useState(new Date());
  const eventDates = eventData.map(({ date, eventTitle }) => {
    const [year, month, day] = date.split("-");
    return { date: new Date(year, month - 1, day), eventTitle };
  });

  const publicHolidayDates = festivalData.map(({ date, festival }) => {
    const [year, month, day] = date.split("-");
    return { date: new Date(year, month - 1, day), festival };
  });

  const all2nd4th5thstd = getAll2nd4th5thSaturdays().map(({ date }) => {
    const [year, month, day] = date.split("-");
    return { date: new Date(year, month - 1, day) };
  });

  function getAll2nd4th5thSaturdays() {
    const dates = [];
    for (
      let year = new Date().getFullYear() - 100;
      year <= new Date().getFullYear() + 100;
      year++
    ) {
      for (let month = 0; month < 12; month++) {
        const firstDayOfMonth = new Date(year, month, 1);
        const firstSaturday = new Date(
          year,
          month,
          1 + ((6 - firstDayOfMonth.getDay() + 1) % 7)
        );
        if (firstSaturday.getMonth() === month) {
          let secondSaturday = new Date(firstSaturday);
          secondSaturday.setDate(secondSaturday.getDate() + 7);
          let fourthSaturday = new Date(firstSaturday);
          fourthSaturday.setDate(fourthSaturday.getDate() + 21);
          let fifthSaturday = new Date(firstSaturday);
          fifthSaturday.setDate(fifthSaturday.getDate() + 28);
          if (secondSaturday.getMonth() === month) {
            dates.push({ date: secondSaturday.toISOString().slice(0, 10) });
          }
          if (fourthSaturday.getMonth() === month) {
            dates.push({ date: fourthSaturday.toISOString().slice(0, 10) });
          }
          if (fifthSaturday.getMonth() === month) {
            dates.push({ date: fifthSaturday.toISOString().slice(0, 10) });
          }
        }
      }
    }
    return dates;
  }

  // Function to render tile content
  const tileContent = ({ date }) => {
    const eventDate = eventDates.find(
      (event) => event.date.getTime() === date.getTime()
    );
    const publicHolidayDate = publicHolidayDates.find(
      (holiday) => holiday.date.getTime() === date.getTime()
    );
    const Sat2nd4th = all2nd4th5thstd.find(
      (satdate) => satdate.date.getTime() === date.getTime()
    );

    if (Sat2nd4th) {
      return (
        <span
          className="std-date"
          style={{ color: "var(--secondary-text-color)" }}
        >
          {date.getDate()}
        </span>
      );
    }

    if (eventDate) {
      return (
        <Tooltip TransitionComponent={Zoom} title={eventDate.eventTitle}>
          <span
            className="event-date"
            style={{ color: "#347ccc", fontWeight: "bold" }}
          >
            {date.getDate()}
          </span>
        </Tooltip>
      );
    } else if (publicHolidayDate) {
      return (
        <Tooltip title={publicHolidayDate.festival}>
          <span
            className="public-holiday"
            style={{ color: "#9bc31c", fontWeight: "bold" }}
          >
            {date.getDate()}
          </span>
        </Tooltip>
      );
    } else {
      return null;
    }
  };

  // Function to determine the style of each calendar tile
  const tileClassName = ({ date }) => {
    const eventDate = eventDates.find(
      (event) => event.date.getTime() === date.getTime()
    );
    const publicHolidayDate = publicHolidayDates.find(
      (holiday) => holiday.date.getTime() === date.getTime()
    );
    const Sat2nd4th = all2nd4th5thstd.find(
      (satdate) => satdate.date.getTime() === date.getTime()
    );

    if (Sat2nd4th) {
      return "std-date";
    }

    if (eventDate) {
      return "event-date";
    } else if (publicHolidayDate) {
      return "public-holiday";
    }
  };

  return (
    <Box sx={{ paddingTop: "20px", paddingBottom: "20px" }}>
      <Calendar
        className={
          isDarkTheme ? "DarkThemeEventCalendar" : "LightThemeEventCalendar"
        }
        onChange={onChange}
        value={value}
        tileClassName={tileClassName}
        tileContent={tileContent}
        showNeighboringMonth={false}
      />
    </Box>
  );
}

export default EventCalendar;
