import { Box, Button, DialogActions, FormControlLabel, IconButton, TextField, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(({ isdarktheme }) => ({
    background: isdarktheme === "true" ? "#121e2e" : "#ffffff"
}))

export const InnerBoxOne = styled(Box)(() => ({
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "550px",
    height: "75px",
}))

export const InnerBoxTwo = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const TitleBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#62626280",
    borderRadius: "1000px",
    padding: "8px",
    marginRight: "25px",
}))

export const InnerBoxThree = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "left",
    marginTop: "-15px",
    paddingLeft: "35px",
    marginRight: "25px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    '@media (max-width: 600px)': {
       width:"92%",
       paddingLeft: '10px',
       marginRight: '10px',
    },
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    paddingLeft: "38px",
    marginTop: "0px",
}))

export const InnerBoxFour = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
    // alignItems: "flex-end",
    marginTop: "20px",
    paddingLeft: "35px",
    marginRight: "25px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    '@media (max-width: 600px)': {
        paddingLeft: '10px',
        marginRight: '10px',
        marginTop: "15px",
    },
}))

export const InnerBoxFive = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    marginTop: "20px",
    paddingLeft: "35px",
    marginRight: "25px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const FormControlLabels = styled(FormControlLabel)(() => ({
    color: 'var(--secondary-text0-color)',
    ".MuiCheckbox-root": {
        color: "var(--secondary-text-color)",
    }
}))

export const DialogsActions = styled(DialogActions)(() => ({
    background: "var(--highlight-color)",
    boxShadow: "0px 2px 10px var(--dark-highlight-color)",
}))

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textTransform: "capitalize",
    fontWeight: "bold",
    marginRight: "10px",
    borderRadius: "5px",
}))

export const CustomButton = styled(Button)(() => ({
    borderRadius: "5px",
    textTransform: "capitalize",
}))

export const DateTextField = styled(TextField)(({ isdarktheme }) => ({
    width: "100%",
    color: isdarktheme === "true" ? "white" : "black",
    background: isdarktheme === "true" ? "#142840" : "#ffffff",
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      filter: isdarktheme === "true" ? "invert(1)" : "none",
    },
  }));

export const CloseIconButton = styled(IconButton)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "-5px",
    fontWeight: "bold",
    textTransform: "capitalize",
    marginRight: "15px",
    fontSize: "2px",
    "&:hover": {
        background: "var(--highlight-color)",
    },
}))