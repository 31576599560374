import { Box, Typography, styled } from "@mui/material"
import SquareIcon from "@mui/icons-material/Square";

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    display: "inline",
    padding: "5px",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "130%",
}))

export const MainBox = styled(Box)(() => ({
    right: 10,
    position: "relative",
    display: "flex",
    flexDirection: "row", // Change to row to align boxes horizontally
    alignItems: "flex-end",
    justifyContent: "flex-end", // Align boxes to the right
}))

export const CustomTypography = styled(Typography)(() => ({
    fontWeight: "bold",
    display: "flex",
    alignContent: "flex-start",
    color: "var(--secondary-text-color)",
}))

export const WorkingHourSquareIcon = styled(SquareIcon)(() => ({
    marginTop: "3px",
    fill: "var(--secondary-color)",
    borderWidth: "20px",
    borderColor: "var(--secondary-text-color)",
}))

export const BreakHourSquareIcon = styled(SquareIcon)(() => ({
    marginTop: "3px",
    fill: "var(--primary-color)"
}))
