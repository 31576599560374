import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
} from "@mui/material";

import {
	empTaskTableBodyCell,
	empTaskTableHeadCell,
} from "./CustomDesignMUI/CustomMUI";

function TableComponent(props) {
	const { array } = props;
	return (
		<TableContainer
			sx={{
				backgroundColor: "var(--event-calendar-color)",
			}}
		>
			<Table sx={{
				'@media (max-width: 600px)': {
					width: '360%',
				},
			}}>
				<TableBody>
					{array.map((row, rowIndex) => (
						<TableRow
							key={rowIndex}
							sx={{
								background:
									rowIndex === 0
										? "var(--background-table-borderHeader-color)"
										: null,
							}}
						>
							{row.map((subRow, subRowIndex) => (
								<TableCell
									key={subRowIndex}
									sx={
										rowIndex === 0
											? empTaskTableHeadCell
											: empTaskTableBodyCell
									}
								>
									{subRow}
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
export default TableComponent;
