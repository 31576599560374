import { Autocomplete, Box, Button, DialogActions, IconButton, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    borderBottom: "1px solid var(--table-border-color)",
    color: "var(--secondary-text-color)",
    marginBottom: "20px",
    '@media (max-width: 600px)': {
        flexDirection: "column-reverse",
        alignItems: "flex-start",
        justifyContent: "flex-start",
    },
}))

export const InnerBoxOne = styled(Box)(() => ({
    padding: "5px 0px 0px 18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '@media (max-width: 600px)': {
        padding: "0px 0px 0px 0px",
    },
}))

export const PublicKnowledgeButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "bold",
    borderRadius: "0px",
    color:
        selectedbutton === "Public Knowledge"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "Public Knowledge"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
    '@media (max-width: 600px)': {
        margin: "0px 0px 0px 0px",
    },
}))


export const MySharedKnowledgeButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "bold",
    borderRadius: "0px",
    color:
        selectedbutton === "My Shared Knowledge"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "My Shared Knowledge"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
    '@media (max-width: 600px)': {
        margin: "0px 0px 0px 0px",
    },
}))

export const InnerBoxTwo = styled(Box)(() => ({
    margin: "20px 2px 2px 10px",
    bottom: 2,
    display: "flex",
    // '@media (max-width: 600px)': {
    //     flexDirection: "column",
    // },
}))

export const EmployeeNameAutocomplete = styled(Autocomplete)(() => ({
    width: "400px",
    marginRight: "5px",
    ".css-38raov-MuiButtonBase-root-MuiChip-root": {
        background: "var(--tag-background-color)",
    },
    ".css-i4bv87-MuiSvgIcon-root": {
        fill: "var(--tag-close-icon-color)",
    },
    ".MuiAutocomplete-tagSizeMedium": {
        color: "var(--primary-text-color)",
    },
    ".css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator":
        { color: "var(--primary-text-color)" },

    '@media (max-width: 600px)': {
        width: "300px",
    },
}))

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "55vh",
}))

export const CloseIconButton = styled(IconButton)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "-5px",
    fontWeight: "bold",
    textTransform: "capitalize",
    marginRight: "15px",
    fontSize: "2px",
    "&:hover": {
        background: "var(--highlight-color)",
    },
}))

export const InnerBoxThree = styled(Box)(({ isdarktheme }) => ({
    position: "fixed",
    top: 0,
    right: 0,
    whiteSpace: "nowrap",
    '@media (max-width: 600px)': {
        whiteSpace: "normal",
        width: "100%",
    },
    height: "100vh",
    background: isdarktheme === "true" ? "#0b1727" : "#ffffff",
    borderRadius: "5px",
    zIndex: 999,
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
    overflowY: "auto",
}))

export const NoDataFoundTypography = styled(Typography)(() => ({
    textTransform: "capitalize",
    userSelect: "none",
}))

export const InnerBoxFour = styled(Box)(() => ({
    margin: "5px 5px 5px 5px",
    "&:hover": {
        "& > div": {
            background:
                "var(--primary-highlight-color)",
            color: "var(--primary-color)",
        },
    },
    "@media (max-width: 600px)": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "16px"
    },
}))

export const InnerBoxFive = styled(Box)(() => ({
    cursor: "alias",
    borderRadius: "5px",
    minHeight: "160px",
    height: "18vh",
    background:
        "var(--background-table-sidebar-card-color)",
    padding: "5px 15px 5px 15px",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ccc",
    color: "var(--secondary-text-color)",
    justifyContent: "center",
    position: "relative",
    "@media (max-width: 600px)": {
        height: "10vh",
        minHeight: "100px",
    },
}))


export const InnerBoxSix = styled(Box)(() => ({
    position: "absolute",
    top: "5px",
    right: "5px",
}))

export const ContentBox = styled(Box)(() => ({
    // Center content horizontally and vertically
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
}))

export const TitleBox = styled(Box)(() => ({
    fontWeight: "bold",
    textTransform:
        "capitalize",
    overflow: "hidden",
    textOverflow:
        "ellipsis",
    width: "150px",
}))

export const EmployeeDetailBox = styled(Box)(() => ({
    padding: "5px",
    textTransform:
        "capitalize",
}))

export const MainBoxDialog = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "500px",
    "@media (max-width: 600px)": {
        maxWidth: "100%",
    },
}))

export const InnerBoxOneDialog = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "35px 0px",
}))

export const InnerBoxTwoDialog = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: isdarktheme === "true" ? "#ff000046" : "#ff000221",
    borderRadius: "1000px",
    padding: "8px",
    marginRight: "25px",
}))

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textTransform: "capitalize",
    fontWeight: "bold",
    marginRight: "10px",
    borderRadius: "5px",
}))

export const DialogsActions = styled(DialogActions)(() => ({
    background: "var(--highlight-color)",
    boxShadow: "0px 2px 10px var(--dark-highlight-color)",
}))

export const DeleteButton = styled(Button)(() => ({
    borderRadius: "5px",
    textTransform: "capitalize",
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    marginTop: "20px"
}))

export const KnowledgeButtonBox = styled(Box)(() => ({
    paddingRight: "15px",
    paddingBottom: "5px",
    marginLeft: "auto",
    '@media (max-width: 600px)': {
        paddingRight: "0px",
    },
}))

export const SliderBox = styled(Box)(() => ({
    '@media (max-width: 600px)': {
        padding: "10px 0px 0px 0px",
    },
}))

export const InnerSliderBox = styled(Box)(() => ({
    "&::-webkit-scrollbar": {
        display: "none",
    },
    overflowY: "hidden",
}))