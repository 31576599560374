import { AccordionDetails, AccordionSummary, Box, Button, Typography, styled } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"

export const MainBox = styled(Box)(() => ({
    margin: "0px 0px",
}))

export const InnerBoxOne = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    '@media (max-width: 600px)': {
        flexDirection: "column",
    }
}))

export const UpcomingLeavesButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedbutton === "Upcoming Leaves"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "Upcoming Leaves"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const OldLeavesButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedbutton === "Old Leaves"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "Old Leaves"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const DataGrids = styled(DataGrid)(({ dataGridHeight }) => ({
    height: dataGridHeight ? "230px" : "fit-content",
    ".MuiDataGrid-filler": {
        height: "0px !important",
        display: "none",
    },
    color: "var(--primary-text-color)",
    textTransform: "capitalize",
    ".MuiDataGrid-columnHeaderTitle": {
        textTransform: "none !important",
    },
    backgroundColor: "var(--subAccordian-background-color)",
    ".MuiDataGrid-cell ": {
        cursor: "pointer",
        alignContent: "center",
    },
    ".MuiDataGrid-cell:focus": {
        outline: "none",
    },
    ".MuiInput-underline:before": {
        borderBottom: "1px solid var(--primary-text-color) !important",
    },
    ".MuiSvgIcon-fontSizeSmall": {
        fill: "var(--primary-text-color) !important",
    },
    ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
    {
        color: "var(--primary-text-color) !important",
    },
    ".MuiDataGrid-overlay": {
        color: "var(--primary-text-color) !important",
        backgroundColor: "var(--subAccordian-background-color)",
    },
    ".MuiDataGrid-columnHeader:focus": {
        outline: "none",
    },
    // ".MuiSelect-icon": {
    //   fill: "var(--secondary-text-color)",
    // },
    // ".MuiTablePagination-root": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-root": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-cellContent": {
    //   color: "var(--secondary-text-color)",
    //   fontSize: "14px",
    // },
    // ".MuiDataGrid-columnHeader": {
    //   color: "var(--secondary-text-color)",
    //   fontSize: "18px",
    //   backgroundColor: "var(--primary-highlight-color)",
    //   width: "100vh",
    // },
    // ".MuiDataGrid-footerContainer": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-menuIconButton": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-sortIcon": {
    //   color: "var(--secondary-text-color)",
    //   visibility: true,
    // },
    // "MuiDataGrid-virtualScroller": { color: "red" },
    ".MuiDataGrid-actionsCell": { type: "actions" },
    // ".MuiDataGrid-columnHeader:focus": {
    //   outlineColor: "rgb(25, 118, 210)",
    //   outlineStyle: "solid",
    //   outlineWidth: "0px",
    // },

    //color: "var(--secondary-text-color)",
    // // overflow: overlay
    // ".MuiDataGrid-cell:focus": {
    //   outlineColor: "rgb(25, 118, 210)",
    //   outlineStyle: "solid",
    //   outlineWidth: "0px",
    // },
}))

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "76vh",
}))

export const LeavesTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
    marginLeft: "15px",
}))

export const InnerBoxTwo = styled(Box)(() => ({
    paddingRight: "15px",
    paddingBottom: "5px",
}))

export const LeaveAccordianMainBox = styled(Box)(() => ({
    marginTop: "20px",
    marginBottom: "20px",
}))

export const AccordionSummarys = styled(AccordionSummary)(() => ({
    textTransform: "capitalize",
    backgroundColor: "var(--accordian-background-color)",
    color: "var(--primary-text-color)",
    fontWeight: "bold",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const AccordionsDetails = styled(AccordionDetails)(() => ({
    textTransform: "capitalize",
    backgroundColor: "var(--accordian-background-color)",
    color: "var(--primary-text-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const ContentBox = styled(Box)(() => ({
    padding: "5px",
    backgroundColor: "var(--subAccordian-background-color)",
    marginBottom: "5px",
    borderRadius: "5px",
    height: "25px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    "@media (max-width: 600px)": {
        height: "20%",
      },
}))

export const LeaveButtonBox = styled(Box)(() => ({
    marginBottom: "20px",
    paddingRight: "15px",
    display: "flex",
    justifyContent: "flex-end",
    '@media (max-width: 600px)': {
        paddingRight: "2px",
    },
}))

export const SliderBox = styled(Box)(() => ({
    "@media (max-width: 600px)": {
        padding: "10px 0px 0px 0px",
      },
}))

export const InnerSliderBox = styled(Box)(() => ({
    "&::-webkit-scrollbar": {
        display: "none",
      },
      overflowY: "hidden",
}))
