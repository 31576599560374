import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CardContent,
  Grid,
  IconButton,
  Typography,
  useMediaQuery
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

import { formatDate } from "../../ReusableComponents/UtilityFunctions";
import { setCalenderValue } from "../../../Slices/searchData";
import { setEmployeeEfficieny } from "../../../Slices/UserDataSlice";
import DonotChart from "./DonotChart";
import BarChart from "./BarChart";
import EmployeeEfficiencyGrid from "./EmployeeEfficiencyGrid";
import { upsertEmployeeEffecicency } from "../../../Services/TaskManageServices";
import { MainBox, MonthlyButton, AllButtonBox, TitleBox, InnerBoxOne, InnerCardOne, MainCard, CalendarTypography, DateCalendarBox, WeeklyButton, YearlyButton, ChartCard } from '../../../Styles/TaskManagement/EmployeeEfficiencyStyle'
import '../../../assets/styles/DateCalender.css';

function EmployeeEfficiency({ handleBackShowEfficiency, employeeName, employeeEmpId, EmpId }) {
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.searchData.calenderValue);
  const selectedEmpId = useSelector((state) => state.addEmployee.employeeIndex.empId);
  const isMobile = useMediaQuery('(max-width:600px)');

  const SelectedEmpId = selectedEmpId || EmpId;
  const employeeEfficieny = useSelector(
    (state) => state.employeeData.employeeEfficieny
  );

  const [dateRange, setDateRange] = useState([null, null]); // State to store the selected date range
  const [change, setChange] = useState(false);
  const [taskEdited, setTaskEdited] = useState(false);
  const [selectedButton, setSelectedButton] = useState("Weekly"); // State to store the selected date range
  const [date, setDate] = useState(dayjs(new Date()));
  // const [displayMessageError, setDisplayMessageError] = useState("");

  const dataArray = [
    {
      label: "In Progress",
      value: employeeEfficieny[5]?.length || null,
      color: "var(--taskinProgress-highlight-color)",
    },
    {
      label: "OverDue",
      value: employeeEfficieny[2]?.length || null,
      color: "var(--taskOverdue-highlight-color)",
    },
    {
      label: "Completed",
      value: employeeEfficieny[1]?.length || null,
      color: "var(--taskComplete-highlight-color)",
    },
    {
      label: "Remaining",
      value: employeeEfficieny[6]?.length || null,
      color: "var(--taskRemain-highlight-color)",
    },
  ];

  const getWeekDates = (formattedDate) => {
    setSelectedButton("Weekly");
    const today = dayjs(formattedDate, "DD-MM-YYYY");
    const startOfWeek = today.startOf("week");
    const endOfWeek = today.endOf("week");
    const dateArray = [formatDate(startOfWeek.$d), formatDate(endOfWeek.$d)];
    setDateRange(dateArray);
    // memoizedAdminFetchData(dateArray);
  };

  const getMonthDates = () => {
    setSelectedButton("Monthly");
    const today = dayjs(selectedDate, "DD-MM-YYYY");
    const startOfMonth = today.startOf("month");
    const endOfMonth = today.endOf("month");
    const dateArray = [formatDate(startOfMonth.$d), formatDate(endOfMonth.$d)];
    setDateRange(dateArray);
    // memoizedAdminFetchData(dateArray);
  };

  const getYearDates = () => {
    setSelectedButton("Yearly");
    const today = dayjs(selectedDate, "DD-MM-YYYY");
    const startOfYear = today.startOf("year");
    const endOfYear = today.endOf("year");
    const dateArray = [formatDate(startOfYear.$d), formatDate(endOfYear.$d)];
    setDateRange(dateArray);
    // memoizedAdminFetchData(dateArray);
  };

  const handleDateChange = (newValue) => {
    setDate(newValue); // Update the selected date
    const formattedDate = formatDate(newValue.$d);
    dispatch(setCalenderValue(formattedDate)); // Dispatch action with formatted date
    getWeekDates(formattedDate);
  };

  const memoizedAdminFetchData = useMemo(() => {
    const getTaskdata = async (dates) => {
      try {
        // const response = await axios.post(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/getEffecicency`,
        //   {
        //     dates,
        //     selectedEmpId,
        //   },
        //   postHeader
        // );
        const postData = { dates, selectedEmpId: SelectedEmpId, };
        const response = await upsertEmployeeEffecicency(postData)
        if (response.status === 200 || response.status === 201) {
          const Task = response.data.data;
          const HighEfficiencyTask = response.data.efficienctWork;
          const lowEfficiencyTask = response.data.notEfficienct;
          const remainingTask = response.data.remaining;
          const average = response.data.average;
          const inProgress = remainingTask.filter(
            (state) => state.status === "In Progress"
          );
          const TotalRemaining = remainingTask.filter(
            (state) => state.status !== "In Progress"
          );

          const array = [
            Task,
            HighEfficiencyTask,
            lowEfficiencyTask,
            remainingTask,
            average,
            inProgress,
            TotalRemaining,
          ];
          dispatch(setEmployeeEfficieny(array));
          // dispatch(setToast(true));
          // dispatch(setToastMsg(response.data.message));
          // dispatch(setToastType("success"));
        }
      } catch (error) {
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   } else if (error.response.status === 500) {
        //     // setDisplayError(error.response.data.message);
        //   }
        // }
      }
    };
    return getTaskdata;
  }, [dispatch, SelectedEmpId]);

  useEffect(() => {
    if (dateRange[0] === null || dateRange[1] === null) {
      const today = dayjs(new Date());
      const startOfWeek = today.startOf("week");
      const endOfWeek = today.endOf("week");
      const dates = [formatDate(startOfWeek.$d), formatDate(endOfWeek.$d)];
      memoizedAdminFetchData(dates);
    } else {
      memoizedAdminFetchData(dateRange);
    }
  }, [memoizedAdminFetchData, dateRange, change, taskEdited]);


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <MainBox>
          <>
            {EmpId ? <></> : <TitleBox>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton onClick={handleBackShowEfficiency}>
                  <ArrowBackIcon color="primary" />
                </IconButton>

                <Typography variant={isMobile ? "body2" : "h6"} sx={{ fontWeight: "bold" }}>
                  Task Efficiency  ( {employeeEmpId}: {employeeName} )
                </Typography>
              </Box>
            </TitleBox>}

            <Box sx={{ marginTop: "20px" }}>
              <Box sx={{ overflow: "auto" }}>
                <Grid item container sx={{ paddingTop: "10px" }}>
                  <Grid sm={4} xs={12} item sx={{ paddingRight: isMobile ? "" : "8px", marginBottom: isMobile ? "8px" : "" }}>
                    <MainCard>
                      <CardContent>
                        <CalendarTypography>
                          Calendar
                        </CalendarTypography>
                        <DateCalendarBox>
                          <Box sx={{ flex: "1 1 auto" }}>
                            <DateCalendar
                              sx={{ left: 0 }}
                              value={date}
                              onChange={handleDateChange}
                            />
                          </Box>
                        </DateCalendarBox>
                      </CardContent>
                    </MainCard>
                  </Grid>
                  <Grid sm={8} xs={12} sx={{ paddingLeft: isMobile ? "" : "8px" }} item>
                    <InnerCardOne>
                      <CardContent>
                        <Box sx={{ display: !isMobile && "flex" }}>
                          <InnerBoxOne>
                            <AllButtonBox>
                              <WeeklyButton
                                variant="outlined"
                                onClick={() => getWeekDates(selectedDate)}
                                selectedbutton={selectedButton}
                              >
                                Weekly
                              </WeeklyButton>
                              <MonthlyButton
                                variant="outlined"
                                onClick={() => getMonthDates(selectedDate)}
                                selectedbutton={selectedButton}
                              >
                                Monthly
                              </MonthlyButton>
                              <YearlyButton
                                variant="outlined"
                                onClick={() => getYearDates(selectedDate)}
                                selectedbutton={selectedButton}
                              >
                                Yearly
                              </YearlyButton>
                            </AllButtonBox>
                          </InnerBoxOne>
                          <Box>
                            <DonotChart  employeeEfficieny={employeeEfficieny} />
                          </Box>
                        </Box>
                      </CardContent>
                    </InnerCardOne>
                  </Grid>
                </Grid>
                <Grid item container sx={{ paddingTop: "16px" }}>
                  <Grid sm={8} xs={12} item sx={{ paddingRight: isMobile ? "" : "8px", marginBottom: isMobile ? "8px" : "" }}>
                    <InnerCardOne>
                      <CardContent>
                        {" "}
                        <EmployeeEfficiencyGrid
                          setChange={setChange}
                          change={change}
                          setTaskEdited={setTaskEdited}
                          taskEdited={taskEdited}
                          employeeEfficieny={employeeEfficieny}
                        />
                      </CardContent>
                    </InnerCardOne>
                  </Grid>
                  <Grid sm={4} xs={12} item sx={{ paddingLeft: isMobile ? "" : "8px" }}>
                    <ChartCard>
                      <CardContent sx={{ padding: isMobile ? "6px" : "16px" }}>
                        <BarChart
                          isMobile={isMobile}
                          dataArray={dataArray}
                          selectedButton={selectedButton}
                        />
                      </CardContent>
                    </ChartCard>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </>
        </MainBox>
      </Box>
    </LocalizationProvider>
  );
}

export default EmployeeEfficiency;
