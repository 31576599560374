import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";

import { formatDate } from "../../ReusableComponents/UtilityFunctions";
import { ActivityTitle } from "../../../components/ReusableComponents/CustomDesignMUI/CustomMUI";
import { getEmployee } from "../../../Services/EmployeeManageServices";
import { TitleTypography, MainBox, CustomTypography, Timelines, TimelineDots, AccessTimeIcons } from "../../../Styles/EmployeeManagement/Employee/TodayActivityStyle"

const TodayActivity = () => {
  const activityData = useSelector(
    (state) => state.addEmployee.employeeAllAttendenceData
  );

  const [employeesNameArray, setEmployeesNameArray] = useState([])

  const todayDate = new Date();
  const filteredData = activityData.filter(
    (item) => item.date === formatDate(todayDate)
  );
  const records = filteredData[0]?.dailytimer?.timer;

  function extractTime(timestamp) {
    const dateTime = new Date(timestamp);
    const time = dateTime.toLocaleTimeString([], {
      hour12: true,
      hour: "2-digit",
      minute: "2-digit",
    }); // Extract time part
    return time;
  }

  function getName(empId) {
    const employee = employeesNameArray.find((state) => state.empId === empId);
    return employee?.firstName;
  }

  const fetchEmployeeName = async () => {
    try {
      // const response = await axios.get(
      //   `${CLOUD_FUNCTIONS_ORIGIN}/user/getEmployeeName`,
      //   getHeader
      // );
      const response = await getEmployee()
      if (response.status === 200 || response.status === 201) {
        setEmployeesNameArray(response.data.namesData);
      }
      else {
        setEmployeesNameArray([])
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchEmployeeName();
  }, []);

  return (
    <Box>
      <TitleTypography>
        Today Activity
      </TitleTypography>
      <MainBox>
        <Timelines>
          {records?.length === 0 ? (
            <Typography>No activity </Typography>
          ) : (
            records?.map((record, index) => (
              <Box key={index}>
                <Box>
                  <TimelineItem>
                    <TimelineSeparator>
                      <TimelineDots
                        variant="outlined"
                      />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography sx={ActivityTitle}>Punch In at</Typography>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <AccessTimeIcons />
                        <CustomTypography>
                          {extractTime(record?.startTime)}
                        </CustomTypography>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                </Box>
                {record?.endTime === 0 ? null : (
                  <>
                    <Box>
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDots
                            variant="outlined"
                          />
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography sx={ActivityTitle}>
                            Punch Out at
                          </Typography>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <AccessTimeIcons />
                            <CustomTypography>
                              {extractTime(record?.endTime)}&nbsp;(
                              {record?.updatedByEmpId ? `${record?.reason} [ Updated by ${getName(record?.updatedByEmpId)}]  ` : record?.reason})
                            </CustomTypography>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    </Box>
                  </>
                )}
              </Box>
            ))
          )}
        </Timelines>
      </MainBox>
    </Box>
  );
};

export default TodayActivity;
