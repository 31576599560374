import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
// import ClearIcon from '@mui/icons-material/Clear';

import { CLOUD_FUNCTIONS_ORIGIN } from "../../function-origin";
import {
  setProjectData,
  setProjectWorkingMemberName,
} from "../../Slices/adminDetailSlice";
import { projectStatus } from "../Constant/constant";
import { errorMessage } from "../../Services/axiosInstance";
import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import { editProjects, upsertProjectDocument, upsertProjects } from "../../Services/ProjectManageServices";
import { getEmployee } from "../../Services/EmployeeManageServices";
import { AdditionalDetailsTypography, CloseBox, InputTextField, LeaderNameAutocomplete, MainBox, MainCard, MemberNameAutocomplete, StatusTextField, TechnologyStackTypography, WorkFlowNameAutocomplete } from "../../Styles/ProjectManagement/AddProjectStyle";
import { getWorkFlow } from "../../Services/WorkFlowServices";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function AddProject({
  setIsLoading,
  setChange,
  change,
  onClose,
  isExistingData,
  setIsExistingData,
  existingData,
  setExistingData,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.selectedActiveView.role);
  const projectData = useSelector((state) => state.adminData.projectData);
  // const allEmpData = useSelector((state) => state.taskData.employeesNames);
  const projectWorkingMemberName = useSelector(
    (state) => state.adminData.projectWorkingMemberName
  );
  const employeeDisplay = user === "employee";
  const adminLogin = user === "admin";
  // const token = window.localStorage.getItem("Token");

  const [selectedMemberNames, setSelectedMemberNames] = useState(
    projectWorkingMemberName || []
  );
  const [showUpdatedFileName, setShowUpdatedFileName] = useState("");
  const [displayMessageError, setDisplayMessageError] = useState("");
  const [addProjectData, setAddProjectData] = useState({
    project: projectData ? projectData?.project : "",
    projectAliasName: projectData ? projectData?.projectAliasName : "",
    status: projectData ? projectData?.status : "",
    clientName: projectData ? projectData?.clientName : "",
    Description: projectData ? projectData?.Description : "",
    leaderName: null,
    frontend: projectData ? projectData?.frontend : "",
    backend: projectData ? projectData?.backend : "",
    datebase: projectData ? projectData?.datebase : "",
    workFlowId: null,
  });
  const [errors, setErrors] = useState("");
  const [selectedMemberNamesError, setSelectedMemberNamesError] = useState("");
  const [fileSelectError, setFileSelectError] = useState("");
  const [projectDocumentationFile, setProjectDocumentationFile] = useState({});
  const [docAvailable, setDocAvailable] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [workFlowList, setWorkFlowList] = useState([]);

  const EmployeesValueSetter = (event, newValue) => {
    setSelectedMemberNames(newValue);
    setDisplayMessageError("");
    setSelectedMemberNamesError("");
  };

  const leaderNameValueSetter = (event, newValue) => {
    setAddProjectData((prevState) => ({
      ...prevState,
      leaderName: newValue,
    }));
  };

  const WorkFlowValueSetter = (event, newValue) => {
    setAddProjectData((prevState) => ({
      ...prevState,
      workFlowId: newValue,
    }));
  };

  const setClose = () => {
    dispatch(setProjectData({}));
    dispatch(setProjectWorkingMemberName([]));
    setExistingData({});
    setIsExistingData(false);
    onClose();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProjectDocumentationFile(file);
      setFileSelectError("");
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDisplayMessageError("");

    setErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));

    const capitalizedValue =
      name === "status"
        ? value
        : value
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

    setAddProjectData((prevState) => ({
      ...prevState,
      [name]: capitalizedValue,
    }));
  };

  const handleSubmit = async (e) => {
    const error = [];
    e.preventDefault();
    if (!addProjectData.project) {
      error.project = "Project can't be empty.";
    }
    if (!addProjectData.status) {
      error.status = "Provide status of project.";
    }
    if (!addProjectData.clientName) {
      error.clientName = "Client name can't be empty.";
    }
    // if (!addProjectData.leaderName?.firstName) {
    //   error.leaderName = "Select leader name.";
    // }
    // if (!addProjectData?.workFlowId?.workFlowName) {
    //   error.workFlowId = "Select workflow.";
    // }
    if (addProjectData.leaderName.length === 0) {
      error.leaderName = "Select leader name.";
    }
    if (addProjectData?.workFlowId?.length === 0) {
      error.workFlowId = "Select workflow.";
    }
    if (!addProjectData.Description) {
      error.Description = "Add project description.";
    }
    if (selectedMemberNames.length === 0) {
      setSelectedMemberNamesError("Select team members.");
    }
    if (!addProjectData.frontend) {
      error.frontend = "Frontend field can't be empty.";
    }
    if (!addProjectData.backend) {
      error.backend = "Backend field can't be empty.";
    }
    if (!addProjectData.datebase) {
      error.datebase = "Datebase field can't be empty.";
    }

    setErrors(error);
    const noErrors = Object.keys(error).length === 0;

    // Edit project Api Call
    if (isExistingData && noErrors && selectedMemberNames.length !== 0) {
      setIsLoading(true);
      try {
        setDisplayMessageError("");
        // const response = await axios.put(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/project/${existingData._id}`,
        //   JSON.stringify({ projectData, selectedMemberNames }),
        //   postHeader
        // );
        const postData = JSON.stringify({ projectData, selectedMemberNames });
        const response = await editProjects(existingData._id, postData)
        if (response.status === 200) {
          if (change === true) {
            setChange(false);
          } else {
            setChange(true);
          }
          setClose();
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Project updated."));
          dispatch(setToastType("success"));
        }
        return;
      } catch (error) {
        setIsLoading(false);
        setDisplayMessageError(errorMessage ?? "")
        return;
      }
    } else if (isExistingData) {
      return;
    }

    if (!projectDocumentationFile.name && noErrors) {
      // setFileSelectError("Select Project Documentation File.");
      // return;
    } else if (projectDocumentationFile.name) {
      try {
        const formData = new FormData();
        formData.append("ProjectDocumentationFile", projectDocumentationFile);
        formData.append("FileName", addProjectData.project);

        // const response = await axios.post(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/upload-project-documentation?fileName=${addProjectData.project}`,
        //   formData,
        //   {
        //     headers: {
        //       Authorization: `${token}`,
        //       "Content-Type": "multipart/form-data",
        //     },
        //   }
        // );
        const response = await upsertProjectDocument(addProjectData.project, formData)
        if (response.status === 200 || response.status === 201) {
          setProjectDocumentationFile({});
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayMessageError("Error uploading project documentation:", error);
        return;
      }
    }

    // Add project Api Call
    if (noErrors && selectedMemberNamesError === "") {
      try {
        setDisplayMessageError("");
        // const response = await axios.post(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/project`,
        //   JSON.stringify({ projectData, selectedMemberNames }),
        //   postHeader
        // );
        const postData = JSON.stringify({ projectData, selectedMemberNames });
        const response = await upsertProjects(postData);
        if (response.status === 200) {
          if (change === true) {
            setChange(false);
          } else {
            setChange(true);
          }
          setClose();
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message ?? "Project added."));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayMessageError(errorMessage ?? "")
      }
    }
  };

  const fetchEmployeeName = async () => {
    try {
      // const response = await axios.get(
      //   `${CLOUD_FUNCTIONS_ORIGIN}/user/getEmployeeName`,
      //   getHeader
      // );
      const response = await getEmployee()
      if (response.status === 200 || response.status === 201) {
        setEmployeeList(response.data.namesData);
      }
      else {
        setEmployeeList([])
      }
    } catch (error) {
      // if (error.response) {
      //   if (error.response.status === 401 || error.response.status === 403) {
      //     window.localStorage.removeItem("Token");
      //     navigate("/");
      //   }
      // }
    }
  };

  const fetchWorkFlowName = async () => {
    const user = employeeDisplay ? "user" : adminLogin ? "admin" : "";
    if (user) {
      try {
        const response = await getWorkFlow(user)
        if (response.status === 200 || response.status === 201) {
          setWorkFlowList(response?.data?.workflows);
        }
        else {
          setWorkFlowList([])
        }
      } catch (error) {
      }
    }
  };

  const handleFileChangeAndUpload = async (event) => {
    const file = event.target.files[0];
    setShowUpdatedFileName(file);
    const fileNameFromPrevious = existingData.documentation.replace(
      /\.pdf$/,
      ""
    );
    if (file) {
      try {
        const formData = new FormData();
        formData.append("ProjectDocumentationFile", file);
        formData.append("FileName", fileNameFromPrevious);

        // const response = await axios.post(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/upload-project-documentation?fileName=${fileNameFromPrevious}`,
        //   formData,
        //   {
        //     headers: {
        //       Authorization: `${token}`,
        //       "Content-Type": "multipart/form-data",
        //     },
        //   }
        // );
        const response = await upsertProjectDocument(fileNameFromPrevious, formData);
        if (response.status === 200 || response.status === 201) {
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
        }
      } catch (error) {
        setDisplayMessageError("Error uploading project documentation:", error);
        return;
      }
    }
  };

  const ViewDocumentOfProject = async () => {
    try {
      window.open(
        `${CLOUD_FUNCTIONS_ORIGIN}/admin/ProjectDocumentationFile/${existingData.documentation}`,
        "_blank",
        "noreferrer"
      );
    } catch (error) { }
  };

  useEffect(() => {
    if (isExistingData) {
      const checkApi = async () => {
        try {
          const response = await fetch(
            `${CLOUD_FUNCTIONS_ORIGIN}/admin/ProjectDocumentationFile/${existingData.documentation}`,
            { method: "HEAD" }
          );
          // console.log(
          //   response.ok
          //     ? "Success: The API is accessible."
          //     : `Error: Received status code ${response.status}`
          // );
          setDocAvailable(response.ok);
        } catch (error) {
          setDocAvailable(false);
        }
      };
      checkApi();
      setAddProjectData({
        project: existingData?.project,
        projectAliasName: existingData?.aliasName,
        status: existingData?.status,
        clientName: existingData?.clientName,
        Description: existingData?.Description,
        leaderName: existingData?.leaderName,
        frontend: existingData?.frontEnd,
        backend: existingData?.backEnd,
        datebase: existingData?.database,
        workFlowId: existingData?.workFlowId,
      });
      setSelectedMemberNames(existingData.memberOfDevTeam);
    } else {
      setSelectedMemberNames([]);
      setAddProjectData({
        project: "",
        projectAliasName: "",
        status: "",
        clientName: "",
        Description: "",
        leaderName: { firstName: "" },
        // workFlowId: { workFlowName: "" },
        workFlowId: null,
        frontend: "",
        backend: "",
        datebase: "",
      });
    }
  }, [existingData, isExistingData]);

  useEffect(() => {
    dispatch(setProjectWorkingMemberName(selectedMemberNames));
    setSelectedMemberNamesError("");
    dispatch(setProjectData(addProjectData));
  }, [selectedMemberNames, addProjectData, dispatch]);

  useEffect(() => {
    fetchEmployeeName();
    fetchWorkFlowName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ padding: "22px 4px 8px 10px", height: "container" }}>
      <MainBox>
        {/* Box for items on the left */}
        <Box sx={{ padding: "5px 0px 5px 18px" }}>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {adminLogin
              ? isExistingData
                ? "Edit Project"
                : "Add Project"
              : "Project Details"}
          </Typography>
        </Box>
        <CloseBox>
          <IconButton onClick={setClose}>
            <CloseIcon color="primary" />
          </IconButton>
        </CloseBox>
      </MainBox>

      <Box sx={{ padding: { xs: "8px", sm: "8px 4px 8px 10px" }, marginRight: { xs: 0, sm: "8px" }}}>
        <MainCard>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  label="Project"
                  name="project"
                  value={addProjectData.project}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                />
                <Typography color="error">{errors.project}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  label={
                    employeeDisplay
                      ? "Project AliasName"
                      : "Project AliasName (Optional)"
                  }
                  name="projectAliasName"
                  value={addProjectData.projectAliasName}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <StatusTextField
                  fullWidth
                  select
                  employeedisplay={employeeDisplay ? "true" : "false"}
                  label="Status"
                  name="status"
                  value={addProjectData.status}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                >
                  {projectStatus?.map((status, index) => (
                    <MenuItem key={index} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </StatusTextField>
                <Typography color="error">{errors.status}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  label="Client Name"
                  name="clientName"
                  value={addProjectData.clientName}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                />
                <Typography color="error">{errors.clientName}</Typography>
              </Grid>

              {isExistingData ? (
                <>
                  {docAvailable ? (
                    <Grid
                      item xs={12}
                      sm={employeeDisplay ? 12 : 6}
                      sx={{ textAlign: "center" }}
                    >
                      <Tooltip
                        title="View Documentation"
                        placement="right"
                        arrow
                      >
                        <IconButton
                          color="success"
                          onClick={ViewDocumentOfProject}
                        >
                          <CloudDownloadIcon />
                        </IconButton>
                      </Tooltip>
                      <Typography color="primary">
                        {existingData.documentation}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}

                  {adminLogin ? (
                    <Grid
                      item
                      xs={12}
                      sm={docAvailable ? 6 : 12}
                      sx={{ textAlign: "center" }}
                    >
                      <Tooltip
                        title={
                          docAvailable
                            ? "ReUpload Documentation"
                            : "Upload Documentation"
                        }
                        placement="left"
                        arrow
                      >
                        <Button
                          component="label"
                          role={undefined}
                          variant="text"
                          tabIndex={-1}
                          color={docAvailable ? "warning" : "primary"}
                        >
                          {docAvailable ? (
                            <CloudSyncIcon />
                          ) : (
                            <CloudUploadIcon />
                          )}
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleFileChangeAndUpload}
                            accept="application/pdf"
                          />
                        </Button>
                      </Tooltip>
                      <Typography color="primary">
                        {showUpdatedFileName.name}
                      </Typography>
                    </Grid>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                  <Tooltip title="Upload Documentation" placement="right" arrow>
                    <Button
                      component="label"
                      role={undefined}
                      variant="text"
                      tabIndex={-1}
                    >
                      <CloudUploadIcon />
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange}
                        accept="application/pdf"
                      />
                    </Button>
                  </Tooltip>
                  <Typography color="error">{fileSelectError}</Typography>
                  <Typography color="primary">
                    {projectDocumentationFile.name}
                  </Typography>
                </Grid>
              )}

              <Grid item xs={12} sm={12}>
                <InputTextField
                  fullWidth
                  multiline
                  maxRows={5}
                  label="Description"
                  name="Description"
                  value={addProjectData.Description}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                />
                <Typography color="error">{errors.Description}</Typography>
              </Grid>

              <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                <AdditionalDetailsTypography
                  variant="h6"
                >
                  Additional Details
                </AdditionalDetailsTypography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <LeaderNameAutocomplete
                  fullWidth
                  // options={allEmpData}
                  options={employeeList}
                  getOptionLabel={(option) => option.firstName}
                  renderInput={(params) => (
                    <TextField {...params} label="Leader Name" />
                  )}
                  value={addProjectData.leaderName}
                  name="leaderName"
                  onChange={leaderNameValueSetter}
                  readOnly={employeeDisplay ? true : false}
                  employeedisplay={employeeDisplay ? "true" : "false"}
                  adminlogin={adminLogin ? "true" : "false"}
                />
                <Typography color="error">{errors.leaderName}</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <MemberNameAutocomplete
                  fullWidth
                  multiple
                  // limitTags={3}
                  // options={allEmpData}
                  options={employeeList.filter(
                    (option) => !selectedMemberNames.includes(option)
                  )}
                  // options={allEmpData.filter(
                  //   (option) => !selectedMemberNames.includes(option)
                  // )}
                  getOptionLabel={(option) => option.firstName}
                  renderInput={(params) => (
                    <TextField {...params} label="Team Members" />
                  )}
                  value={selectedMemberNames}
                  onChange={EmployeesValueSetter}
                  readOnly={employeeDisplay ? true : false}
                  employeedisplay={employeeDisplay ? "true" : "false"}
                />
                <Typography color="error">
                  {selectedMemberNamesError}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <WorkFlowNameAutocomplete
                  fullWidth
                  options={workFlowList}
                  getOptionLabel={(option) => option.workFlowName}
                  renderInput={(params) => (
                    <TextField {...params} label="Work Flow" />
                  )}
                  name="workFlowId"
                  value={addProjectData.workFlowId || null}
                  onChange={WorkFlowValueSetter}
                // disableClearable={!addProjectData.workFlowId}
                // clearIcon={addProjectData.workFlowId ? <ClearIcon /> : null}
                />
                <Typography color="error">
                  {errors.workFlowId}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
                <TechnologyStackTypography
                  variant="h6"
                >
                  Technology Stack
                </TechnologyStackTypography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  label="Front End"
                  name="frontend"
                  value={addProjectData.frontend}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                />
                <Typography color="error">{errors.frontend}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  label="Back End"
                  name="backend"
                  value={addProjectData.backend}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                />
                <Typography color="error">{errors.backend}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputTextField
                  fullWidth
                  label="Database"
                  name="datebase"
                  value={addProjectData.datebase}
                  onChange={handleChange}
                  disabled={employeeDisplay ? true : false}
                />
                <Typography color="error">{errors.datebase}</Typography>
              </Grid>
            </Grid>

            <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={setClose} sx={{ ml: 2, marginRight: "10px" }}>
                Cancel
              </Button>
              {adminLogin ? (
                isExistingData ? (
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={handleSubmit}
                  >
                    Update project
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Add project
                  </Button>
                )
              ) : (
                <></>
              )}
            </Box>
            <Box mt={2}>
              <Typography sx={{ color: "red" }}>
                {displayMessageError}
              </Typography>
            </Box>
          </CardContent>
        </MainCard>
      </Box>
    </Box>
  );
}

// const MultiSelect = ({
//   allEmployeeNames,
//   selectedMemberNames,
//   setSelectedMemberNames,
//   handleChangeTeamMembers,
//   adminLogin,
//   employeeDisplay,
// }) => {
//   return (
//     <FormControl sx={{ width: "100%" }}>
//       <InputLabel>Team Members</InputLabel>
//       <Select
//         sx={{
//           ".css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
//             fill: employeeDisplay
//               ? "var(--background-table-sidebar-card-color) !important"
//               : "",
//           },
//         }}
//         readOnly={employeeDisplay ? true : false}
//         multiple
//         value={selectedMemberNames}
//         onChange={handleChangeTeamMembers}
//         input={<OutlinedInput label="Multiple Select" />}
//         renderValue={(selected) => (
//           <Stack gap={1} direction="row" flexWrap="wrap">
//             {selected?.map((value) => (
//               <Chip
//                 sx={{ background: "var(--tag-background-color)" }}
//                 key={value}
//                 label={value}
//                 onDelete={() =>
//                   setSelectedMemberNames(
//                     selectedMemberNames?.filter((item) => item !== value)
//                   )
//                 }
//                 deleteIcon={
//                   adminLogin ? (
//                     <CancelIcon
//                       onMouseDown={(event) => event.stopPropagation()}
//                     />
//                   ) : (
//                     <></>
//                   )
//                 }
//               />
//             ))}
//           </Stack>
//         )}
//       >
//         {allEmployeeNames?.map((name) => (
//           <MenuItem
//             key={name}
//             value={name}
//             sx={{ justifyContent: "space-between" }}
//             disabled={employeeDisplay ? true : false}
//           >
//             {name}
//             {selectedMemberNames.includes(name) ? (
//               <CheckIcon color="info" />
//             ) : null}
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

export default AddProject;
