import React, { useEffect, useState } from "react";
import { Box, MenuItem, TextField } from "@mui/material";

import LeaveManagement from "../LeaveManagement/LeaveManagement";
import { getEmployee } from "../../Services/EmployeeManageServices";
import UpdateAttendance from "../UpdateEmployee/UpdateAttendance/UpdateAttendance";
import SelectedEmployeeTaskDataGrid from "../AdminDashboard/Tables/SelectedEmployeeTaskDataGrid";
import { MainBox, LeaveButton, AttendanceButton, TasksButton, InnerBoxOne, InnerGrid } from "../../Styles/TeamBoard/TeamBoardStyle"

function TeamBoard() {
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedTab, setSelectedTab] = useState("Attendance");
  const [empId, setEmpId] = useState(null);

  const handleButtonClick = (buttonName) => {
    setSelectedTab(buttonName);
  };

  const handleSelect = (event) => {
    if (event.target.value) {
      const selectedEmployee = employeeList?.find(
        (employee) => employee.empId === event.target.value
      );
      if (selectedEmployee) {
        setEmpId(selectedEmployee);
      }
    }
  };

  const fetchEmployeeName = async () => {
    try {
      const response = await getEmployee();
      if (response.status === 200 || response.status === 201) {
        // const selectedEmployee = response?.data?.namesData?.find(
        //   (employee) => employee?.role === "CEO"
        // );
        setEmployeeList(response?.data?.namesData);
        setEmpId(response?.data?.namesData[0]);
      } else {
        setEmployeeList([]);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchEmployeeName();
  }, []);

  return (
    <Box>
      <InnerBoxOne margin={1} marginRight={2} marginBottom={2} sx={{
        '@media (max-width: 600px)': {
          width: '100%',
        },
      }}>
        <InnerGrid item xs={12} sm={12} md={2} lg={2}>
          <TextField
            fullWidth
            select
            label="Employee Name"
            name="employeeName"
            value={empId ? empId?.empId : ""}
            onChange={(event, newValue) => handleSelect(event)}
            multiline
            variant="outlined"
          >
            {employeeList?.map((option) => (
              <MenuItem key={option.empId} value={option.empId}>
                {`${option.empId} - ${option.firstName}`}
              </MenuItem>
            ))}
          </TextField>
        </InnerGrid>
      </InnerBoxOne>
      {empId && empId?.empId && <MainBox bottom={0}>
        <AttendanceButton
          onClick={() => handleButtonClick("Attendance")}
          selectedtab={selectedTab}
        >
          Attendance
        </AttendanceButton>
        <TasksButton
          onClick={() => handleButtonClick("Tasks")}
          selectedtab={selectedTab}
        >
          Tasks
        </TasksButton>
        <LeaveButton
          onClick={() => handleButtonClick("Leave")}
          selectedtab={selectedTab}
        >
          Leaves
        </LeaveButton>
      </MainBox>}
      <Box marginTop={2}>
        {selectedTab === "Attendance" ? (
          <div>
            {empId && empId?.empId && <UpdateAttendance EmpId={empId?.empId} />}
          </div>
        ) : null}
        {selectedTab === "Tasks" ? (
          <SelectedEmployeeTaskDataGrid EmpId={empId?.empId} />
        ) : null}
        {selectedTab === "Leave" ? (
          <LeaveManagement EmpId={empId?.empId} />
        ) : null}
      </Box>
    </Box>
  )
}

export default TeamBoard