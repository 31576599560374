import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { Box, useMediaQuery } from "@mui/material";

const baseWidth = 600;
const chartText = {
  text: "#000000" /* Use the secondary text color */,
};

function DonotChart({ employeeEfficieny }) {
  const isMobile = useMediaQuery('(max-width:600px)');

  const svgRef = useRef();
  const [efficiency, setEfficiency] = useState(0);
  const [data, setData] = useState([
    { name: "No Task", value: 1, color: "Gray" },
  ]);
  const width = isMobile ? 265 : baseWidth;
  const height = isMobile ? 300 : 340;

  useEffect(() => {
    if (employeeEfficieny[0]?.length === 0) {
      setData([{ name: "No Task", value: 1, color: "white" }]);
      setEfficiency(0.0);
    } else {
      setData([
        {
          name: employeeEfficieny[2]?.length !== 0 ? "Overdue" : "",
          value:
            employeeEfficieny?.length !== 0 ? employeeEfficieny[2]?.length : 0,
        },
        {
          name: employeeEfficieny[1]?.length !== 0 ? "Completed" : "",
          value:
            employeeEfficieny?.length !== 0 ? employeeEfficieny[1]?.length : 0,
        },
        {
          name: employeeEfficieny[3]?.length !== 0 ? "Remaining" : "",
          value:
            employeeEfficieny?.length !== 0 ? employeeEfficieny[3]?.length : 0,
        },
      ]);

      setEfficiency(employeeEfficieny[4]);
    }
  }, [employeeEfficieny]);

  useEffect(() => {
    d3.select(svgRef.current).selectAll("*").remove();
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", width); // Set height equal to width for a circular aspect ratio

    // const height = 340; // Set height equal to width

    const radius = Math.min(width, height) / 2;

    const arc = d3
      .arc()
      .innerRadius(radius * 0.67)
      .outerRadius(radius - 1.2);

    const pie = d3
      .pie()
      .padAngle(1 / radius)
      .sort(null)
      .value((d) => d.value);

    const colorScale = d3
      .scaleOrdinal()
      .domain(["No Task", "Overdue", "Completed", "Remaining"])
      .range([
        "#3b9ff0dc",
        "var(--taskOverdue-highlight-color)",
        "var(--taskComplete-highlight-color)",
        "var(--taskRemain-highlight-color)",
      ]);
    // Apply translation transformation to move the chart
    const chartGroup = svg
      .append("g")
      .attr("transform", `translate(${width / 2}, ${height / 2})`);

    // Append a text element for the first line
    chartGroup
      .append("text")
      .attr("text-anchor", "middle")
      .attr("font-size", isMobile ? "19px" : "24px")
      .attr("font-weight", "bold")
      .attr("font-family", "sans-serif")
      .text("Efficiency")
      .append("tspan")
      .attr("x", 0)
      .attr("dy", "1.2em")
      .text(`${efficiency}%`);

    chartGroup
      .selectAll()
      .data(pie(data))
      .enter()
      .append("path")
      .attr("fill", (d) => colorScale(d.data.name))
      .attr("d", arc)
      .append("title")
      .text((d) => `${d.data.name}: ${d.data.value.toLocaleString()}`)
      .classed(chartText, true);

    chartGroup
      .selectAll()
      .data(pie(data))
      .enter()
      .append("text")
      .attr("transform", (d) => `translate(${arc.centroid(d)})`)
      .attr("text-anchor", "middle")
      .attr("font-size", isMobile ? "12px" : "16px")
      .attr("font-family", "sans-serif")
      .call((text) =>
        text
          .append("tspan")
          .attr("y", "-0.4em")
          .attr("font-weight", "bold")
          .text((d) => d.data.name)
      )
      .call((text) =>
        text
          .filter((d) => d.endAngle - d.startAngle > 0.25)
          .append("tspan")
          .attr("x", 0)
          .attr("y", "0.7em")
          .attr("fill-opacity", 1)
          .text((d) => {
            if (d.data.name === "No Task") {
              return "";
            } else {
              return d.data.value.toLocaleString("en-US");
            }
          })
      );
    // eslint-disable-next-line
  }, [data, efficiency, isMobile]);

  return (
    <Box sx={{
      paddingLeft: isMobile ? "0px" : "20px",
      marginTop: "20px",
      display: "flex",
      justifyContent: "center",
    }}>
      <svg ref={svgRef} style={{ height: "360px" }}></svg>
    </Box>
  );
}

export default DonotChart;
