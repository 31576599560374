import { AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, TableHead, TableRow, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    "@media (max-width: 600px)": {
        flexDirection: "column",
        alignItems: "stretch",
        "& .button-container": {
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginTop:"20px"
        },
    },
}));

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    alignItems: "center"
}))

export const ClearButton = styled(Button)(() => ({
    marginLeft: "20px",
    marginRight: "5px",
    color: "#fff",
    "&:hover": {
        color: "none",
        backgroundColor: "var(--secondary-text-color-H)",
    },
    backgroundColor: "var(--secondary-text-color)",
}))

export const InnerBoxTwo = styled(Box)(() => ({
    marginLeft: "7px"
}))

export const ErrorTypography = styled(Typography)(() => ({
    color: "red",
    padding: "10px 0px 0px 15px"
}))

export const AccordionsSummary = styled(AccordionSummary)(() => ({
    textTransform: "capitalize",
    backgroundColor: "var(--accordian-background-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    color: "var(--primary-text-color)",
}))

export const InnerBoxThree = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingRight: "20px",
}))

export const InnerBoxFour = styled(Box)(() => ({
    display: "flex",
    paddingLeft: "5px",
}))

export const AccordionsDetails = styled(AccordionDetails)(() => ({
    backgroundColor: "var(--accordian-background-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    color: "var(--primary-text-color)",
}))

export const InnerBoxFive = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-end",
}))

export const AssignleaveButton = styled(Button)(() => ({
    marginLeft: "15px",
    marginBottom: "10px",
    backgroundColor: "#f57c00",
    color: "#fff",
}))

export const TableHeads = styled(TableHead)(() => ({
    backgroundColor: 'var(--primary-background-color)'
}))

export const TableRows = styled(TableRow)(() => ({
    cursor: 'pointer',
    width: '100%'
}))

export const IconButtons = styled(IconButton)(() => ({
    padding: '2px !important',
}))

export const TableCompMainBox = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'flex-start'
}))

export const UpdateButton = styled(Button)(() => ({
    padding: '2px !important',
    marginRight: '10px'
}))

export const EmpMainBox = styled(Box)(() => ({
    margin: '10px 0px 10px 10px', width: '100%',
    ".MuiGrid-root>.MuiGrid-item": {
        paddingTop: "0px !important",
        paddingLeft: "0px !important",
    },
}))

export const EmpDetailGrid = styled(Grid)(() => ({
    ".MuiGrid-root>.MuiGrid-item": {
        paddingTop: "0px !important",
    },
    ".MuiCheckbox-root.Mui-disabled": {
        color: "var(--selected-checkbox)",
    }
}))