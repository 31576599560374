import {  LinearProgress, Typography, styled } from "@mui/material"

export const StatisticsTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    display: "inline",
    fontWeight: "bold",
    fontSize: "130%",
}))


export const DateLinearsProgress = styled(LinearProgress)(() => ({
    margin: "5px 0px 5px 0px",
    borderRadius: "5px",
    backgroundColor: "var(--secondary-highlight-color)",
    ".MuiLinearProgress-bar": {
        backgroundColor: "var(--secondary-color)",
    },
}))

export const WeekLinearsProgress = styled(LinearProgress)(() => ({
    margin: "5px 0px 5px 0px",
    borderRadius: "5px",
    backgroundColor: "var(--reject-highlight-color)",
    ".MuiLinearProgress-bar": {
        backgroundColor: "var(--reject-color)",
    },
}))

export const MonthLinearsProgress = styled(LinearProgress)(() => ({
    margin: "5px 0px 5px 0px",
    borderRadius: "5px",
    backgroundColor: "var(--totalHrs-highlight-color)",
    ".MuiLinearProgress-bar": {
        backgroundColor: "var(--totalHrs-color)",
    },
}))

export const OvertimeLinearsProgress = styled(LinearProgress)(() => ({
    margin: "5px 0px 5px 0px",
    borderRadius: "5px",
    backgroundColor: "var(--avgWorkingHrs-highlight-color)",
    ".MuiLinearProgress-bar": {
        backgroundColor: "var(--avgWorkingHrs-color)",
    },
}))