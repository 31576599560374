import { Box, Button, Card, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    overflowY: "auto",
    margin: "0px 0px 25px 0px",
}))

export const TitleBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    borderBottom: "1px solid var(--table-border-color)",
    color: "var(--secondary-text-color)",
    marginBottom: "5px",
}))

export const MainCard = styled(Card)(() => ({
    // overflow: "auto",
    height: "100%",
    // width: "103%",
    borderRadius: "5px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const CalendarTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    display: "flex",
    padding: "5px",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "130%",
}))

export const DateCalendarBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: 2,
    ".MuiDateCalendar-root": {
        ".MuiPickersCalendarHeader-label": {
            color: "var(--secondary-text-color)",
            fontWeight: "bold",
        },
        ".MuiPickersCalendarHeader-switchViewIcon":
        {
            color: "var(--secondary-text-color)",
        },
        ".MuiPickersDay-root":
        {
            color: "var(--secondary-text-color)",
            ".MuiPickersDay-root:not(.Mui-selected)":
            {
                color: "#fff",
                border: "1px solid var(--primary-color)",
                backgroundColor: "var(--primary-color)",
            },
        },
        ".MuiSvgIcon-root": {
            color: "var(--secondary-text-color)",
        },
        ".MuiDayCalendar-weekDayLabel":
        {
            color: "var(--secondary-color)",
            fontWeight: "bold",
        },
        ".MuiPickersYear-yearButton": {
            color: "var(--secondary-text-color)",
        },
        ".Mui-selected":
        {
            color: "#fff",
            border:
                "1px solid var(--secondary-text-color) !important",
        },
        ".MuiDateCalendar-root": {
            overflow: "auto",
        },
        "@media (max-width: 600px)": {
            flexDirection: "column",
            "& > div": {
                width: "100%",
            },
            ".MuiPickersCalendarHeader-root": {
                paddingLeft: "7%",
                width: "90%"
            },
        },
    },

}))

export const InnerCardOne = styled(Card)(() => ({
    backgroundColor:
        "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    borderRadius: "5px",
}))

export const ChartCard = styled(Card)(() => ({
    backgroundColor:
        "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    borderRadius: "5px",
    padding: 0,
    height: "100%",
}))

export const InnerBoxOne = styled(Box)(() => ({
    flex: "1 1 auto",
    display: "flex",
    flexDirection: "column",
}))

export const AllButtonBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "180px",
    "@media (max-width: 600px)": {
        flexDirection: "column",
    },
}))

export const WeeklyButton = styled(Button)(({ selectedbutton }) => ({
    mb: 1,
    mr: 1,
    "&:hover": {
        backgroundColor:
            "var(--primary-color) !important",
        color: "#fff",
    },
    backgroundColor:
        selectedbutton === "Weekly"
            ? "var(--primary-color)"
            : "",
    color:
        selectedbutton === "Weekly" ? "#fff" : "",
}))

export const MonthlyButton = styled(Button)(({ selectedbutton }) => ({
    mb: 1,
    mr: 1,
    "&:hover": {
        backgroundColor:
            "var(--primary-color) !important",
        color: "#fff",
    },
    backgroundColor:
        selectedbutton === "Monthly"
            ? "var(--primary-color)"
            : "",
    color:
        selectedbutton === "Monthly" ? "#fff" : "",
}))

export const YearlyButton = styled(Button)(({ selectedbutton }) => ({
    mb: 1,
    mr: 1,
    "&:hover": {
        backgroundColor:
            "var(--primary-color) !important",
        color: "#fff",
    },
    backgroundColor:
        selectedbutton === "Yearly"
            ? "var(--primary-color)"
            : "",
    color:
        selectedbutton === "Yearly" ? "#fff" : "",
}))

export const RecordTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    display: "inline",
    padding: "5px",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "130%",
}))