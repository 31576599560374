import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Tooltip,
  // Switch,
  Zoom,
  Button,
  TextField,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  DialogTitle,
  Autocomplete,
  Chip,
} from "@mui/material";
import { Close, Edit } from "@mui/icons-material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ClearIcon from '@mui/icons-material/Clear';

import DialogComponent from "../../ReusableComponents/DialogBoxComponents/DialogComponent.js";
import { viewProfileSubtitle } from "../../ReusableComponents/CustomDesignMUI/CustomMUI.js";
import { setAllData } from "../../../Slices/UserDataSlice.js";
import { set_index } from "../../../Slices/employeeDetailSlice.js";
import { setAllLeaveData } from "../../../Slices/leaveData.js";
import {
  setemployeeAllData,
  // setemployeeAllLeaveData,
} from "../../../Slices/employeeDetailSlice.js";
import {
  setToast,
  setToastMsg,
  setToastType,
} from "../../../Slices/toastSlice.js";
import ProfileImgGirl from "../../../assets/img/icons/girl.png";
import ProfileImgBoy from "../../../assets/img/icons/man.svg";
import ProfileImgOthers from "../../../assets/img/icons/others.png";
import { CLOUD_FUNCTIONS_ORIGIN } from "../../../function-origin.js";
import {
  bloodGroupsCategory,
  departmentNames,
  genderCategory,
  jobCategory,
} from "../../Constant/constant.js";
import {
  changeStatusEmployee,
  getEmployee,
  upsertPersonal,
  addSpecialization,
} from "../../../Services/EmployeeManageServices.js";
import { errorMessage } from "../../../Services/axiosInstance.js";
import {
  ActiveButton,
  BloodGroupSelect,
  CancelButton,
  CustomSelect,
  DOBJoiningTextField,
  DOBTextField,
  DesignationTextField,
  EmpIdTypography,
  ErrorTypography,
  FooterBox,
  FormBox,
  GenderSelect,
  InnerBoxOne,
  InnerBoxTwo,
  InputTextField,
  MainBox,
  MainBoxDialog,
  ReportingPersonAutocomplete,
  SaveButton,
  SaveNextButton,
  TrainingEndDateTextField,
} from "../../../Styles/UpdateEmployee/UpdatePersonalStyle.js";
import { CloseIconButton } from "../../../Styles/ManageEvents/ManageEventsStyle.js";
import { DialogsActions } from "../../../Styles/Authentication/LogOutStyle.js";

function UpdatePersonal(props) {
  const {
    employeeCall,
    saveNextButtonCallback,
    setIsUpdated,
    isUpdated,
    image,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const allData = useSelector((state) => state.taskData.employeesNames);
  const roles = useSelector((state) => state.adminData?.roles);
  const user = useSelector((state) => state.selectedActiveView.role);
  const themeChange = useSelector((state) => state.theme.isDarkTheme);
  // const onAdminId = useSelector((state) => state.adminData.adminData?.empId);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const isEmpManagement = useSelector(
    (state) => state.selectedActiveView.EmployeeManagment
  );
  const [employeeList, setEmployeeList] = useState([]);
  const [displayError, setDisplayError] = useState("");
  const [ProfileImageAvailable, setProfileImageAvailable] = useState(false);
  const [editEmployee, setEditEmployee] = useState(false);
  const [checked, setChecked] = useState(
    employeeCall ? (employeeCall?.TrainingEndDate ? true : false) : false
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [errors, setErrors] = useState({});
  // const [specializations, setSpecializations] = useState(
  //   employeeCall?.specializationList || []
  // );
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const [newEmployeeData, setNewEmployeeData] = useState({
    id: employeeCall?._id ?? "",
    empId: employeeCall?.empId ?? "",
    FirstName: employeeCall?.FirstName ?? "",
    Department: employeeCall?.Department ?? "",
    Designation: employeeCall?.Designation ?? "",
    Category: employeeCall?.Category ?? "",
    Date_Of_Join: employeeCall?.Date_Of_Join ?? "",
    Date_Of_Birth: employeeCall?.Date_Of_Birth ?? "",
    BloodGroup: employeeCall?.BloodGroup ?? "",
    Gender: employeeCall?.Gender ?? "",
    ReportingPerson: [],
    Role: employeeCall ? employeeCall?.Role?.role : "",
    TrainingEndDate: employeeCall ? employeeCall?.TrainingEndDate : "",
    Specializations: employeeCall?.specializations
      ? employeeCall?.specializations?.map((spec) => ({
        _id: spec._id,
        name: spec.name,
      }))
      : [],
    SpecializationList: employeeCall?.specializationList
      ? employeeCall?.specializationList?.map((spec) => ({
        _id: spec._id,
        name: spec.name,
      }))
      : [],
  });
  const [options, setOptions] = useState(
    newEmployeeData.SpecializationList || []
  );
  const [selectedSpecializations, setSelectedSpecializations] = useState(
    newEmployeeData.Specializations || []
  );

  // Specializations: employeeCall?.specializations
  //     ? employeeCall.specializations.map(spec => spec.name)
  //     : [],  });
  const EmployeeLogin = user === "employee";
  const adminLogin = user === "admin";
  const formattedDate_Of_Join = newEmployeeData?.Date_Of_Join
    ? new Date(newEmployeeData.Date_Of_Join).toISOString().split("T")[0]
    : "";

  const formattedDate_Of_Birth = newEmployeeData?.Date_Of_Birth
    ? new Date(newEmployeeData.Date_Of_Birth).toISOString().split("T")[0]
    : "";

  const formattedTraning_end_date = newEmployeeData?.TrainingEndDate
    ? new Date(newEmployeeData.TrainingEndDate).toISOString().split("T")[0]
    : "";

  function Gender() {
    if (newEmployeeData.Gender === "Male") {
      return ProfileImgBoy;
    } else if (newEmployeeData.Gender === "Female") {
      return ProfileImgGirl;
    } else return ProfileImgOthers;
  }

  const onClose = () => {
    setOpenDialog(false);
  };

  const EmployeesValueSetter = (event, newValue) => {
    setNewEmployeeData((prevState) => ({
      ...prevState,
      ReportingPerson: newValue,
    }));
  };

  const profileImgSrc = Gender();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const getReportingPersonById = (empId) => {
    let empState = employeeList
      ?.filter((employee) => empId?.includes(employee?.empId))?.map((state) => state?.firstName)?.join(", ");
    return empState; // Check if empState exists before accessing firstName
  };

  const deletedata = async () => {
    try {
      const deleteEmpId = employeeCall?.empId;
      // const response = await axios.put(
      //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/deleteData`,
      //   { deleteEmpId },
      //   postHeader
      // );
      const response = await changeStatusEmployee({ deleteEmpId });
      if (response.status === 200 || response.status === 201) {
        onClose();
        dispatch(setAllData(response.data.User));
        if (isEmpManagement === "true") {
          navigate("/employee-management");
        } else {
          navigate("/dashboard");
        }
        dispatch(set_index(""));
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "");
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!newEmployeeData.FirstName) {
      newErrors.FirstName = "Name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(newEmployeeData.FirstName)) {
      newErrors.FirstName = "Name should contain only alphabetic characters";
    }
    if (!newEmployeeData.Department) {
      newErrors.Department = "Department is required";
    }
    if (!newEmployeeData.Designation) {
      newErrors.Designation = "Designation is required";
    }
    if (!newEmployeeData.Category) {
      newErrors.Category = "Job category is required";
    }
    if (!newEmployeeData.Date_Of_Join) {
      newErrors.Date_Of_Join = "Date of join is required";
    }
    const fourteenYearsAgo = new Date();
    fourteenYearsAgo.setFullYear(fourteenYearsAgo.getFullYear() - 14);
    if (!newEmployeeData.Date_Of_Birth) {
      newErrors.Date_Of_Birth = "Date of birth is required";
    } else if (new Date(newEmployeeData.Date_Of_Birth) > fourteenYearsAgo) {
      newErrors.Date_Of_Birth = "Date of Birth should be at least 14 years ago";
    } else if (newEmployeeData.Date_Of_Birth > newEmployeeData.Date_Of_Join) {
      newErrors.Date_Of_Birth = "Date of Birth cannot be after Date of Joining";
    }
    if (checked) {
      if (!newEmployeeData.TrainingEndDate) {
        newErrors.TrainingEndDate = "End Date of traning is required";
      } else if (
        newEmployeeData.TrainingEndDate < newEmployeeData.Date_Of_Join
      ) {
        newErrors.TrainingEndDate =
          "end date of traning cannot be before date of join";
      }
    }
    if (!newEmployeeData.BloodGroup) {
      newErrors.BloodGroup = "Blood group is required";
    }
    if (!newEmployeeData.Gender) {
      newErrors.Gender = "Gender is required";
    }
    if (newEmployeeData.ReportingPerson.length === 0) {
      newErrors.ReportingPerson = "Reporting person is required";
    } else if (newEmployeeData.ReportingPerson.length > 2) {
      newErrors.ReportingPerson = "Reporting person cant be more than 2";
    }
    if (!newEmployeeData.Role) {
      newErrors.Role = "Role is required";
    }
    setErrors(newErrors);
    const noErrors = Object.keys(newErrors).length === 0;

    if (noErrors) {
      return true;
    } else {
      return noErrors;
    }
  };

  const saveAndNext = async () => {
    try {
      // const response = await axios.put(
      //   `${CLOUD_FUNCTIONS_ORIGIN}/admin/personal`,
      //   JSON.stringify(newEmployeeData),
      //   postHeader
      // );
      const postData = JSON.stringify(newEmployeeData);
      const response = await upsertPersonal(postData);
      if (response.status === 200 || response.status === 201) {
        dispatch(setAllData(response.data.user));
        // dispatch(setemployeeAllLeaveData(response.data.current));
        dispatch(setAllLeaveData(response.data.leave));
        dispatch(setemployeeAllData(response.data.employeeAllDetail));
        if (adminLogin) {
          if (isUpdated === true) {
            setIsUpdated(false);
          } else {
            setIsUpdated(true);
          }
        }
        dispatch(setToast(true));
        dispatch(setToastMsg(response.data.message));
        dispatch(setToastType("success"));
        return true;
      }
    } catch (error) {
      setDisplayError(errorMessage ?? "");
    }
  };

  // const handleAddSpecialization = async (value) => {
  //   const trimmedValue = value.trim();

  //   if (!trimmedValue) {
  //     setError("Specialization cannot be empty");
  //     return;
  //   }

  //   if (selectedSpecializations.some((s) => s.name === trimmedValue)) {
  //     setInputValue("");
  //     setError("This specialization already exists");
  //     return;
  //   }

  //   let existingSpecialization = options.find((s) => s.name === trimmedValue);

  //   if (existingSpecialization) {
  //     setSelectedSpecializations((prev) => [...prev, existingSpecialization]);
  //     setNewEmployeeData((prev) => ({
  //       ...prev,
  //       Specializations: [...prev.Specializations, existingSpecialization],
  //     }));
  //     setInputValue("");
  //     setError("");
  //     return;
  //   }

  //   try {
  //     const response = await addSpecialization({ name: trimmedValue });

  //     if (response.status === 200 || response.status === 201) {
  //       const newSpecialization = response.data;
  //       setOptions((prev) => [...prev, newSpecialization]);
  //       setSelectedSpecializations((prev) => [...prev, newSpecialization]);
  //       setNewEmployeeData((prev) => ({
  //         ...prev,
  //         Specializations: [...prev.Specializations, newSpecialization],
  //       }));

  //       setInputValue("");
  //       setError("");
  //     } else {
  //       setError(response.data.message || "Failed to add specialization");
  //     }
  //   } catch (error) {
  //     console.error("Error adding specialization:", error);
  //     setError("Failed to add specialization");
  //   }
  // };

  const handleAddSpecialization = async (value) => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      setError("Specialization cannot be empty");
      return;
    }

    if (selectedSpecializations.some((s) => s.name === trimmedValue)) {
      setInputValue("");
      setError("This specialization already exists");
      return;
    }

    let existingSpecialization = options.find((s) => s.name === trimmedValue);

    if (existingSpecialization) {
      setSelectedSpecializations((prev) => [...prev, existingSpecialization]);
      setNewEmployeeData((prev) => ({
        ...prev,
        Specializations: [...prev.Specializations, existingSpecialization],
      }));
      setInputValue("");
      setError("");
      return;
    }

    try {
      const response = await addSpecialization({ name: trimmedValue });

      if (response.status === 200 || response.status === 201) {
        const newSpecialization = response.data;
        setOptions((prev) => [...prev, newSpecialization]);
        setSelectedSpecializations((prev) => [...prev, newSpecialization]);
        setNewEmployeeData((prev) => ({
          ...prev,
          Specializations: [...prev.Specializations, newSpecialization],
        }));

        setInputValue("");
        setError("");
      } else {
        setError(response.data.message || "Failed to add specialization");
      }
    } catch (error) {
      console.error("Error adding specialization:", error);
      setError("Failed to add specialization");
    }
  };

  const handleDeleteSpecialization = (id) => {
    setNewEmployeeData((prev) => ({
      ...prev,
      Specializations: prev.Specializations.filter((s) => s._id !== id),
    }));
    setSelectedSpecializations((prev) => prev.filter((s) => s._id !== id));
  };

  const renderForm = () => {
    return (
      <>
        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
          <Box>
            <CloseIconButton
              onClick={() => setOpenDialog(false)}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
          <MainBoxDialog isDarkTheme={isDarkTheme}>
            <Box>
              <DialogTitle sx={{ fontWeight: "bold" }}>
                <InnerBoxOne>
                  <InnerBoxTwo>
                    <WarningAmberIcon
                      sx={{
                        color: "#c70000",
                        fontSize: "30px",
                        borderRadius: "50px",
                      }}
                    />
                  </InnerBoxTwo>
                  <Box>
                    <Box>
                      {employeeCall?.status === "Active"
                        ? "Confirm Archive the employee"
                        : "Confirm Active the employee"}
                    </Box>
                    <Typography>
                      {employeeCall?.status === "Active"
                        ? "Are you sure you want to Archive this data?"
                        : "Are you sure you want to Active this data?"}
                    </Typography>
                  </Box>
                </InnerBoxOne>
              </DialogTitle>
            </Box>
          </MainBoxDialog>
          <DialogsActions>
            <CancelButton onClick={() => setOpenDialog(false)}>
              Cancel
            </CancelButton>
            <ActiveButton
              variant="contained"
              onClick={() => {
                deletedata();
              }}
              component={Link}
              color="error"
            >
              {employeeCall?.status === "Active" ? "Archive" : "Active"}
            </ActiveButton>
          </DialogsActions>
          <ErrorTypography>{displayError}</ErrorTypography>
        </Box>
      </>
    );
  };

  const fetchEmployeeName = async () => {
    try {
      // const response = await axios.get(
      //   `${CLOUD_FUNCTIONS_ORIGIN}/user/getEmployeeName`,
      //   getHeader
      // );
      const response = await getEmployee();
      if (response.status === 200 || response.status === 201) {
        setEmployeeList(response.data.namesData);
      } else {
        setEmployeeList([]);
      }
    } catch (error) {
      // if (error.response) {
      //   if (error.response.status === 401 || error.response.status === 403) {
      //     window.localStorage.removeItem("Token");
      //     navigate("/");
      //   }
      // }
    }
  };

  useEffect(() => {
    fetchEmployeeName();
  }, []);

  useEffect(() => {
    async function checkImageAvailability(id) {
      // const imageUrl = `${CLOUD_FUNCTIONS_ORIGIN}/user/getProfileImage/${id}.png`;
      // try {
      //   const response = await axios.head(imageUrl);
      //   if (response.status === 200) {
      //     setProfileImageAvailable(true);
      //   } else {
      //     setProfileImageAvailable(false);
      //   }
      // } catch (error) {
      //   setProfileImageAvailable(false);
      // }
    }
    setNewEmployeeData({
      id: employeeCall?._id ?? "",
      empId: employeeCall?.empId ?? "",
      FirstName: employeeCall?.FirstName ?? "",
      Department: employeeCall?.Department ?? "",
      Designation: employeeCall?.Designation ?? "",
      Category: employeeCall?.Category ?? "",
      Date_Of_Join: employeeCall?.Date_Of_Join ?? "",
      Date_Of_Birth: employeeCall?.Date_Of_Birth ?? "",
      BloodGroup: employeeCall?.BloodGroup ?? "",
      Gender: employeeCall?.Gender ?? "",
      ReportingPerson:
        employeeList?.filter((employee) =>
          employeeCall?.ReportingPerson?.includes(employee?.empId)
        ) ?? [],
      Role: employeeCall ? employeeCall?.Role?.role : "",
      TrainingEndDate: employeeCall ? employeeCall?.TrainingEndDate : "",
      Specializations: employeeCall?.specializations
        ? employeeCall?.specializations?.map((spec) => ({
          _id: spec._id,
          name: spec.name,
        }))
        : [],
      SpecializationList: employeeCall?.specializationList
        ? employeeCall?.specializationList?.map((spec) => ({
          _id: spec._id,
          name: spec.name,
        }))
        : [],
    });
    checkImageAvailability(employeeCall?.empId);
    setProfileImageAvailable(false);
  }, [employeeCall, employeeList]);

  return (
    <Box>
      <Box>
        {/* EDIT AND DELETE BUTTONS */}
        <MainBox>
          {editEmployee ? null : (
            <Tooltip
              title="Edit Data"
              arrow
              disableInteractive
              TransitionComponent={Zoom}
            >
              <IconButton
                onClick={() => setEditEmployee(true)}
                disabled={editEmployee}
                sx={{ color: "var( --third-color)" }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          {/* {adminLogin ? (
            <>
              {newEmployeeData.empId !== onAdminId ?
                <Tooltip
                  title={
                    employeeCall?.status === "Active"
                      ? "DeActive Employee"
                      : "Active Employee"
                  }
                  arrow
                  disableInteractive
                  TransitionComponent={Zoom}
                >
                  <Switch
                    checked={
                      employeeCall?.status === "Active" ? !openDialog : openDialog
                    }
                    onChange={() => {
                      setOpenDialog(true);
                      dispatch(set_index(newEmployeeData.empId));
                    }}
                  />
                </Tooltip>
                : null}
            </>
          ) : null} */}
        </MainBox>

        {/* {/ FOR PROFILE IMAGES /} */}
        <Box>
          <Box>
            {adminLogin ? (
              image ? (
                ProfileImageAvailable ? (
                  <img
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "var(--primary-highlight-color)",
                      padding: "5px",
                      border: "2px solid var(--secondary-text-color)",
                    }}
                    width={"120px"}
                    height={"120px"}
                    src={`${CLOUD_FUNCTIONS_ORIGIN}/user/getProfileImage/${employeeCall?.empId}.png`}
                    alt="profile"
                  />
                ) : (
                  <img
                    style={{
                      borderRadius: "50%",
                      backgroundColor: "var(--primary-highlight-color)",
                      padding: "5px",
                      border: "2px solid var(--secondary-text-color)",
                    }}
                    width={"120px"}
                    src={profileImgSrc}
                    alt="profile"
                  />
                )
              ) : null
            ) : null}
            <Box>
              {employeeCall?.empId ? (
                <EmpIdTypography>
                  Employee ID : {employeeCall?.empId}
                </EmpIdTypography>
              ) : null}
            </Box>
          </Box>
        </Box>

        {/* FOR EMPLOYEE PERSONEL DETAILS */}
        <FormBox>
          <form>
            <Grid item container>
              {/* LEFT PART */}
              <Box sx={{ color: "" }}></Box>
              <Grid item container xs={12} md={6} rowSpacing={2}>
                <Grid item xs={12}>
                  <Typography sx={viewProfileSubtitle}>
                    Employee Name
                  </Typography>
                  <InputTextField
                    themechange={themeChange ? "true" : "false"}
                    name="FirstName"
                    value={newEmployeeData.FirstName}
                    disabled={!editEmployee}
                    onChange={handleInputChange}
                  />
                  <Typography sx={{ color: "red" }}>
                    {errors.FirstName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={viewProfileSubtitle}>Gender</Typography>
                  {editEmployee ? (
                    <GenderSelect
                      themechange={themeChange ? "true" : "false"}
                      disabled={!editEmployee}
                      name="Gender"
                      value={newEmployeeData.Gender}
                      onChange={handleInputChange}
                    >
                      {genderCategory?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </GenderSelect>
                  ) : (
                    <InputTextField
                      themechange={themeChange ? "true" : "false"}
                      name="Gender"
                      value={newEmployeeData.Gender}
                      disabled
                    />
                  )}

                  <Typography sx={{ color: "red" }}>{errors.Gender}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={viewProfileSubtitle}>Department</Typography>
                  {editEmployee ? (
                    <CustomSelect
                      themechange={themeChange ? "true" : "false"}
                      employeelogin={EmployeeLogin ? "true" : "false"}
                      name="Department"
                      value={newEmployeeData.Department}
                      disabled={EmployeeLogin || (!editEmployee && adminLogin)}
                      onChange={handleInputChange}
                    >
                      {departmentNames?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  ) : (
                    <InputTextField
                      themechange={themeChange ? "true" : "false"}
                      name="Department"
                      value={newEmployeeData.Department}
                      disabled
                    />
                  )}
                  <Typography sx={{ color: "red" }}>
                    {errors.Department}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={viewProfileSubtitle}>Role</Typography>
                  {editEmployee ? (
                    <CustomSelect
                      themechange={themeChange ? "true" : "false"}
                      employeelogin={EmployeeLogin ? "true" : "false"}
                      name="Role"
                      disabled={EmployeeLogin || (!editEmployee && adminLogin)}
                      value={newEmployeeData.Role}
                      onChange={handleInputChange}
                    >
                      {roles?.map((option) => (
                        <MenuItem key={option.role} value={option.role}>
                          {option.role}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  ) : (
                    <InputTextField
                      themechange={themeChange ? "true" : "false"}
                      name="Role"
                      value={newEmployeeData.Role}
                      disabled
                    />
                  )}

                  <Typography sx={{ color: "red" }}>{errors.Role}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={viewProfileSubtitle}>Job Category</Typography>
                  {editEmployee ? (
                    <CustomSelect
                      themechange={themeChange ? "true" : "false"}
                      employeelogin={EmployeeLogin ? "true" : "false"}
                      name="Category"
                      value={newEmployeeData.Category}
                      disabled={EmployeeLogin || (!editEmployee && adminLogin)}
                      onChange={handleInputChange}
                    >
                      {jobCategory?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  ) : (
                    <InputTextField
                      themechange={themeChange ? "true" : "false"}
                      name="Category"
                      value={newEmployeeData.Category}
                      disabled
                    />
                  )}
                  <Typography sx={{ color: "red" }}>
                    {errors.Category}
                  </Typography>
                </Grid>
              </Grid>

              {/* RIGHT PART */}
              <Grid item container xs={12} md={6} rowSpacing={2}>
                <Grid item xs={12}>
                  <Typography sx={viewProfileSubtitle}>
                    Date of Birth
                  </Typography>
                  <DOBTextField
                    themechange={themeChange ? "true" : "false"}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* Add your icon here if needed */}
                        </InputAdornment>
                      ),
                    }}
                    name="Date_Of_Birth"
                    value={formattedDate_Of_Birth || ""}
                    type="date"
                    disabled={!editEmployee}
                    onChange={handleInputChange}
                  />
                  <Typography sx={{ color: "red" }}>
                    {errors.Date_Of_Birth}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={viewProfileSubtitle}>Blood Group</Typography>
                  {editEmployee ? (
                    <BloodGroupSelect
                      themechange={themeChange ? "true" : "false"}
                      name="BloodGroup"
                      value={newEmployeeData.BloodGroup}
                      disabled={!editEmployee}
                      onChange={handleInputChange}
                    >
                      {bloodGroupsCategory?.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </BloodGroupSelect>
                  ) : (
                    <InputTextField
                      themechange={themeChange ? "true" : "false"}
                      name="BloodGroup"
                      value={newEmployeeData.BloodGroup}
                      disabled
                    />
                  )}
                  <Typography sx={{ color: "red" }}>
                    {errors.BloodGroup}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={viewProfileSubtitle}>Job Title</Typography>
                  <DesignationTextField
                    employeelogin={EmployeeLogin ? "true" : "false"}
                    themechange={themeChange ? "true" : "false"}
                    editemployee={editEmployee ? "true" : "false"}
                    name="Designation"
                    value={newEmployeeData.Designation}
                    disabled={EmployeeLogin || (!editEmployee && adminLogin)}
                    onChange={handleInputChange}
                  ></DesignationTextField>
                  <Typography sx={{ color: "red" }}>
                    {errors.Designation}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={viewProfileSubtitle}>
                    Reporting Person
                  </Typography>
                  {editEmployee ? (
                    <ReportingPersonAutocomplete
                      fullWidth
                      multiple
                      disabled={EmployeeLogin || (!editEmployee && adminLogin)}
                      limitTags={2}
                      options={employeeList?.filter(
                        (option) =>
                          !newEmployeeData?.ReportingPerson?.includes(option)
                      )}
                      value={newEmployeeData.ReportingPerson}
                      getOptionLabel={(option) => option.firstName}
                      onChange={EmployeesValueSetter}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: "inharit",
                            background: EmployeeLogin
                              ? themeChange
                                ? "#2D4663"
                                : "#D5D5D5"
                              : themeChange
                                ? "#142840"
                                : "#FFFFFF",
                          }}
                        />
                      )}
                    />
                  ) : (
                    // <Select
                    //   sx={{
                    //     width: "80%",
                    //     height: "55px",
                    //     background: EmployeeLogin
                    //       ? themeChange
                    //         ? "#2d4663"
                    //         : "#d5d5d5"
                    //       : themeChange
                    //         ? "#142840"
                    //         : "#ffffff",
                    //   }}
                    //   disabled={EmployeeLogin || (!editEmployee && adminLogin)}
                    //   name="ReportingPerson"
                    //   value={newEmployeeData.ReportingPerson}
                    //   onChange={handleInputChange}
                    // >
                    //   {allData?.map((option) => (
                    //     <MenuItem key={option.firstName} value={option.empId}>
                    //       {option.firstName}
                    //     </MenuItem>
                    //   ))}
                    // </Select>
                    <InputTextField
                      themechange={themeChange ? "true" : "false"}
                      name="ReportingPerson"
                      value={getReportingPersonById(
                        employeeCall?.ReportingPerson
                      )}
                      disabled
                    />
                  )}
                  <Typography sx={{ color: "red" }}>
                    {errors.ReportingPerson}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={viewProfileSubtitle}>
                    Date of Joining
                  </Typography>
                  <DOBJoiningTextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {/* Add your icon here if needed */}
                        </InputAdornment>
                      ),
                    }}
                    themechange={themeChange ? "true" : "false"}
                    employeelogin={EmployeeLogin ? "true" : "false"}
                    editemployee={editEmployee ? "true" : "false"}
                    name="Date_Of_Join"
                    value={formattedDate_Of_Join || ""}
                    type="date"
                    disabled={EmployeeLogin || (!editEmployee && adminLogin)}
                    onChange={handleInputChange}
                  />

                  <Typography sx={{ color: "red" }}>
                    {errors.Date_Of_Join}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} item md={6} sx={{ paddingTop: "10px" }}>
              {editEmployee && adminLogin ? (
                <Grid item xs={12}>
                  <Box sx={{ height: "55px" }}>
                    <FormControlLabel
                      sx={{
                        ".MuiCheckbox-root": {
                          color: "var(--secondary-text-color)",
                        },
                      }}
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                        />
                      }
                      label="Probation OR Intern"
                    />
                  </Box>
                </Grid>
              ) : null}
              {checked ? (
                <Grid item xs={12}>
                  <Typography sx={viewProfileSubtitle}>
                    Traning End Date
                  </Typography>
                  <TrainingEndDateTextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"></InputAdornment>
                      ),
                    }}
                    themechange={themeChange ? "true" : "false"}
                    disabled={!editEmployee}
                    onChange={handleInputChange}
                    name="TrainingEndDate"
                    value={formattedTraning_end_date}
                    type="date"
                  />
                  <Typography sx={{ color: "red" }}>
                    {errors.TrainingEndDate}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={9.6}>
              <Typography sx={{ viewProfileSubtitle }}>
                Specializations
              </Typography>
              <Autocomplete
                freeSolo
                disabled={!editEmployee}
                options={options}
                getOptionLabel={(option) => typeof option === "string" ? option : option.name || ""}
                value={null}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue || "");
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    // handleAddSpecialization(newValue);
                  } else if (newValue && !selectedSpecializations.some((s) => s._id === newValue._id)) {
                    handleAddSpecialization(newValue?.name);
                  }
                }}
                clearIcon={inputValue && <ClearIcon />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Add or select specialization"
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        handleAddSpecialization(inputValue);
                      }
                    }}
                    helperText={error}
                    error={!!error}
                  />
                )}
              />
              <Box mt={2}>
                {newEmployeeData?.Specializations?.map((specialization) => (
                  <Chip
                    key={specialization._id}
                    label={specialization.name}
                    onDelete={() => editEmployee ? handleDeleteSpecialization(specialization._id) : undefined}
                    style={{
                      margin: "4px",
                      backgroundColor: themeChange ? "#142840" : "rgba(0, 0, 0, 0.08)",
                      color: "var(--secondary-text-color)",
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </form>
          <Box>
            {editEmployee ? (
              <FooterBox>
                <Button
                  sx={{
                    fontWeight: "bold",
                    color: "gray",
                  }}
                  onClick={() => {
                    setEditEmployee(false);
                    setNewEmployeeData({
                      id: employeeCall?._id,
                      empId: employeeCall?.empId,
                      FirstName: employeeCall?.FirstName,
                      Department: employeeCall?.Department,
                      Designation: employeeCall?.Designation,
                      Category: employeeCall?.Category,
                      Date_Of_Join: employeeCall?.Date_Of_Join,
                      Date_Of_Birth: employeeCall?.Date_Of_Birth,
                      BloodGroup: employeeCall?.BloodGroup,
                      Gender: employeeCall?.Gender,
                      ReportingPerson: employeeList?.filter((employee) =>
                        employeeCall?.ReportingPerson?.includes(employee?.empId)
                      ) ?? [],
                      Role: employeeCall?.Role?.role ?? "",
                      TrainingEndDate: employeeCall?.TrainingEndDate,
                      Specializations: employeeCall?.specializations
                        ? employeeCall?.specializations?.map((spec) => ({
                          _id: spec._id,
                          name: spec.name,
                        }))
                        : [],
                      SpecializationList: employeeCall?.specializationList
                        ? employeeCall?.specializationList?.map((spec) => ({
                          _id: spec._id,
                          name: spec.name,
                        }))
                        : [],
                    });
                  }}
                >
                  Cancel
                </Button>
                <SaveButton
                  onClick={() => {
                    if (validateForm()) {
                      if (saveAndNext()) setEditEmployee(false);
                    }
                  }}
                >
                  Save
                </SaveButton>
                <SaveNextButton
                  variant="contained"
                  onClick={() => {
                    if (validateForm()) {
                      if (saveAndNext()) setEditEmployee(false);
                      saveNextButtonCallback();
                    }
                  }}
                >
                  Save & Next
                </SaveNextButton>
              </FooterBox>
            ) : null}
          </Box>
        </FormBox>
        <Box>
          {/* <CustomDialogBox
            open={openDialog}
            setOpenDialog={setOpenDialog}
            status={employeeCall?.status}
            dialogHeading={
              employeeCall?.status === "Active"
                ? "Confirm Archive the employee"
                : "Confirm Active the employee"
            }
            dialogDescription={
              employeeCall?.status === "Active"
                ? "Are you sure you want to Archive this data?"
                : "Are you sure you want to Active this data?"
            }
            deleteEmpId={employeeCall?.empId}
            dialogIcon={
              <WarningAmberIcon
                sx={{
                  color: "#c70000",
                  fontSize: "30px",
                  borderRadius: "50px",
                }}
              />
            }
          /> */}
          <Box>
            <DialogComponent
              open={openDialog}
              onClose={onClose}
              maxWidth="md"
              renderChildern={renderForm}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UpdatePersonal;
