import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getRoles } from '../../Services/DashboardServices';
import { setGetRoleByPermission } from '../../Slices/selectedActive';

function RolePermission(props) {
    const { component, pageName } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const getPermissionLocal = JSON.parse(window.localStorage.getItem("getRoleByPermission"));
    const getRoleByPermission = useSelector((state) => state?.selectedActiveView?.getRoleByPermission);

    // useEffect(() => {
    //     // const getPermission = getRoleByPermission?.length > 0 ? getRoleByPermission : getPermissionLocal;
    //     const getPermission = getRoleByPermission;
    //     const permission = getPermission?.find((item) => item?.pageName === pageName);
    //     if (!permission) {
    //         navigate("/access-denied")
    //     }
    //     // eslint-disable-next-line 
    // }, [])

    useEffect(() => {
        if (getRoleByPermission?.length === 0) {
            dispatch(fetchPermissions()).then((permissions) => {
                if (permissions && permissions?.length > 0) {
                    const permission = permissions?.find(item => item?.pageName === pageName);
                    if (!permission) {
                        navigate('/access-denied');
                    }
                } else {
                    console.error('Permissions not available.');
                }
            });
        } else {
            const permission = getRoleByPermission?.find(item => item?.pageName === pageName);
            if (!permission) {
                navigate('/access-denied');
            }
        }
    }, [dispatch, getRoleByPermission, navigate, pageName]);

    return (
        <>{getRoleByPermission.length > 0 && component}</>
    )
}

export default RolePermission

export const fetchPermissions = () => {
    return async (dispatch) => {
        try {
            const response = await getRoles();
            const getRoleByPermission = response?.data?.getRoleByPermission
            dispatch(setGetRoleByPermission(getRoleByPermission));
        } catch (error) {
            console.error('Error fetching permissions:', error);
        }
    };
};