import { Box, Card, CardContent, Grid, List, ListItem, ListItemText, Typography, styled } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import Avatar from "@mui/material/Avatar";

export const MainGrid = styled(Grid)(() => ({
    padding: "2px",
    marginBottom: "12px",
    height: "100%"
}))

export const MainCard = styled(Card)(({ adminlogin, name }) => ({
    height: "100%",
    borderRadius: "5px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "space-around",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "2px",
    cursor: adminlogin === "true"
        ? "pointer"
        : name === "Monthly Present"
            ? "pointer"
            : null,
}))

export const MainBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
}))

export const TextTypography = styled(Typography)(() => ({
    color: "var(--primary-text-color)",
}))

export const Carousels = styled(Carousel)(() => ({
    height: "100%",
    borderRadius: "5px",
}))

export const BirthDayShowCard = styled(Card)(() => ({
    color: "var(--primary-text-color)",
    height: "100%",
    borderRadius: "5px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "space-around",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "2px",
}))

export const DOBTypography = styled(Typography)(() => ({
    fontWeight: "bold",
    fontSize: "100%"
}))

export const InnerGridOne = styled(Grid)(() => ({
    paddingRight: "8px",
    marginBottom: "12px",
}))

export const InnerGridFour = styled(Grid)(() => ({
    paddingLeft: "8px",
    marginBottom: "12px",
}))

export const InnerCardOne = styled(Card)(() => ({
    height: "100%",
    borderRadius: "5px",
    backgroundColor:
        "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const CardContents = styled(CardContent)(() => ({
    paddingBottom: "16px !important",
    color: "var(--primary-text-color)",
}))

export const PointedMainBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    // paddingBottom: "0px",
}))

export const TitleOfTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
    fontSize: "110%",
    textAlign: "-webkit-center",
}))

export const PointedCarousels = styled(Carousel)(() => ({
    height: "130px",
    borderRadius: "5px",
}))

export const ListItems = styled(ListItem)(() => ({
    paddingTop: "0px",
    paddingBottom: "0px !important",
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
    alignItems: "center",
    "@media (max-width: 600px)": {
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "10px 0",
    },
    "& .MuiTypography-root": {
        "@media (max-width: 600px)": {
            fontSize: "1rem",
            lineHeight: "1rem",
        },
    },
}));

export const ListItemTexts = styled(ListItemText)(() => ({
    width: "fit-content",
    display: "flex",
    justifyContent: "flex-end",

    "@media (max-width: 600px)": {
        justifyContent: "flex-start",
        width: "100%",
    },
}));


export const ActivityTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    display: "inline",
    fontWeight: "bold",
    fontSize: "130%",
    paddingLeft: "14px",
}))

export const EventCard = styled(Card)(() => ({
    paddingTop: "0px",
    paddingBottom: "0px",
    height: "100%",
    borderRadius: "5px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    textAlign: "-webkit-center",
}))

export const EventCardContent = styled(CardContent)(() => ({
    paddingBottom: "3px !important",
}))

export const EventTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    display: "inline",
    fontWeight: "bold",
    fontSize: "130%",
    paddingBottom: "16px",
    paddingLeft: "10px",
}))

export const EventShowList = styled(List)(() => ({
    paddingBottom: "0px",
    width: "100%",
    background: "var(--background-table-sidebar-card-color)",
    color: "var(--primary-text-color)",
    "& .MuiTypography-root": {
        color: "var(--secondary-text-color)",
    },
}))

export const Avatars = styled(Avatar)(({ isdarktheme }) => ({
    bgcolor: isdarktheme === "true" ? "#ffffff37" : "#00000055"
}))

export const InnerGridTwo = styled(Grid)(() => ({
    padding: "2px",
    margin: "12px 0px"
}))

export const LeaveBalanceCard = styled(Card)(() => ({
    height: "100%",
    width: "100%",
    borderRadius: "5px",
    alignItems: "center",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const LeaveBalanceTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    display: "inline",
    fontWeight: "bold",
    fontSize: "130%",
    paddingLeft: "14px",
}))

export const InnerGridThree = styled(Grid)(() => ({
    padding: "2px",
    marginBottom: "12px"
}))

export const DailyCheckInsCard = styled(Card)(() => ({
    height: "100%",
    borderRadius: "5px",
    alignItems: "center",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const DailyCheckInsTypography = styled(Typography)(() => ({
    fontSize: "130%",
    paddingTop: "15px",
    paddingLeft: "25px",
    color: "var(--primary-color)",
    fontWeight: "bold",
}))

export const WeeklyCheckInsCard = styled(Card)(() => ({
    height: "100%",
    borderRadius: "5px",
    backgroundColor: "var(--background-table-sidebar-card-color)",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
}))

export const WeeklyCheckInsTypography = styled(Typography)(() => ({
    fontSize: "130%",
    paddingTop: "15px",
    paddingLeft: "25px",
    color: "var(--primary-color)",
    fontWeight: "bold",
}))