import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setCurrentWorkingTicketNo, setShowTicketDialogBox } from "../../../Slices/timerDetailsSlice";
import DialogComponent from "../../ReusableComponents/DialogBoxComponents/DialogComponent";
import { CloseIconButton, ErrorTypography, CustomButton, ConfirmationNumberIcons, SelectTaskBoxDialog, TicketMainBoxDialog, CancelButton, DialogsActions, ContentBox, InnerBoxOneDialog } from "../../../Styles/EmployeeManagement/Employee/TimerStyle"

const TicketDialog = ({
  taskList,
  open,
  handleStart
}) => {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const [displayError, setDisplayError] = useState("");
  const [selectedValueTicket, setSelectedValueTicket] = useState("");

  const onCloseTicketDialogBox = () => {
    dispatch(setShowTicketDialogBox(false));
  }

  const ticketConfirm = () => {
    if (!selectedValueTicket) {
      setDisplayError("Select Ticket.");
    } else {
      dispatch(setCurrentWorkingTicketNo(selectedValueTicket));
      handleStart(selectedValueTicket);
      onCloseTicketDialogBox();
    }
  };

  const renderTicketDialogBox = () => {
    return (
      <>
        <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff" }}>
          <Box>
            <CloseIconButton
              onClick={onCloseTicketDialogBox}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
          <TicketMainBoxDialog>
            <Box>
              <DialogTitle sx={{ fontWeight: "bold" }}>
                <InnerBoxOneDialog>
                  <SelectTaskBoxDialog>
                    <ConfirmationNumberIcons />
                  </SelectTaskBoxDialog>
                  <Box>
                    <Box sx={{ color: isDarkTheme ? "#e0e0e0e3" : "#544f5a" }}>
                      {"Select The Task !"}
                    </Box>
                  </Box>
                </InnerBoxOneDialog>
              </DialogTitle>
            </Box>
          </TicketMainBoxDialog>
          <DialogContent>
            <DialogContentText id="timer-stop-dialog-description">
              <ContentBox isdarktheme={isDarkTheme ? "true" : "false"}>
                <TextField
                  fullWidth
                  select
                  label="Task"
                  name="Tasks"
                  value={selectedValueTicket}
                  onChange={(event) => setSelectedValueTicket(event.target.value)}
                  multiline
                  variant="outlined"
                >
                  {taskList?.map((option) => (
                    <MenuItem key={option.ticketNo} value={option.ticketNo}>
                      {option.ticketNo} {": "}
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </ContentBox>
              <ErrorTypography>
                {displayError}
              </ErrorTypography>
            </DialogContentText>
          </DialogContent>
          <DialogsActions>
            <CancelButton
              onClick={onCloseTicketDialogBox}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              Cancel
            </CancelButton>
            <CustomButton
              onClick={() => ticketConfirm()}
              variant="contained"
              component={Link}
              color="success"
            >
              Confirm
            </CustomButton>
          </DialogsActions>
        </Box>
      </>)
  }

  return (
    <Box>
      <DialogComponent
        open={open}
        onClose={onCloseTicketDialogBox}
        maxWidth="md"
        renderChildern={renderTicketDialogBox}
      />
    </Box>
  );
};

export default TicketDialog;
