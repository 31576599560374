import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { Box } from "@mui/material";

function Chart({ employeeData }) {
	const svgRef = useRef(null);

	useEffect(() => {
		const timeIntervals = {
			"09:00 - 10:00": [],
			"10:00 - 11:00": [],
			"11:00 - 18:30": [],
		};

		employeeData.forEach((employee) => {
			const startTime = Object.keys(timeIntervals).find(
				(interval) =>
					employee.time >= interval.split(" - ")[0] &&
					employee.time < interval.split(" - ")[1]
			);
			if (startTime) {
				timeIntervals[startTime].push(employee);
			}
		});

		const data = Object.entries(timeIntervals).map(
			([timeInterval, employees]) => ({ timeInterval, employees })
		);

		const handleResize = () => {
			const containerWidth = svgRef.current.parentNode.offsetWidth * 0.9;
			const svgWidth = containerWidth;
			const svgHeight = 300;
			const margin = { top: 30, right: 10, bottom: 20, left: 50 };
			const width = svgWidth - margin.left - margin.right;
			const height = svgHeight - margin.top - margin.bottom;

			const svg = d3
				.select("#employeeChart")
				.attr("width", svgWidth)
				.attr("height", svgHeight);

			const tooltip = d3
      .select(".tooltip1")
      // .select(`#svgChartDivRef`)
      // .append("div")
      .style("opacity", 0)
      // .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style("position", "absolute")
      .style("border", "0")
  .style("left", 0)
  .style("top", 0)
      .style("box-shadow", "rgba(0, 0, 0, 0.24) 0px 3px 8px")
      .style("width", "max-content");

			const xScale = d3
				.scaleBand()
				.domain(data.map((d) => d.timeInterval))
				.range([margin.left, width + margin.left])
				.padding(0.1);

			const yScale = d3
				.scaleLinear()
				.domain([0, d3.max(data, (d) => d.employees.length)])
				.range([height, 0]);

			const xAxis = d3.axisBottom(xScale).tickSize(0).tickPadding(8);

			const yAxis = d3
				.axisLeft(yScale)
				.ticks(d3.max(data, (d) => d.employees.length))
				.tickValues(
					d3.range(0, d3.max(data, (d) => d.employees.length) + 1)
				);

			svg.selectAll("*").remove();

			// Append X-axis label
			svg.append("text")
				.attr("class", "axis-label")
				.attr(
					"transform",
					`translate(${width / 2 + margin.left},${
						height + margin.top + 40
					})`
				)
				.style("text-anchor", "middle")
				.style("font-size", "12px")
				.text("Time Interval");

			// Append Y-axis label
			svg.append("text")
				.attr("class", "axis-label")
				.attr("transform", "rotate(-90)")
				.attr("y", margin.left - 30)
				.attr("x", 0 - (height / 2 + 20))
				.style("text-anchor", "middle")
				.style("font-size", "12px")
				.text("Number of Employees");

			svg.append("g")
				.attr("transform", `translate(0, ${height + margin.top})`)
				.call(xAxis)
				.style("stroke", "var(--secondary-text-color)")
				.style("color", "var(--secondary-text-color)")
				.style("font-size", "12px")
				.style("stroke-width", "0.8px")
				.style("font-weight", "lighter");

			svg.append("g")
				.attr("transform", `translate(${margin.left}, ${margin.top})`)
				.call(yAxis)
				.style("stroke-width", "0.8px")
				.style("color", "var(--secondary-text-color)")
				.style("stroke", "var(--secondary-text-color)")
				.style("font-size", "12px")
				.style("font-weight", "lighter");

			svg.selectAll(".bar")
				.data(data)
				.enter()
				.append("rect")
				.attr("class", "bar")
				.attr("x", (d) => xScale(d.timeInterval) + 15)
				.attr("y", (d) => yScale(d.employees.length) + margin.top)
				.attr("width", xScale.bandwidth() - 30)
				.attr("fill", (d) => {
					switch (d.timeInterval) {
						case "09:00 - 10:00":
							return "var(--approved-color)";
						case "10:00 - 11:00":
							return "var(--totalHrs-color)";
						case "11:00 - 18:30":
							return "var(--late-owl-point-color)";
						default:
							return "black";
					}
				})
				.attr("height", (d) => height - yScale(d.employees.length))
				.on("mouseover", function (event, d) {
					d3.select(this).style("cursor", "pointer");
					const tooltipContent = d.employees
						.map(
							(employee) =>
								`${employee.name} - ${employee.realTime}`
						)
						.join("<br/>");
					tooltip.transition().duration(200).style("opacity", 0.9);
					tooltip.html(tooltipContent);
					// .style("left", event.pageX + "px")
					// .style("top", event.pageY - 28 + "px");
				})
				.on("mousemove", function (event) {
					tooltip
						.style("left", d3.pointer(event)[0] + 350 + "px")
						.style("top", d3.pointer(event)[1] + 80 + "px");
				})
				.on("mouseout", function (d) {
					tooltip.transition().duration(500).style("opacity", 0);
				});
		};

		if (employeeData.length > 0) {
			handleResize();
			window.addEventListener("resize", handleResize);
			return () => {
				window.removeEventListener("resize", handleResize);
			};
		}
	}, [employeeData]);

	return (
		<Box id="svgDivRef" className="svgDivRef" sx={{ paddingLeft: "15px" }}>
			<svg
				id="employeeChart"
				ref={svgRef}
				style={{
					height: "360px",
					bottom: "2px",
				}}
			></svg>
			<Box className="tooltip1"></Box>
		</Box>
	);
}

export default Chart;
