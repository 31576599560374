import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from '@mui/material/Tooltip';
import Zoom from "@mui/material/Zoom";
import { GridToolbar } from "@mui/x-data-grid";

import DialogOfCompanyAndLeavePolicy from "./DialogOfCompanyAndLeavePolicy";
import { getAllFestivalsData } from "../../Services/HolidayServices";
import { numberArray } from "../Constant/constant";
import Loader from "../Loader/Loader";
import { AddHolidayButton, CompanyHolidayBox, AccordionsDetails, NoHolidayAddedTypography, HolidayDataGrid, HolidaysTextTypography, LoadingBox, HolidaysMainBox, AccordionSummarys, CustomHolidayBox, CustomHolidayTypography } from "../../Styles/CompanyAndLeavePolicy/CompanyLeaveAndHolidayAccordianStyle"
import "../../assets/styles/DataGridStyles.css";

function CompanyHolidays(props) {
  const { adminLogin, isDarkTheme } = props;

  const navigate = useNavigate();

  const [festivalsData, setFestivalsData] = useState([]);
  const [pastfestivalRecord, setPastfestivalRecord] = useState([]);
  const [pastfestivalYearRecord, setPastfestivalYearRecord] = useState([]);
  const [futurefestivalRecord, setFuturefestivalRecord] = useState([]);
  const [futurefestivalYearRecord, setFuturefestivalYearRecord] = useState([]);
  const [pageNumber, setPageNumber] = useState(null);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [buttonSelected, setButtonSelected] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [selectedRecordId, setSelectedRecordId] = useState("");
  const [festivalEditRecord, setFestivalEditRecord] = useState(false);
  // const [leaveEditRecord, setLeaveEditRecord] = useState(false);
  // const [leaveDeleteRecord, setLeaveDeleteRecord] = useState(false);
  const [festivalDeleteRecord, setFestivalDeleteRecord] = useState(false);
  // const [displayError, setDisplayError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dataGridHeight1, setDataGridHeight1] = useState(false);
  const [dataGridHeight2, setDataGridHeight2] = useState(false);
  const [dataGridHeight3, setDataGridHeight3] = useState(false);

  const FormattedFestivalsData = festivalsData?.map((festival, index) => ({
    id: index + 1, // Ensure index is defined
    festival_Id: festival?._id,
    festival: festival?.festival,
    festivalDate: festival?.date.split("-").reverse().join("-"),
    festivalDay: festival?.day,
  }));

  const columnOfFestival = adminLogin
    ? [
      { field: "id", headerName: "No.", flex: 1 },
      { field: "festival", headerName: "Festival", flex: 1 },
      { field: "festivalDate", headerName: "Date", flex: 1 },
      { field: "festivalDay", headerName: "Day", flex: 1 },
      {
        field: "actions",
        headerName: "Actions",
        flex: 0.7,
        renderCell: (params) => (
          <Box sx={{ display: "flex", gap: "2px" }}>
            <Tooltip
              title="Edit"
              placement="left"
              arrow
              TransitionComponent={Zoom}
            >
              <IconButton
                sx={{
                  color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                }}
                variant="contained"
                onClick={() => {
                  setFestivalEditRecord(true);
                  setOpenDialogBox(true);
                  setButtonSelected("Add Holiday");
                  setButtonName("Edit Holiday");
                  setSelectedRecordId(params.row.id);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            <Tooltip
              title="Delete"
              placement="right"
              arrow
              TransitionComponent={Zoom}
            >
              <IconButton
                sx={{
                  color: isDarkTheme ? "#d94a38" : "#c62828",
                }}
                variant="contained"
                onClick={() => {
                  setFestivalDeleteRecord(true);
                  setOpenDialogBox(true);
                  setButtonSelected("Add Holiday");
                  setButtonName("Delete Holiday");
                  setSelectedRecordId(params.row.id);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ),
      },
    ]
    : [
      { field: "id", headerName: "Sr No.", flex: 1 },
      { field: "festival", headerName: "Festival", flex: 1 },
      { field: "festivalDate", headerName: "FestivalDate", flex: 1 },
      { field: "festivalDay", headerName: "FestivalDay", flex: 1 },
    ];

  const supportedDataColumn = [
    { field: "id", headerName: "Sr No.", flex: 1 },
    { field: "festival", headerName: "Festival", flex: 1 },
    { field: "festivalDate", headerName: "FestivalDate", flex: 1 },
    { field: "festivalDay", headerName: "FestivalDay", flex: 1 },
  ];

  const memoizedgetFestivalsData = useMemo(() => {
    const getFestivalsData = async () => {
      setIsLoading(true);
      try {
        // const response = await axios.get(
        // 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/getFestival`,
        // 	getHeader
        // );
        const response = await getAllFestivalsData();
        if (response.status === 200) {
          const dataPastfestivalRecord = response.data.pastfestivalRecord;
          const dataFuturefestivalRecord = response.data.futurefestivalRecord;
          setFestivalsData(response.data.festivalRecord);
          setPastfestivalRecord(dataPastfestivalRecord);
          setFuturefestivalRecord(dataFuturefestivalRecord);
          setIsLoading(false);
          const uniquePastYears = [
            ...new Set(
              dataPastfestivalRecord.map((item) => item.date.split("-")[0])
            ),
          ]
            .sort((a, b) => b - a)
            .map((year) => `${year}`);
          const uniqueFutureYears = [
            ...new Set(
              dataFuturefestivalRecord.map((item) => item.date.split("-")[0])
            ),
          ].map((year) => `${year}`);
          setPastfestivalYearRecord(uniquePastYears);
          setFuturefestivalYearRecord(uniqueFutureYears);
        } else {
          setFestivalsData([]);
          setPastfestivalRecord([]);
          setFuturefestivalRecord([]);
          setIsLoading(false);
          setPastfestivalYearRecord([]);
          setFuturefestivalYearRecord([]);
        }
      } catch (error) {
        setIsLoading(false);
        // if (error.response) {
        // 	if (
        // 		error.response.status === 500 ||
        // 		error.response.status === 400
        // 	) {
        // 		setDisplayError(error.response.data.message);
        // 	} else if (
        // 		error.response.status === 401 ||
        // 		error.response.status === 403
        // 	) {
        // 		window.localStorage.removeItem("Token");
        // 		navigate("/");
        // 	}
        // }
      }
    };
    return getFestivalsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    memoizedgetFestivalsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialogBox]);

  useEffect(() => {
    const data = parseInt(window.localStorage.getItem("paginationNo"), 10);
    const pageSize = data ? data : 10;
    setPageNumber(pageSize);
  }, []);

  return (
    <Box>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Box>
          {/* <Accordion sx={{ marginBottom: "10px" }} defaultExpanded>
                <AccordionSummary
                    sx={{
                        textTransform: "capitalize",
                        backgroundColor: "var(--accordian-background-color)",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
                        color: "var(--primary-text-color)",
                    }}
                    expandIcon={
                        <ExpandMoreIcon
                            style={{ color: "var(--primary-text-color)" }}
                        />
                    }
                    aria-controls={`panel-content`}
                    id={`panel-header`}
                >
                    Holiday In {new Date().getFullYear()}
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        backgroundColor: "var(--accordian-background-color)",
                        color: "var(--primary-text-color)",
                        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
                    }}
                > */}
          {adminLogin ? (
            <CompanyHolidayBox>
              <AddHolidayButton
                onClick={() => {
                  setOpenDialogBox(true);
                  setButtonSelected("Add Holiday");
                  setButtonName("Add Holiday");
                }}
                variant="contained"
              >
                + Add Holiday
              </AddHolidayButton>
            </CompanyHolidayBox>
          ) : null}
          <HolidaysMainBox>
            <HolidaysTextTypography>
              Holidays In This Year
            </HolidaysTextTypography>{" "}
          </HolidaysMainBox>
          {FormattedFestivalsData.length !== 0 ? (
            <Box
              sx={{
                width: "100%",
                maxHeight: "fit-content",
                overflowX: "auto",
              }}
            >
              <Box
                sx={{
                  "@media (max-width: 600px)": {
                    width: "200%",
                  },
                }}
              >
                <HolidayDataGrid
                  disableColumnMenu
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  rows={FormattedFestivalsData}
                  columns={columnOfFestival}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: pageNumber },
                    },
                  }}
                  onRowCountChange={(params) => {
                    if (params === 0) {
                      setDataGridHeight1(true);
                    } else {
                      setDataGridHeight1(false);
                    }
                  }}
                  // onRowClick={(params) => {
                  //   setOpenDialogBox(true);
                  //     setButtonSelected("Edit Role");
                  //     setOpenDialogName("Edit Role");
                  //     setSelectedRecord(params.row.id - 1)
                  // }}
                  pageSizeOptions={numberArray}
                  onPaginationModelChange={(pageSize) => {
                    window.localStorage.setItem(
                      "paginationNo",
                      pageSize.pageSize
                    );
                  }}
                  onPageSizeChange={(pageSize) => {
                    window.localStorage.setItem("paginationNo", pageSize);
                  }}
                  components={{
                    Toolbar: GridToolbar,
                    // noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  dataGridHeight1={dataGridHeight1}
                />
              </Box>
            </Box>
          ) : (
            <NoHolidayAddedTypography>
              No Holiday added!
            </NoHolidayAddedTypography>
          )}
          {/* </AccordionDetails>
            </Accordion> */}

          {adminLogin ? (
            <>
              <Box>
                {futurefestivalYearRecord.length === 0 ? (
                  <></>
                ) : (
                  <CustomHolidayBox>
                    <CustomHolidayTypography>
                      Upcoming Years Holidays
                    </CustomHolidayTypography>{" "}
                  </CustomHolidayBox>
                )}
                {futurefestivalYearRecord?.map((year) => (
                  <Accordion sx={{ marginBottom: "10px" }}>
                    <AccordionSummarys
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color: "var(--primary-text-color)",
                          }}
                        />
                      }
                      aria-controls={`panel-content`}
                      id={`panel-header`}
                    >
                      Holiday In {year}
                    </AccordionSummarys>
                    <AccordionsDetails>
                      <HolidayDataGrid
                        disableColumnMenu
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        rows={futurefestivalRecord
                          ?.filter((item) => item.date.startsWith(year))
                          .map((festival, index) => ({
                            id: index + 1, // Ensure index is defined
                            festival_Id: festival?._id,
                            festival: festival?.festival,
                            festivalDate: festival?.date
                              .split("-")
                              .reverse()
                              .join("-"),
                            festivalDay: festival?.day,
                          }))}
                        columns={supportedDataColumn}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: pageNumber,
                            },
                          },
                        }}
                        onRowCountChange={(params) => {
                          if (params === 0) {
                            setDataGridHeight2(true);
                          } else {
                            setDataGridHeight2(false);
                          }
                        }}
                        // onRowClick={(params) => {
                        //   setOpenDialogBox(true);
                        //     setButtonSelected("Edit Role");
                        //     setOpenDialogName("Edit Role");
                        //     setSelectedRecord(params.row.id - 1)
                        // }}
                        pageSizeOptions={numberArray}
                        onPaginationModelChange={(pageSize) => {
                          window.localStorage.setItem(
                            "paginationNo",
                            pageSize.pageSize
                          );
                        }}
                        onPageSizeChange={(pageSize) => {
                          window.localStorage.setItem("paginationNo", pageSize);
                        }}
                        components={{
                          Toolbar: GridToolbar,
                          // noRowsOverlay:
                          // 	CustomNoRowsOverlay,
                        }}
                        dataGridHeight1={dataGridHeight2}
                      />
                    </AccordionsDetails>
                  </Accordion>
                ))}
              </Box>

              <Box>
                {pastfestivalYearRecord.length === 0 ? (
                  <></>
                ) : (
                  <CustomHolidayBox>
                    <CustomHolidayTypography>
                      Past Years Holidays
                    </CustomHolidayTypography>{" "}
                  </CustomHolidayBox>
                )}

                {pastfestivalYearRecord?.map((year) => (
                  <Accordion sx={{ marginBottom: "10px" }}>
                    <AccordionSummarys
                      expandIcon={
                        <ExpandMoreIcon
                          style={{
                            color: "var(--primary-text-color)",
                          }}
                        />
                      }
                      aria-controls={`panel-content`}
                      id={`panel-header`}
                    >
                      Holiday In {year}
                    </AccordionSummarys>
                    <AccordionsDetails>
                      <HolidayDataGrid
                        disableColumnMenu
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                          toolbar: {
                            showQuickFilter: true,
                          },
                        }}
                        rows={pastfestivalRecord
                          ?.filter((item) => item.date.startsWith(year))
                          .map((festival, index) => ({
                            id: index + 1, // Ensure index is defined
                            festival_Id: festival?._id,
                            festival: festival?.festival,
                            festivalDate: festival?.date
                              .split("-")
                              .reverse()
                              .join("-"),
                            festivalDay: festival?.day,
                          }))}
                        columns={supportedDataColumn}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: pageNumber,
                            },
                          },
                        }}
                        onRowCountChange={(params) => {
                          if (params === 0) {
                            setDataGridHeight3(true);
                          } else {
                            setDataGridHeight3(false);
                          }
                        }}
                        // onRowClick={(params) => {
                        //   setOpenDialogBox(true);
                        //     setButtonSelected("Edit Role");
                        //     setOpenDialogName("Edit Role");
                        //     setSelectedRecord(params.row.id - 1)
                        // }}
                        pageSizeOptions={numberArray}
                        onPaginationModelChange={(pageSize) => {
                          window.localStorage.setItem(
                            "paginationNo",
                            pageSize.pageSize
                          );
                        }}
                        onPageSizeChange={(pageSize) => {
                          window.localStorage.setItem("paginationNo", pageSize);
                        }}
                        components={{
                          Toolbar: GridToolbar,
                          // noRowsOverlay:
                          // 	CustomNoRowsOverlay,
                        }}
                        dataGridHeight1={dataGridHeight3}
                      />
                    </AccordionsDetails>
                  </Accordion>
                ))}
              </Box>
            </>
          ) : (
            <></>
          )}
          <Typography sx={{ color: "red" }}>{/* {displayError} */}</Typography>
          <DialogOfCompanyAndLeavePolicy
            open={openDialogBox}
            DialogOfCompanyAndLeavePolicy
            setOpenDialogBox={setOpenDialogBox}
            buttonSelected={buttonSelected}
            buttonName={buttonName}
            selectedRecordId={selectedRecordId}
            FormattedFestivalsData={FormattedFestivalsData}
            festivalEditRecord={festivalEditRecord}
            setFestivalEditRecord={setFestivalEditRecord}
            festivalDeleteRecord={festivalDeleteRecord}
            setFestivalDeleteRecord={setFestivalDeleteRecord}
          // setLeaveEditRecord={setLeaveEditRecord}
          // setLeaveDeleteRecord={setLeaveDeleteRecord}
          />
        </Box>
      )}
    </Box>
  );
}

export default CompanyHolidays;
