import { Box, Button, IconButton, TableCell, TableHead, TableRow, Typography, styled } from "@mui/material";

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "65vh",
}))

export const MainBox = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
}))

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
    marginLeft: "15px",
}))

export const InnerBoxOne = styled(Box)(() => ({
    width: "100%",
    maxHeight: "fit-content"
}))

export const TableHeads = styled(TableHead)(({ isdarktheme }) => ({
    background: isdarktheme === "true" ? "var(--primary-highlight-color)" : "#ffffff",
    height: "50px",
    position: "sticky",
    top: 0,
    zIndex: 1,
}));

export const TableRows = styled(TableRow)(() => ({
    cursor: 'pointer',
    width: '100%',
}))

export const IconButtons = styled(IconButton)(() => ({
    padding: '2px !important',
}))

export const FooterBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
    padding: "5px",
}))

export const SaveButton = styled(Button)(() => ({
    fontWeight: "bold",
    backgroundColor: "var(--secondary-color)",
    color: "#ffffff",
}))

export const CustomTableCell = styled(TableCell)(({ isdarktheme }) => ({
    color: "var(--primary-text-color)",
    padding: '8px',
    width: '150px',
    minWidth: '100px',
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    fontSize: '15px',
    position: 'sticky',
    left: 30,
    top: 65,
    zIndex: 99,
    backgroundColor: isdarktheme === "true" ? "var(--primary-highlight-color)" : '#ffffff',
}))

export const StickyTableCell = styled(TableCell)(({ emptasktablebodycell, isdarktheme }) => ({
    ...emptasktablebodycell,
    position: 'sticky',
    width: '10px',
    padding: "5px",
    left: 0,
    top: 65,
    zIndex: 100,
    backgroundColor: isdarktheme === "true" ? "var(--primary-highlight-color)" : '#ffffff',
}));