import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  DialogTitle,
  Typography,
  Box,
  Button,
  DialogContent,
  useMediaQuery,
} from "@mui/material";
import { Close, Logout } from "@mui/icons-material";

import DialogComponent from "../DialogBoxComponents/DialogComponent";
import { CancelButton, CloseIconButton, DialogsActions, InnerBoxOne, InnerBoxTwo, MainBox } from "../../../Styles/Authentication/LogOutStyle";

const LogoutDialog = ({
  openDialog,
  handleCancelLogout,
  handleLogoutConfirmation,
}) => {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const isMobile = useMediaQuery("(max-width:600px)");

  const renderFormLogout = () => {
    return (<>
      <Box sx={{ background: isDarkTheme ? "#0C1E34" : "#ffffff", padding: "8px" }}>
        <MainBox>
          <Box>
            <DialogTitle sx={{ fontWeight: "bold", padding: "0px 0px", fontSize: { xs: "1.2rem", sm: "1.5rem" } }}>
              <InnerBoxOne>
                <Box sx={{ padding: "8px 5px", }}>
                  <InnerBoxTwo>
                    <Logout color="error" />
                  </InnerBoxTwo>
                </Box>
                <Box>
                  <Box
                    sx={{
                      color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
                      fontSize: { xs: "1rem", sm: "1.2rem" },
                    }}
                  >
                    Confirm Logout
                  </Box>
                </Box>
              </InnerBoxOne>
            </DialogTitle>
          </Box>
          <Box>
            <CloseIconButton
              ismobile={isMobile ? "true" : "false"}
              onClick={handleCancelLogout}
              isdarktheme={isDarkTheme ? "true" : "false"}
            >
              <Close />
            </CloseIconButton>
          </Box>
        </MainBox>
        <DialogContent sx={{ padding: "6px 24px 5px 7px" }}>
          <Typography
            sx={{
              color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82",
              fontSize: { xs: "0.9rem", sm: "1rem" },
            }}
          >
            Are you sure you want to log out?
          </Typography>
        </DialogContent>
        <DialogsActions>
          <CancelButton
            onClick={handleCancelLogout}
            isdarktheme={isDarkTheme ? "true" : "false"}
          >
            Cancel
          </CancelButton>
          <Button
            variant="contained"
            onClick={handleLogoutConfirmation}
            sx={{
              borderRadius: "5px",
              textTransform: "capitalize",
            }}
            component={Link}
            color="error"
            to="/"
          >
            Log Out
          </Button>
        </DialogsActions>
      </Box>
    </>)
  }

  return (
    <DialogComponent open={openDialog} onClose={handleCancelLogout} maxWidth="md" renderChildern={renderFormLogout} />
  );
};

export default LogoutDialog;
