import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	IconButton,
	Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from '@mui/material/Tooltip';
import Zoom from "@mui/material/Zoom";
import { GridToolbar } from "@mui/x-data-grid";

import DialogOfCompanyAndLeavePolicy from "./DialogOfCompanyAndLeavePolicy";
import { numberArray } from "../Constant/constant";
import Loader from "../Loader/Loader";
import { getAllLeavesType } from "../../Services/LeaveManageServices";
import { CompanyLeaveBox, LoadingBox, AddLeaveTypeButton, DataGrids, NoLeavesAddedTypography } from "../../Styles/CompanyAndLeavePolicy/CompanyLeaveAndHolidayAccordianStyle";
import "../../assets/styles/DataGridStyles.css";
// import AddIcon from "@mui/icons-material/Add";
// import { GridToolbarContainer } from "@mui/x-data-grid";

function CompanyLeaves(props) {
	const { adminLogin, isDarkTheme } = props;

	const [leavesData, setLeavesData] = useState([]);
	// const [pageNumber, setPageNumber] = u seState(null);
	const [openDialogBox, setOpenDialogBox] = useState(false);
	const [buttonSelected, setButtonSelected] = useState("");
	const [buttonName, setButtonName] = useState("");
	const [selectedRecordId, setSelectedRecordId] = useState("");
	// const [festivalEditRecord, setFestivalEditRecord] = useState(false);
	// const [festivalDeleteRecord, setFestivalDeleteRecord] = useState(false);
	const [leaveEditRecord, setLeaveEditRecord] = useState(false);
	const [leaveDeleteRecord, setLeaveDeleteRecord] = useState(false);
	const [displayError, setDisplayError] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [dataGridHeight, setDataGridHeight] = useState(false);

	const FormattedLeavesData = leavesData?.map((leave, index) => ({
		id: index + 1, // Ensure index is defined
		leave_Id: leave?._id,
		leaveType: leave?.leaveType,
		leaveNo: leave?.leaveNo,
		enCash: leave?.enCash === true ? "Yes" : "No" || "No",
		deprecateLeave: leave?.deprecateLeave || false,
	}));

	const columnOfLeave = adminLogin
		? [
			{ field: "id", headerName: "No.", flex: 1 },
			{ field: "leaveType", headerName: "Leave Type", flex: 1 },
			{ field: "leaveNo", headerName: "Total Leaves", flex: 1 },
			{ field: "enCash", headerName: "EnCash", flex: 1 },
			{
				field: "actions",
				headerName: "Actions",
				flex: 0.7,
				renderCell: (params) => (
					<Box sx={{ display: "flex", gap: "2px" }}>
						<Tooltip
							title="Edit"
							placement="left"
							arrow
							TransitionComponent={Zoom}
						>
							<IconButton
								sx={{
									color: isDarkTheme
										? "#e0e0e0e3"
										: "#544f5a",
								}}
								variant="contained"
								onClick={() => {
									setLeaveEditRecord(true);
									setOpenDialogBox(true);
									setButtonSelected("Add Leave Type");
									setButtonName("Edit Leave Type");
									setSelectedRecordId(params.row.id);
								}}
							>
								<EditIcon />
							</IconButton>
						</Tooltip>

						{/* <Tooltip
                            title="Delete"
                            placement="right"
                            arrow
                            TransitionComponent={Zoom}
                        >
                            <IconButton
                                sx={{
                                    color: isDarkTheme ? "#d94a38" : "#c62828",
                                }}
                                variant="contained"
                                onClick={() => {
                                    setLeaveDeleteRecord(true);
                                    setOpenDialogBox(true);
                                    setButtonSelected("Add Leave Type");
                                    setButtonName("Delete Leave Type");
                                    setSelectedRecordId(params.row.id);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip> */}
					</Box>
				),
			},
		]
		: [
			{ field: "id", headerName: "Sr No.", flex: 1 },
			{ field: "leaveType", headerName: "Leave Type", flex: 1 },
			{ field: "leaveNo", headerName: "Total Leaves", flex: 1 },
		];

	const memoizedgetLeavesData = useMemo(() => {
		const getLeavesData = async () => {
			setDisplayError("");
			setIsLoading(true);
			try {
				// const response = await axios.get(
				// 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/getLeaveType`,
				// 	getHeader
				// );
				const response = await getAllLeavesType()
				if (response.status === 200) {
					setIsLoading(false);
					setLeavesData(response.data.leaveRecord);
				}
				else {
					setIsLoading(false)
					setLeavesData([])
				}
			} catch (error) {
				setIsLoading(false);
				// if (error.response) {
				// 	if (
				// 		error.response.status === 400 ||
				// 		error.response.status === 500
				// 	) {
				// 		setDisplayError(error.response.data.message);
				// 	} else if (
				// 		error.response.status === 401 ||
				// 		error.response.status === 403
				// 	) {
				// 		window.localStorage.removeItem("Token");
				// 		navigate("/");
				// 	}
				// }
			}
		};
		return getLeavesData;
	}, []);

	useEffect(() => {
		memoizedgetLeavesData();
	}, [memoizedgetLeavesData, openDialogBox]);

	// useEffect(() => {
	// 	const data = parseInt(window.localStorage.getItem("paginationNo"), 10);
	// 	const pageSize = data ? data : 10;
	// 	setPageNumber(pageSize);
	// }, []);

	// function EditToolbar(props) {
	// 	return (
	// 		<GridToolbarContainer>
	// 			<Button
	// 				color="primary"
	// 				startIcon={<AddIcon />}
	// 				onClick={() => {
	// 					setOpenDialogBox(true);
	// 					setButtonSelected("Add Leave Type");
	// 					setButtonName("Add Leave Type");
	// 					setFestivalEditRecord(false);
	// 				}}
	// 			>
	// 				Add record
	// 			</Button>
	// 		</GridToolbarContainer>
	// 	);
	// }

	return (
    <Box>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <>
          {adminLogin ? (
            <CompanyLeaveBox>
              <AddLeaveTypeButton
                onClick={() => {
                  setOpenDialogBox(true);
                  setButtonSelected("Add Leave Type");
                  setButtonName("Add Leave Type");
                  // setFestivalEditRecord(false);
                }}
                variant="contained"
              >
                + Add Leave Type
              </AddLeaveTypeButton>
            </CompanyLeaveBox>
          ) : null}
          {FormattedLeavesData.length !== 0 ? (
            <Box
              sx={{
                width: "100%",
                maxHeight: "fit-content",
                overflowX: "auto",
              }}
            >
              <Box
                sx={{
                  "@media (max-width: 600px)": {
                    width: "200%",
                  },
                }}
              >
                <DataGrids
                  disableColumnMenu
                  slots={{ toolbar: GridToolbar }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  rows={FormattedLeavesData}
                  columns={columnOfLeave}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize:
                          parseInt(
                            window.localStorage.getItem("paginationNo"),
                            10
                          ) || 10,
                      },
                    },
                  }}
                  onRowCountChange={(params) => {
                    if (params === 0) {
                      setDataGridHeight(true);
                    } else {
                      setDataGridHeight(false);
                    }
                  }}
                  onPaginationModelChange={(pageSize) => {
                    window.localStorage.setItem(
                      "paginationNo",
                      pageSize.pageSize
                    );
                  }}
                  onPageSizeChange={(pageSize) => {
                    window.localStorage.setItem("paginationNo", pageSize);
                  }}
                  pageSizeOptions={numberArray}
                  components={{
                    Toolbar: GridToolbar,
                    // noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  // slots={{
                  // 	toolbar: EditToolbar,
                  // }}
                  dataGridHeight={dataGridHeight}
                />
              </Box>
            </Box>
          ) : (
            <NoLeavesAddedTypography>No Leaves added!</NoLeavesAddedTypography>
          )}
        </>
      )}
      <Typography sx={{ color: "red" }}>{displayError}</Typography>
      <DialogOfCompanyAndLeavePolicy
        open={openDialogBox}
        setOpenDialogBox={setOpenDialogBox}
        buttonSelected={buttonSelected}
        buttonName={buttonName}
        selectedRecordId={selectedRecordId}
        FormattedLeavesData={FormattedLeavesData}
        leaveEditRecord={leaveEditRecord}
        // setFestivalEditRecord={setFestivalEditRecord}
        // setFestivalDeleteRecord={setFestivalDeleteRecord}
        setLeaveEditRecord={setLeaveEditRecord}
        leaveDeleteRecord={leaveDeleteRecord}
        setLeaveDeleteRecord={setLeaveDeleteRecord}
      />
    </Box>
  );
}

export default CompanyLeaves;
