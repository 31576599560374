import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import {
    GridToolbar,
} from "@mui/x-data-grid";

import Loader from '../Loader/Loader';
import { numberArray } from "../Constant/constant";
import { getNotificationByEmpId } from '../../Services/DashboardServices';
import { MainBox, InnerBoxOne, LoadingBox, TitleTypography, DataGrids } from "../../Styles/Notification/NotificationStyle"

function NotificationDetail() {
    const user = useSelector((state) => state.selectedActiveView.role);
    const currentEmployeeId = useSelector(
        (state) => state.addEmployee.current?.empId
    );
    // const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
    const currentAdminId = useSelector(
        (state) => state.adminData.adminData?.empId
    );
    const employeeDisplay = user === "employee";
    const adminLogin = user === "admin";
    const empId = employeeDisplay ? currentEmployeeId : currentAdminId;

    const [notifications, setNotifications] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [dataGridHeight, setDataGridHeight] = useState(false);

    const rows = notifications?.map((item, index) => {
        return {
            id: index + 1,
            subject: item?.type,
            description: item?.message,
            isRead: item?.user?.find((item) => item?.empId === empId)?.isRead
        }
    });

    const columns = [
        {
            field: "subject", headerName: "Subject", flex: 1,
            renderCell: (params) => {
                const { isRead } = params.row;
                return (
                    <Typography sx={{ fontWeight: isRead ? "normal" : "bold" }}>
                        {params.value}
                    </Typography>
                );
            },
        },
        {
            field: "description", headerName: "Description", flex: 4,
            renderCell: (params) => {
                const { isRead } = params.row;
                return (
                    <Typography sx={{ fontWeight: isRead ? "normal" : "bold" }}>
                        {params.value}
                    </Typography>
                );
            },
        },
    ];

    const fetchNotification = async () => {
        const user = employeeDisplay ? "user" : adminLogin ? "admin" : "";
        const empId = employeeDisplay ? currentEmployeeId : currentAdminId;
        if (user && empId) {
            setIsLoading(true);
            try {
                const response = await getNotificationByEmpId(user, empId, pageNumber, pageSize);
                if (response.status === 200 || response.status === 201) {
                    setIsLoading(false);
                    setNotifications((prevNotifications) => [
                        ...response.data.notifications
                    ]);
                    setTotalCount(response.data.totalCount);
                    setPageSize(response?.data?.pageSize || pageSize)
                    setPageNumber(response?.data?.pageNumber || pageNumber)
                } else {
                    setIsLoading(false);
                    setNotifications([]);
                    setTotalCount(0);
                    setPageSize(10);
                    setPageNumber(1);
                }
            } catch (error) {
                setIsLoading(false);
                console.log('error:', error);
            }
        }
    };

    useEffect(() => {
        fetchNotification();
        // eslint-disable-next-line
    }, [pageNumber, pageSize]);

    return (
        <Box>
            <MainBox>
                <InnerBoxOne bottom={0}>
                    <TitleTypography variant="h6">
                        Notifications
                    </TitleTypography>
                </InnerBoxOne>
                {isLoading ? (<LoadingBox>
                    <Loader />
                </LoadingBox>) : (<Box sx={{ width: "100%", maxHeight: "fit-content", overflowX: "auto" }}>
                    <Box
                        sx={{
                            "@media (max-width: 600px)": {
                                width: "260%",
                            },
                        }}
                    >
                        <DataGrids
                            disableColumnMenu
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                },
                            }}
                            aria-selected="false"
                            rows={rows ?? []}
                            rowCount={totalCount}
                            paginationMode="server"
                            getRowId={(row) => row.id}
                            columns={columns ?? []}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        startDate: false,
                                    },
                                },
                                pagination: {
                                    paginationModel: {
                                        pageSize: parseInt(
                                            window.localStorage.getItem("pageSize"),
                                            pageSize
                                        ) || pageSize,
                                        page: pageNumber - 1
                                    },
                                    // rowCount: totalCount
                                },
                            }}
                            onRowCountChange={(params) => {
                                if (params === 0) {
                                    setDataGridHeight(true);
                                } else {
                                    setDataGridHeight(false);
                                }
                            }}
                            onPaginationModelChange={(pageSize) => {
                                setPageSize(pageSize?.pageSize);
                                setPageNumber(pageSize?.page + 1);
                                window.localStorage.setItem("paginationNo", pageSize.pageSize);
                            }}
                            onPageSizeChange={(pageSize) => {
                                window.localStorage.setItem("paginationNo", pageSize);
                            }}
                            pageSizeOptions={numberArray}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            dataGridHeight={dataGridHeight}
                        />
                    </Box>
                </Box>)
                }
            </MainBox >
        </Box >
    )
}

export default NotificationDetail