import { Box, Button, DialogActions, IconButton, styled } from "@mui/material"

export const CloseIconButton = styled(IconButton)(({ isdarktheme, ismobile }) => ({
    color: isdarktheme === "true" ? "#e0e0e0e3" : "#544f5a",
    padding: ismobile === "true" ? "8px 5px" : "0px",
}))

export const MainBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "500px",
    "@media (max-width: 600px)": {
        maxWidth: "100%",
    },
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}))

export const InnerBoxTwo = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#ff000221",
    borderRadius: "1000px",
    padding: "8px",
}))

export const DialogsActions = styled(DialogActions)(() => ({
    background: "var(--highlight-color)",
    boxShadow: "0px 2px 10px var(--dark-highlight-color)",
}))

export const CancelButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    textTransform: "capitalize",
    fontWeight: "bold",
    marginRight: "10px",
    borderRadius: "5px",
}))