import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";

import { setDetailLeave } from "../../../Slices/selectedActive";
import { numberArray } from "../../Constant/constant";
import { DataGrids } from "../../../Styles/LeaveManagement/LeaveManagementStyle";

const LeaveDataGrid = ({ selectedButton, leaveData, EmpId }) => {
  const AllLeaveDat =
    useSelector((state) => state.employeeLeaveData.value) || [];
  const user = useSelector((state) => state.selectedActiveView.role);
  const [dataGridHeight, setDataGridHeight] = useState(false);
  const today = new Date();

  const oldLeaves = [];
  const upcomingLeaves = [];
  const employeeByLeaves = [];

  const adminLogin = user === "admin";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const inputDate = new Date();

  function isBefore(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1 < d2;
  }

  // Filter the leaves and assign id


  const formatDate = (date1) => {
    const dateString = date1.split("T");
    const [year, month, day] = dateString[0].split("-");
    const date = new Date(year, month - 1, day); // Month is zero-based
    const dayFormatted = String(date.getDate()).padStart(2, "0");
    const monthFormatted = String(date.getMonth() + 1).padStart(2, "0");
    const yearFormatted = date.getFullYear();
    return `${dayFormatted}/${monthFormatted}/${yearFormatted}`;
  };

  // function dateCompare(startDate, currentDate) {
  // 	const date1 = new Date(startDate);
  // 	const startDateday1 = String(date1.getDate()).padStart(2, "0");
  // 	const startDatemonth1 = String(date1.getMonth() + 1).padStart(2, "0");
  // 	const startDateyear1 = date1.getFullYear();
  // 	const date = new Date();
  // 	const currentDateday = String(date.getDate()).padStart(2, "0");
  // 	const currentDatemonth = String(date.getMonth() + 1).padStart(2, "0");
  // 	const currentDateyear = date.getFullYear();

  // 	if (
  // 		(startDateday1 >= currentDateday &&
  // 			startDatemonth1 >= currentDatemonth &&
  // 			startDateyear1 >= currentDateyear) ||
  // 		(startDateday1 <= currentDateday &&
  // 			startDatemonth1 > currentDatemonth &&
  // 			startDateyear1 >= currentDateyear)
  // 	) {
  // 		return true;
  // 	} else {
  // 		return false;
  // 	}
  // }

  const formattedLeaves = AllLeaveDat?.map((leave) => ({
    ...leave,
    id: leave._id,
  }));

  // Filter the leaves
  formattedLeaves?.forEach((leave) => {
    if (EmpId === leave?.empId) {
      employeeByLeaves.push(leave)
    }
    else {
      const endDate = new Date(leave.endDate);
      if (isBefore(endDate, today)) {
        oldLeaves.push(leave);
      } else {
        upcomingLeaves.push(leave);
      }
    }
  });

  // Sort the arrays by startDate in ascending order
  oldLeaves.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
  upcomingLeaves.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  employeeByLeaves.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

  const AllLeaveData = EmpId ? (employeeByLeaves ?? []) : (adminLogin
    ? selectedButton === "Upcoming Leaves"
      ? upcomingLeaves
      : oldLeaves
    : []);

  const handleShowLeaveDetail = (leaveDetails) => {
    navigate("/leave-details");
    dispatch(setDetailLeave(leaveDetails));
  };

  const capitalizeFirstLetter = (string) => {
    if (string && string.length > 0) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return string;
    }
  };

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "Pending":
        return "var(--pending-color)";
      case "Rejected":
        return "var(--reject-color)";
      case "Approved":
        return "var(--approved-color)";
      case "Cancelled":
        return "var(--cancel-color)";
      default:
        return "";
    }
  };

  const leaveDetailsColumns = useMemo(
    () => [
      // { field: "id", headerName: "No", flex: 1 },
      { field: "EmpName", headerName: "Emp Name", flex: 2 },
      { field: "From", headerName: "From", flex: 1.7 },
      { field: "To", headerName: "To", flex: 1.7 },
      { field: "Reason", headerName: "Reason", flex: 2 },
      { field: "ActionTaker", headerName: "Action Taker", flex: 1.5 },
      { field: "Type", headerName: "Type", flex: 1.5 },
      { field: "Days", headerName: "Day(s)", flex: 1 },
      {
        field: "Status",
        headerName: "Status",
        flex: 1.5,
        renderCell: (params) => (
          <Box
            sx={{
              minWidth: "70px",
              backgroundColor: getStatusBackgroundColor(params.value), // Use the value of status
              alignItems: "center",
              color: "#fff",
              borderRadius: "5px",
              height: "40px",
              display: "flex",
              padding: "0px 10px 0px 10px ",
              justifyContent: "center",
            }}
          >
            {params.value} {/* Render the status value */}
          </Box>
        ),
      },
    ],
    []
  );

  const EmpLeaveDetailsColumns = useMemo(
    () => [
      // { field: "id", headerName: "No", flex: 1 },
      { field: "From", headerName: "From", flex: 1.7 },
      { field: "To", headerName: "To", flex: 1.7 },
      { field: "Reason", headerName: "Reason", flex: 2 },
      { field: "Type", headerName: "Type", flex: 1.5 },
      { field: "ActionTaker", headerName: "Action Taker", flex: 1.5 },
      { field: "Days", headerName: "Day(s)", flex: 1 },
      {
        field: "Status",
        headerName: "Status",
        flex: 1.5,
        renderCell: (params) => (
          <Box
            sx={{
              minWidth: "70px",
              backgroundColor: getStatusBackgroundColor(params.value), // Use the value of status
              alignItems: "center",
              color: "#fff",
              borderRadius: "5px",
              height: "40px",
              display: "flex",
              padding: "0px 10px 0px 10px ",
              justifyContent: "center",
            }}
          >
            {params.value} {/* Render the status value */}
          </Box>
        ),
      },
    ],
    []
  );

  const FormattedLeavesDetails = AllLeaveData
    ? AllLeaveData.map((leaveData, index) => ({
      leave_id: leaveData,
      id: index + 1,
      EmpName: capitalizeFirstLetter(leaveData?.employeeName),
      From: formatDate(leaveData?.startDate),
      To: formatDate(leaveData?.endDate),
      Reason: leaveData?.reason,
      ActionTaker: leaveData?.actionTaker?.firstName,
      Type: leaveData?.leaveType?.leaveType,
      Days: leaveData?.duration,
      Status: leaveData?.status,
    }))
    : [];

  const EmpLeavesDetails = leaveData
    ? leaveData.map((leaveData, index) => ({
      leave_id: leaveData,
      id: index + 1,
      From: formatDate(leaveData?.startDate),
      To: formatDate(leaveData?.endDate),
      Reason: leaveData?.reason,
      ActionTaker: leaveData?.actionTaker?.firstName,
      Type: leaveData?.leaveType?.leaveType,
      Days: leaveData?.duration,
      Status: leaveData?.status,
    }))
    : [];

  return (
    <Box sx={{ overflowX: "auto" }}>
      <Box
        sx={{
          "@media (max-width: 600px)": {
            width: "300%",
          },
          maxHeight: "fit-content !important",
        }}
      >
        <DataGrids
          disableColumnMenu
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          aria-selected="false"
          rows={adminLogin ? FormattedLeavesDetails : EmpLeavesDetails}
          columns={adminLogin ? leaveDetailsColumns : EmpLeaveDetailsColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize:
                  parseInt(window.localStorage.getItem("paginationNo"), 10) ||
                  10,
              },
            },
          }}
          dataGridHeight={dataGridHeight}
          onRowCountChange={(params) => {
            if (params === 0) {
              setDataGridHeight(true);
            } else {
              setDataGridHeight(false);
            }
          }}
          onRowClick={(params) => {
            handleShowLeaveDetail(params.row.leave_id);
          }}
          onPaginationModelChange={(pageSize) => {
            window.localStorage.setItem("paginationNo", pageSize.pageSize);
          }}
          onPageSizeChange={(pageSize) => {
            window.localStorage.setItem("paginationNo", pageSize);
          }}
          pageSizeOptions={numberArray}
          components={{
            Toolbar: GridToolbar,
            // noRowsOverlay: CustomNoRowsOverlay,
          }}
        />
      </Box>
    </Box>
  );
};

export default LeaveDataGrid;
