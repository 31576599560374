import { Box, Card, TextField, styled } from "@mui/material"

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "96vh",
}))

export const MainBox = styled(Box)(() => ({
    padding: "22px 4px 8px 10px",
    height: "container"
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    borderBottom: "1px solid var(--table-border-color)",
    color: "var(--secondary-text-color)",
    marginBottom: "5px",
}))

export const InnerBoxTwo = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 5px 0px",
}))

export const MainCard = styled(Card)(() => ({
    boxShadow: 4,
    width: "100%",
    marginTop: "10px",
    background: "var(--background-table-sidebar-card-color)",
}))

export const InputTextField = styled(TextField)(() => ({
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
    {
        borderColor: "var(--secondary-text-color)",
    },
    svg: {
        fill: "var(--secondary-text-color)",
    },
}))

export const InnerBoxThree = styled(Box)(() => ({
    display: "flex",
    justifyContent: "flex-end"
}))