import { axiosInstance } from "./axiosInstance";

export const getLeavesData = () => axiosInstance.get("/user/employeeOnLeave");

export const upsertYearLeaveAccordian = (postData) =>
  axiosInstance.post("/admin/addYearAccordian", postData);

export const GetLeaveBalanceData = () => axiosInstance.get("/admin/LeavebalanceData");

export const getAllLeavesType = () => axiosInstance.get("/admin/getLeaveType");

export const upsertLeaveType = (postData) => axiosInstance.post("/admin/addLeaveType", postData);

export const editLeaveType = (postData) => axiosInstance.put("/admin/editLeaveType", postData);

export const deleteLeaveType = (_id) => axiosInstance.delete(`/admin/deleteLeaveType/${_id}`);

export const upsertAssignLeave = (postData) => axiosInstance.post("/admin/assignLeave", postData);

export const upsertMonthlyRecord = (postData) => axiosInstance.post("/admin/addMonthlyRecord", postData);

export const editMonthlyRecord = (postData) => axiosInstance.put("/admin/updateMonthlyRecord", postData);

export const getleaveBalanacesData = (data) => axiosInstance.get(`/admin/getLeaveBalanceMonthly/${data}`);

export const getLeavesDropdown = () => axiosInstance.get(`/admin/getLeavesDropdown`);

export const upsertLeave = (postData) => axiosInstance.post("/admin/leave", postData);

export const editLeave = (postData) => axiosInstance.post("/admin/updateLeave", postData);

export const getWorkingDaysMonthData = (selectedButton) => axiosInstance.get(`/admin/getLeaves/${selectedButton}`);

export const upsertRejectLeave = (postData) => axiosInstance.post("/admin/comment", postData);

export const upsertApproveLeave = (postData) => axiosInstance.post("/admin/comment", postData);

export const upsertCancelLeave = (postData) => axiosInstance.post("/admin/comment", postData);

export const getLeaveBalanace = (data, employeeEmpId) => axiosInstance.get(`/admin/getLeaveBalanceMonthly/${data}?employeeEmpId=${employeeEmpId}`);

export const upsertCompanyPolicy = (formData) => axiosInstance.post("/admin/upload-company-policy", formData);

export const upsertLeavePolicy = (formData) => axiosInstance.post("/admin/upload-leave-policy", formData);
