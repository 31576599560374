import { Box, Button, DialogActions, DialogTitle, Grid, IconButton, Typography, styled } from "@mui/material"
import DescriptionIcon from "@mui/icons-material/Description";

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "74vh",
}))

export const MainBox = styled(Box)(() => ({
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}))

export const DailyButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedbutton === "Daily"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "Daily" ? "2px solid #338BA8" : "none",
    margin: "0px 5px 0px 5px",
}))

export const MonthlyButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "10px",
    borderRadius: "0px",
    fontSize: "16px",
    letterSpacing: "1px",
    color:
        selectedbutton === "Monthly"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "Monthly"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const ExportButton = styled(Button)(() => ({
    marginRight: "10px",
    backgroundColor: "var(--secondary-color)",
    "&:hover": {
        backgroundColor: "var(--secondary-color)",
    },
}))

export const DailyMainBox = styled(Box)(({ ismobile }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: ismobile !== "true" && "center",
    flexDirection: ismobile === "true" ? "column" : "row",
    padding: "8px",
    fontSize: ismobile === "true" ? "18px" : "25px",
    marginBottom: "8px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    borderRadius: "5px",
    background: "var(--background-table-sidebar-card-color)",
    color: "var(--secondary-text-color)",
}))

export const DailyTableBox = styled(Box)(() => ({
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    borderRadius: "5px",
    background: "var(--background-table-sidebar-card-color)",
    padding: "0px 10px 2px 10px",
    marginBottom: "20px",
}))

export const MonthlyMainBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    padding: "8px",
    marginBottom: "8px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    borderRadius: "5px",
    background: "var(--background-table-sidebar-card-color)",
    color: "var(--secondary-text-color)",
    flexDirection: "column",
}))

export const GetFullYearTypography = styled(Typography)(({ ismobile }) => ({
    color: "var(--secondary-text-color)",
    display: "flex",
    fontSize: ismobile === "true" ? "20px" : "25px",
    justifyContent: "center",
}))

export const ButtonGrid = styled(Grid)(() => ({
    display: "flex",
    flexDirection: "row-reverse"
}))


export const MonthlyTableBox = styled(Box)(() => ({
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
    borderRadius: "5px",
    background: "var(--background-table-sidebar-card-color)",
    padding: "0px 10px 2px 10px",
}))

export const NoRecordFoundTypography = styled(Typography)(() => ({
    color: "var(--secondary-text-color)",
    display: "flex",
    justifyContent: "center",
}))

export const MainBoxDialog = styled(Box)(({ isdarktheme }) => ({
    background: isdarktheme === "true" ? "#121e2e" : "#FFFFFF"
}))

export const TitleDialog = styled(DialogTitle)(({ isdarktheme }) => ({
    fontWeight: "bold",
    color: isdarktheme === "true" ? "#ffffff" : "#121e2e",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
}))

export const InnerBoxOne = styled(Box)(() => ({
    display: "flex",
    alignItems: "center"
}))

export const DescriptionIcons = styled(DescriptionIcon)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}))

export const IconButtons = styled(IconButton)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#ffffff" : "#121e2e",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "10px",
    right: "-5px",
    fontWeight: "bold",
    textTransform: "capitalize",
    marginRight: "15px",
    fontSize: "2px",
    "&:hover": {
        background: "var(--highlight-color)",
    },
}))

export const DialogsActions = styled(DialogActions)(() => ({
    background: "var(--highlight-color)",
    boxShadow: "0px 2px 10px var(--dark-highlight-color)",
}))

export const CustomButton = styled(Button)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#FFFFFF",
    textTransform: "capitalize",
}))