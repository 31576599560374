import { Box, Button, IconButton, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    borderBottom: "1px solid var(--table-border-color)",
    color: "var(--secondary-text-color)",
    marginBottom: "20px",
    // '@media (max-width: 600px)': {
    //     flexDirection: "column",
    //     alignItems: "flex-end",
    // }
}))

export const UpcomingEventsButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "bold",
    borderRadius: "0px",
    color:
        selectedbutton === "Upcoming Events"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "Upcoming Events"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const CompletedEventsButton = styled(Button)(({ selectedbutton }) => ({
    fontWeight: "bold",
    borderRadius: "0px",
    color:
        selectedbutton === "Completed Events"
            ? "#338BA8"
            : "var(--secondary-text-color)",
    borderBottom:
        selectedbutton === "Completed Events"
            ? "2px solid #338BA8"
            : "none",
    margin: "0px 5px 0px 5px",
}))

export const AddEventBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    padding: "0px 10px 5px 0px",
    '@media (max-width: 600px)': {
       padding: "0px 5px 5px 0px",
    }
}))

export const NoDataAvailableTypography = styled(Typography)(() => ({
    textTransform: "capitalize",
    userSelect: "none"
}))

export const InnerBoxOne = styled(Box)(() => ({
    margin: "5px 5px 5px 5px",
    cursor: "pointer",
    "&:hover": {
        "& > div": {
            background: "var(--primary-highlight-color)",
            color: "var(--primary-color)",
        },
    },
}))

export const InnerBoxTwo = styled(Box)(() => ({
    borderRadius: "5px",
    minHeight: "160px",
    height: "18vh",
    background: "var(--background-table-sidebar-card-color)",
    padding: "5px 15px 5px 15px",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #ccc",
    color: "var(--secondary-text-color)",
    justifyContent: "center",
    position: "relative",
    "@media (max-width: 600px)": {
        height: "10vh",
        minHeight: "100px",
    },
}))

export const InnerBoxFour = styled(Box)(() => ({
    position: "absolute",
    top: "5px",
    right: "5px",
}))

export const ContentBox = styled(Box)(() => ({
    // Center content horizontally and vertically
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
}))

export const EventTitleBox = styled(Box)(() => ({
    fontWeight: "bold",
    textTransform: "capitalize",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    '@media (max-width: 600px)': {
        whiteSpace: "normal",
        width: "100%",
    },
    width: "150px",
}))

export const EventDateBox = styled(Box)(() => ({
    padding: "5px",
    textTransform: "capitalize",
}))

export const TextBox = styled(Box)(() => ({
    userSelect: "none",
    fontWeight: "bold",
    color: "var(--primary-color) !important",
    backgroundColor: "#5e6e825e",
    borderRadius: "13px",
    // marginTop: "4px",
    paddingTop: "3px !important",
    paddingBottom: "4px !important",
    paddingLeft: "15px !important",
    paddingRight: "15px !important",
}))

export const SideBox = styled(Box)(({ isdarktheme }) => ({
    position: "fixed",
    top: 0,
    right: 0,
    width: "40%",
    '@media (max-width: 600px)': {
        width: '100%',
      },
    height: "100vh",
    background: isdarktheme === "true" ? "#0b1727" : "#ffffff",
    borderRadius: "5px",
    zIndex: 999,
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
    overflowY: "auto",
}))

export const CloseIconButton = styled(IconButton)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "5px",
    right: "-5px",
    fontWeight: "bold",
    textTransform: "capitalize",
    marginRight: "15px",
    fontSize: "2px",
    "&:hover": {
        background: "var(--highlight-color)",
    },
}))

export const MainBoxDialog = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "500px",
}))


export const InnerBoxOneDialog = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "35px 0px",
}))

export const InnerBoxTwoDialog = styled(Box)(({ isdarktheme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: isdarktheme === "true"  ? "#ff000046" : "#ff000221",
    borderRadius: "1000px",
    padding: "8px",
    marginRight: "25px",
}))

export const SliderBox = styled(Box)(() => ({
    "@media (max-width: 600px)": {
        padding: "10px 0px 0px 0px",
        },
}))

export const InnerSliderBox = styled(Box)(() => ({
    "&::-webkit-scrollbar": {
        display: "none",
    },
    overflowY: "hidden",
}))

export const AddEventButtonBox = styled(Box)(() => ({
    marginBottom: "20px",
    paddingRight: "15px",
    display: "flex",
    justifyContent: "flex-end",
    '@media (max-width: 600px)': {
        paddingRight: "0px",
    },
}))

export const AddEventButton = styled(Button)(() => ({
    '@media (max-width: 600px)': {
        width: '100%',
    },
}))