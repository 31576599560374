import { axiosInstance } from './axiosInstance';

export const getAllRolesData = () => axiosInstance.get('/admin/getEmployeesRoles');

export const upsertAddGroup = (postData) => axiosInstance.post('/admin/addGroup', postData);

export const upsertRole = (postData) => axiosInstance.post('/admin/addRole', postData);

export const editRole = (postData) => axiosInstance.put('/admin/UpdateRole', postData);

export const getAllGroupData = () => axiosInstance.get('/admin/getGroup');

export const deleteRole = (deleteId) => axiosInstance.delete(`/admin/deleteRole/${deleteId}`);
