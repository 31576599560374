import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [], // Initial state for user data
  allEmployeeAttendenceData: [],
  projectsName: [],
  employeeEfficieny: [],
  profileImageChangeSlice: false,
};

const userDataSlice = createSlice({
  name: "employeeData",
  initialState,
  reducers: {
    setAllData: (state, action) => {
      state.value = action.payload;
    },
    setAllEmployeeAttendenceData: (state, action) => {
      state.allEmployeeAttendenceData = action.payload;
    },
    setProjectsName: (state, action) => {
      state.projectsName = action.payload;
    },
    setEmployeeEfficieny: (state, action) => {
      state.employeeEfficieny = action.payload;
    },
    setProfileImageChangeSlice: (state, action) => {
      state.profileImageChangeSlice = action.payload;
    },
    resetUserDataReducer: (state) => {
      return initialState;
    },
  },
});

export const {
  setAllData,
  setAllEmployeeAttendenceData,
  setProjectsName,
  setEmployeeEfficieny,
  setProfileImageChangeSlice,
  resetUserDataReducer,
} = userDataSlice.actions;

export default userDataSlice.reducer;
