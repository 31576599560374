import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  CardContent,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { getEmp } from "../../Services/EmployeeManageServices";
import { MainBox, CancelButton, CompletedButton, InnerBoxTwo, PendingButton, RenderComponentBox, ValueTypography, TitleTypography, InnerBoxThree, FirstNameBox, MainCard, CloseBox, ProjectTypography, TaskBox, InnerBoxOne } from "../../Styles/TaskManagement/EmplopyeeProfileStyle";

function EmployeeProfile({ onClose, name }) {
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

  const [record, setRecord] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [pendingOverDueToggle, setPendingOverDueToggle] = useState(false);
  const [pendingInProgressToggle, setPendingInProgressToggle] = useState(false);
  const [pendingHoldToggle, setPendingHoldToggle] = useState(false);
  const [pendingToDoToggle, setPendingToDoToggle] = useState(false);
  const [completedToggle, setCompletedToggle] = useState(false);
  const [overDueCompletedToggle, setOverDueCompletedToggle] = useState(false);
  const [cancelToggle, setCancelToggle] = useState(false);
  const [selectedButton, setSelectedButton] = useState("");

  const capitalizedFirstName = record?.FirstName
    ? record.FirstName.split(" ")
      .map((word, index) =>
        index === 0 ? word.charAt(0).toUpperCase() : word.charAt(0)
      )
      .join("")
    : "";

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const renderComponent = () => {
    if (!selectedButton) {
      handleButtonClick("Pending");
    }

    switch (selectedButton) {
      case "completed":
        return (
          <MainBox isdarktheme={isDarkTheme ? "true" : "false"}>
            {completedToggle ? (
              <>
                <Box>
                  <Box sx={{ margin: "10px", display: "flex" }}>
                    <Typography>
                      <ArrowDropDownIcon
                        onClick={() => setCompletedToggle(!completedToggle)}
                        style={{ cursor: "pointer" }}
                      />
                    </Typography>
                    <Typography>Completed</Typography>
                  </Box>
                  <TaskBox isdarktheme={isDarkTheme ? "true" : "false"}>
                    {taskData
                      .filter(
                        (item) =>
                          new Date(item.completedDate).getTime() <=
                          new Date(item.dueDate).getTime()
                      )
                      .map((item, index) => (
                        <Box
                          key={index}
                          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                          <Box key={index}>
                            <ProjectTypography>
                              {item.project}
                            </ProjectTypography>

                            <Typography
                              variant="body2"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <span style={{ flexShrink: 0 }}>
                                {item.ticketNo}:
                              </span>
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginLeft: "5px",
                                }}
                              >
                                {item.title}
                              </span>
                            </Typography>

                            <hr
                              style={{
                                borderTop: isDarkTheme
                                  ? "1px solid #e0e0e0"
                                  : "1px solid #677386",
                                marginBottom: "10px",
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                  </TaskBox>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ margin: "10px", display: "flex" }}>
                  <Typography>
                    <ArrowRightIcon
                      onClick={() => setCompletedToggle(!completedToggle)}
                      style={{ cursor: "pointer" }}
                    />
                  </Typography>
                  <Typography>Completed</Typography>
                </Box>
              </>
            )}
            {overDueCompletedToggle ? (
              <>
                <Box>
                  <Box sx={{ margin: "10px", display: "flex" }}>
                    <Typography>
                      <ArrowDropDownIcon
                        onClick={() =>
                          setOverDueCompletedToggle(!overDueCompletedToggle)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Typography>
                    <Typography>OverDue Completed</Typography>
                  </Box>
                  <TaskBox isdarktheme={isDarkTheme ? "true" : "false"}>
                    {taskData
                      .filter(
                        (item) =>
                          new Date(item.completedDate).getTime() >
                          new Date(item.dueDate).getTime()
                      )
                      .map((item, index) => (
                        <Box
                          key={index}
                          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                          <Box key={index}>
                            <ProjectTypography>
                              {item.project}
                            </ProjectTypography>

                            <Typography
                              variant="body2"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <span style={{ flexShrink: 0 }}>
                                {item.ticketNo}:
                              </span>
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginLeft: "5px",
                                }}
                              >
                                {item.title}
                              </span>
                            </Typography>

                            <hr
                              style={{
                                borderTop: isDarkTheme
                                  ? "1px solid #e0e0e0"
                                  : "1px solid #677386",
                                marginBottom: "10px",
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                  </TaskBox>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ margin: "10px", display: "flex" }}>
                  <Typography>
                    <ArrowRightIcon
                      onClick={() =>
                        setOverDueCompletedToggle(!overDueCompletedToggle)
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </Typography>
                  <Typography>OverDue Completed</Typography>
                </Box>
              </>
            )}
          </MainBox>
        );

      case "Pending":
        return (
          <MainBox isdarktheme={isDarkTheme ? "true" : "false"}>
            {pendingOverDueToggle ? (
              <>
                <Box>
                  <Box sx={{ margin: "10px", display: "flex" }}>
                    <Typography>
                      <ArrowDropDownIcon
                        onClick={() =>
                          setPendingOverDueToggle(!pendingOverDueToggle)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Typography>
                    <Typography>OverDue</Typography>
                  </Box>
                  <TaskBox isdarktheme={isDarkTheme ? "true" : "false"}>
                    {taskData
                      .filter(
                        (item) =>
                          item.status !== "Completed" &&
                          new Date(item.dueDate).getTime() <
                          new Date(item.date).getTime()
                      )
                      .map((item, index) => (
                        <Box
                          key={index}
                          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                          <Box key={index}>
                            <ProjectTypography>
                              {item.project}
                            </ProjectTypography>

                            <Typography
                              variant="body2"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <span style={{ flexShrink: 0 }}>
                                {item.ticketNo}:
                              </span>
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginLeft: "5px",
                                }}
                              >
                                {item.title}
                              </span>
                            </Typography>

                            <hr
                              style={{
                                borderTop: isDarkTheme
                                  ? "1px solid #e0e0e0"
                                  : "1px solid #677386",
                                marginBottom: "10px",
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                  </TaskBox>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ margin: "10px", display: "flex" }}>
                  <Typography>
                    <ArrowRightIcon
                      onClick={() =>
                        setPendingOverDueToggle(!pendingOverDueToggle)
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </Typography>
                  <Typography>OverDue</Typography>
                </Box>
              </>
            )}
            {pendingInProgressToggle ? (
              <>
                <Box>
                  <Box sx={{ margin: "10px", display: "flex" }}>
                    <Typography>
                      <ArrowDropDownIcon
                        onClick={() =>
                          setPendingInProgressToggle(!pendingInProgressToggle)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Typography>
                    <Typography>In Progress</Typography>
                  </Box>
                  <TaskBox isdarktheme={isDarkTheme ? "true" : "false"}>
                    {taskData
                      .filter((item) => item.status === "In Progress")
                      .map((item, index) => (
                        <Box
                          key={index}
                          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                          <Box key={index}>
                            <ProjectTypography>
                              {item.project}
                            </ProjectTypography>

                            <Typography
                              variant="body2"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <span style={{ flexShrink: 0 }}>
                                {item.ticketNo}:
                              </span>
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginLeft: "5px",
                                }}
                              >
                                {item.title}
                              </span>
                            </Typography>

                            <hr
                              style={{
                                borderTop: isDarkTheme
                                  ? "1px solid #e0e0e0"
                                  : "1px solid #677386",
                                marginBottom: "10px",
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                  </TaskBox>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ margin: "10px", display: "flex" }}>
                  <Typography>
                    <ArrowRightIcon
                      onClick={() =>
                        setPendingInProgressToggle(!pendingInProgressToggle)
                      }
                      style={{ cursor: "pointer" }}
                    />
                  </Typography>
                  <Typography>In Progress</Typography>
                </Box>
              </>
            )}
            {pendingHoldToggle ? (
              <>
                <Box>
                  <Box sx={{ margin: "10px", display: "flex" }}>
                    <Typography>
                      <ArrowDropDownIcon
                        onClick={() => setPendingHoldToggle(!pendingHoldToggle)}
                        style={{ cursor: "pointer" }}
                      />
                    </Typography>
                    <Typography>Hold</Typography>
                  </Box>
                  <TaskBox isdarktheme={isDarkTheme ? "true" : "false"}>
                    {taskData
                      .filter((item) => item.status === "Hold")
                      .map((item, index) => (
                        <Box
                          key={index}
                          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                          <Box key={index}>
                            <ProjectTypography>
                              {item.project}
                            </ProjectTypography>

                            <Typography
                              variant="body2"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <span style={{ flexShrink: 0 }}>
                                {item.ticketNo}:
                              </span>
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginLeft: "5px",
                                }}
                              >
                                {item.title}
                              </span>
                            </Typography>

                            <hr
                              style={{
                                borderTop: isDarkTheme
                                  ? "1px solid #e0e0e0"
                                  : "1px solid #677386",
                                marginBottom: "10px",
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                  </TaskBox>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ margin: "10px", display: "flex" }}>
                  <Typography>
                    <ArrowRightIcon
                      onClick={() => setPendingHoldToggle(!pendingHoldToggle)}
                      style={{ cursor: "pointer" }}
                    />
                  </Typography>
                  <Typography>Hold</Typography>
                </Box>
              </>
            )}
            {pendingToDoToggle ? (
              <>
                <Box>
                  <Box sx={{ margin: "10px", display: "flex" }}>
                    <Typography>
                      <ArrowDropDownIcon
                        onClick={() =>
                          setPendingToDoToggle(!setPendingToDoToggle)
                        }
                        style={{ cursor: "pointer" }}
                      />
                    </Typography>
                    <Typography>To Do</Typography>
                  </Box>
                  <TaskBox isdarktheme={isDarkTheme ? "true" : "false"}>
                    {taskData
                      .filter((item) => item.status === "To-Do")
                      .map((item, index) => (
                        <Box
                          key={index}
                          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                          <Box key={index}>
                            <ProjectTypography>
                              {item.project}
                            </ProjectTypography>

                            <Typography
                              variant="body2"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <span style={{ flexShrink: 0 }}>
                                {item.ticketNo}:
                              </span>
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginLeft: "5px",
                                }}
                              >
                                {item.title}
                              </span>
                            </Typography>

                            <hr
                              style={{
                                borderTop: isDarkTheme
                                  ? "1px solid #e0e0e0"
                                  : "1px solid #677386",
                                marginBottom: "10px",
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                  </TaskBox>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ margin: "10px", display: "flex" }}>
                  <Typography>
                    <ArrowRightIcon
                      onClick={() => setPendingToDoToggle(!pendingToDoToggle)}
                      style={{ cursor: "pointer" }}
                    />
                  </Typography>
                  <Typography>To Do</Typography>
                </Box>
              </>
            )}
          </MainBox>
        );

      case "cancel":
        return (
          <MainBox isdarktheme={isDarkTheme ? "true" : "false"}>
            {cancelToggle ? (
              <>
                <Box>
                  <Box sx={{ margin: "10px", display: "flex" }}>
                    <Typography>
                      <ArrowDropDownIcon
                        onClick={() => setCancelToggle(!cancelToggle)}
                        style={{ cursor: "pointer" }}
                      />
                    </Typography>
                    <Typography>Cancel</Typography>
                  </Box>
                  <TaskBox isdarktheme={isDarkTheme ? "true" : "false"}>
                    {taskData
                      .filter((item) => item.status === "Cancelled")
                      .map((item, index) => (
                        <Box
                          key={index}
                          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                          <Box key={index}>
                            <ProjectTypography>
                              {item.project}
                            </ProjectTypography>

                            <Typography
                              variant="body2"
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <span style={{ flexShrink: 0 }}>
                                {item.ticketNo}:
                              </span>
                              <span
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  marginLeft: "5px",
                                }}
                              >
                                {item.title}
                              </span>
                            </Typography>

                            <Typography
                              variant="body2"
                              sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                marginBottom: "5px",
                              }}
                            >
                              Description: {item.description}
                            </Typography>
                            <hr
                              style={{
                                borderTop: isDarkTheme
                                  ? "1px solid #e0e0e0"
                                  : "1px solid #677386",
                                marginBottom: "10px",
                              }}
                            />
                          </Box>
                        </Box>
                      ))}
                  </TaskBox>
                </Box>
              </>
            ) : (
              <>
                <Box sx={{ margin: "10px", display: "flex" }}>
                  <Typography>
                    <ArrowRightIcon
                      onClick={() => setCancelToggle(!cancelToggle)}
                      style={{ cursor: "pointer" }}
                    />
                  </Typography>
                  <Typography>Cancel</Typography>
                </Box>
              </>
            )}
          </MainBox>
        );

      default:
        return null;
    }
  };

  const memoizedgetEmployeeData = useMemo(() => {
    const getEmployeeData = async () => {
      try {
        // const response = await axios.get(
        //   `${CLOUD_FUNCTIONS_ORIGIN}/user/employeeDetail/${name}`,
        //   getHeader
        // );
        const response = await getEmp(name);
        if (response.status === 200 || response.status === 201) {
          setTaskData(response.data.taskData);
          setRecord(response.data.user);
        }
      } catch (error) {
        // if (error.response) {
        //   if (error.response.status === 401 || error.response.status === 403) {
        //     window.localStorage.removeItem("Token");
        //     navigate("/");
        //   }
        // }
      }
    };
    return getEmployeeData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    memoizedgetEmployeeData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InnerBoxOne isdarktheme={isDarkTheme ? "true" : "false"}>
      <CloseBox>
        <IconButton onClick={onClose}>
          <CloseIcon color="primary" />
        </IconButton>
      </CloseBox>

      <InnerBoxTwo isdarktheme={isDarkTheme ? "true" : "false"}>
        <MainCard isdarktheme={isDarkTheme ? "true" : "false"}>
          <CardContent>
            <Box sx={{ display: "flex" }}>
              <Box>
                <FirstNameBox isdarktheme={isDarkTheme ? "true" : "false"}>
                  {capitalizedFirstName}
                </FirstNameBox>
              </Box>
              <InnerBoxThree isdarktheme={isDarkTheme ? "true" : "false"}>
                <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
                  {record?.FirstName}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ padding: "22px 10px 0px 0px " }}>
                    <TitleTypography>
                      Role
                    </TitleTypography>
                    <ValueTypography>
                      {record?.Designation}
                    </ValueTypography>
                  </Box>
                  <Box sx={{ padding: "22px 10px 0px 10px " }}>
                    <TitleTypography>
                      Email
                    </TitleTypography>
                    <ValueTypography>
                      {record?.Email}
                    </ValueTypography>
                  </Box>
                  <Box sx={{ padding: "22px 10px 0px 10px " }}>
                    <TitleTypography>
                      Conatct No.
                    </TitleTypography>
                    <ValueTypography>
                      {record?.Contact_Number}
                    </ValueTypography>
                  </Box>
                </Box>
              </InnerBoxThree>
            </Box>
          </CardContent>
          <Box bottom={0} marginTop={2}>
            <PendingButton
              onClick={() => handleButtonClick("Pending")}
              isdarktheme={isDarkTheme ? "true" : "false"}
              selectedbutton={selectedButton}
            >
              Pending
            </PendingButton>
            <CompletedButton
              onClick={() => handleButtonClick("completed")}
              isdarktheme={isDarkTheme ? "true" : "false"}
              selectedbutton={selectedButton}
            >
              Completed
            </CompletedButton>
            <CancelButton
              onClick={() => handleButtonClick("cancel")}
              isdarktheme={isDarkTheme ? "true" : "false"}
              selectedbutton={selectedButton}
            >
              Cancel
            </CancelButton>
          </Box>
        </MainCard>
      </InnerBoxTwo>
      <RenderComponentBox isdarktheme={isDarkTheme ? "true" : "false"}>
        {renderComponent()}
      </RenderComponentBox>
    </InnerBoxOne>
  );
}

export default EmployeeProfile;
