
export const working_hours = 8;

export const overtimeHours = 20;

export const taskCategoryNames = [
  "Task",
  "Reopen-Bug",
  "Reopen-CR",
  "R&D",
  "Business Event/ Visit",
  "Personal Learning / Session",
  "Work With/ Helped to",
  "Internal Meeting",
  "Client Meeting",
  "Interview",
  "Client Interview",
  "Client Inquiry/ Quote",
  "HR Activity",
  "Partial/ Half-day",
  "Holiday/ Leave",
  "Client Tracker",
  "Training to",
  "POC/ Demo Creation",
  "Testing",
  "Others"
];

export const taskTypeOptions = ["Unplanned", "Planned"];

export const projectNames = ["Project A", "Project B", "Project C"];

export const taskStatusList = ["To-Do", "In Progress", "Completed", "Hold", "Cancelled"];

export const leaveTypeOptions = [
  "Paid Leave",
  "Unpaid Leave",
  "Casual Leave",
  "Sick Leave",
];

export const departmentNames = [
  "HR",
  "Designer",
  "Marketing",
  "Front-end Developer",
  "Back-end Developer",
  "Boards of Member",
];

export const designationNames = [
  "HR",
  "Manager",
  "UI & UX Designer",
  "Java Developer",
  "Jr.Developer",
];

export const jobCategory = ["Full time", "Part time"];

export const bloodGroupsCategory = [
  "AB+",
  "A+",
  "B+",
  "O+",
  "AB-",
  "A-",
  "B-",
  "O-",
];

export const genderCategory = ["Male", "Female", "Others"];

export const projectStatus = ["Active", "DeActive"]

export const jobRole = ["Project Manager", "Team Leader", "Senior developer", "Junior developer", "Interns"]
export const getHeader = {
  get headers() {
    return {
      Authorization: `${window.localStorage.getItem("Token")}`,
    };
  },
};

export const postHeader = {
  get headers() {
    return {
      "Content-Type": "application/json",
      Authorization: `${window.localStorage.getItem("Token")}`,
    };
  },
};

// export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
export const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"|,.<>/?-]).{8,}$/;

export const numberArray = [5, 10, 25, 50, 100];

export const priorityArray = ['Highest', 'High', 'Medium', 'Low', 'Lowest'];