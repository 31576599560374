import { Autocomplete, Box, Select, TextField, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    marginTop: "30px",
    marginLeft: "9px",
}))

export const EmpNameTextField = styled(TextField)(({ themechange }) => ({
    width: "80%",
    height: "55px",
    background: themechange === "true"
        ? "#142840"
        : "#ffffff",
    // for the border changes
    ".MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline ":
    {
        border: themechange === "true"
            ? " "
            : "#fffffff",
    },
}))

export const CustomSelect = styled(Select)(({ themechange }) => ({
    width: "80%",
    height: "55px",
    background: themechange === "true"
        ? "#142840"
        : "#ffffff",
}))

export const DOBTextField = styled(TextField)(({ themechange }) => ({
    width: "80%",
    height: "55px",
    color: "white",
    background: themechange === "true" ? "#142840" : "#ffffff",
    ".css-ca7kl0-MuiInputBase-root-MuiOutlinedInput-root": {
        color: "white",
    },
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
        filter: themechange === "true" ? "invert(1)" : "none",
    },
}))

export const JobTitleTextField = styled(TextField)(({ themechange }) => ({
    width: "80%",
    height: "55px",
    background: themechange === "true" ? "#142840" : "#ffffff",
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
        filter: themechange === "true" ? "invert(1)" : "none",
    },
}))


export const ReportingPersonAutocomplete = styled(Autocomplete)(() => ({
    ".cMuiButtonBase-root-MuiChip-root": {
        background: "var(--tag-background-color)",
    },
    ".MuiSvgIcon-root": {
        fill: "var(--tag-close-icon-color)",
    },
    ".MuiAutocomplete-tagSizeMedium": {
        color: "var(--primary-text-color)",
    },
    ".MuiAutocomplete-clearIndicator": {
        color: "var(--primary-text-color)",
    },
    width: "80%",
    // height: "55px",
}))

export const DateTextField = styled(TextField)(({ themechange }) => ({
    width: "80%",
    height: "55px",
    background: themechange === "true" ? "#142840" : "#ffffff",
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
        filter: themechange === "true" ? "invert(1)" : "none",
    },
}))


