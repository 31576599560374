import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Tooltip, Zoom, useMediaQuery, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { setEmployeeManagment } from "../../Slices/selectedActive";
import EmpDataGrid from "../AdminDashboard/Tables/EmpDataGrid";
import usePermissions from "../RolePermission/usePermissions";
import { MainBox, InnerBoxOne, ActiveEmployeesButton, ArchivedEmployeesButton, EmployeeButtonBox, SliderBox, InnerSliderBox } from '../../Styles/EmployeeManagement/EmpManagementStyle'

const EmpManagement = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { hasPermission } = usePermissions();
	const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

	const [selectedButton, setSelectedButton] = useState("Active Employees");
	const isMobile = useMediaQuery('(max-width:600px)');
	const scrollRef = useRef(null);

	const handleButtonClick = (buttonName) => {
		dispatch(setEmployeeManagment("true"));
		setSelectedButton(buttonName);
	};

	const handleAddEmployee = () => {
		navigate("/employee/add");
	};

	const getScrollAmount = () => {
		if (scrollRef.current) {
		  return scrollRef.current.offsetWidth * 1.05;
		}
		return 700;
	  };

	const scrollLeft = () => {
		if (scrollRef.current) {
		  scrollRef.current.scrollBy({
			left: -getScrollAmount(),
			behavior: "smooth",
		  });
		}
	  };

	  const scrollRight = () => {
		if (scrollRef.current) {
		  scrollRef.current.scrollBy({
			left: getScrollAmount(),
			behavior: "smooth",
		  });
		}
	  };

	return (
		<Box>
			<MainBox>
			{isMobile ? (
					<>
						<EmployeeButtonBox>
							{hasPermission('Add Employee') && selectedButton === "Active Employees" && (
								<Tooltip
									title="Add New Details"
									arrow
									disableInteractive
									TransitionComponent={Zoom}
								>
									<Button
										color="primary"
										variant="contained"
										onClick={handleAddEmployee}
									>
										+ Add Employee
									</Button>
								</Tooltip>
							)}
						</EmployeeButtonBox>
						<SliderBox
							display="flex"
							alignItems="center"
							justifyContent="center"
							flexGrow={1}
							overflow="hidden"
							position="relative"
						>
							<IconButton onClick={scrollLeft} style={{ marginRight: 12,
								color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
							 }}>
							<ChevronLeftIcon />
							</IconButton>
							<InnerBoxOne>
							<InnerSliderBox
								ref={scrollRef}
								display="flex"
								flexDirection="row"
								overflowX="auto"
							>
								<Box
									display="flex"
									flexDirection="row"
									flexShrink="0"
									minWidth="100%"
									justifyContent="center"
							>
							{hasPermission('Active Employees') && (
								<ActiveEmployeesButton
									onClick={() => handleButtonClick("Active Employees")}
									selectedbutton={selectedButton}
								>
									Active Employees
								</ActiveEmployeesButton>
							)}
							</Box>
							<Box
							display="flex"
							flexDirection="row"
							flexShrink="0"
							minWidth="100%"
							justifyContent="center"
							>
							{hasPermission('Archived Employees') && (
								<ArchivedEmployeesButton
									onClick={() => handleButtonClick("DeActive Employees")}
									selectedbutton={selectedButton}
								>
									Archived Employees
								</ArchivedEmployeesButton>
							)}
						</Box>
						</InnerSliderBox>
						</InnerBoxOne>
						<IconButton onClick={scrollRight} style={{ marginLeft: 12,
							color: isDarkTheme === "true" ? "#e0e0e0cc" : "#5e6e82",
						 }}>
						<ChevronRightIcon />
						</IconButton>
					</SliderBox>
					</>
				) : (
					<>
					<InnerBoxOne bottom={0}>
						<Box>
							{hasPermission('Active Employees') && <ActiveEmployeesButton
								onClick={() =>
									handleButtonClick("Active Employees")
								}
								selectedbutton={selectedButton}
							>
								Active Employees
							</ActiveEmployeesButton>}
							{hasPermission('Archived Employees') && <ArchivedEmployeesButton
								onClick={() =>
									handleButtonClick("DeActive Employees")
								}
								selectedbutton={selectedButton}
							>
								Archived Employees
							</ArchivedEmployeesButton>}
						</Box>
						{hasPermission('Add Employee') && selectedButton === "Active Employees" ? (
							<Box sx={{ paddingRight: "15px" }}>
								<Tooltip
									title="Add New Details"
									arrow
									disableInteractive
									TransitionComponent={Zoom}
								>
									<Button
										color="primary"
										variant="contained"
										onClick={handleAddEmployee}
									>
										+ Add Employee
									</Button>
								</Tooltip>
							</Box>
						) : null}
					</InnerBoxOne>
               </>
				)}
				{/* <Box
          sx={{
            marginTop: "10px",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.14) inset",
            borderRadius: "5px",
            background: "var(--background-table-sidebar-card-color)",
            padding: "0px 10px 2px 10px",
          }}
          overflow="auto"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ padding: "15px" }}>
              <SearchBar selectedButton={selectedButton} />
            </Box>
          </Box>
          <Box> */}
				{/* <EmpTable selectedButton={selectedButton} /> */}
				{/* <EmpDataGrid selectedButton={selectedButton} /> */}
				{selectedButton === 'Active Employees' && hasPermission('Active Employees') ? (
					<EmpDataGrid selectedButton={selectedButton} />
				) : selectedButton === 'DeActive Employees' && hasPermission('Archived Employees') ? (
					<EmpDataGrid selectedButton={selectedButton} />
				) : (
					<p>You do not have permission to view {selectedButton} data.</p>
				)}
				{/* </Box>
        </Box> */}
			</MainBox>
		</Box>
	);
};

export default EmpManagement;
