import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import TablePagination from "@mui/material/TablePagination";

import {
  empTaskTableHeadCell,
  empTaskTableBodyCell,
  tablePagination,
} from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import {
  capitalizeFirstLetter,
  formatDate,
  formatTimeWithoutSeconds,
} from "../../ReusableComponents/UtilityFunctions";
import { setIsShowTask } from "../../../Slices/taskSlice";
import { numberArray } from "../../Constant/constant";
import Loader from "../../Loader/Loader";
import { getAdminEmployeeTaskData, getEmployeeTaskData } from "../../../Services/TaskManageServices";
import { LoadingBox, MainBox, ShowMoreButton, NoDataFoundTypography, TableHeads, TableCells, NoDataBox, ListItemTexts } from "../../../Styles/AdminDashboard/EmpTaskTableStyle"

const EmpTaskTable = ({ showButtun }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.selectedActiveView.role);
  const employeeDisplay = user === "employee";
  const adminLogin = user === "admin";

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [taskDataList, setTaskDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function sortData(arr) {
    const sorted = arr.slice(0);
    sorted.sort((a, b) => {
      if (a.startDate === null) return 1;
      if (b.startDate === null) return -1;
      return 0;
    });
    return sorted;
  }

  const memoizedEmployeeTaskData = useMemo(() => {
    const getData = async () => {
      if (employeeDisplay) {
        setIsLoading(true);
        try {
          // const response = await axios.get(
          // 	`${CLOUD_FUNCTIONS_ORIGIN}/user/employeeTaskList`,
          // 	getHeader
          // );
          const response = await getEmployeeTaskData();
          if (response.status === 200) {
            setTaskDataList(response.data.employeeTaskList);
            setIsLoading(false);
          } else {
            setTaskDataList([]);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          // if (error.response) {
          // 	if (
          // 		error.response.status === 401 ||
          // 		error.response.status === 403
          // 	) {
          // 		window.localStorage.removeItem("Token");
          // 		navigate("/");
          // 	}
          // 	else if (error.response.status === 500) {
          // 		console.log(error.response.data.message);
          // 	}
          // }
        }
      }
      if (adminLogin) {
        setIsLoading(true);
        try {
          // const response = await axios.get(
          // 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/employeeTaskList`,
          // 	getHeader
          // );
          const response = await getAdminEmployeeTaskData();
          if (response.status === 200) {
            setTaskDataList(response.data.taskArray);
            setIsLoading(false);
          } else {
            setTaskDataList([]);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
          // if (error.response) {
          // 	if (
          // 		error.response.status === 401 ||
          // 		error.response.status === 403
          // 	) {
          // 		window.localStorage.removeItem("Token");
          // 		navigate("/");
          // 	}
          // 	else if (error.response.status === 500) {
          // 		console.log(error.response.data.message);
          // 	}
          // }
        }
      }
    };
    return getData;
  }, [adminLogin, employeeDisplay]);

  useEffect(() => {
    memoizedEmployeeTaskData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box sx={{ width: "100%", maxHeight: "fit-content", overflowX: 'auto' }}>
    <Box sx={{
      '@media (max-width: 600px)': {
        width: '300%',
      },
     }}>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <TableContainer>
          <Table>
            <TableHeads>
              <TableRow sx={{ textDecoration: "none" }}>
                {adminLogin ? (
                  <>
                    <TableCell sx={empTaskTableHeadCell} width={"21%"}>
                      Name
                    </TableCell>
                    <TableCell sx={empTaskTableHeadCell} width={"15%"}>
                      Ticket
                    </TableCell>
                    <TableCell sx={empTaskTableHeadCell} width={"15%"}>
                      Start Date
                    </TableCell>
                    <TableCell sx={empTaskTableHeadCell} width={"12%"}>
                      Planned Hours
                    </TableCell>
                    <TableCell sx={empTaskTableHeadCell} width={"12%"}>
                      Time Spent
                    </TableCell>
                  </>
                ) : null}
                {employeeDisplay ? (
                  <>
                    <TableCell sx={empTaskTableHeadCell} width={"15%"}>
                      Ticket
                    </TableCell>
                    <TableCell sx={empTaskTableHeadCell} width={"40%"}>
                      Task
                    </TableCell>
                    {/* <TableCell sx={empTaskTableHeadCell}>
											Project
										</TableCell> */}
                    <TableCell sx={empTaskTableHeadCell} width={"13%"}>
                      Status
                    </TableCell>
                    <TableCell sx={empTaskTableHeadCell} width={"12%"}>
                      Planned Hours
                    </TableCell>
                    <TableCell sx={empTaskTableHeadCell} width={"20%"}>
                      Due Date
                    </TableCell>
                  </>
                ) : null}
                {showButtun === true && adminLogin ? (
                  <>
                    <TableCell sx={empTaskTableHeadCell}>Title</TableCell>
                    <TableCell sx={empTaskTableHeadCell}>Due-Date</TableCell>
                    <TableCell sx={empTaskTableHeadCell}>Status</TableCell>
                  </>
                ) : null}
              </TableRow>
            </TableHeads>
            <TableBody>
              {sortData(taskDataList)
                .slice(startIndex, endIndex)
                .map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      maxHeight: "20px",
                      backgroundColor:
                        index % 2 === 1 ? "var(--highlight-color)" : "",
                      background: "var(--background-table-sidebar-card-color)",
                    }}
                  >
                    {adminLogin ? (
                      <>
                        <TableCells
                          emptasktablebodycell={empTaskTableBodyCell}
                        >
                          <ListItemTexts
                            onClick={() => {
                              navigate(`/task-management/${data?.empId}`);
                            }}
                            primary={capitalizeFirstLetter(data.employeeName)}
                          />
                        </TableCells>
                        <TableCells
                          emptasktablebodycell={empTaskTableBodyCell}
                        >
                          {data.task}
                        </TableCells>
                        <TableCells
                          emptasktablebodycell={empTaskTableBodyCell}
                        >
                          {data.startDate === null
                            ? "Out Of Task"
                            : formatDate(new Date(data.startDate))}
                        </TableCells>
                        <TableCells
                          emptasktablebodycell={empTaskTableBodyCell}
                        >
                          {data.plannedHours}
                        </TableCells>
                        <TableCells
                          emptasktablebodycell={empTaskTableBodyCell}
                        >
                          {formatTimeWithoutSeconds(data.totalTime / 1000)}
                        </TableCells>
                      </>
                    ) : null}
                    {employeeDisplay ? (
                      <>
                        {taskDataList.length > 0 ? (
                          <>
                            <TableCells
                              emptasktablebodycell={empTaskTableBodyCell}
                            >
                              {data.ticketNo}
                            </TableCells>
                            <TableCells
                              emptasktablebodycell={empTaskTableBodyCell}
                            >
                              {data.title}
                            </TableCells>
                            {/* <TableCell
															sx={{
																...empTaskTableBodyCell,
																fontWeight:
																	"500 !important",
															}}
														>
															{data.project}
														</TableCell> */}
                            <TableCells
                              emptasktablebodycell={empTaskTableBodyCell}
                            >
                              {data.status}
                            </TableCells>
                            <TableCells
                              emptasktablebodycell={empTaskTableBodyCell}
                            >
                              {data.plannedHours}
                            </TableCells>
                            <TableCells
                              emptasktablebodycell={empTaskTableBodyCell}
                            >
                              {new Date(data.dueDate)
                                .toISOString()
                                .slice(0, 10)
                                .split("-")
                                .reverse()
                                .join("-")}
                            </TableCells>
                          </>
                        ) : (
                          " No Task"
                        )}
                      </>
                    ) : null}
                    {showButtun === true && adminLogin ? (
                      <>
                        <TableCells
                          emptasktablebodycell={empTaskTableBodyCell}
                        >
                          {data.title}
                        </TableCells>
                        <TableCells
                          emptasktablebodycell={empTaskTableBodyCell}
                        >
                          {data.dueDate}
                        </TableCells>
                        <TableCells
                          emptasktablebodycell={empTaskTableBodyCell}
                        >
                          {data.status}
                        </TableCells>
                      </>
                    ) : null}
                  </TableRow>
                ))}
            </TableBody>

            {taskDataList.length === 0 ? (
              <NoDataBox>
                <NoDataFoundTypography>
                  No records found!
                </NoDataFoundTypography>
              </NoDataBox>
            ) : (
              <></>
            )}
          </Table>

          <MainBox
            adminlogin={`'${adminLogin}'`}
            showbuttun={`'${showButtun}'`}
          >
            {adminLogin ? (
              !showButtun ? (
                <>
                  <ShowMoreButton
                    variant="contained"
                    size="small"
                    onClick={() => {
                      dispatch(setIsShowTask(true));
                      navigate("/task-management");
                    }}
                  >
                    Show more...
                  </ShowMoreButton>
                </>
              ) : null
            ) : null}
            {taskDataList.length === 0 ? (
              <></>
            ) : (
              <TablePagination
                className="empTaskTable"
                rowsPerPageOptions={numberArray}
                component="div"
                count={taskDataList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  ...tablePagination,
                  marginBottom: "0px !important",
                }}
              />
            )}
          </MainBox>
        </TableContainer>
      )}
      </Box>
    </Box>
  );
};

export default EmpTaskTable;
