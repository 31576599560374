import React from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import { Box } from '@mui/material';

import { MainBox, GoHomeButton, LockButton, TitleTypography, ContentTypography } from '../../Styles/AccessDenied/AccessDeniedStyle'

function AccessDenied() {
    const navigate = useNavigate()
    const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);

    return (
        <MainBox>
            <Box>
                <Box>
                    <LockButton isdarktheme={isDarkTheme ? "true" : "false"}>
                        <LockIcon style={{ fontSize: "40px" }} />
                    </LockButton>
                </Box>
                <Box>
                    <TitleTypography variant='h4' isdarktheme={isDarkTheme ? "true" : "false"}>
                        Access Denied
                    </TitleTypography >
                    <Box sx={{ marginTop: "2%", marginBottom: "5%" }}>
                        <ContentTypography isdarktheme={isDarkTheme ? "true" : "false"} variant='h6' >
                            You don't have permission to access this page
                        </ContentTypography>
                        <ContentTypography variant='h6' isdarktheme={isDarkTheme ? "true" : "false"}>
                            contact in administrator to get permission or go the home page
                        </ContentTypography>
                        <ContentTypography variant='h6' isdarktheme={isDarkTheme ? "true" : "false"}>
                            and browse other page.
                        </ContentTypography>
                    </Box>
                </Box>
                <GoHomeButton
                    variant="contained"
                    onClick={() => navigate("/dashboard")}
                >
                    Go to home
                </GoHomeButton>
            </Box>
        </MainBox>
    )
}

export default AccessDenied

// <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginTop: "7%" }}>
//     <img alt='AccessDenied' style={{ height: '50%', width: 'auto', maxWidth: '100%', maxHeight: '100%' }} src='./access_denied.png' />
// </div>