import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
  calenderValue: '',
  selectedShowData: [],
};

const searchDataSlice = createSlice({
  name: "searchData",
  initialState,
  reducers: {
    setSearchData: (state, action) => {
      state.value = action.payload;
    },
    setCalenderValue: (state, action) => {
      state.calenderValue = action.payload;
    },
    setSelectedShowData: (state, action) => {
      state.selectedShowData = action.payload;
    },
    resetSearchDataReducer: (state) => {
      return initialState;
    },
  },
});

export const { setSearchData, setCalenderValue, setSelectedShowData, resetSearchDataReducer } =
  searchDataSlice.actions;

export default searchDataSlice.reducer;
