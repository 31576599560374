import { TimelineDot, Timeline } from "@mui/lab"
import { Box, Typography, styled } from "@mui/material"
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    display: "inline",
    fontWeight: "bold",
    fontSize: "130%",
}))

export const MainBox = styled(Box)(() => ({
    mt: 0.5,
    height: "325px",
    overflow: "auto"
}))

export const Timelines = styled(Timeline)(() => ({
    display: "flex",
    marginTop: "-5px",
    justifyContent: "flex-start",
    ".MuiTimelineItem-root::before": {
        content: "none",
    },
}))

export const TimelineDots = styled(TimelineDot)(() => ({
    borderColor: "var(--secondary-color)"
}))

export const AccessTimeIcons = styled(AccessTimeIcon)(() => ({
    fontSize: "12px",
    color: "var(--primary-text-color)",
    mr: 0.5,
}))

export const CustomTypography = styled(Typography)(() => ({
    fontSize: "12px",
    color: "var(--primary-text-color)",
}))