import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newTask: [],
  plannedHours: "",
  employeesNames: [],
  // taskData: [],
  isShowTask: false,
  updatedTask: {}
};

const taskSlice = createSlice({
  name: "taskData",
  initialState,
  reducers: {
    // setNewTask: (state, action) => {
    //   state.newTask = action.payload;
    // },

    // setPlannedHours: (state, action) => {
    //   state.plannedHours = action.payload;
    // },

    setEmployeesNames: (state, action) => {
      state.employeesNames = action.payload;
    },

    // setTaskData: (state, action) => {
    //   state.taskData = action.payload;
    // },

    setIsShowTask: (state, action) => {
      state.isShowTask = action.payload;
    },
    setUpdatedTask: (state, action) => {
      state.updatedTask = action.payload;
    },
    resetTaskSliceReducer: (state) => {
      return initialState;
    },
  },
});

export const { setEmployeesNames, setIsShowTask, setUpdatedTask, resetTaskSliceReducer } =
  taskSlice.actions;

export default taskSlice.reducer;
