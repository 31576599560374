import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Close } from "@mui/icons-material";

import { setAllLeaveData } from "../../Slices/leaveData";
import {
  setEditLeave,
  setDetailLeave,
  setUpdatedLeaveType,
  setisLeave,
} from "../../Slices/selectedActive";
import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import DialogComponent from '../ReusableComponents/DialogBoxComponents/DialogComponent'
import { errorMessage } from "../../Services/axiosInstance";
import { editLeave, upsertLeave } from "../../Services/LeaveManageServices";
import { CloseButton, ContinueButton, InnerBoxOne, CancelButtonDialog, DialogsActions, IconBox, TextTypography, MainBoxHeader } from "../../Styles/LeaveManagement/AddLeaveFormStyle"

const CustomDialogleave = ({
  open,
  setOpenDialog,
  dialogIcon,
  setIsLoading,
  dialogHeading,
  dialogDescription,
  leaveData,
  warning,
  setDisplayError
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const user = useSelector((state) => state.selectedActiveView.role);
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const LeavesType = useSelector(
    (state) => state.employeeLeaveData.leavesTypes
  );
  const isEditLeave = useSelector(
    (state) => state.selectedActiveView.editLeave
  );
  const EmployeeLogin = user === "employee";
  const adminLogin = user === "admin";

  const onClose = () => {
    setOpenDialog(false)
  }

  const applyForLeave = async () => {
    setIsLoading(true);
    setDisplayError('')

    try {
      const postData = JSON.stringify(leaveData)
      const response = !isEditLeave ? await upsertLeave(postData) : await editLeave(postData)
      if (response.status === 201 || response.status === 200) {
        setIsLoading(false);
        dispatch(setAllLeaveData(response.data.leave));
        if (!isEditLeave) {
          dispatch(setDetailLeave(leaveData));
          dispatch(setisLeave(true));
          if (EmployeeLogin) {
            navigate("/leaves");
          } else if (adminLogin) {
            navigate("/leave-management");
          }
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
        } else {
          const leave = LeavesType?.filter(
            (state) => state._id === leaveData.leaveType
          );
          dispatch(setUpdatedLeaveType(leave[0].leaveType));
          const typeOfLeave = LeavesType?.filter(
            (state) => state._id === leaveData.leaveType
          );
          const data = {
            _id: leaveData ? leaveData._id : "",
            empId: leaveData ? leaveData.empId : "",
            employeeName: leaveData ? leaveData.employeeName : "",
            leaveType: {
              _id: leaveData
                ? leaveData.leaveType._id === undefined
                  ? leaveData.leaveType
                  : leaveData.leaveType._id
                : "",
              leaveType: typeOfLeave[0].leaveType,
            },
            reason: leaveData ? leaveData.reason : "",
            startDate: leaveData ? leaveData.startDate : "",
            endDate: leaveData ? leaveData.endDate : "",
            leaveTime: leaveData ? leaveData.leaveTime : "",
            submitDate: leaveData ? leaveData.submitDate : "",
          };
          dialogDescription(data);
          dispatch(setToast(true));
          dispatch(setToastMsg(response.data.message));
          dispatch(setToastType("success"));
          dispatch(setEditLeave(false));
        }
      }

    } catch (error) {
      setIsLoading(false);
      setDisplayError(errorMessage ?? "");
      setOpenDialog(false);
    }
  };

  const renderForm = () => {
    return (<>
      <Box sx={{ background: isDarkTheme ? "#121e2e" : "#ffffff", padding: "8px" }}>
        <MainBoxHeader>
          <Box>
            <DialogTitle sx={{ fontWeight: "bold", padding:  "0px 0px" }}>
              <InnerBoxOne>
                <IconBox ismobile={isMobile ? "true" : "false"}>
                  {dialogIcon}
                </IconBox>
                <Box>
                  <Box sx={{ color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82" }}>{dialogHeading}</Box>
                </Box>
              </InnerBoxOne>
            </DialogTitle>
          </Box>
          <Box>
            <CloseButton
              onClick={onClose}
              isdarktheme={isDarkTheme ? "true" : "false"}
              ismobile={isMobile ? "true" : "false"}
            >
              <Close />
            </CloseButton>
          </Box>
        </MainBoxHeader>
        <DialogContent sx={{ padding: "0px 24px 5px 5px" }}>
          <Box>
            <Typography sx={{ color: isDarkTheme ? "#e0e0e0cc" : "#5e6e82" }}>
              {isEditLeave ? (
                <>Are you sure want to Update Leave ?</>
              ) : (
                dialogDescription
              )}
            </Typography>
            {warning?.map((item, index) => (
              <TextTypography
                key={index}
              >
                <span style={{ marginRight: "4px" }}>&#8226;</span>

                {item}
              </TextTypography>
            ))}
          </Box>
        </DialogContent>
        <DialogsActions>
          <CancelButtonDialog
            ismobile={isMobile ? "true" : "false"}
            onClick={onClose}
          >
            Cancel
          </CancelButtonDialog>
          <ContinueButton
            ismobile={isMobile ? "true" : "false"}
            variant="contained"
            onClick={() => {
              setOpenDialog(false);
              applyForLeave();
            }}
            component={Link}
          >
            Continue
          </ContinueButton>
        </DialogsActions>
      </Box>
    </>)
  }

  return (
    <DialogComponent renderChildern={renderForm} open={open} onClose={onClose} maxWidth="md" />
  );
};

export default CustomDialogleave;
