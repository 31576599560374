import { Box, Typography, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const LoadingBox = styled(Box)(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "65vh",
}))

export const MainBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 13px 10px -20px #111",
    borderBottom: "1px solid var(--table-border-color)",
    marginBottom: "20px",
}))

export const TitleTypography = styled(Typography)(() => ({
    color: "var(--primary-color)",
    fontWeight: "bold",
    marginLeft: "15px",
    '@media (max-width: 600px)': {
      marginLeft: "4px",
  },
}))

export const InnerBoxOne = styled(Box)(() => ({
    width: "100%",
    maxHeight: "fit-content"
}))

export const AddWorkFlowBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    paddingRight: "15px",
    '@media (max-width: 600px)': {
      paddingRight: "4px",
  },
}))

export const DataGrids = styled(DataGrid)(({ dataGridHeight }) => ({
    height: dataGridHeight ? "230px" : "fit-content",
    ".MuiDataGrid-filler": {
      height: "0px !important",
      display: "none",
    },
    color: "var(--primary-text-color)",
    textTransform: "capitalize",
    ".MuiDataGrid-columnHeaderTitle": {
      textTransform: "none !important",
    },
    backgroundColor: "var(--subAccordian-background-color)",
    ".MuiDataGrid-cell ": {
      cursor: "pointer",
      alignContent: "center",
    },
    ".MuiDataGrid-cell:focus": {
      outline: "none",
    },
    ".MuiDataGrid-columnHeader:focus": {
      outline: "none",
    },
    ".MuiInput-underline:before": {
      borderBottom: "1px solid var(--primary-text-color) !important",
    },
    ".MuiSvgIcon-fontSizeSmall": {
      fill: "var(--primary-text-color) !important",
    },
    ".MuiInputBase-input.MuiInput-input.MuiInputBase-inputTypeSearch.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd":
    {
      color: "var(--primary-text-color) !important",
    },
    ".MuiDataGrid-overlay": {
      color: "var(--primary-text-color) !important",
      backgroundColor: "var(--subAccordian-background-color)",
    },
    // ".MuiInput-underline:before": {
    //     borderBottom: "1px solid var(--primary-text-color) !important",
    // },
    // ".MuiSelect-icon": {
    //   fill: "var(--secondary-text-color)",
    // },
    // ".MuiTablePagination-root": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-root": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-cellContent": {
    //   color: "var(--secondary-text-color)",
    //   fontSize: "14px",
    // },
    // ".MuiDataGrid-columnHeader": {
    //   color: "var(--secondary-text-color)",
    //   fontSize: "18px",
    //   backgroundColor: "var(--primary-highlight-color)",
    //   width: "100vh",
    // },
    // ".MuiDataGrid-footerContainer": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-menuIconButton": {
    //   color: "var(--secondary-text-color)",
    // },
    // ".MuiDataGrid-sortIcon": {
    //   color: "var(--secondary-text-color)",
    //   visibility: true,
    // },
    // "MuiDataGrid-virtualScroller": { color: "red" },
    ".MuiDataGrid-actionsCell": { type: "actions" },
    // ".MuiDataGrid-columnHeader:focus": {
    //   outlineColor: "rgb(25, 118, 210)",
    //   outlineStyle: "solid",
    //   outlineWidth: "0px",
    // },
  
    //color: "var(--secondary-text-color)",
    // // overflow: overlay
    // ".MuiDataGrid-cell:focus": {
    //   outlineColor: "rgb(25, 118, 210)",
    //   outlineStyle: "solid",
    //   outlineWidth: "0px",
    // },
  }));
  
