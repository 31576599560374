import { axiosInstance } from './axiosInstance';

export const editProjects = (id, postData) => axiosInstance.put(`/admin/project/${id}`, postData);

export const upsertProjects = (postData) => axiosInstance.post(`/admin/project`, postData);

export const changeProjectStatus = (id) => axiosInstance.put(`/admin/updateProject`, id);

export const getAllProjectData = () => axiosInstance.get(`/admin/getProject`);

export const getProjectsName = () => axiosInstance.get(`/user/getProjectName`);

export const upsertProjectDocument = (project, formData) => axiosInstance.post(`/admin/upload-project-documentation?fileName=${project}`, formData);
