import React from "react";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { format } from "date-fns";

import { AccordionSummarys, ContentBox, AccordionsDetails, LeaveAccordianMainBox } from "../../Styles/LeaveManagement/LeaveManagementStyle";

const LeaveAccordian = ({ absentData }) => {

  const today = new Date();
  today.setDate(today.getDate());
  today.setUTCHours(0, 0, 0, 0);

  return (
    <LeaveAccordianMainBox>
      <Box>
        <Accordion sx={{ marginBottom: "10px" }}>
          <AccordionSummarys
            expandIcon={
              <ExpandMoreIcon style={{ color: "var(--primary-text-color)" }} />
            }
            aria-controls={`panel-content`}
            id={`panel-header`}
          >
            Employees Leave
          </AccordionSummarys>
          <AccordionsDetails>
            {absentData?.map((item, index) => {
              const startDate = new Date(item.startDate).toISOString().split('T')[0];
              const endDate = new Date(item.endDate).toISOString().split('T')[0];

              let leaveDateText;

              if (startDate === endDate) {
                if (startDate === today.toISOString().split('T')[0]) {
                  leaveDateText = "Today";
                } else if (startDate === new Date(new Date(today.toISOString().split('T')[0]).setDate(new Date(today.toISOString().split('T')[0]).getDate() + 1)).toISOString().split('T')[0]) {
                  leaveDateText = "Tomorrow";
                } else {
                  leaveDateText = format(new Date(startDate), "dd-MM-yyyy");
                }
              } else {
                leaveDateText = `${format(new Date(startDate), "dd-MM-yyyy")} to ${format(
                  new Date(endDate),
                  "dd-MM-yyyy"
                )}`;
              }

              return (
                <ContentBox
                  key={index}
                >
                  {/* <hr /> */}
                  <Box sx={{ paddingLeft: "8px" }}>
                    <strong>{item.employeeName}</strong>{" "}
                    <span style={{ textTransform: "none" }}>is on leave</span>{" "}
                    {leaveDateText === "Today" ||
                      leaveDateText === "Tomorrow" ? (
                      ""
                    ) : (
                      <span style={{ textTransform: "none" }}>from </span>
                    )}
                    <span style={{ textTransform: "lowercase" }}>
                      {leaveDateText}.
                    </span>{" "}
                  </Box>
                  {/* <hr /> */}
                </ContentBox>
              );
            })}
          </AccordionsDetails>
        </Accordion>
      </Box>
    </LeaveAccordianMainBox>
  );
};

export default LeaveAccordian;
