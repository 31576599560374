import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDarkTheme, setLightTheme } from "./Slices/blackThemeSlice";

export const useInitializeTheme = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		localStorage.removeItem("theme");

		const storedTheme = localStorage.getItem("is_JT_theme_Dark");

		if (storedTheme === null) {
			if (window.matchMedia) {
				if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
					dispatch(setDarkTheme());
					localStorage.setItem("is_JT_theme_Dark", "true");
				} else {
					dispatch(setLightTheme());
					localStorage.setItem("is_JT_theme_Dark", "false");
				}
			}
		} else {
			if (storedTheme === "true") {
				dispatch(setDarkTheme());
			} else {
				dispatch(setLightTheme());
			}
		}
	}, [dispatch]);
};
