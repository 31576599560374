import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Chip,
} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

import {
  set_Firstname,
  set_Department,
  set_Designation,
  set_jobCategory,
  set_jobDoj,
  set_Dob,
  set_BloodGroup,
  set_gender,
  setReportingPerson,
  setRole,
  setTrainingEndDate,
  setSpecialization,
} from "../../../Slices/employeeDetailSlice";
import ProfileImg from "../../../assets/img/icons/employeeProfileIcon.svg";
import { viewProfileSubtitle } from "../../ReusableComponents/CustomDesignMUI/CustomMUI";
import ExitDialog from "../ExitDialog";
import {
  bloodGroupsCategory,
  departmentNames,
  genderCategory,
  jobCategory,
} from "../../Constant/constant";
import { getEmployee, getSpecialization, addSpecialization } from "../../../Services/EmployeeManageServices";
import {
  MainBox,
  EmpNameTextField,
  CustomSelect,
  DOBTextField,
  JobTitleTextField,
  ReportingPersonAutocomplete,
  DateTextField,
} from "../../../Styles/DisplayEmployee/DisplayPersonalStyle";
import { FooterBox } from "../../../Styles/UpdateEmployee/UpdatePersonalStyle";
import {
  CancelButton,
  SaveNextButton,
} from "../../../Styles/UpdateEmployee/UpdateContactStyle";

const DisplayPersonal = (props) => {
  const { saveNextButtonCallback } = props;
  const dispatch = useDispatch();
  // const allData = useSelector((state) => state.taskData.employeesNames);
  const roles = useSelector((state) => state.adminData.roles);
  const themeChange = useSelector((state) => state.theme.isDarkTheme);
  const employee = useSelector((state) => state.addEmployee.personal);

  const [checked, setChecked] = useState(false);
  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [errors, setErrors] = useState({});
  const [specializations, setSpecializations] = useState([]);
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const [newEmployeeData, setNewEmployeeData] = useState({
    personalFirstname: employee ? employee.firstName : "",
    jobDepartment: employee ? employee.department : "",
    jobDesignation: employee ? employee.designation : "",
    jobCategory: employee ? employee.jobCategory : "",
    jobDoj: employee ? employee.jobDoj : "",
    personalDob: employee ? employee.dob : "",
    personalBlood: employee ? employee.bloodGroup : "",
    gender: employee ? employee.gender : "",
    reportingPerson: employee ? employee.reportingPerson : [],
    role: employee ? employee.role : "",
    trainingEndDate: employee ? employee?.trainingEndDate : "",
    specializations: employee ? employee.specializations : [],
  });

  const reset = () => {
    setNewEmployeeData({
      personalFirstname: "",
      jobDepartment: "",
      jobDesignation: "",
      jobCategory: "",
      jobDoj: "",
      personalDob: "",
      personalBlood: "",
      gender: "",
      reportingPerson: [],
      role: "",
      trainingEndDate: "",
      specializations: [],
    });
  };

  const EmployeesValueSetter = (event, newValue) => {
    setNewEmployeeData((prevState) => ({
      ...prevState,
      reportingPerson: newValue,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCloseCancelDialog = async () => {
    setOpenExitDialog(false);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!newEmployeeData.personalFirstname) {
      newErrors.personalFirstname = "Employee name is required";
    } else if (!/^[a-zA-Z\s]*$/.test(newEmployeeData.personalFirstname)) {
      newErrors.personalFirstname =
        "Employee name should contain only alphabetic characters";
    }
    if (!newEmployeeData.jobDepartment) {
      newErrors.jobDepartment = "Department is required";
    }
    if (!newEmployeeData.jobDesignation) {
      newErrors.jobDesignation = "Job title is required";
    }
    if (!newEmployeeData.jobCategory) {
      newErrors.jobCategory = "Job category is required";
    }
    if (!newEmployeeData.jobDoj) {
      newErrors.jobDoj = "Date of joining is required";
    }
    const fourteenYearsAgo = new Date();
    fourteenYearsAgo.setFullYear(fourteenYearsAgo.getFullYear() - 14);
    if (!newEmployeeData.personalDob) {
      newErrors.personalDob = "Date of birth is required";
    } else if (new Date(newEmployeeData.personalDob) > fourteenYearsAgo) {
      newErrors.personalDob = "Date of Birth should be at least 14 years ago";
    } else if (newEmployeeData.personalDob > newEmployeeData.jobDoj) {
      newErrors.personalDob = "Date of Birth cannot be after Date of Joining";
    }
    if (checked) {
      if (!newEmployeeData.trainingEndDate) {
        newErrors.trainingEndDate = "End date of traning is required";
      } else if (newEmployeeData.trainingEndDate < newEmployeeData.jobDoj) {
        newErrors.trainingEndDate =
          "end date of traning cannot be before date of join";
      }
    }
    if (!newEmployeeData.personalBlood) {
      newErrors.personalBlood = "Blood group is required";
    }
    if (newEmployeeData.reportingPerson.length === 0) {
      newErrors.reportingPerson = "Reporting person is required";
    } else if (newEmployeeData.reportingPerson.length > 2) {
      newErrors.reportingPerson = "Reporting person cant be more than 2";
    }
    if (!newEmployeeData.gender) {
      newErrors.gender = "Gender is required";
    }
    if (!newEmployeeData.role) {
      newErrors.role = "Role is required";
    }
    setErrors(newErrors);
    const noErrors = Object.keys(newErrors).length === 0;
    if (noErrors) {
      return true;
    }
  };

  const fetchEmployeeName = async () => {
    try {
      const response = await getEmployee();
      if (response.status === 200 || response.status === 201) {
        setEmployeeList(response.data.namesData);
      } else {
        setEmployeeList([]);
      }
    } catch (error) { }
  };

  useEffect(() => {
    const fetchSpecializations = async () => {
      try {
        const response = await getSpecialization();

        if (response.status === 200) {
          setSpecializations(response.data);
        } else {
          setSpecializations([]);
        }
      } catch (error) {
        console.error("Error fetching specializations:", error);
      }
    };

    fetchSpecializations();
  }, []);

  const handleAddSpecialization = async (value) => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      setError("Specialization cannot be empty");
      return;
    }

    if (selectedSpecializations.some((s) => s.name === trimmedValue)) {
      setInputValue("");
      setError("This specialization already exists");
      return;
    }

    let newSpecialization = specializations.find(
      (s) => s.name === trimmedValue
    );

    if (!newSpecialization) {
      try {
        const response = await addSpecialization({ name: trimmedValue });
        if (response.status === 200 || response.status === 201) {
          newSpecialization = response.data;
          setSpecializations((prev) => [...prev, newSpecialization]);
        } else {
          setError(response.data.message || "Failed to add specialization");
          return;
        }
      } catch (error) {
        console.error("Error adding specialization:", error);
        setError("Failed to add specialization");
        return;
      }
    }
    setSelectedSpecializations((prev) => [...prev, newSpecialization]);
    setNewEmployeeData((prev) => ({
      ...prev,
      specializations: [...prev.specializations, newSpecialization],
    }));

    setInputValue("");
    setError("");
  };

  const handleDeleteSpecialization = (id) => () => {
    setSelectedSpecializations((prev) =>
      prev.filter((s) => s._id !== id)
    );
    setNewEmployeeData((prev) => ({
      ...prev,
      specializations: prev.specializations.filter((s) => s._id !== id),
    }));
  };

  useEffect(() => {
    fetchEmployeeName();
  }, []);

  return (
    <Box>
      <Box>
        <Box>
          <img width={"120px"} src={ProfileImg} alt="profile" />
          <Box></Box>
        </Box>
      </Box>
      <MainBox>
        <form>
          <Grid item container>
            {/* LEFT PART */}
            <Box sx={{ color: "" }}></Box>
            <Grid container xs={12} md={6} item rowSpacing={2}>
              <Grid item xs={12}>
                <Typography sx={viewProfileSubtitle}>Employee Name</Typography>
                <EmpNameTextField
                  themechange={themeChange ? "true" : "false"}
                  name="personalFirstname"
                  value={newEmployeeData.personalFirstname}
                  onChange={handleInputChange}
                />
                <Typography sx={{ color: "red" }}>
                  {errors.personalFirstname}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={viewProfileSubtitle}>Gender</Typography>
                <CustomSelect
                  themechange={themeChange ? "true" : "false"}
                  name="gender"
                  value={newEmployeeData.gender}
                  onChange={handleInputChange}
                >
                  {genderCategory?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </CustomSelect>
                <Typography sx={{ color: "red" }}>{errors.gender}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={viewProfileSubtitle}>Department</Typography>
                <CustomSelect
                  themechange={themeChange ? "true" : "false"}
                  name="jobDepartment"
                  value={newEmployeeData.jobDepartment}
                  onChange={handleInputChange}
                >
                  {departmentNames?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </CustomSelect>
                <Typography sx={{ color: "red" }}>
                  {errors.jobDepartment}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={viewProfileSubtitle}>Role</Typography>
                <CustomSelect
                  themechange={themeChange ? "true" : "false"}
                  name="role"
                  value={newEmployeeData.role}
                  onChange={handleInputChange}
                >
                  {roles?.map((option) => (
                    <MenuItem key={option.role} value={option.role}>
                      {option.role}
                    </MenuItem>
                  ))}
                </CustomSelect>
                <Typography sx={{ color: "red" }}>{errors.role}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={viewProfileSubtitle}>Job Category</Typography>
                <CustomSelect
                  themechange={themeChange ? "true" : "false"}
                  name="jobCategory"
                  value={newEmployeeData.jobCategory}
                  onChange={handleInputChange}
                >
                  {jobCategory?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </CustomSelect>
                <Typography sx={{ color: "red" }}>
                  {errors.jobCategory}
                </Typography>
              </Grid>
            </Grid>

            {/* RIGHT PART */}
            <Grid container xs={12} item md={6} rowSpacing={2}>
              <Grid item xs={12}>
                <Typography sx={viewProfileSubtitle}>Date of Birth</Typography>
                <DOBTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* Add your icon here if needed */}
                      </InputAdornment>
                    ),
                  }}
                  themechange={themeChange ? "true" : "false"}
                  name="personalDob"
                  value={newEmployeeData.personalDob}
                  type="date"
                  onChange={handleInputChange}
                />
                <Typography sx={{ color: "red" }}>
                  {errors.personalDob}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={viewProfileSubtitle}>Blood Group</Typography>
                <CustomSelect
                  themechange={themeChange ? "true" : "false"}
                  name="personalBlood"
                  value={newEmployeeData.personalBlood}
                  onChange={handleInputChange}
                >
                  {bloodGroupsCategory?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </CustomSelect>
                <Typography sx={{ color: "red" }}>
                  {errors.personalBlood}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={viewProfileSubtitle}>Job Title</Typography>
                <JobTitleTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* Add your icon here if needed */}
                      </InputAdornment>
                    ),
                  }}
                  themechange={themeChange ? "true" : "false"}
                  name="jobDesignation"
                  value={newEmployeeData.jobDesignation}
                  onChange={handleInputChange}
                />
                <Typography sx={{ color: "red" }}>
                  {errors.jobDesignation}
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <Typography sx={viewProfileSubtitle}>
                  Reporting Person
                </Typography>
                <ReportingPersonAutocomplete
                  fullWidth
                  multiple
                  limitTags={2}
                  options={employeeList?.filter(
                    (option) =>
                      !newEmployeeData.reportingPerson.includes(option)
                  )}
                  getOptionLabel={(option) => option.firstName}
                  value={newEmployeeData.reportingPerson}
                  onChange={EmployeesValueSetter}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        width: "inharit",
                        // height: "55px",
                        background: themeChange ? "#142840" : "#FFFFFF",
                      }}
                    />
                  )}
                />
                <Typography sx={{ color: "red" }}>
                  {errors.reportingPerson}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={viewProfileSubtitle}>
                  Date of Joining
                </Typography>
                <DateTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {/* Add your icon here if needed */}
                      </InputAdornment>
                    ),
                  }}
                  themechange={themeChange ? "true" : "false"}
                  name="jobDoj"
                  value={newEmployeeData.jobDoj}
                  type="date"
                  onChange={handleInputChange}
                />
                <Typography sx={{ color: "red" }}>{errors.jobDoj}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={10.8} md={10.8} mt={2}>
            <Typography sx={{ viewProfileSubtitle }}>
              Specializations
            </Typography>
            <Autocomplete
              freeSolo
              // options={specializations.map((option) => option.name)}
              options={specializations}
              getOptionLabel={(option) => typeof option === "string" ? option : option.name || ""}
              value={null}
              inputValue={inputValue}
              onInputChange={(event, newValue) => {
                setInputValue(newValue || "");
              }}
              onChange={(event, newValue) => {
                if (newValue && typeof newValue === "string") {
                  // handleAddSpecialization(newValue);
                }
                else if (newValue && !selectedSpecializations.some((s) => s._id === newValue._id)) {
                  handleAddSpecialization(newValue?.name);
                }
              }}
              clearIcon={inputValue && <ClearIcon />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Add or select specialization"
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleAddSpecialization(inputValue);
                    }
                  }}
                  helperText={error}
                  error={!!error}
                />
              )}
            />
            <Box mt={2}>
              {selectedSpecializations.map((specialization) => (
                <Chip
                  key={specialization._id}
                  label={specialization.name}
                  onDelete={handleDeleteSpecialization(specialization._id)}
                  style={{ margin: "4px" }}
                />
              ))}
            </Box>
          </Grid>
          <Grid container xs={12} item md={6}>
            <Grid item xs={12}>
              <Box sx={{ height: "55px", paddingTop: "10px" }}>
                <FormControlLabel
                  sx={{
                    ".MuiCheckbox-root": {
                      color: "var(--secondary-text-color)",
                    },
                  }}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                  }
                  label="Probation OR Intern"
                />
              </Box>
            </Grid>
            {checked ? (
              <Grid item xs={12}>
                <Typography sx={viewProfileSubtitle}>
                  Traning End Date
                </Typography>
                <DateTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  themechange={themeChange ? "true" : "false"}
                  name="trainingEndDate"
                  value={newEmployeeData.trainingEndDate}
                  type="date"
                  onChange={handleInputChange}
                />
                <Typography sx={{ color: "red" }}>
                  {errors.trainingEndDate}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </form>
        <Box>
          <FooterBox>
            <CancelButton
              onClick={() => {
                setOpenExitDialog(true);
              }}
            >
              Cancel
            </CancelButton>
            <ExitDialog
              open={openExitDialog}
              reset={reset}
              onClose={handleCloseCancelDialog}
            />
            <SaveNextButton
              variant="contained"
              onClick={() => {
                if (validateForm()) {
                  dispatch(set_Firstname(newEmployeeData.personalFirstname));
                  dispatch(set_Department(newEmployeeData.jobDepartment));
                  dispatch(set_Designation(newEmployeeData.jobDesignation));
                  dispatch(set_jobCategory(newEmployeeData.jobCategory));
                  dispatch(set_jobDoj(newEmployeeData.jobDoj));
                  dispatch(set_Dob(newEmployeeData.personalDob));
                  dispatch(set_BloodGroup(newEmployeeData.personalBlood));
                  dispatch(set_gender(newEmployeeData.gender));
                  dispatch(setReportingPerson(newEmployeeData.reportingPerson));
                  dispatch(setRole(newEmployeeData.role));
                  dispatch(setTrainingEndDate(newEmployeeData.trainingEndDate));
                  dispatch(setSpecialization(newEmployeeData.specializations));
                  saveNextButtonCallback();
                }
              }}
            >
              Next
            </SaveNextButton>
          </FooterBox>
        </Box>
      </MainBox>
    </Box>
  );
};
export default DisplayPersonal;
