import { Box, Button, ListItemText, TableCell, TableHead, Typography, styled } from "@mui/material";

export const LoadingBox = styled(Box)(() => ({
    height: "52vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}))

export const TableHeads = styled(TableHead)(() => ({
    background: "var(--primary-highlight-color)",
    height: "fit-content",
}))

export const TableCells = styled(TableCell)(({ emptasktablebodycell }) => ({
    ...emptasktablebodycell,
    fontWeight: "500 !important",
}))

export const ListItemTexts = styled(ListItemText)(() => ({
    width: "fit-content",
    cursor: "pointer",
}))

export const NoDataBox = styled(Box)(() => ({
    height: "63vh",
    display: "table-caption",
    captionSide: "bottom",
    alignContent: "center",
}))

export const NoDataFoundTypography = styled(Typography)(() => ({
    display: "flex",
    color: "var(--secondary-text-color)",
    justifyContent: "center",
}))

export const MainBox = styled(Box)(({ showbuttun, adminlogin }) => ({
    display: "flex ",
    flexDirection: "row",
    justifyContent: adminlogin
        ? showbuttun === true
            ? "flex-end"
            : "space-between"
        : "flex-end",
    alignItems: "center",
}))

export const ShowMoreButton = styled(Button)(() => ({
    height: "fit-content",
    fontSize: "10px",
}))