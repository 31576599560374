import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";

import {
	formatTimeWithoutSeconds,
	formatDate,
} from "../../ReusableComponents/UtilityFunctions";
import { overtimeHours, working_hours } from "../../Constant/constant";
import {
	progessBarTitleBox,
	progessBarTitle,
	progessBarBox,
} from "../../../components/ReusableComponents/CustomDesignMUI/CustomMUI";
import { TitleTypography, OverTimeLinearsProgress, OverTimeBox, ThisMonthLinearsProgress, ThisMonthBox, MainBox, ThisWeekLinearsProgress, LinearsProgress, ThisWeekBox } from "../../../Styles/EmployeeManagement/Employee/StatProgressBarStyle"

const StatProgressBars = () => {
	const attendenceData = useSelector(
		(state) => state.addEmployee.employeeAllAttendenceData
	);
	const date = new Date();
	const calculateDailyWorkingHours = (records, targetDate) => {
		const filteredRecords = records.filter(
			(record) => record.date === targetDate
		);
		const totalHours = filteredRecords[0]?.dailytimer?.TotalTime;
		if (totalHours === undefined) {
			return `00:00`;
		}
		return formatTimeWithoutSeconds(totalHours / 1000);
	};

	const calculateDailyWorkingHoursInSec = (records, targetDate) => {
		const filteredRecords = records.filter(
			(record) => record.date === targetDate
		);
		const totalHours = filteredRecords[0]?.dailytimer?.TotalTime;
		if (totalHours === undefined) {
			return 0;
		}
		if (totalHours > working_hours * 3600 * 1000) {
			return working_hours * 3600;
		}
		return totalHours / 1000;
	};

	//   Function to calculate total working hours for the present week

	const getMondayDate = () => {
		const currentDate = new Date();
		const dayOfWeek = currentDate.getDay();
		const difference = dayOfWeek - 1;
		const mondayDate = new Date(currentDate);
		mondayDate.setDate(currentDate.getDate() - difference);
		return formatDate(mondayDate);
	};

	const filterRecordsByDateRange = (records) => {
		const formattedStartDate = getMondayDate();
		const formattedDate = formattedStartDate.split("-").reverse().join("-");
		const startDate = new Date(formattedDate);
		const endDate = new Date(startDate);
		endDate.setDate(endDate.getDate() + 6);
		const weeks = records.filter((record) => {
			const formattedDate = record?.date.split("-").reverse().join("-");
			const recordDate = new Date(formattedDate);
			return (
				(recordDate >= startDate &&
					recordDate <= endDate &&
					recordDate.getMonth() === startDate.getMonth() &&
					recordDate.getFullYear() === startDate.getFullYear()) ||
				(startDate.getMonth() !== endDate.getMonth() &&
					recordDate <= endDate &&
					recordDate.getMonth() === endDate.getMonth() &&
					recordDate.getFullYear() === endDate.getFullYear())
			);
		});

		const totalHours = weeks.reduce((total, record) => {
			const weeklyTotalHours =
				total + (record?.dailytimer?.TotalTime || 0);
			return weeklyTotalHours;
		}, 0);

		return totalHours / 1000;
	};

	const filterRecordsByMonth = (records) => {
		const currentDate = new Date();
		const firstDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		);
		const monthRecords = records?.filter((record) => {
			const formattedDate = record?.date?.split("-").reverse().join("-");
			const recordDate = new Date(formattedDate);
			return (
				recordDate >= firstDayOfMonth &&
				(recordDate.getDate() <= lastDayOfMonth.getDate() ||
					recordDate <= lastDayOfMonth) &&
				recordDate.getMonth() === firstDayOfMonth.getMonth() &&
				recordDate.getFullYear() === firstDayOfMonth.getFullYear()
			);
		});

		const totalHours = monthRecords?.reduce((total, record) => {
			const monthlyTotalHours =
				total + (record?.dailytimer?.TotalTime || 0);
			return monthlyTotalHours;
		}, 0);
		return totalHours / 1000;
	};
	const totalWrokingHoursInMonth = () => {
		const currentDate = new Date();
		const firstDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		);
		const startDate = new Date(firstDayOfMonth);
		const endDate = new Date(lastDayOfMonth);

		const diff = (endDate - startDate) / (1000 * 60 * 60 * 24);
		const totalWorkingHoursMonth = (diff - 6 + 1) * working_hours;
		return totalWorkingHoursMonth;
	};

	const calculateOvertime = (records, value) => {
		const currentDate = new Date();
		const firstDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			1
		);
		const lastDayOfMonth = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth() + 1,
			0
		);
		const monthRecords = records?.filter((record) => {
			const formattedDate = record?.date?.split("-").reverse().join("-");
			const recordDate = new Date(formattedDate);
			return (
				recordDate >= firstDayOfMonth &&
				(recordDate.getDate() <= lastDayOfMonth.getDate() ||
					recordDate <= lastDayOfMonth) &&
				recordDate.getMonth() === firstDayOfMonth.getMonth() &&
				recordDate.getFullYear() === firstDayOfMonth.getFullYear()
			);
		});

		const totalHours = monthRecords?.reduce((total, record) => {
			if (
				record?.dailytimer?.TotalTime >
				working_hours * 3600 * 1000 + 1800000
			) {
				const monthlyOverTimeTotalHours =
					total +
					(record?.dailytimer?.TotalTime -
						working_hours * 3600 * 1000);
				return monthlyOverTimeTotalHours;
			}
			return total;
		}, 0);
		if (value === 1) {
			return formatTimeWithoutSeconds(totalHours / 1000);
		} else if (value === 2) {
			const time = totalHours / (3600 * 1000);
			if (time > overtimeHours) {
				return overtimeHours;
			} else {
				return time;
			}
		}
	};
	const totalDaysHours = totalWrokingHoursInMonth();
	const totalworking = filterRecordsByMonth(attendenceData) / (60 * 60);
	const monthRecord = (totalworking / totalDaysHours) * 100;
	return (
		<Box>
			<TitleTypography>
				Statistics
			</TitleTypography>

			<MainBox progessbarbox={progessBarBox}>
				<Box sx={progessBarTitleBox}>
					<Typography sx={progessBarTitle}>Today</Typography>
					<Box sx={{ fontSize: "13px" }}>
						<span style={{ fontWeight: "500" }}>
							{calculateDailyWorkingHours(
								attendenceData,
								formatDate(date)
							)}
							/{working_hours}
						</span>{" "}
						<span>Hrs</span>
					</Box>
				</Box>
				<LinearsProgress
					variant="determinate"
					value={
						(calculateDailyWorkingHoursInSec(
							attendenceData,
							formatDate(date)
						) / (working_hours * 3600)) * 100
					}
				/>
			</MainBox>

			<ThisWeekBox progessbarbox={progessBarBox}>
				<Box sx={progessBarTitleBox}>
					<Typography sx={progessBarTitle}>This Week</Typography>
					<Box sx={{ fontSize: "13px" }}>
						<span style={{ fontWeight: "500" }}>
							{formatTimeWithoutSeconds(
								filterRecordsByDateRange(attendenceData)
							)}/{working_hours * 6}
						</span>{" "}
						<span>Hrs</span>
					</Box>
				</Box>
				<ThisWeekLinearsProgress
					variant="determinate"
					value={
						(filterRecordsByDateRange(attendenceData) /
							(working_hours * 6 * 3600)) *
							100 >
							100
							? 100
							: (filterRecordsByDateRange(attendenceData) /
								(working_hours * 6 * 3600)) *
							100
					}
				/>
			</ThisWeekBox>

			<ThisMonthBox progessbarbox={progessBarBox}>
				<Box sx={progessBarTitleBox}>
					<Typography sx={progessBarTitle}>This Month</Typography>
					<Box sx={{ fontSize: "13px" }}>
						<span style={{ fontWeight: "500" }}>
							{formatTimeWithoutSeconds(
								filterRecordsByMonth(attendenceData)
							)}
							/{totalWrokingHoursInMonth()}{" "}
						</span>{" "}
						<span>Hrs</span>
					</Box>
				</Box>
				<ThisMonthLinearsProgress
					variant="determinate"
					value={monthRecord > 100 ? 100 : monthRecord}
				/>
			</ThisMonthBox>

			<OverTimeBox progessbarbox={progessBarBox}>
				<Box sx={progessBarTitleBox}>
					<Typography sx={progessBarTitle}>Overtime</Typography>
					<Box sx={{ fontSize: "13px" }}>
						<span style={{ fontWeight: "500" }}>
							{calculateOvertime(attendenceData, 1)}/
							{overtimeHours}
						</span>{" "}
						<span>Hrs</span>
					</Box>
				</Box>
				<OverTimeLinearsProgress
					variant="determinate"
					value={
						(calculateOvertime(attendenceData, 2) / overtimeHours) *
							100 >
							100
							? 100
							: (calculateOvertime(attendenceData, 2) /
								overtimeHours) *
							100
					}
				/>
			</OverTimeBox>
		</Box>
	);
};

export default StatProgressBars;
