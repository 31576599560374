import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Avatar } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  GridToolbar,
} from "@mui/x-data-grid";

import {
  formatDate,
  formatTimeWithoutSeconds,
  getIcon,
} from "../../ReusableComponents/UtilityFunctions";
import { numberArray } from "../../Constant/constant";
import Loader from "../../Loader/Loader";
import TaskDialogBox from "../../TaskManagement/DetailTask";
import {
  getAdminEmployeeTaskData,
  getEmployeeTaskData,
} from "../../../Services/TaskManageServices";
import { EmpTaskLoadingBox, DataGrids, EmpTaskMainBox } from "../../../Styles/AdminDashboard/DashBoardStyle";

const EmpTaskDataGrid = ({ showButtun }) => {
  const navigate = useNavigate();
  const admin = useSelector((state) => state.adminData.adminData?.empId);
  const user = useSelector((state) => state.selectedActiveView.role);
  const employee = useSelector((state) => state.addEmployee.current?.empId);
  const employeeDisplay = user === "employee";
  const adminLogin = user === "admin";

  const [taskDataList, setTaskDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [viewer, setViewer] = useState("");
  const [dataGridHeight, setDataGridHeight] = useState(false);

  // const createMarkup = (html) => {
  //   return { __html: html };
  // };

  const removeHtmlTags = (str) => {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  };

  function handleDetailClick(id) {
    setOpenDialog(true);
    setTaskId(id);
  }

  function sortData(arr) {
    const sorted = arr.slice(0);
    sorted.sort((a, b) => {
      if (a.startDate === null) return 1;
      if (b.startDate === null) return -1;
      return 0;
    });
    return sorted;
  }

  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]}${name.split(" ")[1] ? name.split(" ")[1][0] : ""
        }`,
    };
  }

  const getStatusBackgroundColor = (status) => {
    switch (status) {
      case "In Progress":
        return "var(--pending-color)";
      case "Cancelled":
        return "var(--reject-color)";
      case "Completed":
        return "var(--approved-color)";
      case "Hold":
        return "var(--cancel-color)";
      case "To-Do":
        return "var(--primary-color)";
      default:
        return "var(--primary-color)";
    }
  };

  // function generateRandomColor() {
  // 	const letters = "0123456789ABCDEF";
  // 	let color = "#";
  // 	for (let i = 0; i < 6; i++) {
  // 		color += letters[Math.floor(Math.random() * 16)];
  // 	}
  // 	return color;
  // }

  const taskDataRows = sortData(taskDataList).map((data, index) => ({
    fullrecord: data,
    id: data.empId,
    empName: data.employeeName,
    ticketNo: data.task,
    description: removeHtmlTags(data.description),
    // description: data.description,
    startDate: data?.startDate ? formatDate(new Date(data.startDate)) : "-",
    plannedHour: data.plannedHours,
    timeSpent: formatTimeWithoutSeconds(data.totalTime / 1000),
    title: data.title,
    dueDate: data?.dueDate ? formatDate(new Date(data.dueDate)) : "-",
    status: data.status,
    statusValue: data?.statusValue,
    statusColor: data?.statusColor,
    priority: data.priority,
  }));

  const memoizedEmployeeTaskData = useMemo(() => {
    const getData = async () => {
      if (employeeDisplay) {
        setIsLoading(true);
        try {
          // const response = await axios.get(
          // 	`${CLOUD_FUNCTIONS_ORIGIN}/user/employeeTaskList`,
          // 	getHeader
          // );
          const response = await getEmployeeTaskData();
          if (response.status === 200) {
            setTaskDataList(response.data.employeeTaskList);
            setIsLoading(false);
          } else {
            setTaskDataList([]);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
      if (adminLogin) {
        setIsLoading(true);
        try {
          //   const response = await axios.get(
          //     `${CLOUD_FUNCTIONS_ORIGIN}/admin/employeeTaskList`,
          //     getHeader
          //   );
          const response = await getAdminEmployeeTaskData();
          if (response.status === 200) {
            setTaskDataList(response.data.taskArray);
            setIsLoading(false);
          } else {
            setTaskDataList([]);
            setIsLoading(false);
          }
        } catch (error) {
          setIsLoading(false);
        }
      }
    };
    return getData;
  }, [adminLogin, employeeDisplay]);

  const employeeColumns = useMemo(
    () => [
      // { field: "empName", headerName: "Name", flex: 1.4 },
      {
        field: "empName",
        headerName: "",
        flex: 0.4,
        renderCell: (params) => (
          <Tooltip
            title={params.value}
            arrow
            placement="bottom"
            slotProps={{
              popper: {
                sx: {
                  [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                  {
                    marginTop: "0px",
                  },
                  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                  {
                    marginBottom: "0px",
                  },
                  [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                  {
                    marginLeft: "0px",
                  },
                  [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                  {
                    marginRight: "0px",
                  },
                },
              },
            }}
          >
            <Avatar
              sx={{
                background: "var(--textProfile-background-color)",
                color: "var(--primary-text-color)",
                width: 32,
                height: 32,
                fontSize: "90%",
                border: "1px solid var(--primary-text-color)",
              }}
              {...stringAvatar(params.value)}
              onClick={(event) => {
                event.stopPropagation();
                navigate(`/task-management/${params?.id}`);
              }}
            />
          </Tooltip>
        ),
      },
      { field: "ticketNo", headerName: "Ticket", flex: 1.1 },
      { field: "title", headerName: "Title", flex: 3 },
      { field: "description", headerName: "Description", flex: 3 },
      //     {
      //       field: "description",
      //       headerName: "Description",
      //       flex: 3,
      //       renderCell: (params) => (
      //         <Box>
      //           <style>
      //             {`
      //   .overflow-ellipsis {
      //     white-space: nowrap;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //     display: block;
      //     width: 100%; /* Adjust the width as necessary */
      //   }

      //   .overflow-ellipsis * {
      //     margin: 0px !important;
      //   }

      //   .ql-syntax {
      //     margin: 0px !important;
      //   }
      // `}
      //           </style>
      //           <span
      //             className="overflow-ellipsis"
      //             style={{ display: "block" }} // Additional styles can be applied here
      //             dangerouslySetInnerHTML={createMarkup(params.value)}
      //           />
      //         </Box>
      //       ),
      //     },
      { field: "startDate", headerName: "Start Date", flex: 1.2 },
      { field: "dueDate", headerName: "Due Date", flex: 1.2 },
      { field: "plannedHour", headerName: "Planned Hours", flex: 1.3 },
      { field: "timeSpent", headerName: "Time Spent", flex: 1.2 },
      {
        field: "priority",
        headerName: "Priority",
        flex: 1.4,
        renderCell: (params) => (
          <Box sx={{ display: "flex", height: "25px" }}>
            <Typography sx={{ marginRight: "8px" }}>
              {getIcon(params.value)}
            </Typography>
            <Typography
              sx={{
                color: "var(--secondary-text-color) !important",
              }}
            >
              {params.value}
            </Typography>
          </Box>
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1.4,
        sortable: true,
        renderCell: (params) => {
          const { statusValue, statusColor } = params.row;
          return (
            <Box
              sx={{
                backgroundColor: (statusValue && statusValue !== "0") ? statusColor : getStatusBackgroundColor(params.value),
                minWidth: "70px",
                alignItems: "center",
                color: "#fff",
                borderRadius: "5px",
                height: "40px",
                display: "flex",
                padding: "0px 10px 0px 10px ",
                justifyContent: "center",
              }}
            >
              {params.value}
            </Box>
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (employeeDisplay) {
      setViewer(employee);
    } else if (adminLogin) {
      setViewer(admin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    memoizedEmployeeTaskData();
  }, [memoizedEmployeeTaskData]);

  return (
    <>
      {isLoading ? (
        <EmpTaskLoadingBox>
          <Loader />
        </EmpTaskLoadingBox>
      ) : (
        <EmpTaskMainBox>
          <DataGrids
            disableColumnMenu
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
              },
            }}
            aria-selected="false"
            // rows={[]}
            // columns={[]}
            rows={taskDataRows}
            columns={employeeColumns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  startDate: false,
                },
              },
              pagination: {
                paginationModel: {
                  pageSize:
                    parseInt(window.localStorage.getItem("paginationNo"), 10) ||
                    10,
                },
              },
            }}
            onRowCountChange={(params) => {
              if (params === 0) {
                setDataGridHeight(true);
              } else {
                setDataGridHeight(false);
              }
            }}
            onRowClick={(params) => {
              // navigate(`/task-management/${params?.id}`);
              handleDetailClick(params.row.ticketNo);
            }}
            onPaginationModelChange={(pageSize) => {
              window.localStorage.setItem("paginationNo", pageSize.pageSize);
            }}
            // onResize={(containerSize, event, details) => {
            // 	console.log(containerSize);
            // 	console.log(event);
            // 	console.log(details);
            // }}
            onPageSizeChange={(pageSize) => {
              window.localStorage.setItem("paginationNo", pageSize);
            }}
            pageSizeOptions={numberArray}
            components={{
              Toolbar: GridToolbar,
              // noRowsOverlay: CustomNoRowsOverlay,
            }}
            dataGridHeight={dataGridHeight}
          />
          <TaskDialogBox
            unapprovedTask={true}
            open={openDialog}
            setOpenDialog={setOpenDialog}
            taskId={taskId}
            viewer={viewer}
          />
        </EmpTaskMainBox>
      )}
    </>
  );
};

export default EmpTaskDataGrid;
