import { Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { useMediaQuery } from "@mui/material";

import { formatTimeWithoutSeconds } from "./UtilityFunctions";
import { TitleTypography, MainBox, CustomTypography, WorkingHourSquareIcon, BreakHourSquareIcon } from "../../Styles/EmployeeManagement/Employee/VerticalBarGraphStyle"

function Graph({ data }) {
  const svgRef = useRef();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  useEffect(() => {
    // Remove previous content to avoid duplication
    d3.select(svgRef.current).selectAll("*").remove();
    // set the dimensions and margins of the graph
    const margin = { top: 10, right: 30, bottom: 20, left: 10 };
    const width = isMobile ? 270 - margin.left - margin.right - 20 : 330 - margin.left - margin.right;
    const height = 250 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3
      .select(svgRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom + 40)
      .append("g")
      .attr("transform", `translate(${margin.left + 20},${margin.top + 28})`);

    const subgroups = Object.keys(data[0]).filter((key) => key !== "label");

    // List of groups = unique labels
    const groups = Array.from(new Set(data.map((d) => d.label)));
    const x = d3.scaleBand().domain(groups).range([0, width]).padding([0.5]);

    svg
      .append("g")
      .attr("class", "custom-text-group")
      .attr("transform", `translate(0, ${height})`) // Translate to the top
      .call(d3.axisTop(x).tickSize(2))
      .selectAll(".tick text") // Select all text elements of the ticks
      .attr("dy", "1.2rem") // Adjust the vertical position of the text
      .style("text-anchor", "middle") // Center the text horizontally
      .style("font-size", "12px")
      .attr("fill", "var(--secondary-text-color)");

    const y = d3
      .scaleLinear()
      .domain([0, 8])
      .range([height - 1, 0]); // Adjusted the range to start from the bottom
    const yAxis = svg.append("g").call(d3.axisLeft(y));
    yAxis
      .selectAll("*")
      // .style("fill", "var(--secondary-text-color)")
      .style("stroke", "var(--secondary-text-color)")
      .style("size", "")
      .style("stroke-width", "1.6px");

    svg
      .append("line")
      .attr("x1", 0)
      // .attr("y1", y(8)) // Set the y-coordinate for the starting point of the line
      .attr("x2", width - 30)
      // .attr("y2", y(8)) // Set the y-coordinate for the ending point of the line
      .attr("stroke", "var(--secondary-text-color)") // Set the color of the line
      .attr("stroke-dasharray", "2,2"); // Set the stroke-dasharray for a dotted line effect

    // Another scale for subgroup position
    const xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, 8])
      .padding([0.5]);

    svg
      .append("g")
      .selectAll("g")
      .data(data)
      .enter()
      .append("g")
      .attr(
        "transform",
        (d) => `translate(${x(d.label) + x.bandwidth() / 18}, 0)`
      )
      .selectAll("rect")
      .data((d) => subgroups.map((key) => ({ key, value: d[key] })))
      .enter()
      .append("rect")
      .attr(
        "x",
        (d, i) =>
          xSubgroup(d.key) -
          4 +
          i * (xSubgroup.bandwidth() + 20 / (subgroups.length - 0.2))
      )
      .attr("y", (d) => y(d.value))
      .attr("width", xSubgroup.bandwidth() * 6)
      .attr("height", (d) => height - y(d.value))

      .attr("fill", (d, i) => {
        if (i === 1) {
          return "var(--primary-color)";
        } else {
          // if (d.value <= 8) {
          return "var(--secondary-color)";
          // } 
          // else {
          //   const gradient = svg
          //     .append("linearGradient")
          //     .attr("id", "gradient-" + i) // Unique ID for each gradient
          //     .attr("gradientUnits", "userSpaceOnUse")
          //     .attr("x1", 0)
          //     .attr("y1", y(7)) // Start point (0, 8)
          //     .attr("x2", 0)
          //     .attr("y2", y(d.value)); // End point (8, d.value)

          //   gradient
          //     .append("stop")
          //     .attr("offset", "0%")
          //     .attr("stop-color", "var(--secondary-color)");

          //   gradient
          //     .append("stop")
          //     .attr("offset", "100%")
          //     .attr("stop-color", "var(--avgWorkingHrs-color)");

          //   return "url(#gradient-" + i + ")";
          // }
        }
      })
      .on("mouseover", function (event, d, i) {
        const tooltipText = `${formatTimeWithoutSeconds(
          d.value.toFixed(2) * 3600
        )} ${d.key}`;
        d3.select(this).append("title").text(tooltipText);
      })
      .on("mouseout", function () {
        d3.select(this).select("title").remove();
      });
          // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Box sx={{ marginBottom: "-20px" }}>
      <Box className="bar-chart">
        <TitleTypography>
          Weekly Records
        </TitleTypography>
        <MainBox
          className="chart-info-box"
        >
          {/* First box */}
          <Box sx={{ marginRight: "20px" }}>
            <CustomTypography>
              <WorkingHourSquareIcon
                fontSize="small"
              />
              : Working-Hours
            </CustomTypography>

            <CustomTypography>
              <BreakHourSquareIcon
                fontSize="small"
              />
              : Break-Hours
            </CustomTypography>
          </Box>
        </MainBox>

        <Box>
          <svg
            id="my_dataviz"
            style={{
              height: "290px",
            }}
            ref={svgRef}
          ></svg>
        </Box>
      </Box>
    </Box>
  );
}

export default Graph;
