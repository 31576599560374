import { Box, Button, Typography, styled } from "@mui/material"

export const MainBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: "center",
    marginTop: "8%"
}))

export const GoHomeButton = styled(Button)(() => ({
    fontWeight: "bold",
    backgroundColor: "var(--secondary-color)",
    color: "#ffffff",
    paddingBottom: "8px",
    paddingTop: "8px"
}))

export const LockButton = styled(Button)(({ isdarktheme }) => ({
    fontWeight: "bold",
    fontSize: "20px",
    marginBottom: "2%",
    color: isdarktheme === "true" ? "#e0e0e0e3" : "#eeeeee",
    borderRadius: "999px",
    borderColor: isdarktheme === "true" ? "#e0e0e0e3" : "#eeeeee",
    borderWidth: "3px",
    borderStyle: "solid",
    outline: "none",
    padding: "12px",
    "&:hover": {
        background: "var(--highlight-color)"
    },
    "&:hover, &:focus": {
        borderColor: "var(--highlight-color)",

    },
}))

export const TitleTypography = styled(Typography)(({ isdarktheme }) => ({
    fontWeight: "bold",
    paddingY: "6px",
    paddingX: "3px",
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

export const ContentTypography = styled(Typography)(({ isdarktheme }) => ({
    color: isdarktheme === "true" ? "#e0e0e0cc" : "#5e6e82",
}))

