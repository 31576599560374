import { axiosInstance } from './axiosInstance';

export const editEvent = (postData) => axiosInstance.put('/admin/editEvent', postData);

export const upsertEvent = (postData) => axiosInstance.post('/admin/addEvent', postData);

export const deleteEvent = (idToDelete) => axiosInstance.delete(`/admin/deleteEvent/${idToDelete}`);

export const getAllEvents = () => axiosInstance.get(`/admin/getEvent`);

export const upsertEventImage = (choosenEventImageToUploadName, formData) => axiosInstance.post(`/admin/event-image?fileName=${choosenEventImageToUploadName}`, formData);
