import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GridToolbar } from "@mui/x-data-grid";
import { Box, Button, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from '@mui/material/Tooltip';
import Zoom from "@mui/material/Zoom";

import RolesDialog from "./RolesDialog";
import { numberArray } from "../Constant/constant";
import { deleteRole, getAllRolesData } from '../../Services/ManageRoleServices'
import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";
import { InnerBoxOne, MainBox, ManageRoleDataGrid, TitleTypography, InnerMainBox, AddRoleButton } from "../../Styles/ManageRoles/ManageRolesStyle";
import usePermissions from "../RolePermission/usePermissions";
import "../../assets/styles/DataGridStyles.css";

function ManageRoles() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
	const { hasPermission } = usePermissions();

	const [openDialogBox, setOpenDialogBox] = useState(false);
	const [buttonSelected, setButtonSelected] = useState("");
	const [openDialogName, setOpenDialogName] = useState("");
	const [employeeData, setEmployeeData] = useState([]);
	const [selectedRecord, setSelectedRecord] = useState("");
	const [change, setChange] = useState(true);
	const [dataGridHeight, setDataGridHeight] = useState(false);
	const [roleDeleted, setRoleDeleted] = useState(false);

	const FormattedTaskData = employeeData?.map((task, index) => ({
		id: index + 1,
		role_Id: task?._id,
		role: task?.role,
		groupname: task?.groupName.groupName,
		groupId: task?.groupName._id,
		levelName: task?.levelName,
	}));

	const column = [
		// { field: "id", headerName: "No.", flex: 1 },
		{ field: "role", headerName: "Role", flex: 1 },
		{ field: "groupname", headerName: "Group", flex: 1 },
		{ field: "levelName", headerName: "Level", flex: 1 },
		{
			field: "actions",
			headerName: "Actions",
			flex: 0.7,
			renderCell: (params) => (
				<Box sx={{ display: "flex", gap: "2px" }}>
					{hasPermission('EditRole') && <Tooltip
						title="Edit"
						placement="left"
						arrow
						TransitionComponent={Zoom}
					>
						<IconButton
							sx={{
								color: isDarkTheme ? "#e0e0e0e3" : "#544f5a",
							}}
							variant="contained"
							onClick={() => {
								setOpenDialogBox(true);
								setButtonSelected("Update Role");
								setOpenDialogName("Update Role");
								setSelectedRecord(params.row.role);
							}}
						>
							<EditIcon />
						</IconButton>
					</Tooltip>}

					{hasPermission('DeleteRole') && <Tooltip
						title="Delete"
						placement="right"
						arrow
						TransitionComponent={Zoom}
					>
						<IconButton
							sx={{
								color: isDarkTheme ? "#d94a38" : "#c62828",
							}}
							variant="contained"
							onClick={() => handleDelete(params.row)}
						>
							<DeleteIcon />
						</IconButton>
					</Tooltip>}
				</Box>
			),
		},
	];

	const handleDelete = async (data) => {
		try {
			// const response = await axios.delete(
			// 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/deleteRole/${data.role_Id}`,
			// 	postHeader
			// );
			const response = await deleteRole(data?.role_Id)
			if (response.status === 200) {
				if (response.data.message === "Role deleted.") {
					dispatch(setToast(true));
					dispatch(setToastMsg(response.data.message));
					dispatch(setToastType("success"));
					setRoleDeleted(!roleDeleted);
				} else {
					dispatch(setToast(true));
					dispatch(setToastMsg(response.data.message));
					dispatch(setToastType("warning"));
				}
			}
		} catch (error) {
			if (error.response) {
				if (error.response.status === 404) {
					dispatch(setToast(true));
					dispatch(setToastMsg("Api not found!!"));
					dispatch(setToastType("error"));
				} else if (
					error.response.status === 400 ||
					error.response.status === 500
				) {
					dispatch(setToast(true));
					dispatch(setToastMsg(error.response.data.message));
					dispatch(setToastType("error"));
				} else if (
					error.response.status === 401 ||
					error.response.status === 403
				) {
					window.localStorage.removeItem("Token");
					navigate("/");
				}
			}
		}
	};

	const memoizedgetRolesData = useMemo(() => {
		const getRolesData = async () => {
			try {
				// const response = await axios.get(
				// 	`${CLOUD_FUNCTIONS_ORIGIN}/admin/getEmployeesRoles`,
				// 	getHeader
				// );
				const response = await getAllRolesData()
				if (response.status === 200) {
					setEmployeeData(response.data.employeeRecord);
				}
				else {
					setEmployeeData([])
				}
			} catch (error) {
			}
		};
		return getRolesData;
	}, []);

	useEffect(() => {
		memoizedgetRolesData();
		  // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [change, roleDeleted]);

	return (
		<Box>
			<MainBox bottom={0}
					>
				<TitleTypography variant="h6">
					Manage Roles
				</TitleTypography>
				<InnerMainBox>
					{hasPermission('Add Role') && <AddRoleButton
						variant="contained"
						color="primary"
						onClick={() => {
							setOpenDialogBox(true);
							setButtonSelected("Add Role");
							setOpenDialogName("Role Name");
						}}
					>
						+ Add Role
					</AddRoleButton>}

					{hasPermission('Add Group') && <Button
						variant="contained"
						color="primary"
						onClick={() => {
							setOpenDialogBox(true);
							setButtonSelected("Add Group");
							setOpenDialogName("Group Name");
						}}
						sx={{
							marginRight: "15px",
							'@media (max-width: 600px)': {
									display:"flex",
									justifyItems:"flex-end"
								},
							// background: "var(--primary-color)",
						}}
					>
						+ Add Group
					</Button>}

					{/* <Button
          onClick={() => {
            setOpenDialogBox(true);
            setButtonSelected("Add Level");
            setOpenDialogName("Level Name");
          }}
          variant="contained"
          sx={{ marginRight: "10px" }}
        >
          + Add Level
        </Button> */}
				</InnerMainBox>
			</MainBox>
			<InnerBoxOne sx={{ width: "100%", overflowX: 'auto'}}>
		      <Box
				sx={{
					'@media (max-width: 600px)': {
					width: '200%',
					},
				}}>
				<ManageRoleDataGrid
					disableColumnMenu
					slots={{ toolbar: GridToolbar }}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
						},
					}}
					rows={FormattedTaskData}
					columns={column}
					initialState={{
						pagination: {
							paginationModel: {
								pageSize:
									parseInt(
										window.localStorage.getItem(
											"paginationNo"
										),
										10
									) || 10,
							},
						},
					}}
					onRowCountChange={(params) => {
						if (params === 0) {
							setDataGridHeight(true);
						} else {
							setDataGridHeight(false);
						}
					}}
					// onRowClick={(params) => {
					//   setOpenDialogBox(true);
					//     setButtonSelected("Edit Role");
					//     setOpenDialogName("Edit Role");
					//     setSelectedRecord(params.row.id - 1)
					// }}
					onPaginationModelChange={(pageSize) => {
						window.localStorage.setItem(
							"paginationNo",
							pageSize.pageSize
						);
					}}
					onPageSizeChange={(pageSize) => {
						window.localStorage.setItem("paginationNo", pageSize);
					}}
					pageSizeOptions={numberArray}
					components={{
						Toolbar: GridToolbar,
						// noRowsOverlay: CustomNoRowsOverlay,
					}}
					dataGridHeight={dataGridHeight}
				/>
			 </Box>
			</InnerBoxOne>
			<RolesDialog
				selectedRecord={selectedRecord}
				FormattedTaskData={FormattedTaskData}
				buttonSelected={buttonSelected}
				open={openDialogBox}
				setOpenDialogBox={setOpenDialogBox}
				openDialogName={openDialogName}
				setChange={setChange}
				change={change}
			/>
		</Box>
	);
}

export default ManageRoles;
