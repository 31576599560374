import React from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";

import favicon from "../../assets/img/favicon.png";
import "../../Loader.css";

function Loader() {
  useEffect(() => {
  }, []);
  return (
    <Box className="parent-component">
      <Box className="tb-preloader-container">
        <Box id="preloader" className="tb-preloader">
          <Box id="preloader-in" className="tb-preloader-in">
            <Box className="loader"></Box>
            <Box className="logo-container">
              <img src={favicon} width="35" alt="loader-logo" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Loader;
