import { Alert, useMediaQuery } from "@mui/material";
import { useDispatch } from "react-redux";

import { setToast, setToastMsg, setToastType } from "../../Slices/toastSlice";

const CustomToast = (props) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleToastClose = () => {
    dispatch(setToast(false));
    dispatch(setToastMsg(""));
    dispatch(setToastType(""));
  };
  setTimeout(handleToastClose, 3000);

  return (
    <>
      <Alert
        variant="standard"
        severity={props.toastType}
        sx={{
          // position: "absolute",
          position: 'fixed',
          left: '50%',
          top: "15px",
          transform: 'translateX(-50%)',
          width: '90%',
          maxWidth: 400,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textTransform: 'capitalize',
          zIndex: 1301,
          fontSize: isMobile ? '0.90rem' : '0.875rem',
          paddingX: isMobile ? '6px' : '16px',
          paddingY: isMobile ? '2px' : '8px',
          '& .MuiAlert-message': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        onClose={handleToastClose}
      >
        {props.message}
      </Alert>
    </>
  );
};

export default CustomToast;

// title="Error"
// toastType="warning"
// toastType="info"
// toastType="success"
