import { CardContent, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

import Timer from "./Timer";
import AttendanceTableComp from "./AttendanceTableComp";
import StatProgressBars from "./StatProgressBars";
import TodayActivity from "./TodayActivity";
import VerticalBarGraph from "./VerticalBarGraph";
import DateRangePickerDialog from "./DateRangePickerDialog";
import Loader from "../../Loader/Loader";
import { MainBox, LoadingBox, MainCard, TitleTypography, InnerCardOne, InnerBoxOne, CustomButton } from "../../../Styles/EmployeeManagement/Employee/AttendenceStyle"
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// function ButtonField(props) {
//   const {
//     value,
//     setValue,
//     setOpen,
//     label,
//     id,
//     disabled,
//     InputProps: { ref } = {},
//     inputProps: { "aria-label": ariaLabel } = {},
//   } = props;

//   return (
//     <Button
//       variant={label ? "contained" : "outlined"}
//       id={id}
//       disabled={disabled}
//       ref={ref}
//       aria-label={ariaLabel}
//       onClick={() => {
//         if (value) {
//           setValue(null);
//           return;
//         }
//         setOpen?.((prev) => !prev);
//       }}
//     >
//       {label ? `Selected Month: ${label}` : "Month"}
//     </Button>
//   );
// }

// function ButtonDatePicker(props) {
//   const [open, setOpen] = React.useState(false);
//   const { value, setValue } = props;

//   return (
//     <DatePicker
//       slots={{ field: ButtonField, ...props.slots }}
//       slotProps={{ field: { setOpen, value, setValue } }}
//       {...props}
//       open={open}
//       onClose={() => setOpen(false)}
//       onOpen={() => setOpen(true)}
//     />
//   );
// }

function Attendence() {
  const isMobile = useMediaQuery('(max-width:600px)');

  const [dateRangeDialog, setDateRangeDialog] = useState(false);
  const [dateRange, setDateRange] = React.useState({});
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const [weeklyButtonVariant, setWeeklyButtonVariant] = useState("outlined");
  // const [weeklyData, setWeeklyData] = useState(false);
  // const [monthValue, setMonthValue] = React.useState(null);

  // const handleWeeklyButtonVariantChange = () => {
  //   setMonthValue(null);
  //   setWeeklyData(!weeklyData);
  //   if (weeklyButtonVariant === "outlined") {
  //     setWeeklyButtonVariant("contained");
  //   } else {
  //     setWeeklyButtonVariant("outlined");
  //   }
  // };

  // useEffect(() => {
  //   if (monthValue !== null) {
  //     setWeeklyButtonVariant("outlined");
  //     setWeeklyData(false);
  //   }
  // }, [monthValue]);

  useEffect(() => {
    if (
      !(Object.keys(dateRange).length === 0 && dateRange.constructor === Object)
    ) {
      const startDateFormatted = dayjs(dateRange.startDate).format(
        "DD/MM/YYYY"
      );
      const endDateFormatted = dayjs(dateRange.endDate).format("DD/MM/YYYY");
      setStartDate(startDateFormatted);
      setEndDate(endDateFormatted);
    }
  }, [dateRange]);

  return (
    <MainBox>
      {isLoading ? (
        <LoadingBox>
          <Loader />
        </LoadingBox>
      ) : (
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <MainCard>
              <CardContent>
                <Timer setIsLoading={setIsLoading} />
              </CardContent>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MainCard>
              <CardContent>
                <StatProgressBars />
              </CardContent>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MainCard>
              <CardContent>
                <TodayActivity />
              </CardContent>
            </MainCard>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <InnerCardOne>
              <InnerBoxOne sx={{
                display: isMobile && Object.keys(dateRange).length === 0 &&
                  dateRange.constructor === Object && "flex"
              }} ismobile={isMobile ? "true" : "false"}>
                <TitleTypography ismobile={isMobile ? "true" : "false"}>
                  Attendance Records
                </TitleTypography>
                {Object.keys(dateRange).length === 0 &&
                  dateRange.constructor === Object ? (
                  <>
                    <CustomButton
                      variant="outlined"
                      size="medium"
                      sx={{ justifyContent: "flex-end" }}
                      onClick={() => {
                        setDateRangeDialog(true);
                      }}
                    >
                      Filter
                    </CustomButton>
                  </>
                ) : (
                  <>
                    <CustomButton
                      ismobile={isMobile ? "true" : "false"}
                      variant="contained"
                      size="medium"
                      onClick={() => {
                        setDateRange({});
                        setStartDate("");
                        setEndDate("");
                      }}
                    >
                      {startDate === endDate
                        ? `Date: ${startDate}`
                        : `Date Range: ${startDate} to ${endDate}`}
                    </CustomButton>
                  </>
                )}
              </InnerBoxOne>
              <AttendanceTableComp
                // weeklyData={weeklyData}
                // monthValue={monthValue}
                startDate={startDate}
                endDate={endDate}
              />
            </InnerCardOne>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MainCard>
              <CardContent>
                <VerticalBarGraph />
              </CardContent>
            </MainCard>
          </Grid>
        </Grid>
      )}
      <DateRangePickerDialog
        open={dateRangeDialog}
        setOpenDialog={setDateRangeDialog}
        dialogHeading={"Date Range"}
        setDateRange={setDateRange}
      />
    </MainBox>
  );
}

export default Attendence;
